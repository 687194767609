import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { elevation, themed, colors } from '@atlaskit/theme';
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle';
import ChevronDownIcon from '@atlaskit/icon/glyph/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/glyph/chevron-up';

import Button from '@atlaskit/button';
import { H2 } from '../Typography';
import { Step } from './Stepper';

const StyledBox = styled.div`
  ${elevation.e100}
  background-color: ${() => themed({ light: 'white', dark: '#283447' })};
  border-radius: 3px;
  margin: 6px 0;
  padding: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ContentWrapper = styled.div`
  padding: 6px 0;
  text-align: left;
`;

const Title = styled.div`
  flex-grow: 2;
  text-align: left;
  padding: 0 6px;
  cursor: pointer;
`;

type Props = {
  step: Step,
  onSelectStep: Function,
};

export default ({ step, onSelectStep }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(step.isCurrent);
  const iconColor = () => (step.completed ? colors.green() : colors.N40);
  const toggleExpansion = () => {
    onSelectStep(step.key);
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(step.isCurrent);
  }, [step]);

  return (
    <StyledBox>
      <Header>
        <CheckCircleIcon label="completion" primaryColor={iconColor()} />
        <Title onClick={toggleExpansion}><H2 margin="4px 0">{ step.title }</H2></Title>
        {
          expanded
            ? (
              <Button
                appearance="subtle"
                spacing="none"
                iconBefore={<ChevronUpIcon label="expansion" />}
                onClick={toggleExpansion}
                testId="chevron-up-btn"
              />
            )
            : (
              <Button
                appearance="subtle"
                spacing="none"
                iconBefore={<ChevronDownIcon label="expansion" />}
                onClick={toggleExpansion}
                testId="chevron-down-btn"
              />
            )
        }
      </Header>
      {
        expanded
        && (
        <ContentWrapper>
          { step.content }
        </ContentWrapper>
        )
      }
    </StyledBox>
  );
};
