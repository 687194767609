import React from 'react';
import Button from '@atlaskit/button/new';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import { ErrorMessage } from '@atlaskit/form';
import { useHistory } from 'react-router-dom';
import { Radio } from '@atlaskit/radio';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { CreateTeamChannelSupplierMutation, useCreateTeamChannelSupplierMutation } from '../../graphql/types';
import { useAuth, clientSideLogoutTask } from '../../utils/useAuth';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import PlanUpgrade from '../Billing/PlanUpgrade';

export default (prop: { isDisabled: boolean }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [
    createTeamChannelSupplier,
    createTeamChannelSupplierState,
  ] = useCreateTeamChannelSupplierMutation();
  const { state: { currentTeamId }, dispatch } = useAuth();
  const [errorMsg, setErrorMsg] = React.useState('');
  const [name, setName] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [sourceIdentifier, setSourceIdentifier] = React.useState('');
  const [targetChannel, setTargetChannel] = React.useState('Synnex');
  const history = useHistory();

  const { isDisabled } = prop;

  const [disable, setDisable] = React.useState(false);
  const closeUpgrade = () => {
    setDisable(false);
  };

  const handleSupplierUpdate = (
    data: CreateTeamChannelSupplierMutation | null | undefined,
  ) => {
    try {
      const teamChannelSupplier = data?.CreateTeamChannelSupplier.teamChannelSupplier;
      const settings = JSON.parse(teamChannelSupplier!.settings);
      // update local state
      dispatch({
        type: 'addTeamChannelSupplier',
        addTeamChannelSupplierPayload: {
          id: teamChannelSupplier!.id,
          name: teamChannelSupplier!.name,
          onBoarded: teamChannelSupplier!.onBoarded,
          createdAt: teamChannelSupplier!.createdAt,
          updatedAt: teamChannelSupplier!.updatedAt,
          settings: {
            consumerKey: settings.consumerKey,
            consumerSecret: settings.consumerSecret,
            sourceIdentifier: settings.sourceIdentifier,
          },
        },
      });
    } catch (error) {
      // if encouter unexpected err,
      // we force user to relogin so can retrive the local state again
      // therefore, the frontend can have consistent data with backend.
      clientSideLogoutTask();
    }
  };

  const handleOnClose = () => {
    // clear up the data to allow next time open with a fresh look
    setUsername('');
    setErrorMsg('');
    // close the modal
    setIsOpen(false);
  };

  const handleNextButtonClick = async () => {
    // const channelId = getTargetChannelId(targetChannel);
    const teamChannelVariables = {
      teamId: currentTeamId!,
      name,
      tcsSettings: {
        consumerKey: username,
        consumerSecret: password,
        sourceIdentifier,
      },
    };
    await createTeamChannelSupplier({
      variables: teamChannelVariables,
    }).then(({ data }) => {
      handleSupplierUpdate(data);
      setIsOpen(false);
      // push user to onboarding
      history.push('/marketplace');
    }).catch((err) => setErrorMsg(graphQLErrorsReader(err)));
  };

  return (
    <>
      <Button
        onClick={() => (isDisabled === false ? setIsOpen(true) : setDisable(true))}
        appearance="default"
      >
        +
      </Button>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={handleOnClose}
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
          >
            <ModalHeader>
              <ModalTitle>{t('channels.sc.ctans')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                {t('channels.sc.psast')}
                <Radio
                  value="Synnex"
                  label="Synnex"
                  name="Synnex"
                  testId="Synnex"
                  isChecked={targetChannel === 'Synnex'}
                  onChange={() => {
                    setTargetChannel('Synnex');
                  }}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                />
              </p>
              <br />
              {targetChannel === 'Synnex' && (
                <>
                  <Textfield
                    value={name}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setName(e.target.value))}
                    placeholder={t('channels.sc.eysn')}
                  />
                  <br />
                  <Textfield
                    value={username}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setUsername(e.target.value))}
                    placeholder={t('channels.sc.eyck')}
                  />
                  <br />
                  <Textfield
                    value={password}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setPassword(e.target.value))}
                    placeholder={t('channels.sc.eycs')}
                  />
                  <br />
                  <Textfield
                    value={sourceIdentifier}
                    onChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => (setSourceIdentifier(e.target.value))}
                    placeholder={t('channels.sc.eysi')}
                  />
                </>
              )}
              {errorMsg
                && <ErrorMessage>{errorMsg}</ErrorMessage>}
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleNextButtonClick} isLoading={createTeamChannelSupplierState.loading} isDisabled={username.trim().length === 0}>{i18next.t('channels.mc.next') as string}</Button>
              <Button onClick={handleOnClose}>{i18next.t('channels.mc.cancel') as String}</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
