import styled from 'styled-components';

const ActionContainer = styled.div`
  padding:10px;
  font-size:0.825rem;
  font-weight:normal;
  line-height:20px;
`;

export default ActionContainer;
