import React from 'react';
import { useParams } from 'react-router-dom';
import ActivateInvitedUser from '../components/ActivateInvitedUser/ActivateInvitedUser';

export default function ActivateInvitedUserPage() {
  const { userId, token } = useParams<{userId : string, token: string}>();
  return (
    <>
      <ActivateInvitedUser userId={userId} token={token} />
    </>
  );
}
