import React from 'react';
import SearchResultContainer from './styles/SearchResultContainer';
// type
// import { ProductSummary } from './types';
// import Product from './Product';
// assets
import noImage from '../../assets/svg/noimage.svg';
import SynnexProduct from './SynnexProduct';

export default ({
  productSummaries,
  handleProductPreview,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  productSummaries: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleProductPreview: any,
}) => (
  <SearchResultContainer>
    <section>
      <ul>
        {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            productSummaries.map((product: any) => (
              <SynnexProduct
                // key={product.epid}
                image={noImage}
                title={product.shortDescription.length > 100 ? `${product.shortDescription.substr(0, 100)}...` : product.shortDescription}
                sku={product.sku}
                brand={product.brand}
                category={product.categoryName}
                handleProductPreview={handleProductPreview}
              />
            ))
          }
      </ul>
    </section>
  </SearchResultContainer>
);
