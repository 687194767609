import React from 'react';
import moment from 'moment';
import { colors } from '@atlaskit/theme';
import i18next from 'i18next';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import ActionButton from '../widget/ActionButton';
import getColor from '../helper/getColor';
import Tag from '../widget/Tag';
import { isPostageNearDue } from '../../OrderDetails/helpers';
import { Order } from '../types';

/**
 * the localStorage key for the column hide/show option
 */
export const DS_ORDER_COLUMNS = 'DS_ORDER_COLUMNS';

/**
 * columns config
 */
export const columnConfigs = [
  {
    name: 'Order ID',
    lng: 'orders.left.table.head.orderId',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['orderNumber'],
    defaultValue: '',
    transformData: (v: string, order:Order) => <div style={isPostageNearDue(order) ? { color: 'red' } : {}}>{v}</div>,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Store',
    lng: 'orders.left.table.head.store',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['teamChannel', 'name'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Ship By',
    lng: 'orders.left.table.head.shipBy',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['shipByDate'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v ? moment.utc(v).fromNow() : ''}</>,
    reportData: (v: string) => (v ? moment.utc(v).fromNow() : ''),
    width: 1,
  },
  {
    name: 'Channel',
    lng: 'orders.left.table.head.channel',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['channel', 'name'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => moment(v).format('DD MMM YYYY'),
    width: 1,
  },
  {
    name: 'Created',
    lng: 'orders.left.table.head.created',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['createdAt'],
    defaultValue: '',
    transformData: (v: string) => moment(v).format('DD MMM YYYY'),
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => moment(v).format('DD MMM YYYY'),
    width: 1,
  },
  {
    name: 'Updated',
    lng: 'orders.left.table.head.updated',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['lastModifiedDate'],
    defaultValue: '',
    transformData: (v: string) => moment(v).format('DD MMM YYYY'),
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => moment(v).format('DD MMM YYYY'),
    width: 1,
  },
  {
    name: 'Customer',
    lng: 'orders.left.table.head.customer',
    defaultChecked: true,
    isSortable: false,
    dataSource: ['teamCustomer', 'name'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Username',
    lng: 'orders.left.table.head.username',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['teamCustomer', 'externalCustomerID'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Email',
    lng: 'orders.left.table.head.email',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['teamCustomer', 'email'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Fax',
    lng: 'orders.left.table.head.fax',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['teamCustomer', 'fax'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Phone',
    lng: 'orders.left.table.head.phone',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['teamCustomer', 'phone'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Mobile',
    lng: 'orders.left.table.head.mobile',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['teamCustomer', 'mobile'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Paid',
    lng: 'orders.left.table.head.paid',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['totalPaidAmt'],
    defaultValue: 0,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    reportData: (v: number) => formatMoneyWithPrefix(v),
    width: 1,
  },

  {
    name: 'Total (Inc.)',
    lng: 'orders.left.table.head.totalInc',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['totalAmtInclTax'],
    defaultValue: 0,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    reportData: (v: number) => formatMoneyWithPrefix(v),
    width: 1,
  },
  {
    name: 'Transaction fee',
    lng: 'orders.left.table.head.transactionFee',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['marketPlaceFee'],
    defaultValue: 0,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    reportData: (v: number) => formatMoneyWithPrefix(v),
    width: 1,
  },
  {
    name: 'Sales person',
    lng: 'orders.left.table.head.seller',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['seller'],
    defaultValue: 0,
    transformData: (v: string) => v,
    render: (v: string) => <>{v}</>,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'GST',
    lng: 'orders.left.table.head.gst',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['totalTaxAmt'],
    defaultValue: 0,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    reportData: (v: number) => formatMoneyWithPrefix(v),
    width: 1,
  },
  {
    name: 'Shipping (Inc.)',
    lng: 'orders.left.table.head.shippingInc',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['totalShippingAmtInclTax'],
    defaultValue: 0,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    reportData: (v: number) => formatMoneyWithPrefix(v),
    width: 1,
  },
  // this column might confuse user, we remove it for now.
  // {
  //   name: 'Shipping GST',
  //   defaultChecked: false,
  //   isSortable: true,
  //   dataSource: ['shippingTaxAmt'],
  //   defaultValue: 0,
  //   transformData: (v: number) => formatMoneyWithPrefix(v),
  //   render: (v: number) => <>{v}</>,
  // },

  // this column might confuse user, we remove it for now.
  // {
  //   name: 'Shipping GST rate',
  //   defaultChecked: false,
  //   isSortable: true,
  //   dataSource: ['shippingTaxRate'],
  //   defaultValue: 0,
  //   transformData: (v: number) => formatMoneyWithPrefix(v),
  //   render: (v: number) => <>{v}</>,
  // },
  {
    name: 'Discount',
    lng: 'orders.left.table.head.discount',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['totalDiscountAmt'],
    defaultValue: 0,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    reportData: (v: number) => formatMoneyWithPrefix(v),
    width: 1,
  },
  /**
   * don't remove below comment,
   * it's provision field for profit will add later
   */
  // {
  //   name: 'Profit',
  //   defaultChecked: true,
  //   isSortable: false,
  //   //on db, we don't have 'profit' field, but still put here
  //   //it will use defaultValue instead.
  //   dataSource: ['profit'],
  //   defaultValue: 0,
  //   transformData: (v: number) => formatMoneyWithPrefix(v),
  //   render: (v: number) => <>{v}</>,
  // },
  {
    name: 'Status',
    lng: 'orders.left.table.head.status',
    defaultChecked: true,
    isSortable: false,
    dataSource: ['status', 'name'],
    defaultValue: 'Unkonw',
    transformData: (v: string) => v.toUpperCase(),
    render: (
      v: string,
      testId:string,
    ) => <Tag text={v} color={getColor(v)} testId={testId} />,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Payment',
    lng: 'orders.left.table.head.payment',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['paymentStatus'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (
      v: string,
    ) => v,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Xero Sync Status',
    lng: 'orders.left.table.head.xeroSyncStatus',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['xeroInvoiceId'],
    defaultValue: '',
    transformData: (v: string) => v,
    render: (
      v: string,
    ) => v,
    reportData: (v: string) => v,
    width: 1,
  },
  {
    name: 'Action', // '...'button
    lng: 'orders.left.table.head.action',
    defaultChecked: true, // should allways selected
    dataSource: [],
    defaultValue: i18next.t<string>('orders.left.table.head.vd'),
    transformData: (v: string) => v,
    render: (v: string, handleTableRowClick: () => void) => (
      <ActionButton
        v={v}
        handleTableRowClick={handleTableRowClick}
      />
    ),
    reportData: (v: string) => v,
    width: 1,
  },
];

export const COMPACT_MODEL_COLUMNS = ['Order ID', 'Created', 'Updated', 'Customer', 'Total (Inc.)', 'Status'];

/**
 * status config
 */
export const DEFAULT_STAT = [
  {
    name: 'orders.left.config.all',
    code: '',
    value: '',
    isSelected: true,
    color: '',
  },
  {
    name: 'orders.left.config.unpaid',
    code: 'UNPAID',
    value: '',
    isSelected: false,
    color: colors.R200,
  },
  {
    name: 'orders.left.config.paid',
    code: 'PAID',
    value: '',
    isSelected: false,
    color: '#deebff',
  },
  {
    name: 'orders.left.config.packed',
    code: 'PACKED',
    value: '',
    isSelected: false,
    color: '#fff0b3',
  },
  {
    name: 'orders.left.config.shipped',
    code: 'SHIPPED',
    value: '',
    isSelected: false,
    color: colors.green(),
  },
  {
    name: 'orders.left.config.refunded',
    code: 'REFUNDED',
    value: '',
    isSelected: false,
    color: colors.purple(),
  },
  {
    name: 'orders.left.config.cancelled',
    code: 'CANCELLED',
    value: '',
    isSelected: false,
    color: colors.DN80A,
  },
];
