import React, { useState } from 'react';
import DynamicTableStateless from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import gitFixedSizeString from '../TicketsContainer/utils';

import {
  Ticket,
  ColumnProps,
  TicketQueryInputProps,
} from '../TicketsContainer/types';
import formattedDate from '../../utils/formattedDate';

import { useTicketsByTeamQuery } from '../../graphql/types';

const PAGE_SIZE = 100;

const TicketsContainer = styled.div`
    margin-bottom:20px; 
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    } 
`;

const TicketsListing = (props: {teamID: string, orderId: string}) => {
  const history = useHistory();
  const [sortKey] = useState('name');
  const [ticketStatus] = useState('');
  const [inputSortOrder] = useState('ASC');
  const { teamID, orderId } = props;
  const columnsVal = [
    {
      key: 'id', label: 'ID', isSortable: false, isChecked: true, width: 1,
    },
    {
      key: 'status', label: 'Status', isSortable: true, isChecked: true, width: 1,
    },
    {
      key: 'subject', label: 'Subject', isSortable: false, isChecked: true, width: 3,
    },
    {
      key: 'requester', label: 'Requester', isSortable: false, isChecked: true, width: 1,
    },
    {
      key: 'created_at', label: 'Created', isSortable: true, isChecked: true, width: 1,
    },
  ];
  const variables: TicketQueryInputProps = {
    teamID,
    status: ticketStatus,
    searchTxt: orderId,
    sortKey,
    sortOrder: inputSortOrder,
    pageNum: 1,
    pageSize: PAGE_SIZE,
  };
  const {
    data, error,
  } = useTicketsByTeamQuery({ variables });

  if (error) {
    /* return <p>Something went wrong!</p>; */
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const tickets: any[] = data?.TicketsByTeam?.tickets
  || [];
  const total = data?.TicketsByTeam?.total || 0;
  const pages = Math.ceil(total / PAGE_SIZE);
  const totalPages = [];
  for (let i = 1; i <= pages; i += 1) {
    totalPages.push(i);
  }
  const headCells = columnsVal.filter((col: ColumnProps) => (
    col.isChecked
  )).map((col: ColumnProps) => ({
    key: col.key,
    content: col.label,
    isSortable: col.isSortable,
    width: col.width,
  }));
  const head = { cells: headCells };
  const rows = tickets.map(
    (tc: Ticket, idx) => {
      const {
        id,
        subject,
        created_at: createdAt,
        channel,
      } = tc;
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const cells: any = columnsVal.filter((col: ColumnProps) => (
        col.isChecked
      )).map((col: ColumnProps) => {
        if (col.key === 'subject') {
          return {
            key: 'subject',
            content: gitFixedSizeString(subject, 30),
          };
        }
        if (col.key === 'created_at') {
          return {
            key: 'updated_at',
            content: formattedDate(createdAt),
          };
        }
        return {
          key: col.key,
          content: tc[col.key],
        };
      });
      return {
        key: `row-${idx}-ticket`,
        cells,
        onClick: () => history.push(`/tickets/${id}?channel=${channel}`),
      };
    },
  );

  return tickets.length > 0
    ? (
      <TicketsContainer>
        <h5>Tickets</h5>
        <DynamicTableStateless
          head={head}
          rows={rows}
        />
      </TicketsContainer>
    )
    : <></>;
};

export default TicketsListing;
