import React from 'react';
import Button from '@atlaskit/button';
import AddIcon from '@atlaskit/icon/glyph/add';

interface Props {
  onClick:()=>void
}
export default ({ onClick }:Props) => (
  <Button
    onClick={onClick}
  >
    <AddIcon label="Add" size="small" />
  </Button>
);
