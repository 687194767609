/* eslint-disable max-len */
import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import Button from '@atlaskit/button/new';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalBody,
} from '@atlaskit/modal-dialog';
import EditIcon from '@atlaskit/icon/glyph/edit';
import styled from 'styled-components';
import Spinner from '@atlaskit/spinner';
import { Order } from '../../OrdersListing/types';
import ErrorSection from '../../ErrorSection/ErrorSection';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import OrderItemEditor from '../../OrderItemEditor/OrderItemEditor';
import { SelectedOrderItem } from '../../OrderItemEditor/types';
import { useProductsQuery, useUpdateManualOrderMutation } from '../../../graphql/types';
import { isItemsInValid } from '../../CreateOrder/helpers';
import { Footer } from '../../CreateOrder/shares/widget.style';
import getOrderByIdQuery from '../../../graphql/order/queries/getOrderById';
import { parseOrderItemsToDefaultSelection } from '../../OrderItemEditor/helpers';

const event = new CustomEvent('refetchLogs');

const UpdateOrderItemContainer = styled.div`
    padding-bottom:15px;
`;

interface ModalContainerProps {
  order: Order,
  isOpen: boolean,
  close: () => void,
}

const ModalContainer = ({ order, isOpen, close }: ModalContainerProps) => {
  const { teamChannel, orderItems, teamID } = order;
  const [updateManualOrder, updateManualOrderState] = useUpdateManualOrderMutation({
    refetchQueries: [{
      query: getOrderByIdQuery,
      variables: {
        orderId: order.id,
      },
    }],
  });

  const productIDs = orderItems
    .map((cur) => cur.productID)
    .filter((cur) => cur !== '');

  const { data, loading, error } = useProductsQuery({
    variables: {
      teamID,
      skip: 0,
      take: orderItems.length,
      // attemps to remove duplicates by use Set object
      // orderItems can have multiple rows refered to same product
      productIDs: [...new Set(productIDs)],
    },
  });

  const defaultSelection: Array<SelectedOrderItem> = useMemo(
    () => parseOrderItemsToDefaultSelection(data, order),
    [order, data],
  );

  const [items, setItems] = useState<SelectedOrderItem[]>([]);
  const [isAmountIncTax, setIsAmountIncTax] = useState<boolean>(true);

  useEffect(() => {
    setItems(defaultSelection);
  }, [defaultSelection]);

  const pipe = useCallback((
    orderTtems: SelectedOrderItem[],
    isTaxIncludeInUnitPrice: boolean,
  ) => {
    setItems(orderTtems);
    setIsAmountIncTax(isTaxIncludeInUnitPrice);
  }, []);

  const onUpdate = async () => {
    try {
      await updateManualOrder({
        variables: {
          teamChannelId: teamChannel.id,
          data: {
            orderId: order.id,
            isAmountIncTax,
            orderItems: items.map((item) => ({
              orderItemId: '',
              teamChannelProductId: item.teamChannelProductid,
              teamTaxClassId: item.taxClassId,
              qty: item.qty,
              unitPrice: Number(item.unitPrice),
            })),
          },
        },
      });

      document.body.dispatchEvent(event);
      close();
    } catch (err) {
      // leave blank intentionaly
      // error handling on updateManualOrderState.error
    }
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={close}
          width={1240}
          shouldScrollInViewport
        >
          <ModalHeader>
            <ModalTitle>Update Order</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {error && <ErrorSection>{graphQLErrorsReader(error)}</ErrorSection>}
            {updateManualOrderState.error && <ErrorSection>{graphQLErrorsReader(updateManualOrderState.error)}</ErrorSection>}

            {loading && <Spinner size="large" />}
            {
            !loading
            && (
              <UpdateOrderItemContainer>
                <OrderItemEditor teamChannel={teamChannel} callBack={pipe} defaultSelection={defaultSelection} />
                <Footer>
                  <Button
                    onClick={close}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={onUpdate}
                    isDisabled={(isItemsInValid(items))}
                    appearance="primary"
                    isLoading={updateManualOrderState.loading}
                    testId="updateOrderItemButton"
                  >
                    Update
                  </Button>
                </Footer>
              </UpdateOrderItemContainer>
            )
          }
          </ModalBody>

        </Modal>
      )}
    </ModalTransition>
  );
};

interface Props {
  order: Order,
}

export default ({ order }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  return (
    <>
      <Button
        onClick={open}
        appearance="subtle"
        spacing="compact"
        testId="updateOrderItemsButton"
      >
        <EditIcon label="edit" size="small" />
      </Button>
      <ModalContainer
        order={order}
        isOpen={isOpen}
        close={close}
      />
    </>
  );
};
