import _, { isUndefined } from 'lodash';
import {
  QueryFulfilmentPoliciesQuery,
  QueryPaymentPoliciesQuery,
  QueryReturnPoliciesQuery,
  FindOrCreateLocationQuery,
} from '../../graphql/types';

const mapFulfillmentsOptions = (
  ebayResponse: QueryFulfilmentPoliciesQuery | undefined,
) => {
  if (isUndefined(ebayResponse)) return [];
  const fulfillmentPolicies = _.get(
    ebayResponse,
    ['QueryEbay', 'response', 'fulfillmentPolicies'],
    [],
  );
  return fulfillmentPolicies
    .map((policy: { name: string, fulfillmentPolicyId: string }) => (
      {
        label: policy.name,
        value: policy.fulfillmentPolicyId,
      }
    ));
};

const mapPaymentsOptions = (
  ebayResponse: QueryPaymentPoliciesQuery | undefined,
) => {
  if (isUndefined(ebayResponse)) return [];
  const paymentPolicies = _.get(
    ebayResponse,
    ['QueryEbay', 'response', 'paymentPolicies'],
    [],
  );
  return paymentPolicies
    .map((policy: { name: string, paymentPolicyId: string }) => (
      {
        label: policy.name,
        value: policy.paymentPolicyId,
      }
    ));
};

const mapReturnsOptions = (
  ebayResponse: QueryReturnPoliciesQuery | undefined,
) => {
  if (isUndefined(ebayResponse)) return [];
  const returnPolicies = _.get(
    ebayResponse,
    ['QueryEbay', 'response', 'returnPolicies'],
    [],
  );
  return returnPolicies
    .map((policy: { name: string, returnPolicyId: string }) => (
      {
        label: policy.name,
        value: policy.returnPolicyId,
      }
    ));
};

const mapLocationssOptions = (
  ebayResponse: FindOrCreateLocationQuery | undefined,
) => {
  if (isUndefined(ebayResponse)) return [];
  const locations = _.get(
    ebayResponse,
    ['FindOrCreateMerchantLocation', 'response', 'locations'],
    [],
  );

  return locations
    .map((cur: {
      name: string | undefined,
      merchantLocationKey: string,
      location: {
        address: {
          city: string,
        }
      } | undefined,
    }) => {
      const { name, merchantLocationKey, location } = cur;
      let label: string = '';
      if (name) {
        label = name;
      }

      if (!label && !isUndefined(location)) {
        label = location?.address?.city;
      }

      return ({
        label,
        value: merchantLocationKey,
      });
    });
};

export {
  mapFulfillmentsOptions,
  mapPaymentsOptions,
  mapReturnsOptions,
  mapLocationssOptions,
};
