import React from 'react';
import moment from 'moment';
import Spinner from '@atlaskit/spinner';
import { useTranslation } from 'react-i18next';
import LineChart, { LineChartDataType } from './LineChart';
import { useQueryTopSkuSalesQuery } from '../../graphql/types';

const TopSkuSalesChart = ({ teamId }: {
  teamId: string
}) => {
  const { t } = useTranslation();
  const dateStart = moment().subtract(29, 'days').format('DD/MM/YYYY');
  const dateEnd = moment().format('DD/MM/YYYY');
  const { data, error, loading } = useQueryTopSkuSalesQuery({
    variables: {
      teamId,
      dateStart,
      dateEnd,
    },
  });
  if (loading === true) {
    return <Spinner />;
  }
  if (error) {
    return <p>Something wrong when retrieving SKU Sales Report</p>;
  }
  if (loading === false && data) {
    const resp = data.QueryTopSKUSalesReport.response;
    const mappedChartData = resp.dateRange
      .map((dateStr: string) => resp.topSKUs.map((sku: string) => {
        const salesNum = resp.topSKUSalesMap[dateStr]
        && resp.topSKUSalesMap[dateStr][sku]
          ? resp.topSKUSalesMap[dateStr][sku] : 0;
        return {
          target: sku,
          x: moment(dateStr, 'DD/MM/YYYY').format('DD/MM'),
          y: salesNum,
        };
      })).reduce(
        (carry: LineChartDataType[],
          item: LineChartDataType[]) => [...carry, ...item],
      );

    const initchartOptions = resp.topSKUs.map((sku: string) => ({
      label: sku,
      value: sku,
    }));

    return (
      <>
        <h4>{t('home.tss.tss')}</h4>
        { mappedChartData && <LineChart data={mappedChartData} prefix="" postfix="" initchartOptions={initchartOptions} />}
      </>
    );
  }
  return <></>;
};

export default TopSkuSalesChart;
