/* eslint-disable no-shadow */
import React from 'react';
import Select from '@atlaskit/select';
import { GetTeamTaxClassesQuery } from '../../graphql/types';
import { SingleOption } from '../ProductEdit/types';

const OrderItemTaxClassSelector = ({
  teamTaxClasses, orderItemId, taxClassId, onChange,
}:
{teamTaxClasses: GetTeamTaxClassesQuery['GetTeamTaxClasses']['taxClasses'], orderItemId: string,
  taxClassId: string | null | undefined,
  onChange: (orderItemId: string, taxClassId: string) => void}) => {
  const taxClassOptions = teamTaxClasses!.map((tc) => ({
    label: tc.name,
    value: tc.id,
  }));
  const matchedOptionArr = taxClassOptions
    .filter((tcOption) => tcOption.value === taxClassId);
  const defaultValue = Array.isArray(matchedOptionArr)
    ? matchedOptionArr[0] : taxClassOptions[0];
  return (
    <Select
      options={taxClassOptions}
      defaultValue={defaultValue}
      placeholder="Choose a tax class"
      onChange={(selected) => {
        if (selected) {
          if (defaultValue !== undefined) {
            if (defaultValue.value !== (selected as SingleOption).value) {
              onChange(orderItemId, (selected as SingleOption).value);
            }
          } else {
            onChange(orderItemId, (selected as SingleOption).value);
          }
        }
      }}
    />
  );
};

export default OrderItemTaxClassSelector;
