import React from 'react';
import { useMeQuery } from '../graphql/types';

// Temporary page to show case sign in and session support
export default () => {
  const { data, loading, error } = useMeQuery();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  if (data === undefined || data.Me === undefined) {
    return null;
  }

  return <p>{ `hi ${data.Me!.firstName} ${data.Me!.lastName}!` }</p>;
};
