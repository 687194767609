import { ProductFilterCondition, ProductFilterKey } from '../../../../graphql/types';

export const NumberCondition = [
  {
    label: 'is greater than',
    value: ProductFilterCondition.IsGreaterThan,
  },
  {
    label: 'is equal to',
    value: ProductFilterCondition.IsEqualTo,
  },
  {
    label: 'is less than',
    value: ProductFilterCondition.IsLessThan,
  },
] as const;

export const StringCondition = [
  {
    label: 'contains',
    value: ProductFilterCondition.Contains,
  },
] as const;
export const AvailableFilters = [
  {
    displayName: 'SKU',
    key: ProductFilterKey.Sku,
    condition: StringCondition,
    valueType: 'text',
  },
  {
    displayName: 'Listing ID', // used to display label text
    key: ProductFilterKey.ExternalListingId, // used for select option value, also it's the column name of the database
    condition: StringCondition, // used for condition <select> option
    valueType: 'text', // used to validate input value
  },
  {
    displayName: 'Item title',
    key: ProductFilterKey.Name,
    condition: StringCondition,
    valueType: 'text',
  },
  {
    displayName: 'Stock on hand',
    key: ProductFilterKey.StockOnHand,
    condition: NumberCondition,
    valueType: 'number',
  },
  {
    displayName: 'Price (Inc.)',
    key: ProductFilterKey.UnitPrice,
    condition: NumberCondition,
    valueType: 'number',
  },
] as const;
