import { StateUser, StateTeam } from './types';

export const getAccessToken = () => window.localStorage.getItem('accessToken');
export const setAccessToken = (v: string) => {
  window.localStorage.setItem('accessToken', v);
};
export const getStorageUser = ():StateUser|undefined => {
  const user = window.localStorage.getItem('user');
  if (user) return JSON.parse(user);
  return undefined;
};
export const setStorageUser = (user:StateUser) => {
  window.localStorage.setItem('user', JSON.stringify(user));
};
export const getStorageTeams = ():StateTeam[] => {
  const teams = window.localStorage.getItem('teams');
  if (teams) return JSON.parse(teams);
  return [];
};
export const setStorageTeams = (teams: StateTeam[]) => {
  window.localStorage.setItem('teams', JSON.stringify(teams));
};
export const getStorageCurrentTeam = ():string|undefined => {
  const currentTeam = window.localStorage.getItem('currentTeam');
  if (currentTeam) return currentTeam;
  return undefined;
};
export const setStorageCurrentTeam = (currentTeam: string) => {
  window.localStorage.setItem('currentTeam', currentTeam);
};
export const getQuickstartCancelled = ():boolean => window.localStorage.getItem('quickstartCancelled') === 'true';
export const setQuickstartCancelled = (t: boolean) => {
  window.localStorage.setItem('quickstartCancelled', t ? 'true' : 'false');
};
export const clearStorage = () => {
  // comment out window.localStorage.clear()
  // to allow grid width remain in localstorage
  window.localStorage.removeItem('accessToken');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('teams');
  window.localStorage.removeItem('currentTeam');
  window.localStorage.removeItem('flaged');
};
