import React from 'react';
import styled from 'styled-components';
import { isArray } from 'lodash';
import { useShipping } from '../Shipping';

const Container = styled.div`
  margin-top:20px;
  color: rgb(23, 43, 77);
  .section {
    h5{
    font-size:12px;
    font-weight:600;
    margin-bottom:10px;
    }
  }     
`;

const MarkAsShipped = () => {
  const { state } = useShipping();
  const selectedCourier = (() => {
    const { shippingMethod } = state;
    if (isArray(shippingMethod) && shippingMethod.length !== 0) {
      return shippingMethod.slice(-1)[0].label;
    }
    return '';
  })();
  return (
    <Container>
      <div className="section inputs">
        <h5>
          <div>
            {selectedCourier}
          </div>
        </h5>
        <div className="block">
          On click onfirm, this order will be marked as shipped.
        </div>
      </div>
    </Container>
  );
};

export default MarkAsShipped;
