import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Profile } from '@atlaskit/atlassian-navigation';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../utils/useAuth';
import getLoginUserFullName from '../../helpers/getLoginUserFullName';
import MerpAvatar from '../MerpAvatar/MerpAvatar';

const SignoutButton = () => {
  const { signOut } = useAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const logout = () => {
    signOut()
      .catch(() => {
      /**
       * force to redirect signIn page if experice a error from server
       * logout procedure on client side has excuted allready on signOut!() in useAuth()
       */
        history.push('/signin');
      });
  };
  return (
    <Button
      onClick={logout}
      appearance="subtle"
      shouldFitContainer
      style={{ textAlign: 'left' }}
    >
      {t('nav.profile.button.signOut')}
    </Button>
  );
};

const ProfileButton = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <Button
      appearance="subtle"
      shouldFitContainer
      style={{ textAlign: 'left' }}
      onClick={() => (history.push('/profile'))}
    >
      {t('nav.profile.button.profile')}
    </Button>
  );
};

// const LanguageButton = () => {
//   const history = useHistory();
//   const { t } = useTranslation();
//   return (
//     <Button
//       appearance="subtle"
//       shouldFitContainer
//       style={{ textAlign: 'left' }}
//       onClick={() => (history.push('/language'))}
//     >
//       {t('nav.profile.button.language')}
//     </Button>
//   );
// };

// const BillingButton = () => {
//   const history = useHistory();
//   const { t } = useTranslation();
//   return (
//     <Button
//       appearance="subtle"
//       shouldFitContainer
//       style={{ textAlign: 'left' }}
//       onClick={() => (history.push('/Billing'))}
//     >
//       {t('nav.profile.button.billing')}
//     </Button>
//   );
// };

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const { state: { user } } = useAuth();
  const onClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const ProfileContent = () => (
    <div style={{ minWidth: 150, padding: 5 }}>
      <DropdownItemGroup title={getLoginUserFullName(user!)}>
        <DropdownItem onClick={onClose}><ProfileButton /></DropdownItem>
        <DropdownItem onClick={onClose}><SignoutButton /></DropdownItem>
      </DropdownItemGroup>
    </div>
  );
  if (user) {
    return (
      <Popup
        placement="bottom-start"
        content={ProfileContent}
        isOpen={isOpen}
        onClose={onClose}
        trigger={(triggerProps) => (
          <Profile
            icon={<MerpAvatar />}
            onClick={onClick}
            isSelected={isOpen}
            tooltip="Your profile and settings"
            {...triggerProps}
          />
        )}
      />
    );
  }
  return (<></>);
};
