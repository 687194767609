import React from 'react';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import addNotification from '../../utils/addNotification';
import { useCreateXeroInvoicesMutation } from '../../graphql/types';

const BtnContainer = styled.div`
  margin-left: 20px;
`;

const CreateXeroInvoices = ({ teamId }:{ teamId: string }) => {
  const [createXeroInvoicesMutation] = useCreateXeroInvoicesMutation();
  const createXeroInvoices = () => {
    createXeroInvoicesMutation({
      variables: {
        teamId,
      },
    })
      .then(() => {
        addNotification('Sync Xero Invoices Success', 'success');
      })
      .catch((err) => {
        const msg = err.graphQLErrors[0]?.message || 'Sync Xero Invoices Fail, Please Try Again';
        addNotification(msg, 'danger');
      });
  };
  const { t } = useTranslation();
  return (
    <BtnContainer>
      <Button
        onClick={createXeroInvoices}
        appearance="primary"
        testId="createXeroInvoices"
      >
        {t('orders.left.button.syncMultipleInvoices')}
      </Button>
    </BtnContainer>
  );
};

export default CreateXeroInvoices;
