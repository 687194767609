import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import OrderDetails from '../components/OrderDetails/OrderDetails';
import { useGetOrderByIdQuery } from '../graphql/types';
import NotFound from './NotFound';

export default () => {
  const { orderId } = useParams<{orderId : string}>();

  const {
    data, loading, error, refetch,
  } = useGetOrderByIdQuery({
    variables: {
      orderId,
    },
  });

  if (loading) {
    return <Spinner size={50} />;
  }

  if (error) {
    return <NotFound />;
  }

  if (data && data?.GetOrderById?.order) {
    return (
      <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
      >
        <div style={{ width: 1024 }}>
          <OrderDetails
            order={data.GetOrderById.order}
            refetch={refetch}
          />
        </div>

      </div>
    );
  }
  return (
    <NotFound />
  );
};
