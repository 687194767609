import React from 'react';
import EditFilledIcon from '@atlaskit/icon/glyph/edit-filled';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import { Card } from '../CourierIntegrationList/CourierIntegrationList.style';
import zendesk from '../../assets/svg/zendesk.svg';

const ZendeskConnector = () => (
  <Card>
    <div>
      <div className="head">
        <img src={zendesk} className="logo" alt="zendesk" data-testid="ZendeskLogo" />
        <div className="name">
          <div className="value">Zendesk</div>
          <div className="subtitle">
            Omnichannel customer service and engagement platform
          </div>
        </div>
        <div className="edit">
          <EditFilledIcon label="edit" primaryColor="grey" />
        </div>

      </div>
      <div className="state">
        <div className="state-item feedback">
          <div className="state-item-name">Configured</div>
          <div className="state-item-value">
            <PresenceActiveIcon label="status" primaryColor="grey" />
          </div>
        </div>
        <div className="state-item revenue">
          <div className="state-item-name">Tested</div>
          <div className="state-item-value">
            <PresenceActiveIcon label="status" primaryColor="grey" />
          </div>
        </div>
        <div className="state-item revenue">
          <div className="state-item-name">In use</div>
          <div className="state-item-value">
            <PresenceActiveIcon label="status" primaryColor="grey" />
          </div>
        </div>
      </div>
    </div>
  </Card>
);
export default ZendeskConnector;
