import React from 'react';
import Spinner from '@atlaskit/spinner';
import { Control } from 'react-hook-form';
import { useGetTeamChannelsByTeamIdQuery } from '../../graphql/types';
import { ErrorType } from './types';
import TeamChannelPolicySelector from './TeamChannelPolicySelector';
import { TeamChannelProduct } from '../ProductDetailsContainer/type';

const TeamChannelsPoliciesSelector = ({
  teamId, teamChannelProducts, control, errors, setValue, isDisable,
}: {
  teamId : string,
  teamChannelProducts: TeamChannelProduct[],
  // eslint-disable-next-line
  control: Control<Record<string, any>>,
  errors: ErrorType,
  setValue: Function,
  isDisable: boolean,
}) => {
  // Get all active team channels under the team
  const { data, loading } = useGetTeamChannelsByTeamIdQuery({
    variables: {
      teamId,
    },
  });

  const getChannelProductSettings = (teamChannelId: string) => {
    const filteredTcProducts = teamChannelProducts
      .filter((tcP) => tcP.teamChannel.id === teamChannelId);
    return filteredTcProducts && Array.isArray(filteredTcProducts)
    && filteredTcProducts.length === 1
      ? JSON.parse(filteredTcProducts[0].settings || '{}') : {};
  };
  return loading === true ? <Spinner /> : (
    // eslint-disable-next-line react/no-unknown-property
    <div test-id="teamChannelPolicies">
      {data && data.GetTeamChannelsByTeamId.teamChannels
      // only ebay channel need policies
        .filter((c) => c.channel.name === 'eBay')
        .map((tc) => (
          <TeamChannelPolicySelector
            key={tc.id}
            teamChannelId={tc.id}
            teamChanelName={tc.name}
            teamChannelSetttings={tc.settings ? JSON.parse(tc.settings) : {}}
            channelProductSettings={getChannelProductSettings(tc.id)}
            control={control}
            errors={errors}
            setValue={setValue}
            isDisable={isDisable}
          />
        ))}
    </div>
  );
};

export default TeamChannelsPoliciesSelector;
