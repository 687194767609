import React from 'react';
import Spinner from '@atlaskit/spinner';
import _ from 'lodash';
import {
  useTeamCustomerFeedBackQuery,
} from '../../graphql/types';

interface InputProps {
  teamID: string,
  userId: string,
  name: string,
}

const CustomerFeedBack = (props: InputProps) => {
  const { teamID, userId, name } = props;
  const variables = { teamID, userId };
  const {
    data, error, loading,
  } = useTeamCustomerFeedBackQuery({ variables });
  if (loading) {
    return <Spinner />;
  }
  // let score = '';
  let score;
  if (error) {
    score = 'unknown';
  }

  score = _.get(data, 'TeamCustomerFeedBack.score', '');
  const url = `https://www.ebay.com/usr/${userId}`;
  return (
    <div>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {score ? `${name} (${score})` : `${name}`}
      </a>
    </div>
  );
};

export default CustomerFeedBack;
