import React from 'react';
import { formatNumber } from 'accounting';
import moment from 'moment';
// import i18next from 'i18next';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import ActionButton from './widget/ActionButton';
import { Product } from './types';
import ItemTitleCard from './widget/ItemTitleCard';
import unescapeHtml from '../../utils/unescapeHtml';
import type { TeamTaxClasses } from '../OrderItemEditor/types';

interface Config {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  name: string | any;
  defaultChecked: boolean;
  isSortable: boolean;
  dataSource: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  compactMode: boolean;
  transformData: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render: (v: any | undefined, sku: any, img: any) => JSX.Element|string;
  width: number;
  /**
     * extraIdentifier is used to modify the key of column where
     * some column might have same key if not modifed.
     */
  extraIdentifier?:string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  label?: string | any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ProductAttributeValue = Extract<Product['productAttributeValues'], Array<any>>[number];

/**
 * the localStorage key for the column hide/show option
 */
export const DS_PRODUCTS_COLUMNS = 'DS_PRODUCTS_COLUMNS';

/**
 * columns config
 */
export const COLUMNS_CONFIG:Config[] = [
  {
    name: 'Title',
    label: '',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['name'],
    defaultValue: '',
    compactMode: true,
    transformData: (v: string) => unescapeHtml(v),
    render: (
      v: string,
      sku:string,
      img:string,
    ) => (
      <ItemTitleCard
        title={v}
        sku={sku}
        image={img}
      />
    ),
    width: 9,
  },
  {
    name: 'Brand',
    label: '',
    defaultChecked: true,
    isSortable: false,
    dataSource: ['productAttributeValues'],
    defaultValue: [],
    compactMode: true,
    transformData: (v:Array<ProductAttributeValue>) => {
      const [brand] = v.filter((value) => value.productAttribute.name === 'Brand'
        || value.productAttribute.name === 'Motherboard Brand');
      return brand;
    },
    render: (
      v: undefined | ProductAttributeValue,
    ) => (v ? v.value : ''),
    width: 1,
  },
  {
    name: 'Category',
    label: '',
    defaultChecked: true,
    isSortable: false,
    dataSource: ['attributeSet', 'name'],
    defaultValue: '',
    compactMode: true,
    transformData: (v: string) => unescapeHtml(v).split(';')[0].split(',')[0].split('/')[0].split(':')[0],
    render: (v: string) => <>{v}</>,
    width: 1,
  },
  {
    name: 'Stock on hand',
    label: '',
    defaultChecked: true,
    isSortable: true,
    dataSource: ['stockOnHand'],
    defaultValue: '',
    compactMode: true,
    transformData: (v: number) => formatNumber(v),
    render: (v: number) => <>{v}</>,
    width: 1,
  },
  {
    name: 'Total stock value',
    label: '',
    defaultChecked: false,
    isSortable: false,
    dataSource: ['totalInventoryCost'],
    defaultValue: 0,
    compactMode: false,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: number) => <>{v}</>,
    width: 1,
  },
  {
    name: 'RRP (Ex.)',
    label: '',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['unitPriceExclGST'],
    defaultValue: '',
    compactMode: false,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: string) => <>{v}</>,
    width: 1,
  },
  {
    name: 'RRP (Inc.)',
    label: '',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['unitPrice'],
    defaultValue: '',
    compactMode: true,
    transformData: (v: number) => formatMoneyWithPrefix(v),
    render: (v: string) => <>{v}</>,
    width: 1,
  },
  {
    name: 'Created',
    label: '',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['createdAt'],
    defaultValue: '',
    compactMode: false,
    transformData: (v: string) => moment(v).format('DD MMM YYYY [at] LT'),
    render: (v: string) => <>{v}</>,
    width: 1,
  },
  {
    name: 'Updated',
    label: '',
    defaultChecked: false,
    isSortable: true,
    dataSource: ['updatedAt'],
    defaultValue: '',
    compactMode: false,
    transformData: (v: string) => moment(v).format('DD MMM YYYY [at] LT'),
    render: (v: string) => <>{v}</>,
    width: 1,
  },
  {
    name: '', // '...'button
    label: '',
    defaultChecked: true, // should allways selected
    isSortable: false,
    dataSource: [],
    defaultValue: '',
    compactMode: false,
    transformData: (v: string) => v,
    render: (v: string, product:Product, taxClasses:TeamTaxClasses) => (
      <ActionButton product={product} taxClasses={taxClasses} />
    ),
    width: 1,
  },
];
