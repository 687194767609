import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle';
import addNotification from '../../utils/addNotification';
import { useCreateXeroInvoiceMutation } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const GenerateXeroInvoice = ({ order }: { order: any }) => {
  const { state: { currentTeamId: teamId } } = useAuth();

  const [downloading, setDownloading] = useState(false);
  const [createXeroInvoiceMutation] = useCreateXeroInvoiceMutation();

  const createXeroInvoice = () => {
    setDownloading(true);
    createXeroInvoiceMutation({
      variables: {
        teamID: teamId || '',
        orderId: order?.id,
      },
    })
      .then(() => {
        setDownloading(false);
        addNotification('Upload Invoice is in queue', 'success');
      })
      .catch((err) => {
        setDownloading(false);
        const msg = err.graphQLErrors[0]?.message || 'Sync Xero Invoices Fail, Please Try Again';
        addNotification(msg, 'danger');
      });
  };

  return (
    <>
      <Button
        iconBefore={(iconProps) => <CrossCircleIcon {...iconProps} label="Xero" />}
        onClick={createXeroInvoice}
        isLoading={downloading}
        testId="createXeroInvoiceBtn"
      >
        Sync Xero Invoice
      </Button>
    </>
  );
};

export default GenerateXeroInvoice;
