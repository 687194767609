import React from 'react';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Button from '@atlaskit/button';
import { useProducts } from '../../ProductsListing';
import ProductDetailsContainer from '../../../ProductDetailsContainer/ProductDetailsContainer';

/**
  * render order details view
  */
const ProductView = ({ teamId }:{teamId:string}) => {
  const { state, dispatch } = useProducts();
  const close = () => {
    dispatch({
      type: 'setCurrentProductID',
      payload: undefined,
    });
  };

  return (
    <div data-testid="productDetailView" style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          right: 20,
          top: 30,
        }}
      >
        <Button
          appearance="subtle-link"
          onClick={close}
          testId="close-button"
        >
          <CrossIcon label="close" size="large" />
        </Button>

      </div>
      <div style={{ padding: 30 }}>
        <ProductDetailsContainer
          productId={state.display.currentProductId!}
          teamID={teamId}
        />
      </div>
    </div>
  );
};

export default ProductView;
