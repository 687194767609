import React from 'react';
import { RowType, HeadType, RowCellType } from '@atlaskit/dynamic-table/types';
import { get, isArray } from 'lodash';
import i18next from 'i18next';
import { ProductsQuery } from '../../../graphql/types';
import { Product, TeamChannelForTableHead } from '../types';
import { COLUMNS_CONFIG } from '../config';
import isChecked from './isChecked';
import noImage from '../../../assets/svg/noimage.svg';
import ListStockOnHandEdit from '../widget/listStockOnHandEdit';
import ListUnitPriceEdit from '../widget/listUnitPriceEdit';
import { ChannelLogo } from '../widget/MarketplaceStatus';

/**
 * extend localstorage settings with columns configs
 */
export const withStorages = (
  c: typeof COLUMNS_CONFIG,
) => c.map((cur) => {
  const { extraIdentifier } = cur;
  const key = `${cur.dataSource.toString().replace(',', '-')}${extraIdentifier || ''}`;
  let group = '';
  if (cur.dataSource.length === 0) {
    group = '';
  } else if (cur.dataSource.length === 1) {
    group = 'Product';
  } else if (cur.dataSource.length === 2 && cur.dataSource[0] === 'attributeSet') {
    group = 'Category';
  }

  let label = '';
  if (cur.name === 'Title') {
    label = i18next.t('products.left.columns.product.title');
  } else if (cur.name === 'Brand') {
    label = i18next.t('products.left.columns.product.brand');
  } else if (cur.name === 'Category') {
    label = i18next.t('products.left.columns.category.category');
  } else if (cur.name === 'Stock on hand') {
    label = i18next.t('products.left.columns.product.stockOnHand');
  } else if (cur.name === 'Total stock value') {
    label = i18next.t('products.left.columns.product.totalStockValue');
  } else if (cur.name === 'RRP (Ex.)') {
    label = i18next.t('products.left.columns.product.upeg');
  } else if (cur.name === 'RRP (Inc.)') {
    label = i18next.t('products.left.columns.product.uni');
  } else if (cur.name === 'Created') {
    label = i18next.t('products.left.columns.product.created');
  } else if (cur.name === 'Updated') {
    label = i18next.t('products.left.columns.product.updated');
  } else {
    label = cur.label;
  }

  return ({
    ...cur,
    label,
    key,
    group,
    isChecked: isChecked(key) ?? cur.defaultChecked,
  });
});

export const defaultColumns = withStorages(COLUMNS_CONFIG);

export const getHeadAndRow = (
  d: ProductsQuery | undefined,
  c: typeof defaultColumns,
  handleTableRowClick:(product:Product)=>void,
  freeExpired: boolean,
) => {
  const head: HeadType = {
    cells: c.filter((cur) => cur.isChecked).map((column) => {
      const {
        name, key, isSortable, width, label,
      } = column;
      return {
        name,
        key,
        testId: `ts-${key}`,
        content: label,
        isSortable,
        shouldTruncate: true,
        width,
      };
    }),
  };
  const rows: RowType[] = [];

  const products = d?.Products?.products;
  const taxClasses = d?.Products.teamTaxClasses;

  if (isArray(products)) {
    products.forEach((product) => {
      const cells: Array<RowCellType> = [];
      // generate row cells
      c.filter((cur) => cur.isChecked).forEach((column) => {
        const {
          key, dataSource, defaultValue, transformData, render,
        } = column;
        let content = get(product, dataSource, defaultValue);
        if (content === 'NO_EBAY_CATEGORY_ID') {
          content = get(product, ['productProp'], 'NO_EBAY_CATEGORY_ID');
        }
        const transformedContent = (
          transformData as (v: string|number) => string|number
        )(content);

        let image = noImage;
        try {
          image = product.images[0].url;
        } catch (error) {
          image = noImage;
        }
        if (key === 'stockOnHand') {
          cells.push({
            key,
            testId: 'cell',
            content: <ListStockOnHandEdit
              product={product}
              isDisable={freeExpired}
            />,
          });
        } else if (key.indexOf('teamChannelProducts') > -1 && key !== 'teamChannelProducts') {
          cells.push({
            key,
            testId: 'cell',
            content: <ListUnitPriceEdit
              product={product}
              viewShow={column.key}
              isDisable={freeExpired}
            />,
          });
        } else {
          cells.push({
            key,
            testId: 'cell',
            content: key !== 'name' ? (render as Function)(
              transformedContent,
              product,
              taxClasses,
            ) : (render as Function)(
              transformedContent,
              product.sku,
              image,
            ),
          });
        }
      });
      // push to the rows
      rows.push({
        cells,
        testId: 'row',
        key: product.id,
        onClick: () => {
          handleTableRowClick(product);
        },
      });
    });
  }
  return {
    head,
    rows,
  };
};

export const getChannelLogoWithChannelName = (teamChannel: TeamChannelForTableHead) => (
  <span style={{ verticalAlign: 'middle' }}>
    <ChannelLogo name={teamChannel.channel.name} />
    {' '}
    {teamChannel.name}
    {' '}
    (Inc.)
  </span>
);
