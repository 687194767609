import React from 'react';
import Spinner from '@atlaskit/spinner';
import { useProductImageQuery } from '../../graphql/types';
import noImage from '../../assets/svg/noimage.svg';

interface Props {
  teamID:string,
  productId:string,
  height:number,
  width:number,
}
const containStyle:React.CSSProperties = {
  objectFit: 'contain',
};
export default (
  {
    teamID, productId, height, width,
  }:Props,
) => {
  const { data, loading, error } = useProductImageQuery({
    variables: {
      teamID,
      productId,
    },
  });
  if (loading) return <Spinner size="xsmall" />;
  if (error) {
    return <img src={noImage} alt="" height={height} width={width} style={containStyle} />;
  }
  if (data && data.Product?.product) {
    const { name, images } = data.Product.product;
    const [firstImage] = images;
    return (
      <img
        data-testid="productThumbnail"
        src={firstImage?.url || noImage}
        alt={name}
        height={height}
        width={width}
        style={containStyle}
      />
    );
  }

  return <></>;
};
