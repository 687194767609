import { colors } from '@atlaskit/theme';
import styled from 'styled-components';

const OptionContainer = styled.ul`
  z-index:10;
  background-color:#fff;
  box-sizing:border-box;
  border: 1px solid #ccc;
  position: absolute;
  margin: 0;
  list-style:none;
  width:100%;
  border-radius:5px;
  padding:0px 2px 0px 2px;
  div {
    color:${colors.subtleText};
    padding:10px 7px 10px 7px;
  }
`;

export default OptionContainer;
