import * as React from 'react';
import { LinkItem, MenuGroup, Section } from '@atlaskit/menu';
import { useLocation } from 'react-router-dom';

export default function SettingMenu() {
  const location = useLocation();

  // Define an array of settings with their paths and titles
  const settings = [
    { path: '/settings/teamProfile', title: 'Company Profile' },
    { path: '/settings/teamSMTP', title: 'Company SMTP' },
    { path: '/settings/language', title: 'Language' },
    // { path: '/settings/teamSellers', title: 'Company Sellers' },
    // { path: '/settings/', title: 'Company Profile' },
  ];

  return (
    <MenuGroup>
      <Section title="Seetings">
        {settings.map((setting) => (
          <LinkItem
            key={setting.path}
            href={setting.path}
            isSelected={location.pathname === setting.path}
          >
            {setting.title}
          </LinkItem>
        ))}
      </Section>
    </MenuGroup>
  );
}
