import React, { useState } from 'react';
import Modal, {
  ModalTransition,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { Controller, useForm } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { useTranslation } from 'react-i18next';
import { useCreateCourierInquiryMutation } from '../../graphql/types';
import { ButtonContainer, ErrorMsg } from './AppInquiryModal.styles';

interface AppInquiryModalInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  teamID: string | undefined,
}

// interface FormValuesProps {
//   courierName: string,
// }

const AppInquiryModal = (props: AppInquiryModalInputProps) => {
  const { t } = useTranslation();
  const { isOpen, close, teamID } = props;

  const { control, handleSubmit, errors } = useForm();
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [createCourierInquiryMutation] = useCreateCourierInquiryMutation();

  const [errorMessage, setErrorMsg] = useState<string>();

  const onSubmit = (data: any) => { //eslint-disable-line
    setSpinner(true);
    const { courierName } = data;
    if (!teamID) return;
    createCourierInquiryMutation({
      variables: {
        courierName,
        teamID,
      },
    })
      .then(() => {
        setSpinner(false);
        close();
      })
      .catch((err: { graphQLErrors: { message: string; }[]; }) => {
        const msg = err?.graphQLErrors[0]?.message || 'send email failed';
        setErrorMsg(msg);
        setSpinner(false);
      });
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={close}
          width="medium"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <ModalHeader>
            <ModalTitle>{t('channels.ai.ai')}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <div className="subtitle">
                  {t('channels.ai.desc')}
                </div>
                <br />
                <Controller
                  as={Textfield}
                  name="courierName"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  testId="courierName"
                />
                {errors.courierName
                  && <ErrorMsg data-testid="courierName-error-message">email body is required</ErrorMsg>}
                {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
                <ButtonContainer>
                  <Button
                    testId="submit-button"
                    type="submit"
                    appearance="primary"
                    iconAfter={
                      showSpinner ? <Spinner appearance="invert" /> : <></>
                    }
                  >
                    {t('channels.ai.submit')}
                  </Button>
                  <Button
                    testId="cancel-button"
                    appearance="link"
                    onClick={() => close()}
                  >
                    {t('channels.ai.cancel')}
                  </Button>
                </ButtonContainer>
              </div>
            </form>
          </ModalBody>

        </Modal>
      )}
    </ModalTransition>
  );
};

export default AppInquiryModal;
