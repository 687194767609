const DEFAULT_STAT = [
  {
    name: 'All',
    value: '',
    isSelected: true,
    color: '',
  },
  {
    name: 'Open',
    value: 'open',
    abbr: 'O',
    isSelected: false,
    color: '#e34f32',
  },
  {
    name: 'Pending',
    value: 'pending',
    abbr: 'P',
    isSelected: false,
    color: '#3091ec',
  },
  {
    name: 'Solved',
    value: 'solved',
    abbr: 'S',
    isSelected: false,
    color: '#87929d',
  },
  {
    name: 'Closed',
    value: 'closed',
    abbr: 'C',
    isSelected: false,
    color: '#222222',
  },
];
export default DEFAULT_STAT;
