import React from 'react';
import ItemTitleCardContainer from '../styles/ItemTitleCardContainer';

const ItemTitleCard = (
  { title, sku, image }:
  {title:string, sku:string, image:string},
) => {
  const value = title.substr(0, 80);
  return (
    <ItemTitleCardContainer>
      <div className="wrapper">
        <div className="block image">
          <p style={{ fontSize: 11 }}>
            <img src={image} alt={`sku: ${sku}`} data-testid="itemTitleCard-image" />
          </p>
        </div>
        <div className="block details">
          <div className="title">{value}</div>
          <div className="sku">{sku}</div>
        </div>
      </div>

    </ItemTitleCardContainer>
  );
};

export default ItemTitleCard;
