import styled from 'styled-components';

const ItemTitleCardContainer = styled.div`
  align-items:center;
  display: flex;
  .block {
    margin-right: 8px;
    margin-bottom: 10px;
  }
  .block.image {
    width: 50px;
    height: 50px;
    float: left;
  } 
  .block.details {
    position:relative;
    overflow:hidden
  }
  .sku {
    color: rgb(107, 119, 140);
    font-size: 12px;
    line-height: 16px;
  }
  img {
    object-fit: contain;
    width: 50px;
    height: 50px;
    overflow-wrap: anywhere;
  }
  
`;

export default ItemTitleCardContainer;
