import { diff } from 'deep-diff';
import {
  isNumber, isNull, isUndefined, isArray,
} from 'lodash';
import { formatNumber } from 'accounting';

export const SKIPLIST = [
  'id',
  'teamID',
  'teamId',
  'createdViaTeamChannelID',
  'channelID',
  'externalCustomerID',
  'createdAt',
  'updatedAt',
  'productID',
  'teamChannelProductID',
  'orderID',
  'shipByDate',
  'externalId',
  'code',
  'order',
  'orderId',
  'paymentMethodId',
  'teamChannelId',
  'createdById',
  'updatedById',
  'paidById',
  'orderNumber',
  'extraInfo',
  'description',
  'active',
  'courierId',
  'noOfBoxes',
  'externalRes',
  'shipmentOrderId',
  'productTaxClassId',
  'labelUrl',
] as const;

export const CurrencyIdentifier = [
  'amt',
  'amount',
  'totalExpense',
  'price',
  'totalCost',
  'totalCostExTax',
  'totalCost',
  'totalExpense',
] as const;

export const isAmount = (key: string) => CurrencyIdentifier
  .map((each) => key.toLowerCase().search(each.toLowerCase()))
  .filter((each) => each !== -1)
  .length > 0;

export const nullToNone = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (
    isNull(value)
    || value === ''
    || isUndefined(value)
  ) {
    return 'None';
  }
  return value;
};

export const parseCurrencyAmount = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  desc: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  isCurrency: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  if (
    (isAmount(desc)
      && isNumber(value))
    || isCurrency
  ) {
    return formatNumber(value / 100, 2);
  }
  return value;
};

export const applyMiddleWare = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  desc: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  isCurrency: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  let result = parseCurrencyAmount(desc, value, isCurrency);
  result = nullToNone(result);
  return result;
};

export const getDiffs = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  before: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  after: any,
) => {
  const difference = diff(
    before,
    after,
    (_, key) => SKIPLIST.includes(key),
  );

  return difference;
};

export const getDesc = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  path: any,
) => {
  if (isArray(path) && path.length > 0) {
    return `${path[path.length - 1]}: `;
  }

  return '';
};
