import styled from 'styled-components';

const OrderDetailContainer = styled.div`
  box-sizing:border-box;
  padding:2rem;
  width:100%;
  color: rgb(23, 43, 77);  
  >*{
    overflow-wrap:break-word;
  }
`;

const OrderInfoContainer = styled.div`  
  margin-bottom:20px;
  width:100%;
  display:flex;
  .column {
    box-sizing:border-box;
    width:33%;
    padding-right:5px;
  }
`;

export default OrderDetailContainer;
export { OrderInfoContainer };
