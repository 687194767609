/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Form, {
  Field, FormSection, FormFooter, HelperMessage,
} from '@atlaskit/form';
import { useHistory } from 'react-router-dom';
import TextField from '@atlaskit/textfield';
import { Grid, GridColumn } from '@atlaskit/page';
import Button from '@atlaskit/button/new';
import { ButtonGroup } from '@atlaskit/button';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { useSmtpSendCheckMutation } from '../../graphql/types';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';

export interface SMTPEditorFormData {
  host: string,
  port: string,
  secure: boolean,
  username: string,
  password: string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (props: any) => {
  const { t } = useTranslation();
  const { teamId, values, actions } = props;
  const history = useHistory();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const [sendTo, setSendTo] = useState('');
  const [smtpSendCheckMutation] = useSmtpSendCheckMutation();

  const onSubmit = (data: SMTPEditorFormData) => {
    const mergedData = {
      ...data,
    };
    actions.handleSubmit(mergedData);
    // console.log(mergedData);
  };

  const isEmail = (email: string) => {
    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
    return regex.test(email);
  };

  const testSmtpButton = () => {
    if (isEmail(sendTo)) {
      smtpSendCheckMutation({
        variables: {
          teamId,
          sendTo,
        },
      }).then(({ data: resData }) => {
        if (resData?.SmtpSendCheck.checkSuccess) {
          addNotification('Email check success', 'success');
        } else {
          addNotification(resData?.SmtpSendCheck.errorMsg || 'Email test failed', 'danger');
        }
      // tell the form has updated
      })
        .catch((err) => {
          addNotification(graphQLErrorsReader(err), 'danger');
        });
      return undefined;
    }

    addNotification('Pls enter corrent email addrsss', 'danger');

    return undefined;
  };

  const renderFooterButton = (submitting: boolean) => (
    <FormFooter>
      <ButtonGroup>
        <Button
          appearance="default"
          isLoading={submitting}
          onClick={() => history.push('/')}
        >
          {i18next.t('settings.smtp.cancel') as string}
        </Button>
        <Button
          type="submit"
          appearance="primary"
          isLoading={submitting}
          ref={buttonRef}
        >
          {i18next.t('settings.smtp.save') as string}
        </Button>
      </ButtonGroup>
    </FormFooter>
  );

  return (
    <>
      <Form<SMTPEditorFormData>
        onSubmit={onSubmit}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <Grid>
              <GridColumn medium={3} />
              <GridColumn medium={6}>
                <FormSection title={t('settings.smtp.sco')}>
                  <Field
                    name="host"
                    label={t('settings.smtp.host')}
                    isRequired
                    defaultValue={values.host}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="hostInput" />
                        <HelperMessage>
                          {t('settings.smtp.shnaia')}
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                  <Field
                    name="port"
                    label={t('settings.smtp.port')}
                    isRequired
                    defaultValue={values.port}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="portInput" />
                        <HelperMessage>
                          {t('settings.smtp.dp')}
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                  <Field
                    name="username"
                    label={t('settings.smtp.username')}
                    isRequired
                    defaultValue={values.username}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField {...fieldProps} testId="usernameInput" />
                        <HelperMessage>
                          {t('settings.smtp.ti')}
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                  <Field
                    name="password"
                    label={t('settings.smtp.pwd')}
                    isRequired
                    defaultValue={values.password}
                  >
                    {({ fieldProps }) => (
                      <>
                        <TextField
                          {...fieldProps}
                          type="password"
                          testId="passwordInput"
                        />
                        <HelperMessage>
                          {t('settings.smtp.tiy')}
                        </HelperMessage>
                      </>
                    )}
                  </Field>
                </FormSection>
                <GridColumn>
                  {renderFooterButton(submitting)}
                </GridColumn>
              </GridColumn>

              <GridColumn medium={3} />
            </Grid>
          </form>
        )}
      </Form>
      <Grid>
        <GridColumn medium={3} />
        <GridColumn medium={6}>
          <FormSection title={t('settings.smtp.ste')}>
            <Field
              name="Send To"
              label={t('settings.smtp.st')}
              isRequired
            >
              {({ fieldProps }) => (
                <>
                  <TextField
                    {...fieldProps}
                    testId="sendToInput"
                    type="email"
                    onChange={(event) => setSendTo(event.currentTarget.value)}
                  />
                  <HelperMessage>
                    {t('settings.smtp.eye')}
                    <strong>
                      &nbsp;
                      {t('settings.smtp.tn')}
                      &nbsp;
                    </strong>
                    {t('settings.smtp.bt')}
                  </HelperMessage>
                </>
              )}
            </Field>
            <FormFooter>
              <Button
                type="submit"
                appearance="primary"
                // isLoading={submitting}
                ref={buttonRef}
                onClick={testSmtpButton}
              >
                {t('settings.smtp.tn')}
              </Button>
            </FormFooter>
          </FormSection>
        </GridColumn>
        <GridColumn medium={3} />
      </Grid>
    </>
  );
};
