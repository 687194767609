import client from '../../../../../graphql/client';
import getQuoteQuery from '../../../../../graphql/auspost/queries/getQuote';
import { AuspostGetQuoteQuery, ItemsInput } from '../../../../../graphql/types';

interface AuspostQuoteParams {
  itemsInput: ItemsInput;
}

export default ({
  itemsInput,
}: AuspostQuoteParams) => client.query<AuspostGetQuoteQuery>({
  query: getQuoteQuery,
  variables: {
    itemsInput,
  },
});
