import styled from 'styled-components';

export const RightAlignedText = styled.div`
  display:flex;
  justify-content:flex-end;
  font-weight:500;
`;

export const SummaryValue = styled.div`
`;

export const Footer = styled.div`
    margin-top:20px;
    display:flex;
    justify-content:flex-end;
    >*{
      margin-left:10px;
    }
`;
export const FlexBox = styled.div`
    display:flex;
    align-items:center;
`;

export const TotalCost = styled.div`

`;

export const TableContainer = styled.div`
table {
  table-layout:fixed;
}
`;

export const ProgressContainer = styled.div`
  padding:20px;
`;

export const ProgressDescriptionGroup = styled.div`
  color:#333333;
  margin-bottom:10px;  
`;

export const ProgressDescriptionItem = styled.div`
  display:flex;
  align-items:center;
`;

export const QueueText = styled.div`
  color:#333333;
  width:100%;
  text-align:center;
`;

export const SkuBox = styled.div`
padding-right:5px;
`;

export const ValueBefore = styled.div`

`;

export const ValueAfter = styled.div`

`;

export const MainContent = styled.div`
margin-top:10px;
`;
