import { ApolloError } from 'apollo-client/errors/ApolloError';

export default (error: ApolloError) => {
  let errMsg = '';
  if (error?.graphQLErrors) {
    error.graphQLErrors.forEach((e) => {
      errMsg += `${e.message} `;
    });
  }
  if (error?.networkError) {
    errMsg += error.networkError.message;
  }

  if (errMsg) return errMsg;

  return error.message || 'unknow error';
};
