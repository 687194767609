import React from 'react';
import { Grid } from '@atlaskit/primitives';

import SettingMenu from './SettingMenu';

export default function Settings() {
  return (
    <Grid
      testId="grid-basic"
      rowGap="space.200"
      columnGap="space.400"
      templateColumns="1fr 5fr"
    >
      <SettingMenu />
    </Grid>
  );
}
