import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button/new';
import Spinner from '@atlaskit/spinner';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import Select from '@atlaskit/select';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { isString } from 'lodash';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  UserInvitationContainer,
  FormHeader,
  Label,
  ButtonContainer,
  ErrorMsg,
  MessageWrapper,
} from './UserInvitation.styles';
import { useInviteUserMutation } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';

interface ValueProps {
  email: string,
  selectedTeam: {
    label: string,
    value: string,
  },
}

export default function UserInvitation() {
  const { t } = useTranslation();
  const { state: { teams } } = useAuth();
  const { email: defaultEmail } = queryString.parse(window.location.search);

  const options = teams.map((team) => ({
    label: team.name,
    value: team.id,
  }));
  const {
    control, handleSubmit, errors, getValues,
  } = useForm();
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [inviteUser] = useInviteUserMutation();
  const [errorMessage, setErrorMsg] = useState<string>();
  const history = useHistory();
  const onSubmit = ({ email, selectedTeam }: ValueProps) => {
    setSpinner(true);
    inviteUser({ variables: { email, teamID: selectedTeam.value } })
      .then(() => {
        setSpinner(false);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
          history.push('/users');
        }, 2000);
      })
      .catch((err) => {
        const msg = err.graphQLErrors[0]?.message || 'server internal error';
        setErrorMsg(msg);
        setSpinner(false);
      });
  };
  return (
    <UserInvitationContainer>
      <FormHeader>{t('users.ui.ui')}</FormHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label>{t('users.ui.iue')}</Label>
        <Controller
          as={Textfield}
          name="email"
          placeholder={t('users.ui.placeholder')}
          control={control}
          defaultValue={isString(defaultEmail) ? defaultEmail : ''}
          rules={{
            required: true,
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: i18next.t('users.ui.message'),
            },
          }}
          testId="user-invitation-email"
        />
        {errors.email && <ErrorMsg>{errors.email.message || 'Email required'}</ErrorMsg>}
        <Label>{t('users.ui.team')}</Label>
        <Controller
          control={control}
          name="selectedTeam"
          rules={{ required: true }}
          defaultValue={options[0]}
          render={({ onChange, value }) => (
            <Select
              onChange={(val) => onChange(val)}
              options={options}
              value={value}
              placeholder="Choose a team"
            />
          )}
          data-testid="testid"
        />
        {errors.selectedTeam && <ErrorMsg>{errors.selectedTeam.message || 'Team required'}</ErrorMsg>}
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
        <ButtonContainer>
          <Button
            type="submit"
            appearance="primary"
            iconAfter={(iconProps) => <Spinner {...iconProps} appearance="invert" />}
            testId="user-invite-submit-btn"
          >
            {t('users.ui.submit')}
          </Button>
        </ButtonContainer>
      </form>
      {showMessage
        && (
          <MessageWrapper>
            <Flag
              icon={<SuccessIcon primaryColor={G300} label="Info" />}
              description={`${t('users.ui.uieis')} ${getValues('email')}`}
              id="1"
              key="1"
              title="User Invitation"
              testId="user-invitation-success-message"
            />
          </MessageWrapper>
        )}
    </UserInvitationContainer>
  );
}
