import React, { useEffect } from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';
import { useCreateShipmentMutation } from '../../../../graphql/types';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';
import { isShipmentValid } from '../../helper';

const ManualShipConfirmButton = (
  { onShipped }:{onShipped:()=>void},
) => {
  const { state, dispatch } = useShipping();
  const [createShipment] = useCreateShipmentMutation();
  const close = () => dispatch({ type: 'setIsModalOpen', payload: false });

  // validation check
  useEffect(() => {
    if (isShipmentValid(state.shipment)) {
      dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
    } else {
      dispatch({ type: 'setIsPrimaryButtonDisabled', payload: true });
    }
  }, [state.shipment, state.ui.stage]); // eslint-disable-line

  const createManualShipment = async () => {
    dispatch({ type: 'setIsSubmiting', payload: true });
    dispatch({ type: 'setIsPrimaryButtonDisabled', payload: true });
    dispatch({ type: 'setError', payload: '' });
    const {
      orderId,
      trackingNumber,
      courierId,
      totalCost,
      totalWeight,
    } = state.shipment;

    const variables = {
      shipment: {
        orderId,
        trackNumber: [trackingNumber],
        courierId,
        weight: Number(totalWeight),
        cost: Number(totalCost),
      },
    };

    await createShipment({
      variables,
    })
      .then(() => {
        // callback
        onShipped();
        close();
      })
      .catch((err) => {
        dispatch({ type: 'setError', payload: graphQLErrorsReader(err) });
      })
      .finally(() => {
        dispatch({ type: 'setIsSubmiting', payload: false });
        dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
      });
  };

  return (
    <Button
      appearance="primary"
      isDisabled={state.ui.isPrimaryButtonDisabled}
      onClick={createManualShipment}
      testId="ManualShipConfirmButton"
    >
      Confirm
    </Button>
  );
};

export default ManualShipConfirmButton;
