import { store } from 'react-notifications-component';

export default (
  message: string,
  type: 'success' | 'danger' | 'info' | 'default' | 'warning' | undefined,
) => {
  store.addNotification({
    message,
    type,
    insert: 'top',
    container: 'top-right',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
    },
  });
};
