import React from 'react';
import Form, {
  Field,
  FormFooter,
  ErrorMessage,
  FormHeader,
  FormSection,
} from '@atlaskit/form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button/new';

import {
  FormFooterChildContainer,
  FormTitle,
} from './ForgotPassword.styles';

export interface ResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
  error: string;
}

interface ResetPasswordFormProps{
  handleResetPassword: (formData: ResetPasswordFormData) => Promise<{
    passwordConfirmation: string | undefined;
  } | undefined>,
  error:string,
}
const ResetPasswordForm = (props: ResetPasswordFormProps) => {
  const { handleResetPassword, error } = props;
  return (
    <Form<ResetPasswordFormData>
      onSubmit={handleResetPassword}
    >
      {({ formProps, submitting }) => (
        <form {...formProps}>
          <FormHeader>
            <FormTitle>Choose a new password?</FormTitle>
          </FormHeader>
          <FormSection>
            <Field
              name="password"
              label="New password"
              defaultValue=""
              isRequired
            >
              {({ fieldProps }) => (
                <Textfield testId="password" type="password" {...fieldProps} />
              )}
            </Field>
            <Field
              name="passwordConfirmation"
              label="Confirm new poassword"
              defaultValue=""
              isRequired
            >
              {({ fieldProps, error: formError }) => (
                <>
                  <Textfield testId="passwordConfirmation" type="password" {...fieldProps} />
                  {formError && <ErrorMessage testId="passwordConfirmationErr">{formError}</ErrorMessage>}
                </>
              )}
            </Field>

          </FormSection>
          {error
            && <ErrorMessage testId="serverError">{error}</ErrorMessage>}
          <FormFooter align="start">
            <FormFooterChildContainer>
              <Button
                type="submit"
                appearance="primary"
                isLoading={submitting}
                shouldFitContainer
              >
                Continue
              </Button>
              <ul>
                <li>
                  <a href="/signin">Return to log in</a>
                </li>
              </ul>
            </FormFooterChildContainer>
          </FormFooter>
        </form>
      )}
    </Form>
  );
};

export default ResetPasswordForm;
