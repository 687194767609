import React, { useState } from 'react';
import { Chart, Legend, Line } from 'bizcharts';
import Select from '@atlaskit/select';
import styled from 'styled-components';
import i18next from 'i18next';

export const FilterItem = styled.div`
  width: 300px;
  margin-left: 90em;
`;

export interface LineChartDataType {
  target: string,
  x: string,
  y: number
}

const LineChart = ({
  data, prefix = '', postfix = '', initchartOptions,
}:
{data: LineChartDataType[], prefix: string, postfix: string, initchartOptions: []}) => {
  const [skuOptions, setSKUOptions] = useState({
    label: i18next.t('home.chooseTheDisplayValue'), value: 'ALL',
  });
  const handleSKUChange = (val: { label: string, value: string }) => {
    setSKUOptions(val);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SKUChange = (options: any) => {
    handleSKUChange(options);
  };

  return (
    <>
      <FilterItem>
        <Select
          className="multi-select"
          classNamePrefix="react-select"
          options={initchartOptions}
          isSearchable
          placeholder="choose the display value"
          onChange={(option) => SKUChange(option)}
          value={skuOptions}
        />
      </FilterItem>
      <Chart
        scale={{ y: { min: 0 } }}
        padding={[30, 20, 50, 40]}
        autoFit
        height={320}
        data={data}
      >
        <Line
          shape="smooth"
          position="x*y"
          color="target"
          label={[
            'y',
            (yValue) => ({
              content: yValue > 0 ? `${prefix}${yValue}${postfix}` : '',
            }),
          ]}
        />
        <Legend
          name="target"
          filter={skuOptions === null || skuOptions.value === 'ALL' ? undefined : (target) => target === skuOptions.value}
          onChange={() => {
            setSKUOptions({ label: 'Choose the display value', value: 'ALL' });
          }}
        />
      </Chart>

    </>
  );
};

export default LineChart;
