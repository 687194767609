import gql from 'graphql-tag';
import sharedOrderFragment from '../fragments/sharedOrderFragment';

const getOrderById = gql`
query GetOrderById($orderId: String!){
  GetOrderById(orderId: $orderId){
    status,
    order{
      ...sharedOrderFragment,
    }
  }
}
${sharedOrderFragment}
`;

export default getOrderById;
