import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { isUndefined } from 'lodash';
import CustomerFinder from './FindCustomerSelectUI';
import { TeamCustomer } from '../../types';
import FindCustomerPreview from './FindCustomerPreview';
import { Footer } from '../../shares/widget.style';

const closeNewOrderModalEvent = new CustomEvent('closeNewOrderModal');

const SelectCustomerContainer = styled.div`
    margin-top:5px;
    display:flex;
    align-items:center;
    align-content:center;
    .customer-finder {
      flex-basis:100%;
    }
    .customer-add {
      margin-left:5px;
    }
`;

interface Props {
  onConfirmSelection:(teamCustomer:TeamCustomer | undefined)=>void,
  elementAfter:React.ReactNode,
}
export default ({ onConfirmSelection, elementAfter }:Props) => {
  const [teamCustomer, setTeamCustomer] = useState<TeamCustomer>();

  const confirmButtonIsDisabled = isUndefined(teamCustomer);

  const closeOrderCreationModal = () => {
    document.dispatchEvent(closeNewOrderModalEvent);
  };

  const cb = useCallback((tc:TeamCustomer | undefined) => {
    setTeamCustomer(tc);
  }, []);

  const handleCallback = () => {
    onConfirmSelection(teamCustomer);
  };

  return (
    <>
      <h5>Find customer</h5>
      <SelectCustomerContainer>
        <div className="customer-finder">
          <CustomerFinder onSelect={cb} />
        </div>
        <div className="customer-add">
          {elementAfter}
        </div>
      </SelectCustomerContainer>

      <FindCustomerPreview
        teamCustomer={teamCustomer}
      />

      <Footer>
        <Button
          onClick={closeOrderCreationModal}
        >
          Cancel
        </Button>
        <Button
          onClick={handleCallback}
          isDisabled={confirmButtonIsDisabled}
          appearance="primary"
        >
          Confirm
        </Button>
      </Footer>

    </>
  );
};
