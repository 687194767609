export default {
  cells: [
    {
      key: 'orderNumber',
      content: 'Order Number',
      testId: 'orderNumber',
    },
    {
      key: 'createdAt',
      content: 'Created At',
      testId: 'createdAt',
    },
    {
      key: 'updatedAt',
      content: 'Updated At',
      testId: 'updatedAt',
    },
    {
      key: 'total',
      content: 'Total',
      testId: 'total',
    },
    {
      key: 'status',
      content: 'Status',
      testId: 'status',
    },
  ],
  testId: 'head',
};
