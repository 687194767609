import React, { useState, useEffect } from 'react';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import { useGetCouriersQuery, GetCouriersQuery } from '../../graphql/types';
// eslint-disable-next-line
export type Couriers = Extract<GetCouriersQuery['GetCouriers']['couriers'], Array<any>>;
export type Courier = Couriers[number];

interface Props {
  // eslint-disable-next-line react/require-default-props
  placeholder?:string,
  // eslint-disable-next-line react/require-default-props
  lookupDefaultValue?:string,
  onChange:(selectedCourier:Courier)=>void,
}

export default ({
  placeholder = 'Select courier',
  lookupDefaultValue = undefined,
  onChange,
}:Props) => {
  const { data, loading } = useGetCouriersQuery();
  const [value, setValue] = useState<OptionType|undefined>(undefined);

  const options = data?.GetCouriers?.couriers?.map((cur) => ({
    label: cur.name, value: cur.id,
  }));

  const handleChange = (option:ValueType<OptionType>) => {
    setValue(option as OptionType);
  };

  // callback should be called on selected value changes
  useEffect(() => {
    const couriers = data?.GetCouriers.couriers;
    if (couriers) {
      const [selectedCourier] = couriers.filter(
        (cur) => cur.id === value?.value,
      );
      if (selectedCourier) {
        onChange(selectedCourier);
      }
    }
  }, [value, data, onChange]);

  // component should update on data arrive
  useEffect(() => {
    if (lookupDefaultValue && data?.GetCouriers.couriers) {
      const [lookupValue] = data.GetCouriers.couriers.filter(
        (cur) => cur.code === lookupDefaultValue,
      );
      if (lookupValue) {
        setValue({
          label: lookupValue.name,
          value: lookupValue.id,
        });
      }
    }
  }, [data, lookupDefaultValue]);

  return (
    <Select<OptionType>
      options={options}
      isLoading={loading}
      onChange={handleChange}
      placeholder={placeholder}
      aria-label={placeholder}
      value={value}
    />
  );
};
