import React from 'react';
import { Buttons } from '../../Shipping.style';
import PostShipmentButton from '../Buttons/PostShipmentButton';
import PostShipmentReview from '../PostShipmentReview';

export default ({ onShipped }:{onShipped:()=>void}) => (
  <>
    <PostShipmentReview />
    <Buttons>
      <PostShipmentButton onShipped={onShipped} />
    </Buttons>
  </>
);
