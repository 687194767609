import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';

interface CallToConfirmModalProps {
  heading: string,
  message: React.ReactNode,
  onConfirm: Function,
  drawToActionComponent: (onClick: () => void) => JSX.Element,
}

const SimpleConfirmModal = ({
  heading,
  message,
  onConfirm,
  drawToActionComponent,
}: CallToConfirmModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  return (
    <>
      {drawToActionComponent(() => setIsOpen(true))}
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            width="medium"
          >
            <ModalHeader>
              <ModalTitle appearance="danger">{heading}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {message}
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleConfirm} testId="confirmButton">Confirm</Button>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>

  );
};

export default SimpleConfirmModal;
