import React from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import EbayConnector from '../components/EbayConnector/EbayConnector';

export default () => {
  const { teamChannelID } = useParams<{teamChannelID : string}>();
  let { redirectPath } = queryString.parse(window.location.search);
  if (!redirectPath) redirectPath = '/';
  return (
    <EbayConnector
      teamChannelId={teamChannelID}
      redirectPath={redirectPath as string} // the path to be redirected after oAuth
    />
  );
};
