import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import firebase from '../../utils/firebase';

interface ProgressInputProps {
  jobId: string,
  numOfProducts: number,
  setDownloadReport: Function,
}
const env = process.env.REACT_APP_NODE_ENV || 'development';

const BulkProductsUpdateProgressBar = (props: ProgressInputProps) => {
  const {
    jobId, numOfProducts, setDownloadReport,
  } = props;
  const [completeNum, setCompleteNum] = useState(0);
  const completeRef = firebase.firestore()
    .collection(`scheduleJob_${env}`)
    .where('jobId', '==', jobId);
  useEffect(() => {
    completeRef.onSnapshot((querySnapshot) => {
      if (querySnapshot.size === numOfProducts) {
        setDownloadReport(false);
      }
      setCompleteNum(querySnapshot.size);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Progress
        strokeColor={{
          from: '#108ee9',
          to: '#87d068',
        }}
        percent={Math.floor((completeNum / numOfProducts) * 100)}
        status="active"
      />
    </div>
  );
};
export default BulkProductsUpdateProgressBar;
