import styled from 'styled-components';

export const LabelContainer = styled.div`
  color: #6B778C;
  margin-top: 20px;
  font-size: 0.85em;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;
