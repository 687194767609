import styled from 'styled-components';

const FilterWrapper = styled.div`
  min-width: 600px;
  border:1px solid #ccc;
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  padding:10px;
  .statusSelector {
    width:200px;
    margin:10px;
  }
  .dateSelector {
    width:200px;
    margin:10px;
  }
  .teamChannelsSelector {
    min-width:200px;
    margin:10px;
  }
  .filterTypeSelector {
    width:200px;
    margin:10px;
  }
  .xeroConnectSelector {
    min-width:200px;
    margin:10px;
  }
  .productFilter {
    width:200px;
    margin:10px;
    display:flex;
    align-content:center;
    align-items:center;
    position: relative;
    .autoComplete {
      z-index:999;
      width:200px;
      list-style:none;
      padding:0px;
      margin:0px;
      display:block;     
      position:absolute;
      top:40px;
      border:1px solid #DFE1E6;
      border-radius:5px;
      background-color:#fff;
      li {
        padding:5px;
        &:hover{
          background-color:#ccc;
          cursor:pointer;
        }
        
      }
    }
  }
  .reset {
    margin:10px;
  }
`;

export default FilterWrapper;
