import React, {
  useEffect,
  useState,
} from 'react';
import Form, {
  Field, FormFooter, FormSection,
} from '@atlaskit/form';
import styled from 'styled-components';

import Avatar from '@atlaskit/avatar';
import Tag from '@atlaskit/tag';
import TextField from '@atlaskit/textfield';
import { Grid, GridColumn } from '@atlaskit/page';
import Button, { ButtonGroup } from '@atlaskit/button';
import { ApolloError } from 'apollo-client';
import addNotification from '../../utils/addNotification';
import Box from '../Box';
import {
  useGetTeamByIdQuery,
  useUpdateTeamSellersByIdMutation,
  useDelTeamSellerByIdMutation,
} from '../../graphql/types';
// import SMTPEditorForm, { SMTPEditorFormData } from './SMTPEditorForm';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';

interface TeamProfileEditorProps {
  teamId: string,
}

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width:100%;
`;

export interface TSEFormData {
  username: string,
}

const TeamSellersEditor = (props: TeamProfileEditorProps) => {
  const [updateTeamSellersById] = useUpdateTeamSellersByIdMutation();
  const [delTeamSellerById] = useDelTeamSellerByIdMutation();
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const { teamId } = props;
  // const [updateTeamSmtpSetting] = useSetTeamSmtpMutation();
  const {
    data,
  } = useGetTeamByIdQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });

  const [sellers, setSellers] = useState<string[]>([]);
  // console.log(sellersFromApi.split(','));
  // const { dispatch } = useAuth();

  useEffect(() => {
    const tss = data?.GetTeamById.team?.sellers;
    if (tss) {
      setSellers(tss.split(','));
    }
  }, [data]);

  const handleDel = async (seller: string) => {
    await delTeamSellerById({
      variables: {
        teamId,
        seller,
      },
    })
      .then(() => {
      })
      .catch((err: ApolloError) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const handleSubmit = async (submitInfo: TSEFormData) => {
    // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxx', submitInfo);
    await updateTeamSellersById({
      variables: {
        teamId,
        sellers: [
          ...sellers,
          submitInfo.username,
        ].join(','),
      },
    })
      .then(() => {
        setSellers([
          ...sellers,
          submitInfo.username,
        ]);
      })
      .catch((err: ApolloError) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const renderFooterButton = () => (
    <FormFooter>
      <ButtonGroup>
        <Button
          type="submit"
          appearance="primary"
          // isLoading={submitting}
          ref={buttonRef}
        >
          Submit
        </Button>
      </ButtonGroup>
    </FormFooter>
  );

  return (
    <Wrapper>
      <Box style={{ minWidth: 400 }}>
        <h3>Team Sellers</h3>
        <br />
        {sellers.length > 0
          && sellers.map((element, index) => (
            <Tag
              onAfterRemoveAction={handleDel}
              // eslint-disable-next-line react/no-array-index-key
              key={`${element}-sellers-${index}`}
              appearance="rounded"
              removeButtonLabel="Remove"
              text={element}
              isRemovable
              elemBefore={<Avatar borderColor="transparent" size="xsmall" />}
            />
          ))}
        <Form<TSEFormData>
          onSubmit={handleSubmit}
        >
          {({ formProps }) => (
            <form {...formProps}>
              <Grid>
                <GridColumn medium={6} />
                <GridColumn medium={12}>
                  <FormSection title="Add New Team Seller">
                    <Field
                      name="username"
                      label="Please enter Seler name"
                      // isRequired
                      defaultValue=""
                    >
                      {({ fieldProps }) => (
                        <>
                          <TextField
                            {...fieldProps}
                            testId="addTeamNewSeller"
                            elemBeforeInput={<Avatar size="small" borderColor="transparent" />}
                          />
                        </>
                      )}
                    </Field>

                  </FormSection>
                  <GridColumn>
                    {renderFooterButton()}
                  </GridColumn>
                </GridColumn>

                <GridColumn medium={6} />
              </Grid>
            </form>
          )}
        </Form>
      </Box>
    </Wrapper>
  );
};

export default TeamSellersEditor;
