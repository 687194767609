import React from 'react';
import { LeftSidebar, Main } from '@atlaskit/page-layout';
import { isUndefined } from 'lodash';
import { gridSize } from '@atlaskit/theme';
import styled from 'styled-components';

const PaddingRight20 = styled.div`
  padding-right:20px;  
`;

const SplitView = (
  { left, right }: {
    left: React.ReactNode | null,
    right: React.ReactNode | null,
  },
) => {
  let defaultProps = {};
  const defaultLeftWidth = Math.floor((
    (window.innerWidth - gridSize() * 3 * 2) / 2));

  try {
    const DS_PAGE_LAYOUT_UI_STATE = JSON.parse(
      window.localStorage.getItem('DS_PAGE_LAYOUT_UI_STATE')!,
    ).gridState.leftSidebarWidth;

    if (isUndefined(DS_PAGE_LAYOUT_UI_STATE)) {
      defaultProps = { width: defaultLeftWidth };
    } else {
    // if has value stored, let the UI kit to control the layout
      defaultProps = {};
    }
  } catch (error) {
    defaultProps = { width: defaultLeftWidth };
  }

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      {/* if left and right both has value */}
      {(left && right)
        && (
        <>
          <LeftSidebar
            testId="left-sidebar"
            {...defaultProps}
          >
            <PaddingRight20>{left}</PaddingRight20>
          </LeftSidebar>
          <Main
            testId="main-content"
          >
            {right}
          </Main>
        </>
        )}

      {/* if only left has value */}
      {(left && !right)
        && (
        <Main
          testId="main-content"
        >
          {left}
        </Main>
        )}

      {/* if only right has value */}
      {(!left && right)
        && (
        <Main
          testId="main-content"
        >
          {right}
        </Main>
        )}
    </div>
  );
};

export default SplitView;
