import React from 'react';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { cloneDeep, isNaN } from 'lodash';
import i18next from 'i18next';
import ProductFinder from '../ProductFinder/ProductFinder';
import { Product } from '../ProductsListing/types';
import { TeamTaxClasses } from '../OrderItemEditor/types';
import ProductTitle from '../ProductTitle/ProductTitle';
import QtyEditor from '../QtyEditor/QtyEditor';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import PriceEditor from '../PriceEditor/PriceEditor';
import RemoveButton from '../RemoveButton/RemoveButton';
import { getCompoundedTaxRateMutiplier } from '../../utils/taxHelper';
import {
  RightAlignedText, SummaryValue, TotalCost, TableContainer, SkuBox,
} from './styles';
import { PurchaseFormEditorProps } from './types';

const head:HeadType = {
  cells: [
    {
      content: i18next.t('recv.head.name') as string,
      width: 40,
    },
    {
      content: 'SKU',
      width: 25,
    },
    {
      content: i18next.t('recv.head.qty') as String,
      width: 10,
    },
    {
      content: i18next.t('recv.head.uc') as String,
      width: 15,
    },
    {
      content: i18next.t('recv.head.tc') as String,
      width: 10,
    },
    {
      content: '',
      width: 5,
    },
  ],
};

export default ({
  lineItems,
  setLineItems,
  isSubmiting,
}:PurchaseFormEditorProps) => {
  const onSelect = (
    product:Product,
    teamTaxClasses:TeamTaxClasses,
  ) => {
    setLineItems([
      ...lineItems,
      {
        id: product.id,
        name: product.name,
        sku: product.sku,
        purchaseQty: 1,
        unitCostEx: 0.00,
        status: 'Pending',
        product,
        teamTaxClasses,
      },
    ]);
  };

  const onQtyChange = (newQty:number, index:number) => {
    try {
      setLineItems((prev) => {
        const mutableLineItems = cloneDeep(prev);
        mutableLineItems[index].purchaseQty = newQty;
        return mutableLineItems;
      });
    } catch (error) {
      // leave blank intentional
    }
  };

  const onUnitCostExChange = (newUnitCost:number|string, index:number) => {
    try {
      setLineItems((prev) => {
        const mutableLineItems = cloneDeep(prev);
        mutableLineItems[index].unitCostEx = newUnitCost;
        return mutableLineItems;
      });
    } catch (error) {
      // leave blank intentional
    }
  };

  const onRemove = (index:number) => {
    try {
      setLineItems((prev) => {
        const mutableLineItems = cloneDeep(prev);

        return mutableLineItems.filter((_, i) => i !== index);
      });
    } catch (error) {
      // leave blank intentional
    }
  };

  const totalTax = (() => {
    let value = 0;
    lineItems.forEach((item) => {
      const {
        teamTaxClasses, purchaseQty, unitCostEx: unitCostExRaw, product,
      } = item;
      const taxMutiplier = getCompoundedTaxRateMutiplier(product.productTaxClass?.id || '', teamTaxClasses);

      const unitCostEx = Number(unitCostExRaw);

      if (!isNaN(unitCostEx)) {
        value += unitCostEx * (taxMutiplier - 1) * purchaseQty;
      }
    });

    return value;
  })();

  const subTotal = (() => {
    let value = 0;
    lineItems.forEach((item) => {
      const { purchaseQty, unitCostEx: unitCostExRaw } = item;
      const unitCostEx = Number(unitCostExRaw);

      if (!isNaN(unitCostEx)) {
        value += unitCostEx * purchaseQty;
      }
    });

    return value;
  })();

  const rows:Array<RowType> = (() => {
    const rowItems:Array<RowType> = lineItems.map((item, index) => ({
      key: `${item.id}-${index}`,
      cells: [
        {
          content: <ProductTitle product={item.product} />,
        },
        { content: <SkuBox>{item.sku}</SkuBox> },
        {
          content: <QtyEditor
            qty={item.purchaseQty}
            onChange={(newQty:number) => onQtyChange(newQty, index)}
            isDisabled={isSubmiting}
            testid="qtyEditor"
          />,
        },
        {
          content: <PriceEditor
            price={item.unitCostEx}
            onChange={(newUnitCost:number|string) => onUnitCostExChange(
              newUnitCost,
              index,
            )}
            isDisabled={isSubmiting}
            testid="costEditor"
          />,
        },
        {
          content:
  <TotalCost data-testid="totalCost">
    {
              formatMoneyWithPrefix(
                (item.purchaseQty * (Number(item.unitCostEx) || 0) * 100),
              )
            }
  </TotalCost>,
        },
        {
          content: <RemoveButton
            onRemove={() => onRemove(index)}
            isDisabled={isSubmiting}
          />,
        },
      ],
    }
    ));

    const purchaseSummary:Array<RowType> = [
      {
        key: 'subTotal',
        cells: [
          {
            content: <RightAlignedText>Subtotal (Excl. GST):</RightAlignedText>,
            colSpan: head.cells.length - 2,
          },
          {
            content:
  <SummaryValue>
    {formatMoneyWithPrefix(subTotal * 100)}
  </SummaryValue>,
            colSpan: 2,
          },
        ],
      },
      {
        key: 'totalTax',
        cells: [
          {
            content: <RightAlignedText>Total GST:</RightAlignedText>,
            colSpan: head.cells.length - 2,
          },
          {
            content:
  <SummaryValue>
    {formatMoneyWithPrefix(totalTax * 100)}
  </SummaryValue>,
            colSpan: 2,
          },
        ],
      },
      {
        key: 'total',
        cells: [
          {
            content: <RightAlignedText>Total (Inc GST):</RightAlignedText>,
            colSpan: head.cells.length - 2,
          },
          {
            content:
  <SummaryValue>
    {formatMoneyWithPrefix((totalTax + subTotal) * 100)}
  </SummaryValue>,
            colSpan: 2,
          },
        ],
      },
    ];

    const productFinder:RowType = {
      key: 'finder',
      cells: [
        {
          content: <ProductFinder
            onSelect={onSelect}
            isDisabled={isSubmiting}
          />,
          colSpan: head.cells.length,
        },
      ],
    };

    return [
      ...rowItems,
      productFinder,
      ...(rowItems.length > 0 ? purchaseSummary : []),
    ];
  })();

  return (
    <TableContainer>
      <DynamicTableStateless
        head={head}
        rows={rows}
      />
    </TableContainer>
  );
};
