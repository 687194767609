import styled from 'styled-components';

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 651px;
  & > div {
    border-top: none;
    border-left: none;
    border-right: none;
    background: white;
    border-radius: 0;
    border-bottom: 3px solid rgb(0, 101, 255);
  }
  & input[name="search"] {
    font-size: 2em;
    padding-left: 0px;
    margin-bottom: 5px;
  }
  & input.searchComplated[name="search"] {
    font-size: 1.25em;
    margin-bottom: 0px;
    transition: all .2s ease-in-out;
  }
`;

export default InputWrap;
