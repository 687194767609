import i18next from 'i18next';

export default {
  cells: [
    {
      key: 'fullName',
      content: i18next.t('users.table.fn'),
      testId: 'fullName',
    },
    {
      key: 'email',
      content: i18next.t('users.table.email'),
      testId: 'email',
    },
    {
      key: 'activated',
      content: i18next.t('users.table.activated'),
      testId: 'activated',
    },
    {
      key: 'createdAt',
      content: i18next.t('users.table.cd'),
      testId: 'createdAt',
    },
    {
      key: 'updatedAt',
      content: i18next.t('users.table.ud'),
      testId: 'updatedAt',
    },
    {
      key: 'action',
      content: i18next.t('users.table.action'),
      testId: 'action',
    },
  ],
  testId: 'head',
};
