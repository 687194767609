import styled from 'styled-components';

export const LineHead = styled.h4`
  font-size: 16px;
  color: #6B778C;
  margin-right: 160px;
`;

export const LineContent = styled.p`
font-size: 16px;
color: black;
margin-right: 160px;
`;

export const TicketInfoCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TicketInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 160px;
`;

export const TicketInfoLabel = styled.label`
  font-size: 0.85em;
  color: #6B778C;
  font-weight: 600;
  line-height: 2em;
`;

export const TicketInfoInput = styled.div`
  margin-left: -8px;
  margin-top: -12px;
`;

export const MessageWrapper = styled.div`
  position: fixed;
  left: 40px;
  bottom: 40px;
`;

export const TicketInfoSummaryContainer = styled.div`
  margin-bottom: 40px;
`;
