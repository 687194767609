import React, { useState, SyntheticEvent } from 'react';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import Pagination from '@atlaskit/pagination';
import styled from 'styled-components';
import { useLogsQuery } from '../../../graphql/types';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import MerpAvatar from '../../MerpAvatar/MerpAvatar';
import MerpDateTime from '../../MerpDateTime/MerpDateTime';
import DefaultAvatar from '../Widgets/DefaultAvatar';
import DefaultName from '../Widgets/DefaultName';
import AuditLogCommentCard from './AuditLogCommentCard';

const LogRecord = styled.li`
  list-style:none;
  padding:0;
  margin:0px 0px 10px 0px;
  display:flex;
  align-items:flex-start;
  width:100%;
  .content span {
    font-weight:500;
  }
  .date {
    margin-left: 5px;
    color:rgb(107, 119, 140);
  }
  .title {
    display: flex;
    flex-direction: row;
  }
  * {
    margin-right:5px;
  }  
`;

export const RecordGroup = styled.ul`
  padding:0;
  margin:0;
`;

export const PaginationWrapper = styled.div`
display:flex;
justify-content:flex-end;
`;

interface Props {
  teamId:string,
  tableId:string,
  take?:number,
}
const HistoryLogsViewer = (
  { teamId, tableId, take = 10 }:Props,
) => {
  const [skip, setSkip] = useState(0);
  const {
    data, loading, error, refetch,
  } = useLogsQuery({
    variables: {
      teamId,
      skip,
      take,
      tableId,
      type: 'API',
    },
    fetchPolicy: 'network-only',
  });

  React.useEffect(() => {
    const callback = () => {
      refetch();
    };
    document.body.addEventListener('refetchLogs', callback);
    return () => {
      document.body.removeEventListener('refetchLogs', callback);
    };
  }, [refetch]);

  const handlePageChange = async (event: SyntheticEvent, newPage: number) => {
    setSkip((newPage - 1) * take);
  };

  const logs = data?.Logs.logs;
  const total = data?.Logs.total;
  const totalPages = Math.ceil((total ?? 0) / take);
  const pages = [];
  for (let i = 1; i <= totalPages; i += 1) {
    pages.push(i);
  }

  if (error) {
    return (
      <SectionMessage
        title="It appears to be something wrong."
        appearance="error"
      >
        <p>{graphQLErrorsReader(error)}</p>
      </SectionMessage>
    );
  }

  if (loading) {
    return (
      <Spinner size="medium" />
    );
  }
  const records = logs?.map((log) => {
    const {
      id, content, createdBy, createdAt, comments,
    } = log;

    return (
      <LogRecord
        key={id}
      >
        <div className="avatar">
          {createdBy && (
            <MerpAvatar
              firstName={createdBy.firstName}
              lastName={createdBy.lastName}
              size={32}
            />
          )}
          {!createdBy && <DefaultAvatar />}
        </div>
        <div className="content">
          <div className="title">
            <span>
              {createdBy && `${createdBy.firstName} ${createdBy.lastName}`}
              {!createdBy && <DefaultName />}
            </span>
            {content}
            <div className="date"><MerpDateTime>{createdAt}</MerpDateTime></div>
          </div>
          <div className="comments">
            <AuditLogCommentCard log={comments} />
          </div>
        </div>
      </LogRecord>
    );
  });

  return (
    <>
      <RecordGroup>
        {records}
      </RecordGroup>
      {pages.length > 1
        && (
        <PaginationWrapper>
          <Pagination
            pages={pages}
            onChange={handlePageChange}
            selectedIndex={skip / take}
          />
        </PaginationWrapper>
        )}

    </>
  );
};

HistoryLogsViewer.defaultProps = {
  take: 10,
};

export default HistoryLogsViewer;
