/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable quote-props */
import React, { useState } from 'react';
import _, { get } from 'lodash';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalBody,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import i18next from 'i18next';
import {
  LabelContainer,
  ButtonContainer,
  ErrorMsg,
} from './CreateOrderPaymentModal.styles';
import {
  useCreatePaymentMutation,
  useGetPaymentMethodsQuery,
} from '../../graphql/types';
import { Order } from '../OrdersListing/types';
import { useAuth } from '../../utils/useAuth';

interface CreateOrderPaymemtModalInputProps {
  isOpen: boolean,
  order: Order,
  close: any, //eslint-disable-line
  refetchOrder: Function,
}

interface FormValuesProps {
  paymentMethod: {
    label: string,
    value: string,
  }
  amount: string,
}
interface MethodProps {
  id: string,
  name: string,
}

const CreateOrderPaymentModal = (props: CreateOrderPaymemtModalInputProps) => {
  const { t } = useTranslation();
  const {
    isOpen, close, order, refetchOrder,
  } = props;
  const { control, handleSubmit, errors } = useForm();
  const [createPaymentMutation] = useCreatePaymentMutation();
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [errorMessage, setErrorMsg] = useState<string>();
  const { state: { user } } = useAuth();

  const {
    data, error, loading,
  } = useGetPaymentMethodsQuery();
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>{t('orderDetails.payment.fpmf')}</h1>;
  }

  const methods = _.get(data, 'GetPaymentMethods.paymentMethods', []);
  // console.log(methods);

  // Define mapping for method names to label keys
  const methodLabels: { [key: string]: string } = {
    'Amazon': 'orderDetails.pm.am',
    'Bank Direct Deposit': 'orderDetails.pm.bdd',
    'Cash': 'orderDetails.pm.cash',
    'eBay': 'orderDetails.pm.ebay',
    'EFTPOS': 'orderDetails.pm.eft',
    'Magento': 'orderDetails.pm.mag',
    'Shopify': 'orderDetails.pm.sho',
    'Woocommerce': 'orderDetails.pm.woo',
  };

  // Map methods to options
  const options = methods.map((method: MethodProps) => {
    const labelKey = methodLabels[method.name] || method.name;
    return {
      label: i18next.t<string>(labelKey),
      value: method.id,
    };
  });

  const onSubmit = (data: any) => { //eslint-disable-line
    setSpinner(true);
    const {
      id,
      currency,
      orderNumber,
      teamChannelID,
      teamCustomerID,
    } = order;
    const { paymentMethod, amount } = data;
    const args = {
      orderId: id,
      paymentMethodId: paymentMethod.value || '',
      teamChannelId: teamChannelID,
      orderNumber,
      currency,
      paidAmount: parseFloat(amount) * 100,
      paidById: teamCustomerID,
      createdById: user?.id || '',
    };

    createPaymentMutation({
      variables: {
        payment: args,
      },
    }).then(() => {
      setSpinner(false);
      close();
      refetchOrder();
    }).catch((err) => {
      const msg = err?.graphQLErrors[0]?.message || 'create payment failed';
      setErrorMsg(msg);
      setSpinner(false);
    });
  };
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close}>
          <ModalHeader>
            <ModalTitle>{t('orderDetails.payment.op')}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <LabelContainer>{t('orderDetails.payment.pam')}</LabelContainer>
              <Controller
                name="paymentMethod"
                control={control}
                options={options}
                defaultValue={options[0].value}
                rules={{ required: true }}
                as={Select}
                testId="paymentMethod"
              />
              {errors.paymentMethod
              && <ErrorMsg data-testid="paymentMethod-error-message">{t('orderDetails.payment.pmir')}</ErrorMsg>}
              <LabelContainer>
                {t('orderDetails.payment.amount')}
                {' '}
                *
              </LabelContainer>
              <Controller
                as={Textfield}
                name="amount"
                control={control}
                defaultValue=""
                rules={{
                  required: true,
                  min: 0,
                  max: order.totalAmtInclTax / 100,
                }}
                placeholder={t('orderDetails.payment.pipa')}
                testId="amount"
              />
              {get(errors, 'amount.type') === 'required'
              && <ErrorMsg data-testid="amount-error-message">{t('orderDetails.payment.pair')}</ErrorMsg>}
              {get(errors, 'amount.type') === 'min'
              && <ErrorMsg data-testid="amount-error-message">{t('orderDetails.payment.asblt')}</ErrorMsg>}
              {get(errors, 'amount.type') === 'max'
              && <ErrorMsg data-testid="amount-error-message">{t('orderDetails.payment.asblto')}</ErrorMsg>}
              {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
              <ButtonContainer>
                <Button
                  testId="submit-button"
                  type="submit"
                  appearance="primary"
                  iconAfter={
                  showSpinner ? <Spinner appearance="invert" testId="submit-spinner" /> : <></>
                }
                >
                  {t('orderDetails.payment.sb')}
                </Button>
                <Button
                  testId="cacel-button"
                  appearance="link"
                  onClick={() => close()}
                >
                  {t('orderDetails.payment.cc')}
                </Button>
              </ButtonContainer>
            </form>
          </ModalBody>

        </Modal>
      )}
    </ModalTransition>
  );
};

export default CreateOrderPaymentModal;
