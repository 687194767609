import React, { useState, useEffect } from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { isNaN } from 'lodash';
import { SelectedOrderItem, TeamTaxClasses, AmountBase } from './types';
import { TeamChannel } from '../CreateOrder/types';
import {
  SectionHeading,
  SummaryTag,
} from './OrderItemEditor.style';
import OrderTaxBaseSelector from '../OrderDetails/OrderTaxBaseSelector';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import { Product } from '../ProductsListing/types';
import { getCompoundedTaxRateMutiplier } from '../../utils/taxHelper';
import ProductFinder from '../ProductFinder/ProductFinder';
import ProductTitle from '../ProductTitle/ProductTitle';
import QtyEditor from '../QtyEditor/QtyEditor';
import UnitPriceEditor from '../PriceEditor/PriceEditor';
import RemoveButton from '../RemoveButton/RemoveButton';
import TaxSelector, { TaxInfo } from './widgets/TaxSelector';

interface Props {
  teamChannel:TeamChannel,
  callBack:(
    selectedItems:SelectedOrderItem[],
    isTaxIncludeInUnitPrice:boolean,
  )=>void,
  // eslint-disable-next-line react/require-default-props
  defaultSelection?:Array<SelectedOrderItem>,
}

export default ({ teamChannel, callBack, defaultSelection = [] }:Props) => {
  // TAXINCLU , TAXEXCLU, TAXFREE,
  const [amountBase, setAmountBase] = useState<AmountBase>('TAXINCLU');
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<Array<SelectedOrderItem>>(defaultSelection);

  const onAmountBaseChange = (taxBase:string) => {
    setAmountBase(taxBase as AmountBase);

    // need to update current items's tax if changed to amount as 'TAXFREE'
    if ((taxBase as AmountBase) === 'TAXFREE') {
      const deepCopySelectedItem:Array<SelectedOrderItem> = JSON.parse(
        JSON.stringify(selectedItem),
      );
      const newItems:Array<SelectedOrderItem> = deepCopySelectedItem
        .map((each) => {
          const { teamTaxClasses } = each;
          const [taxFreeClass] = teamTaxClasses.filter((t) => t.name.includes('GST Free Goods'));

          return {
            ...each,
            taxName: taxFreeClass?.name || each.taxName,
            taxClassId: taxFreeClass?.id || each.taxClassId,
            taxMutiplier: taxFreeClass ? 1 : each.taxMutiplier,
          };
        });
      setSelectedItem(newItems);
    }
  };

  const head:HeadType = {
    cells: [
      {
        content: 'Name',
        width: 30,
      },
      {
        content: 'SKU',
        width: 10,
      },
      { content: 'Qty' },
      { content: 'RRP(Inc.)' },
      { content: 'Unit price' },
      {
        content: 'Tax',
        width: 15,
      },
      {
        content: 'Total',
        width: 11,
      },
      {
        content: '',
        width: 1,
      },
    ],
  };

  const addItem = (product:Product, teamTaxClasses:TeamTaxClasses) => {
    const taxMutiplier = (() => {
      if (amountBase === 'TAXFREE') {
        const [taxFreeClass] = teamTaxClasses.filter((t) => t.name.includes('GST Free Goods'));
        return getCompoundedTaxRateMutiplier(taxFreeClass?.id || '', teamTaxClasses);
      }
      return getCompoundedTaxRateMutiplier(product.productTaxClass?.id || '', teamTaxClasses);
    })();
    const productId = product.id;
    const teamChannelProductid = product.teamChannelProducts
      .filter(
        (tcp) => tcp.teamChannel.id === teamChannel.id,
      )[0].id;
    const [tcp] = product.teamChannelProducts.filter(
      (cur) => cur.teamChannel.id === teamChannel.id,
    );
    const { name, sku } = product;
    const qty = 1;
    const tcpUnitPrice = tcp.unitPrice / 100;
    const tcpUnitPriceExclGST = tcp.unitPrice / taxMutiplier / 100;
    const unitPrice = (amountBase === 'TAXINCLU' ? tcpUnitPrice : tcpUnitPriceExclGST).toFixed(2);
    const taxClassId = (() => {
      if (amountBase === 'TAXFREE') {
        const [taxFreeClass] = teamTaxClasses.filter((t) => t.name.includes('GST Free Goods'));
        return taxFreeClass?.id || '';
      }
      return product.productTaxClass?.id || '';
    })();

    const taxName = (() => {
      if (amountBase === 'TAXFREE') {
        const [taxFreeClass] = teamTaxClasses.filter((t) => t.name.includes('GST Free Goods'));
        return taxFreeClass?.name || '';
      }
      return product.productTaxClass?.id || '';
    })();

    const newItem:SelectedOrderItem = {
      productId,
      teamChannelProductid,
      name,
      sku,
      qty,
      unitPrice,
      taxClassId,
      taxName,
      taxMutiplier,
      // give a copy of original will always helps
      product,
      // give a copy, so we can access when every we want
      teamTaxClasses,
    };
    setSelectedItem((prev) => ([...prev, newItem]));
  };

  const InputSelectorElement:RowType = {
    cells: [
      {
        colSpan: head.cells.length,
        content: <ProductFinder
          teamChannelId={teamChannel.id}
          onSelect={addItem}
        />,
      },
    ],
  };

  const onQtyChange = (newQty:number, index:number) => {
    try {
      setSelectedItem((prev) => {
        const deepCopyed:Array<SelectedOrderItem> = JSON.parse(
          JSON.stringify(prev),
        );
        deepCopyed[index].qty = newQty;
        return deepCopyed;
      });
    } catch (error) {
      // leave blank intentional
    }
  };

  const onRemove = (index:number) => {
    try {
      setSelectedItem((prev) => {
        const deepCopyed:Array<SelectedOrderItem> = JSON.parse(
          JSON.stringify(prev),
        );

        return deepCopyed.filter((cur, i) => i !== index);
      });
    } catch (error) {
      // leave blank intentional
    }
  };

  const onUnitPriceChange = (newUnitPrice:number|string, index:number) => {
    try {
      setSelectedItem((prev) => {
        const deepCopyed:Array<SelectedOrderItem> = JSON.parse(
          JSON.stringify(prev),
        );
        deepCopyed[index].unitPrice = newUnitPrice;
        return deepCopyed;
      });
    } catch (error) {
      // leave blank intentional
    }
  };

  const onTaxChange = (taxInfo:TaxInfo, index:number) => {
    try {
      setSelectedItem((prev) => {
        const deepCopyed:Array<SelectedOrderItem> = JSON.parse(
          JSON.stringify(prev),
        );
        deepCopyed[index].taxClassId = taxInfo.taxClassId;
        deepCopyed[index].taxName = taxInfo.taxName;
        deepCopyed[index].taxMutiplier = taxInfo.taxMutiplier;
        return deepCopyed;
      });
    } catch (error) {
      // leave blank intentional
    }

    // while current amountBase is taxFree,
    // AND one of item attempt to change its individual taxbase NOT GST Free
    // THEN change Global amount base to TAXINCL
    if ((amountBase === 'TAXFREE') && !taxInfo.taxName.includes('GST Free Goods')) {
      setAmountBase('TAXINCLU');
    }
  };

  const rows:Array<RowType> = selectedItem.map((item, index) => ({
    key: `${item.productId}-${index}`,
    cells: [
      {
        content: <ProductTitle
          product={item.product}
        />,
      },
      { content: item.sku },
      {
        content: <QtyEditor
          qty={item.qty}
          onChange={(newQty:number) => onQtyChange(newQty, index)}
        />,
      },
      {
        content: formatMoneyWithPrefix(item.product.unitPrice),
      },
      {
        content: <UnitPriceEditor
          price={item.unitPrice}
          testid="unitPriceInput"
          onChange={(newUnitPrice:number|string) => onUnitPriceChange(
            newUnitPrice,
            index,
          )}
        />,
      },
      {
        content: <TaxSelector
          teamTaxClasses={item.teamTaxClasses}
          currentSelectedTaxId={item.taxClassId}
          onChange={(taxInfo:TaxInfo) => onTaxChange(taxInfo, index)}
        />,
      },
      {
        content: formatMoneyWithPrefix(
          Number(item.unitPrice) * item.qty * 100,
        ),
      },
      {
        content: <RemoveButton onRemove={() => onRemove(index)} />,
      },
    ],
  }
  ));

  const calculateTotalTax = (items:SelectedOrderItem[]) => {
    let totalTax = 0;

    items.forEach((item) => {
      const { taxMutiplier, qty } = item;
      const unitPrice = Number(item.unitPrice);

      if (!isNaN(unitPrice)) {
        if (amountBase === 'TAXINCLU') {
          totalTax
            += (unitPrice - (unitPrice / taxMutiplier)) * qty;
        }
        if (amountBase === 'TAXEXCLU') {
          totalTax += unitPrice * (taxMutiplier - 1) * qty;
        }
        if (amountBase === 'TAXFREE') {
          // leave blank intentional for completeness
        }
      }
    });
    // rounding the taxamount with accordance of ato GST rounding rule
    // https://www.ato.gov.au/business/gst/tax-invoices/#:~:text=Rounding%20of%20GST&text=Total%20invoice%20rule%20%E2%80%93%20under%20this,(rounding%200.5%20cents%20upwards).
    totalTax = Number(totalTax.toFixed(2));

    return totalTax;
  };

  const calculateOrderTotal = (items:SelectedOrderItem[]) => {
    let orderTotal = 0;
    items.forEach((item) => {
      const { taxMutiplier, qty } = item;
      const unitPrice = Number(item.unitPrice);

      if (!isNaN(unitPrice)) {
        if (amountBase === 'TAXINCLU') {
          orderTotal += Number((unitPrice * qty).toFixed(2));
        }
        if (amountBase === 'TAXEXCLU') {
          orderTotal += Number((unitPrice * taxMutiplier * qty).toFixed(2));
        }
        if (amountBase === 'TAXFREE') {
          // leave blank intentional for completeness
          orderTotal += Number((unitPrice * qty).toFixed(2));
        }
      }
    });

    return orderTotal;
  };

  const OrderItemTaxTotalElement:RowType = {
    cells: [
      {
        colSpan: head.cells.length - 1,
        content: <SummaryTag>Total GST</SummaryTag>,
      },
      {
        content: <div data-testid="total-tax">{formatMoneyWithPrefix(calculateTotalTax(selectedItem) * 100)}</div>,
      },
    ],
  };

  const OrderItemTotal:RowType = {
    cells: [
      {
        colSpan: head.cells.length - 1,
        content: <SummaryTag>Total (Inc.)</SummaryTag>,
      },
      {
        content: <div data-testid="total-amount">{formatMoneyWithPrefix(calculateOrderTotal(selectedItem) * 100)}</div>,
      },
    ],
  };

  const combinedRows = () => {
    if (selectedItem.length > 0) {
      return [
        ...rows,
        OrderItemTaxTotalElement,
        OrderItemTotal,
        InputSelectorElement,
      ];
    }

    return [...rows, InputSelectorElement];
  };

  useEffect(() => {
    callBack(selectedItem, amountBase === 'TAXINCLU');
  }, [selectedItem, amountBase, callBack]);
  return (
    <>
      <SectionHeading>
        <h5>Products</h5>
        <div>
          <OrderTaxBaseSelector
            value={amountBase}
            onChange={onAmountBaseChange}
          />
        </div>
      </SectionHeading>
      <DynamicTableStateless
        head={head}
        rows={combinedRows()}
        testId="orderItemEditorTable"
      />
    </>
  );
};
