import React, { useEffect, useState } from 'react';
import Popup from '@atlaskit/popup';
import { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Notifications } from '@atlaskit/atlassian-navigation';
import { B200, N0 } from '@atlaskit/theme/colors';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import Badge from '@atlaskit/badge';
import i18next from 'i18next';
import firebase from '../../utils/firebase';
import NotificationProps from './types';
import NotificationItem from './NotificationItem';
import useAddFlagForEbayBadTokenError from './useAddFlagForEbayBadTokenError';
import addNotification from '../../utils/addNotification';

const env = process.env.REACT_APP_NODE_ENV || 'development';

const SettingsContainer = styled.div`
  min-width: 450px;
  min-height: 600px;
  margin-bottom: 60px;
  padding-bottom:20px;
  font-size:0.825rem;
  font-weight:normal;
  line-height:20px;
`;
const HeaderContainer = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentContainer = styled.div`
  margin: 20px 20px;
  border-top: 2px solid rgb(220,220,220);
  z-index: 999;
`;

const TextContainer = styled.div`
  text-align: center;
  font-size: 24px;
  padding: 50px;
`;

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);
  useAddFlagForEbayBadTokenError(notifications);
  const showNotifications = notifications.slice(0, 10);

  const onClick = () => {
    setIsOpen(!isOpen);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  const teamId = window.localStorage.getItem('currentTeam');
  const ref = firebase.firestore()
    .collection(`notifications_${env}`)
    .where('teamId', '==', teamId)
    .where('status', '==', 'UNREAD')
    .orderBy('updatedAt', 'desc');
  useEffect(() => {
    ref.onSnapshot((querySnapshot) => {
      const items: NotificationProps[] = [];
      querySnapshot.forEach((doc) => {
        if (items.length < 50) {
          items.push({ ...doc.data(), docId: doc.id });
        }
      });

      setNotifications(items);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const NotificationsBadge = () => {
    const result = notifications.length
      ? (
        <Badge style={{ backgroundColor: B200, color: N0 }} max={10}>
          {notifications.length}
        </Badge>
      )
      : null;
    return result;
  };

  const markAllAsRead = async () => {
    const querySnapshot = await ref.get();
    const batches = [];
    let batchHandle;
    let currentBatchSize = 0;
    for (let i = 0; i < querySnapshot.docs.length; i += 1) {
      const doc = querySnapshot.docs[i];

      if (currentBatchSize === 0) {
        const currentBatch = firebase.firestore().batch();
        batchHandle = currentBatch;
        batches.push(currentBatch);
      }

      batchHandle!.update(doc.ref, {
        status: 'READ',
      });

      currentBatchSize += 1;

      if (currentBatchSize === 500) {
        currentBatchSize = 0;
      }
    }

    try {
      await Promise.all(batches.map((batch) => batch.commit()));
      // await batch.commit();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      addNotification('Notification mark all as read failed', 'danger');
    }

    // let currentBatch = firebase.firestore().batch();
    // let currentBatchSize = 0;
    // const batches = [currentBatch];

    // try {
    //   querySnapshot.forEach((doc) => {
    //     /* eslint-disable-next-line no-plusplus */
    //     if (++currentBatchSize >= 500) {
    //       currentBatch = firebase.firestore().batch();
    //       batches.push(currentBatch);
    //       currentBatchSize = 1;
    //     }
    //     currentBatch.update(doc.ref, {
    //       status: 'READ',
    //     });
    //   });

    //   await Promise.all(batches.map((batch) => batch.commit()));
    //   // await batch.commit();
    // } catch (err) {
    //   // eslint-disable-next-line no-console
    //   console.log(err);
    //   addNotification('Notification mark all as read failed', 'danger');
    // }
  };

  const NotificationsContent = () => (
    <SettingsContainer>
      <HeaderContainer>
        <h3>{i18next.t('nav.nc') as string}</h3>
        <Button appearance="link" onClick={() => markAllAsRead()}>
          {i18next.t('nav.maar') as string}
        </Button>
      </HeaderContainer>
      {
        showNotifications.length
          ? (
            <ContentContainer>
              <DropdownItemGroup>
                {showNotifications.map((item: NotificationProps) => (
                  <DropdownItem key={item.id}>
                    <NotificationItem data={item} />
                  </DropdownItem>
                ))}
              </DropdownItemGroup>
            </ContentContainer>
          )
          : <TextContainer>{i18next.t('nav.nnrn') as string}</TextContainer>
      }
    </SettingsContainer>
  );

  return (
    <Popup
      placement="bottom-end"
      content={NotificationsContent}
      isOpen={isOpen}
      onClose={onClose}
      testId="settings-popup"
      trigger={(triggerProps) => (
        <Notifications
          badge={NotificationsBadge}
          testId="settings-button"
          isSelected={isOpen}
          onClick={onClick}
          tooltip="Product and Order Message"
          {...triggerProps}
        />
      )}
    />
  );
};
