import { isUndefined, sortBy } from 'lodash';
import { TeamTaxClasses } from '../components/OrderItemEditor/types';

const getCompoundedTaxRateMutiplier = (
  taxClassId:string,
  taxClasses:TeamTaxClasses,
) => {
  let taxRateToBeReturned = 1;

  const [selectedTaxClass] = taxClasses
    // get rid of shipping tax
    .filter((t) => !t.isForShipping)
    // find shipping id
    .filter((cur) => cur.id === taxClassId);

  if (isUndefined(selectedTaxClass)) {
    return taxRateToBeReturned;
  }

  const taxRules = sortBy(selectedTaxClass.taxRules, ['priority']);

  // TODO sum the taxRate of taxRules with same priorty.
  // if we later impletement the tax prioirty,
  // we need to refactor this pieces of code by taking the priority
  // for HELP, see getUniquePriorityArrWithSort() and related testing in API code to get more ideas
  taxRules.forEach((taxRule) => {
    taxRule.taxRates.forEach((taxRate) => {
      const { ratePercent } = taxRate;
      taxRateToBeReturned *= (1 + ratePercent / 100);
    });
  });
  // beware, inteneded to return a mutiplier number
  // in order word, will be 1.xxx
  return taxRateToBeReturned;
};

// eslint-disable-next-line import/prefer-default-export
export { getCompoundedTaxRateMutiplier };
