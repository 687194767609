import React from 'react';
import { useAuth } from '../../utils/useAuth';
import TeamProfileEditor from '../../components/TeamProfileEditor/TeamProfileEditor';

export default () => {
  const { state: { currentTeamId: teamId } } = useAuth();

  return (
    <TeamProfileEditor teamId={teamId!} />
  );
};
