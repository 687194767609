import styled from 'styled-components';
import { colors } from '@atlaskit/theme';

export const FormFooterChildContainer = styled.div`
  width:100%;
  font-weight:400;
  ul {
    display:flex;
    margin:0;
    margin-top:1rem;
    padding:0;
    justify-content:center;
    list-style:none;
    border-top:1px solid #ccc;
    li {
      padding:5px;
      font-size:0.825rem;
      margin:0px;
    }    
  }  
`;

export const FormTitle = styled.div`
  font-size:1rem;
  font-weight:500; 
  color:${colors.subtleHeading};
  width:100%;
  text-align:center;
`;

export const Feedback = styled.div`
  width:100%;
  text-align:center;
  padding-top:2rem;
  color:${colors.subtleText};
  .msg{
    margin-top:10px;
  }
  .target{
    font-weight:600;
    color:${colors.text};
  }
  .icon {
    margin-top:0px;
  }
`;
