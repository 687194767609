import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';

interface ErrorDisplayModalProps {
  heading: string,
  message: React.ReactNode,
  drawToActionComponent: (onClick: () => void) => JSX.Element,
}

const ErrorDisplayModal = ({
  heading,
  message,
  drawToActionComponent,
}: ErrorDisplayModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      {drawToActionComponent(() => setIsOpen(true))}
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            width="medium"
          >
            <ModalHeader>
              <ModalTitle appearance="danger">{heading}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {message}
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ErrorDisplayModal;
