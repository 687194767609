import React from 'react';
import ProductsListing from '../components/ProductsListing/ProductsListing';
import { useAuth } from '../utils/useAuth';

export default function Products() {
  const { state: { currentTeamId: teamID } } = useAuth();
  return (
    <>
      <ProductsListing teamID={teamID!} />
    </>
  );
}
