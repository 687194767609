import styled from 'styled-components';

const FlexSpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const FlexSellerSpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export default FlexSpaceBetweenContainer;
