import React from 'react';
import Spinner from '@atlaskit/spinner';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import {
  TicketCommentsContainer,
  TickeDetailHeader,
  TicketCommentFormContainer,
} from './TicketsContainer.styles';
import { useAuth } from '../../utils/useAuth';
import { useGetTicketCommentsQuery } from '../../graphql/types';
import TicketCommentsListing from '../TicketCommentsListing/TicketCommentsListing';
import TicketCommentForm from '../TicketCommentForm/TicketCommentForm';
import TicketSummaryInfo from '../TicketSummaryInfo/TicketSummaryInfo';
import { useTicket } from './TicketsContainer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TicketDetailPage({ ticketId }: { ticketId: string }) {
  const { state: { currentTeamId } } = useAuth();
  const { state, dispatch } = useTicket();
  const teamId = currentTeamId!;
  const variables = {
    teamID: teamId,
    ticketId,
  };
  const {
    data, error, loading, refetch,
  } = useGetTicketCommentsQuery({ variables });
  if (loading) {
    return (
      <Spinner size="medium" />
    );
  }
  if (error) {
    return <p>Something went wrong!</p>;
  }
  const assigneeId = data?.getTicketComments.assigneeId || '';
  const comments = data?.getTicketComments.comments;
  const closeOrdeView = () => {
    dispatch({
      type: 'closeDetail',
      payload: { id: '', status: '' },
    });
  };
  return (
    <TicketCommentsContainer>
      <div
        style={{
          position: 'absolute',
          right: 20,
          top: 60,
        }}
      >
        <Button
          appearance="subtle-link"
          onClick={closeOrdeView}
          testId="close-button"
        >
          <CrossIcon label="close" size="large" />
        </Button>
      </div>
      <TickeDetailHeader>{`Support Ticket #${ticketId}`}</TickeDetailHeader>
      <TicketSummaryInfo
        teamID={teamId}
        ticket={state.ticket}
      />
      <TicketCommentsListing comments={comments} />
      <TicketCommentFormContainer>
        <TicketCommentForm
          ticketStatus={state.ticket?.status}
          assigneeId={assigneeId}
          ticketId={ticketId}
          teamID={teamId}
          refetch={refetch}
        />
      </TicketCommentFormContainer>
    </TicketCommentsContainer>
  );
}
