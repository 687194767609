import React, { useState } from 'react';
import { InlineEditableTextfield } from '@atlaskit/inline-edit';
import Spinner from '@atlaskit/spinner';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import _ from 'lodash';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import {
  TeamCustomerContainer,
  TeamCustomerDetailContainer,
  TeamCustomerBasicInfoContainer,
  Header,
  SubTitle,
  TeamCustomerInfoCol,
  TeamCustomerInfoItem,
  TeamCustomerInfoInput,
  Label,
  TeamCustomerAddressContainer,
  TeamCustomerShippingAddressContainer,
  TeamCustomerShippingAddressDetailContainer,
  TeamCustomerBillingAddressContainer,
  TeamCustomerBillingAddressDetailContainer,
  MessageWrapper,
  SinceTitleContainer,
} from './TeamCustomerDetail.styles';

import {
  useTeamCustomerDetailQuery,
  useUpdateTeamCustomerMutation,
  useUpdateAddressMutation,
} from '../../graphql/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import calculateDateDifference from '../../utils/calculateDateDifference';
import CustomerFeedBack from './CustomerFeedBack';
import PlanUpgrade from '../Billing/PlanUpgrade';
import addNotification from '../../utils/addNotification';
import { useSubscription } from '../../utils/useAuth';

const TeamCustomerDetail = (props: { customerId: string, teamId: string, customerName: string }) => {
  const { t } = useTranslation();
  const freeExpired = useSubscription();
  const [disable, setDisable] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setDisable(false);
  };
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState('');
  const { customerId, teamId, customerName } = props;
  const variables = { customerId };
  const {
    data, error, loading, refetch,
  } = useTeamCustomerDetailQuery({ variables });
  const [updateTeamCustomer] = useUpdateTeamCustomerMutation();
  const [updateAddress] = useUpdateAddressMutation();

  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>internal error</h1>;
  }
  const shippingAddressId = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.id', '');
  const billingAddressId = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.id', '');
  // const name = _.get(data, 'TeamCustomerDetail.teamCustomer.name', '');
  const externalCustomerID = _.get(data, 'TeamCustomerDetail.teamCustomer.externalCustomerID', '');
  // const fullName = _.isEmpty(externalCustomerID) ? name : `${name}(${externalCustomerID})`;
  // const nameArr = name.split(/(\s+)/);
  // const firstName = nameArr[0];
  // const lastName = nameArr[3] || '';
  const email = _.get(data, 'TeamCustomerDetail.teamCustomer.email', '');
  const phone = _.get(data, 'TeamCustomerDetail.teamCustomer.phone', '');
  const mobile = _.get(data, 'TeamCustomerDetail.teamCustomer.mobile', '');
  const shippingCompany = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.company', '');
  const shippingName = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.name', '');
  const shippingStreet = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.street', '');
  const shippingState = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.state', '');
  const shippingCity = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.suburb', '');
  const shippingPostcode = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.postcode', '');
  const shippingCountry = _.get(data, 'TeamCustomerDetail.teamCustomer.shippingAddresses[0].address.country', '');
  const billingCompany = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.company', '');
  const billingName = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.name', '');
  const billingStreet = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.street', '');
  const billingState = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.state', '');
  const billingCity = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.suburb', '');
  const billingPostcode = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.postcode', '');
  const billingCountry = _.get(data, 'TeamCustomerDetail.teamCustomer.billingAddresses[0].address.country', '');
  const createdAt = _.get(data, 'TeamCustomerDetail.teamCustomer.createdAt', '');
  const sincedTitle = `${calculateDateDifference(createdAt)}`;

  const handleUpdateBasicInfo = (val: object) => {
    const key = String(Object.keys(val));
    const value = String(Object.values(val));
    let errormsg = '';
    let isValid = true;
    switch (key) {
      case 'mobile':
        isValid = validator.isMobilePhone(value, 'any');
        errormsg = 'Please enter right mobile number';
        break;
      case 'phone':
        isValid = validator.isMobilePhone(value, 'any');
        errormsg = 'Please enter right phone number';
        break;
      case 'email':
        isValid = validator.isEmail(value);
        errormsg = 'Please enter right email address';
        break;
      default:
        errormsg = '';
        isValid = true;
    }

    if (freeExpired === true) {
      setDisable(true);
      return;
    }

    if (!isValid) {
      addNotification(errormsg, 'danger');
      return;
    }

    updateTeamCustomer({
      variables: { ...val, customerId },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        setShowMessage(true);
        const msg = graphQLErrorsReader(err) || 'Update failed';
        setErrorMsg(msg);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
  };

  const handleUpdateShippingAddress = (val: object) => {
    const key = String(Object.keys(val));
    const value = String(Object.values(val));
    let errormsg = '';
    let isValid = true;
    switch (key) {
      case 'postcode':
        isValid = validator.isPostalCode(value, 'any');
        errormsg = 'Please enter right postcode';
        break;
      default:
        errormsg = '';
        isValid = true;
    }

    if (freeExpired === true) {
      setDisable(true);
      return;
    }

    if (!isValid) {
      addNotification(errormsg, 'danger');
      return;
    }

    updateAddress({
      variables: { addressParams: val, addressId: shippingAddressId },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        setShowMessage(true);
        const msg = graphQLErrorsReader(err) || 'Update failed';
        setErrorMsg(msg);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
  };

  const handleUpdateBillingAddress = (val: object) => {
    const key = String(Object.keys(val));
    const value = String(Object.values(val));
    let errormsg = '';
    let isValid = true;
    switch (key) {
      case 'postcode':
        isValid = validator.isPostalCode(value, 'any');
        errormsg = 'Please enter right postcode';
        break;
      default:
        errormsg = '';
        isValid = true;
    }

    if (freeExpired === true) {
      setDisable(true);
      return;
    }

    if (!isValid) {
      addNotification(errormsg, 'danger');
      return;
    }

    updateAddress({
      variables: { addressParams: val, addressId: billingAddressId },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        setShowMessage(true);
        const msg = graphQLErrorsReader(err) || 'Update failed';
        setErrorMsg(msg);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
  };
  return (
    <>
      <TeamCustomerContainer>
        {/* <MerpAvatar size={72} firstName={firstName} lastName={lastName} /> */}
        <TeamCustomerDetailContainer>
          <Header>
            <CustomerFeedBack teamID={teamId} userId={externalCustomerID} name={customerName} />
          </Header>
          <SinceTitleContainer>{sincedTitle}</SinceTitleContainer>
          <SubTitle>{t('customers.cd.bi')}</SubTitle>
          <TeamCustomerBasicInfoContainer>
            <TeamCustomerInfoCol>
              <TeamCustomerInfoItem>
                <Label>{t('customers.cd.ea')}</Label>
                <TeamCustomerInfoInput>
                  <InlineEditableTextfield
                    defaultValue={email}
                    readViewFitContainerWidth
                    onConfirm={(value) => handleUpdateBasicInfo({ email: value })}
                    placeholder=""
                  />
                </TeamCustomerInfoInput>
              </TeamCustomerInfoItem>
              <TeamCustomerInfoItem>
                <Label>{t('customers.cd.cp')}</Label>
                <TeamCustomerInfoInput>
                  <InlineEditableTextfield
                    defaultValue={phone}
                    readViewFitContainerWidth
                    onConfirm={(value) => handleUpdateBasicInfo({ phone: value })}
                    placeholder=""
                  />

                </TeamCustomerInfoInput>
              </TeamCustomerInfoItem>
            </TeamCustomerInfoCol>
            <TeamCustomerInfoCol>
              <TeamCustomerInfoItem>
                <Label>{t('customers.cd.cm')}</Label>
                <TeamCustomerInfoInput>
                  <InlineEditableTextfield
                    defaultValue={mobile}
                    readViewFitContainerWidth
                    onConfirm={(value) => handleUpdateBasicInfo({
                      mobile: value,
                    })}
                    placeholder=""
                  />
                </TeamCustomerInfoInput>
              </TeamCustomerInfoItem>
            </TeamCustomerInfoCol>
          </TeamCustomerBasicInfoContainer>
          <TeamCustomerAddressContainer>
            <TeamCustomerShippingAddressContainer>
              <SubTitle>{t('customers.cd.sa')}</SubTitle>
              <TeamCustomerShippingAddressDetailContainer>
                <TeamCustomerInfoCol>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.cn')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingCompany}
                        readViewFitContainerWidth
                        onConfirm={(value) => handleUpdateShippingAddress(
                          { company: value },
                        )}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.saddr')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingStreet}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateShippingAddress(
                          { street: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.state')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingState}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateShippingAddress(
                          { state: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.country')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingCountry}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateShippingAddress(
                          { country: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                </TeamCustomerInfoCol>
                <TeamCustomerInfoCol>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.cname')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingName}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateShippingAddress(
                          { name: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.city')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingCity}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateShippingAddress(
                          { suburb: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.pc')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={shippingPostcode}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateShippingAddress(
                          { postcode: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                </TeamCustomerInfoCol>
              </TeamCustomerShippingAddressDetailContainer>
            </TeamCustomerShippingAddressContainer>
            <TeamCustomerBillingAddressContainer>
              <SubTitle>{t('customers.cd.ba')}</SubTitle>
              <TeamCustomerBillingAddressDetailContainer>
                <TeamCustomerInfoCol>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.cn')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingCompany}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { company: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.saddr')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingStreet}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { street: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.state')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingState}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { state: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.country')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingCountry}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { country: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                </TeamCustomerInfoCol>
                <TeamCustomerInfoCol>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.cname')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingName}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { name: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>City</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingCity}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { suburb: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                  <TeamCustomerInfoItem>
                    <Label>{t('customers.cd.pc')}</Label>
                    <TeamCustomerInfoInput>
                      <InlineEditableTextfield
                        defaultValue={billingPostcode}
                        readViewFitContainerWidth
                        onConfirm={(value) => (handleUpdateBillingAddress(
                          { postcode: value },
                        ))}
                        placeholder=""
                      />
                    </TeamCustomerInfoInput>
                  </TeamCustomerInfoItem>
                </TeamCustomerInfoCol>
              </TeamCustomerBillingAddressDetailContainer>
            </TeamCustomerBillingAddressContainer>
          </TeamCustomerAddressContainer>
          {showMessage
          && (
            <MessageWrapper>
              <Flag
                appearance="error"
                icon={<ErrorIcon secondaryColor={R400} label="error" />}
                id="error"
                key="error"
                title={errorMsg}
              />
            </MessageWrapper>
          )}
        </TeamCustomerDetailContainer>
      </TeamCustomerContainer>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
    </>
  );
};

export default TeamCustomerDetail;
