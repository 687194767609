import React from 'react';
import SearchResultContainer from './styles/SearchResultContainer';
// type
import { ProductSummary } from './types';
import Product from './Product';
// assets
import noImage from '../../assets/svg/noimage.svg';

export default ({
  productSummaries,
  handleProductPreview,
}: {
  productSummaries: [ProductSummary],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleProductPreview: any,
}) => (
  <SearchResultContainer>
    <section data-testid="ebay-product-search-result">
      <ul>
        {
            productSummaries.map((product: ProductSummary) => (
              <Product
                key={product.epid}
                image={product.image?.imageUrl
                  ? product.image.imageUrl : noImage}
                title={product.title.length > 100 ? `${product.title.substr(0, 100)}...` : product.title}
                epid={product.epid}
                aspects={product.aspects}
                handleProductPreview={handleProductPreview}
              />
            ))
          }
      </ul>
    </section>
  </SearchResultContainer>
);
