import React from 'react';
import queryString from 'query-string';
import Modal, {
  ModalHeader, ModalTitle, ModalTransition, ModalFooter,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';

export default function TeamCharge() {
  const { team_charge_success: success } = queryString.parse(window.location.search);

  return (
    <ModalTransition>
      <Modal>
        <ModalHeader>
          <ModalTitle>
            Your subscriptions for SAAS Standard is
            {' '}
            {success === 'true' ? 'Successful' : 'Failed'}
          </ModalTitle>
        </ModalHeader>
        <ModalFooter>
          <Button onClick={() => window.location.assign('/Billing')}>Done</Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  );
}
