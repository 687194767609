import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { isUndefined } from 'lodash';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import { Footer } from '../../shares/widget.style';
import { TeamChannel } from '../../types';
import { useGetTeamChannelsByTeamIdQuery } from '../../../../graphql/types';
import { useAuth } from '../../../../utils/useAuth';
import ErrorSection from '../../../ErrorSection/ErrorSection';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';

const closeNewOrderModalEvent = new CustomEvent('closeNewOrderModal');

const SelectChannelContainer = styled.div`
    margin-top:5px;
`;

interface Props {
  onConfirmSelection:(teamChannel:TeamChannel | undefined)=>void,
}

export default ({ onConfirmSelection }:Props) => {
  const { state: { currentTeamId: teamID } } = useAuth();
  const [teamChannel, setTeamChannel] = useState<TeamChannel>();

  const { data, loading, error } = useGetTeamChannelsByTeamIdQuery({
    variables: {
      teamId: teamID || '',
    },
  });

  const parseDataToOptions = (payload:typeof data) => {
    try {
      const options = payload?.GetTeamChannelsByTeamId.teamChannels
        ?.filter((tc) => tc.channel.name === 'in-store')
        ?.map(
          (tc) => ({
            label: tc.name,
            value: tc.id,
          }),
        );
      return options;
    } catch {
      return undefined;
    }
  };

  const handleOnChange = useCallback((
    selection:ValueType<OptionType>,
  ) => {
    try {
      const teamChannelId = (selection as OptionType).value as string;
      const selectedTeamChannel = data?.GetTeamChannelsByTeamId.teamChannels
        ?.filter(
          (tc) => tc.id === teamChannelId,
        );
      setTeamChannel(selectedTeamChannel![0]);
    } catch {
      setTeamChannel(undefined);
    }
  },
  [data]);

  const closeOrderCreationModal = useCallback(() => {
    document.dispatchEvent(closeNewOrderModalEvent);
  }, []);

  const handleConfirm = useCallback(
    () => {
      onConfirmSelection(teamChannel);
    },
    [teamChannel, onConfirmSelection],
  );

  const options = parseDataToOptions(data);

  return (
    <>
      {error
      && <ErrorSection>{graphQLErrorsReader(error)}</ErrorSection>}
      <h5>Select Channel</h5>
      <SelectChannelContainer>
        <Select<OptionType>
          isLoading={loading}
          placeholder="Select a store"
          options={options}
          isClearable
          onChange={handleOnChange}
        />
      </SelectChannelContainer>
      <Footer>
        <Button
          onClick={closeOrderCreationModal}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          isDisabled={isUndefined(teamChannel)}
          appearance="primary"
        >
          Next
        </Button>
      </Footer>
    </>
  );
};
