import React from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';

const AddTrackingNumberButton = () => {
  const { state, dispatch } = useShipping();
  const handleClick = () => dispatch({ type: 'setStage', payload: 'manual' });

  return (
    <Button
      appearance="primary"
      isDisabled={state.ui.isPrimaryButtonDisabled}
      onClick={handleClick}
      testId="AddTrackingNumberButton"
    >
      Add Tracking Number
    </Button>
  );
};

export default AddTrackingNumberButton;
