import gql from 'graphql-tag';

const catalogSearch = gql`
query QueryEbayProducts($q:String!,$categoryId:String) {
  QueryEbay(
    resource: "catalog.search",
    params: [{value: {q: $q,category_ids:$categoryId}}],
    ){
    response
  }
}
`;

export default catalogSearch;
