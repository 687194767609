import gql from 'graphql-tag';

const setTeamCourierMutation = gql`
  mutation SetTeamCourier(
  $teamId:String!,
  $courierId:String!,
  $configuration:String!,
  $active:Boolean!) {
SetTeamCourier (teamId:$teamId,courierId:$courierId,configuration:$configuration,active:$active){
  status
  teamCourier {
    id,
    teamId,
    courierId,
    configuration,
    active,
  }
}
}
`;
export default setTeamCourierMutation;
