import styled from 'styled-components';

export const FilterLine = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

export const FilterItem = styled.div`
  width: 300px;
  margin-right: 20px;
`;

export const FilterButtonContainer = styled.div`
  min-width: 45%;
`;
export const TicketTableContainer = styled.div`
  min-height: 200px;
  width: 100%;
`;
export const TicketPagination = styled.div`
  display: flex;
  justify-content: center;
`;
export const IconLink = styled.a`
  &,
  &:hover,
  &:active,
  &:focus {
    border-radius: 5px;
    color: inherit;
    cursor: pointer;
    display: block;
    line-height: 0;
    padding: 10px;
  }

  &:hover {
    background: light-grey;
  }
`;

export const LineContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SearchInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  position: absolute;
  margin-top: 8px !important;
`;

export const AdvancedContainer = styled.div`
  margin-top: 22px;
  margin-left: 20px;
  color: rgb(0, 73, 176);
  font-size: 14px;
  font-weight: 500;
`;

export const ColumnsContentContainer = styled.div`
  width: 150px;
  height: 250px;
`;

export const CheckboxItem = styled.div`
  margin-left: 10px;
  margin-top: 10px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: -20px;
  margin-bottom: 20px;
`;

export const MoreSpan = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 40px;
  background-color: white;
  &:hover {
    cursor: pointer;
    background-color: rgb(244, 245, 247);
  }
`;
