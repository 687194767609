/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { store } from 'react-notifications-component';
import {
  isUndefined,
} from 'lodash';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import { Product } from '../types';
import {
  useTeamChannelProductUpdateMutation,
} from '../../../graphql/types';
import SuccessFlag from '../../ProductEdit/SuccessFlag';
import ErrorFlag from '../../ProductEdit/ErrorFlag';
import PlanUpgrade from '../../Billing/PlanUpgrade';

const ListUnitPriceEdit = (
  { product, viewShow, isDisable }: { product: Product, viewShow: String, isDisable: boolean },
) => {
  const handleRow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };
  let defaultValue = '';
  let readView = 0;
  let currTcpID = '';
  if (viewShow !== 'stockOnHand') {
    const teamChannelId = viewShow.replace('teamChannelProducts', '');
    const [tcp] = product.teamChannelProducts.filter(
      (cur) => cur.teamChannel.id === teamChannelId,
    );
    if (!isUndefined(tcp)) {
      currTcpID = tcp.id;
      defaultValue = formatMoneyWithPrefix(tcp.unitPrice);
      readView = tcp.unitPrice / 100;
    }
  }
  const [disable, setDisable] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setDisable(false);
  };
  const [useTeamChannelProductUpdate] = useTeamChannelProductUpdateMutation();
  const [editValue, setEditValue] = useState(defaultValue);

  const HandleSave = (value: string) => {
    const preValue = editValue;
    setEditValue(formatMoneyWithPrefix(parseFloat(value) * 100));

    useTeamChannelProductUpdate({
      variables: {
        id: currTcpID,
        unitPrice: parseFloat(value) * 100,
        stockOnHand: product.stockOnHand,
      },
    })
      .then(() => {
        store.addNotification({
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
          content: <SuccessFlag title="Product update success!" />,
        });
      }).catch((err) => {
        const msg = err.graphQLErrors[0]?.message || 'server internal error';
        store.addNotification({
          id: 'error',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 6000,
            click: false,
            touch: false,
          },
          content: <ErrorFlag title={msg} />,
        });
        setEditValue(preValue);
      });
  };
  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={handleRow}>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
      <InlineEdit
        defaultValue={readView}
        editView={({ ...fieldProps }) => (
          <Textfield {...fieldProps} autoFocus testId="edit-view" isDisabled={isDisable} />
        )}
        readView={() => (
          <div data-testid="read-view" style={{ marginBottom: '8px' }}>
            {editValue}
          </div>
        )}
        onConfirm={(value) => (isDisable === false ? HandleSave(value) : setDisable(true))}
      />
    </div>

  );
};

export default ListUnitPriceEdit;
