import styled from 'styled-components';

const Footer = styled.div`
    margin-top:20px;
    display:flex;
    justify-content:flex-end;
    >*{
      margin-left:10px;
    }
`;

// eslint-disable-next-line import/prefer-default-export
export { Footer };
