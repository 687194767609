import gql from 'graphql-tag';

const orderStatusFragment = gql`
fragment fullOrderStatusFragment on OrderStatus {
    id,
    name,
    code,
    order
}
`;

export default orderStatusFragment;
