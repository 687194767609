import styled from 'styled-components';

export const PreviewShipmentContainer = styled.div`
  color: rgb(23, 43, 77);
  .alert {
    font-weight:500;
  }
  .subtle {
    color:rgb(107, 119, 140);
  }
  .sku{
    color:rgb(107, 119, 140);
    font-size:11px;
    line-height:16px;
  };
  .mt-20 {
    margin-top:20px;
  };
  table {
      tbody {
        tr {
          td {
            line-height: 20px;
            vertical-align:top;
          }
        }
      }
    };

  .section {
    margin-bottom:10px;
    h5{
    font-size:12px;
    font-weight:600;
    margin-bottom:10px;
    }
    .label{
    color:rgb(107, 119, 140);
    font-size:11px;
    line-height:16px;
  };
  }    
`;

export const Cell = styled.div`
  display:flex;
  align-items:center;
  span {
    padding-left:5px;
  }
`;

export const Buttons = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
  width:100%;
  margin-bottom:2rem;
  margin-top:2rem;
  >* {
    margin-left:10px;
  }
`;

export const Dimensions = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
  >* {
    margin-right:5px;
  }
`;

export const FlexBox = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
`;
