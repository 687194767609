import React from 'react';
import Button from '@atlaskit/button';
// import { ProductProps } from './types';
// import AspectsList from './AspectsList';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (props: any) => {
  const {
    image, title, brand, category, handleProductPreview,
    // epid, aspects,
  } = props;
  return (
    <li>
      <div className="product-image">
        <img src={image} alt={title} />
      </div>
      <div className="product-detail">
        <h3>{title}</h3>
        <h3>
          Brand: &nbsp;
          {brand}
        </h3>
        <h3>
          Category: &nbsp;
          {category}
        </h3>
        <Button
          testId={title}
          data-testid={title}
          appearance="primary"
          className="select-button"
          onClick={() => handleProductPreview()}
        >
          Select
        </Button>
      </div>
    </li>
  );
};
