import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';

interface Props {
  isOpen: boolean,
  heading: string,
  // eslint-disable-next-line react/require-default-props
  width?: string,
  children: React.ReactNode,
  onClose: () => void,
  onConfirm: () => void,
}

export default ({
  isOpen,
  heading,
  width = 'small',
  children,
  onClose,
  onConfirm,
}: Props) => (
  <ModalTransition>
    {isOpen && (
      <Modal
        onClose={onClose}
        width={width}
      >
        <ModalHeader>
          <ModalTitle appearance="danger">{heading}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
        <ModalFooter>
          <Button appearance="default" onClick={onClose}>Cancel</Button>
          <Button appearance="danger" onClick={() => { onConfirm(); onClose(); }} testId="deleteConfirmButton">Delete</Button>
        </ModalFooter>
      </Modal>
    )}
  </ModalTransition>
);
