import React, { useState, useCallback, useEffect } from 'react';
import Button from '@atlaskit/button/new';
import { useHistory } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import { TeamCustomer, TeamChannel } from '../../types';
import { Footer } from '../../shares/widget.style';
import OrderItemEditor from '../../../OrderItemEditor/OrderItemEditor';
import TeamCustomerEditMini from '../../../TeamCustomerDetail/CustomerInlineEditMini';
import { SelectedOrderItem } from '../../../OrderItemEditor/types';
import { useCreateOrderMutation, useGetTeamByIdQuery, useProductsLazyQuery } from '../../../../graphql/types';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';
import ErrorSection from '../../../ErrorSection/ErrorSection';
import { isItemsInValid } from '../../helpers';
import { Order } from '../../../OrdersListing/types';

import { parseOrderItemsToDefaultSelection } from '../../../OrderItemEditor/helpers';
import { useAuth } from '../../../../utils/useAuth';

const closeNewOrderModalEvent = new CustomEvent('closeNewOrderModal');

interface Props {
  teamCustomer:TeamCustomer,
  teamChannel:TeamChannel,
  // eslint-disable-next-line react/require-default-props
  order?:Order,
}
export default ({ teamCustomer, teamChannel, order }:Props) => {
  const { state: { currentTeamId: teamId } } = useAuth();
  const [items, setItems] = useState<SelectedOrderItem[]>([]);
  const [seller, setSeller] = useState<string>('');
  const [isAmountIncTax, setIsAmountIncTax] = useState<boolean>(true);
  const history = useHistory();
  const [createOrderMutation, { loading, error }] = useCreateOrderMutation();
  const [getProductsQuery, { data }] = useProductsLazyQuery();

  const shouldWaitForData = !!order && !data;

  useEffect(() => {
    if (order) {
      getProductsQuery({
        variables: {
          teamID: order.teamID,
          skip: 0,
          take: order.orderItems.length,
          // attemps to remove duplicates by use Set object
          // orderItems can have multiple rows refered to same product
          productIDs: [...new Set(order.orderItems
            .map((cur) => cur.productID)
            .filter((cur) => cur !== ''))],
        },
      });
    }
  }, [order, getProductsQuery]);

  const closeOrderCreationModal = () => {
    document.dispatchEvent(closeNewOrderModalEvent);
  };

  const isValid = (teamCustomer)
                  && (teamChannel)
                  && (!isItemsInValid(items));

  const pipe = useCallback((
    orderTtems:SelectedOrderItem[],
    isTaxIncludeInUnitPrice:boolean,
  ) => {
    setItems(orderTtems);
    setIsAmountIncTax(isTaxIncludeInUnitPrice);
  }, [setItems, setIsAmountIncTax]);

  const onSave = async () => {
    try {
      const res = await createOrderMutation({
        variables: {
          teamChannelId: teamChannel.id,
          data: {
            seller,
            teamCustomerId: teamCustomer.id,
            comments: '',
            isAmountIncTax,
            orderItems: items.map((item) => ({
              teamChannelProductId: item.teamChannelProductid,
              teamTaxClassId: item.taxClassId,
              qty: item.qty,
              unitPrice: Number(item.unitPrice),
            })),
          },
        },
      });

      history.push(`/order/${res.data?.CreateOrder.order.id}`);
    } catch (err) {
      // leave blank intentionaly
    }
  };

  const sellersChange = (sellers: string[]) => {
    setSeller(sellers.join(' | '));
  };

  const {
    data: dataTI,
  } = useGetTeamByIdQuery({
    variables: {
      teamId: teamId || '',
    },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });

  const tss = dataTI?.GetTeamById.team?.sellers;
  const options = tss ? tss.split(',').map((ele) => ({
    label: ele,
    value: ele,
  })) : [];

  return (
    <>
      {error && <ErrorSection>{graphQLErrorsReader(error)}</ErrorSection>}
      <TeamCustomerEditMini teamCustomer={teamCustomer} options={options} setSellers={sellersChange} />

      {shouldWaitForData && <Spinner size="large" />}
      {!shouldWaitForData && (
      <OrderItemEditor
        teamChannel={teamChannel}
        callBack={pipe}
        defaultSelection={parseOrderItemsToDefaultSelection(data, order)}
      />
      )}

      <Footer>
        <Button
          onClick={closeOrderCreationModal}
        >
          Cancel
        </Button>
        <Button
          onClick={onSave}
          isDisabled={(!isValid)}
          appearance="primary"
          isLoading={loading}
        >
          Save
        </Button>
      </Footer>
    </>
  );
};
