/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import Textfield from '@atlaskit/textfield';
import InlineEdit from '@atlaskit/inline-edit';
import { store } from 'react-notifications-component';
import { Product } from '../types';
import SuccessFlag from '../../ProductEdit/SuccessFlag';
import {
  useUpdateProductStockOnHandMutation,
} from '../../../graphql/types';
import ErrorFlag from '../../ProductEdit/ErrorFlag';
import PlanUpgrade from '../../Billing/PlanUpgrade';

const ListStockOnHandEdit = (
  { product, isDisable } : {product: Product, isDisable: boolean},
) => {
  const handleRow = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
  };
  const [updateProductStockOnHand] = useUpdateProductStockOnHandMutation();
  const [editValue, setEditValue] = useState(product.stockOnHand);
  const [disable, setDisable] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setDisable(false);
  };

  const handleSave = (value : string) => {
    const preValue = editValue;
    if (+value < 0) {
      const msg = `The entered value ${value} should not less than 0.`;
      store.addNotification({
        id: 'error',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 6000,
          click: false,
          touch: false,
        },
        content: <ErrorFlag title={msg} />,
      });
    } else {
      setEditValue(parseInt(value, 10));
      updateProductStockOnHand({
        variables: { id: product.id, stockOnHand: parseFloat(value) },
      })
        .then(() => {
          store.addNotification({
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 3000,
            },
            content: <SuccessFlag title="Product update success!" />,
          });
        }).catch((err) => {
          const msg = err.graphQLErrors[0]?.message || 'server internal error';
          store.addNotification({
            id: 'error',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 6000,
              click: false,
              touch: false,
            },
            content: <ErrorFlag title={msg} />,
          });
          setEditValue(preValue);
        });
    }
  };
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div onClick={handleRow}>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
      <InlineEdit
        defaultValue={editValue}
        editView={({ ...fieldProps }) => (
          <Textfield {...fieldProps} autoFocus testId="edit-view" isDisabled={isDisable} />
        )}
        readView={() => (
          <div data-testid="read-view">
            {editValue || '0'}
          </div>
        )}
        onConfirm={(value) => (isDisable === false ? handleSave(value) : setDisable(true))}

      />
    </div>
  );
};

export default ListStockOnHandEdit;
