import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../utils/useAuth';
import FastwayIntergration from '../../../components/FastwayIntergration/FastwayIntergration';

export default () => {
  const { state: { currentTeamId: teamId } } = useAuth();
  const { courierId } = useParams<{courierId:string}>();

  return (
    <FastwayIntergration teamId={teamId!} courierId={courierId} />
  );
};
