import React from 'react';
import { store } from 'react-notifications-component';
import Button from '@atlaskit/button/new';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import SuccessFlag from '../ProductEdit/SuccessFlag';
import { useSyncEbayProductsViaQueueMutation } from '../../graphql/types';

const SyncProductsFromEbayBtn = ({
  children,
  teamChannelId,
  teamChannelName,
}: {
  children: string;
  teamChannelId: string;
  teamChannelName: string;
}) => {
  const [
    syncEbayProductsViaQueue,
    syncEbayProductsViaQueueState,
  ] = useSyncEbayProductsViaQueueMutation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isConfirmWordEntered, setIsConfirmWordEntered] = React.useState(false);
  const handleCleanTeamChannel = async () => {
    await syncEbayProductsViaQueue({
      variables: {
        teamChannelId,
      },
    })
      .then(() => {
        store.addNotification({
          insert: 'top',
          container: 'top-right',
          animationIn: ['animated', 'fadeIn'],
          animationOut: ['animated', 'fadeOut'],
          dismiss: {
            duration: 3000,
          },
          content: <SuccessFlag title={`Pulling products from ${teamChannelName}`} />,
        });
        setIsOpen(false);
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  const channelNameInputHandler = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    if (value.trim().toLowerCase() === teamChannelName.toLowerCase()) {
      setIsConfirmWordEntered(true);
    } else {
      setIsConfirmWordEntered(false);
    }
  };

  return (
    <>
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
          >
            <ModalHeader>
              <ModalTitle appearance="danger">Confirm to pull products from eBay?</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                {/* eslint-disable-next-line max-len */}
                This action cannot be undone. It would download all eBay listing and merge into products. Please enter the word
                {' "'}
                {teamChannelName}
                {'" '}
                to continue
              </p>
              <p>
                <Textfield
                  testId="pushProductChannelName"
                  placeholder={`Enter your market place name "${teamChannelName}"`}
                  onChange={channelNameInputHandler}
                />
              </p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleCleanTeamChannel} isLoading={syncEbayProductsViaQueueState.loading} isDisabled={isConfirmWordEntered === false}>Confirm</Button>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
      <Button appearance="danger" onClick={() => setIsOpen(true)}>
        {children}
      </Button>
    </>
  );
};

export default SyncProductsFromEbayBtn;
