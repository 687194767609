import styled from 'styled-components';

const SearchBoxWrap = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  position:relative;
`;

export default SearchBoxWrap;
