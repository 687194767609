import { find } from 'lodash';
import { Order } from '../OrdersListing/types';
import { SelectedOrderItem } from './types';
import { ProductsQuery } from '../../graphql/types';
import { getCompoundedTaxRateMutiplier } from '../../utils/taxHelper';

const parseOrderItemsToDefaultSelection = (
  orderItemsProduct: ProductsQuery | undefined,
  order: Order | undefined,
) => {
  const result: Array<SelectedOrderItem> = [];
  const data = orderItemsProduct;
  const orderItems = order?.orderItems;

  if (data && data.Products) {
    const { products, teamTaxClasses } = data.Products;
    const [taxFreeClass] = teamTaxClasses.filter((t) => t.name.includes('GST Free Goods'));

    try {
      orderItems?.forEach((orderItem) => {
        const product = find(products, { id: orderItem.productID });
        // orderItemEditor company is default TAXCLU
        // TODO: if later we can set the default amountBase value,
        // will require to update how we load the unitPrice.
        const unitPriceIncGST = (orderItem!.unitPrice); // in cents
        const taxId = orderItem!.productTaxClassId || taxFreeClass.id;
        const taxMutiplier = getCompoundedTaxRateMutiplier(
          taxId,
          teamTaxClasses,
        );
        const taxName = find(
          teamTaxClasses, { id: taxId },
        )?.name || taxFreeClass.name;

        result.push({
          productId: product!.id,
          teamChannelProductid: orderItem!.teamChannelProductID,
          name: orderItem.name,
          sku: orderItem.sku,
          qty: orderItem.qty,
          unitPrice: (unitPriceIncGST / 100).toFixed(2), // in dollars
          taxClassId: orderItem!.productTaxClassId || '',
          taxName,
          taxMutiplier,
          // attemps to eliminate potential pointer problem...which may lead to bug..
          // just for safer but at a little expense of performance perhaps...
          product: JSON.parse(JSON.stringify(product)),
          teamTaxClasses: JSON.parse(JSON.stringify(teamTaxClasses)),
        });
      });
    } catch (err) {
      // maybe just skip for the item which encounter an error
      // TODO: Add to Rollbar?
    }
  }

  return result;
};

// eslint-disable-next-line import/prefer-default-export
export { parseOrderItemsToDefaultSelection };
