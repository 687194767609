import React from 'react';
import Button from '@atlaskit/button';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default ({ isOpen, closeModal }:any) => (
  <ModalTransition>
    {isOpen && (
    <Modal onClose={closeModal}>
      <ModalHeader>
        <ModalTitle>Something Missing...</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <ul>
          <li>Please make sure you have your WooCommerce Category defined.</li>
          <li>You can check it on the top of this section which above the title.</li>
        </ul>
      </ModalBody>
      <ButtonContainer>
        <Button appearance="primary" onClick={closeModal} autoFocus>
          Got it!
        </Button>
      </ButtonContainer>
    </Modal>
    )}
  </ModalTransition>
);
