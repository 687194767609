import React, { SyntheticEvent } from 'react';
import { formatMoney } from 'accounting';
import { Radio } from '@atlaskit/radio';
import DynamicTable from '@atlaskit/dynamic-table';
import { useShipping } from '../Shipping';
import { PreviewShipmentContainer, FlexBox } from '../Shipping.style';

interface RadioOptions {
  id: string;
  name: string;
  courierName:string;
  price:string;
}

const PreviewQuotes = () => {
  const { state, dispatch } = useShipping();
  const { quotes = [] } = state;
  const items: Array<RadioOptions> = quotes.map((cur) => ({
    id: cur.product_id,
    name: cur.productName,
    courierName: cur.courierName,
    price: formatMoney(cur.calculatedPrice),
  }));
  const isDisabled = state.ui.stage !== 'quote';

  const setValue = (value:string) => {
    if (!isDisabled) {
      // set selected product Id,
      dispatch({
        type: 'setQuoteId',
        payload: value,
      });

      const [selectedQuote] = quotes
        .filter((cur) => cur.product_id === value);

      // set cost
      dispatch({
        type: 'setCost',
        payload: selectedQuote.calculatedPrice,
      });
      // set courierid
      dispatch({
        type: 'setCourierId',
        payload: selectedQuote.courierId,
      });

      const selectedCourier = (() => {
        if (selectedQuote.courierName.toLowerCase().includes('aramex couriers')) {
          return 'fastway';
        }

        if (selectedQuote.courierName.toLowerCase().includes('australia post')) {
          return 'auspost';
        }

        return '';
      })();

      dispatch({
        type: 'setSelectedIntegratedCourier',
        payload: selectedCourier,
      });
    }
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = ({ currentTarget: { value } }: SyntheticEvent<any>) => {
    setValue(value);
  };

  const rows = items.map((item) => ({
    cells: [
      {
        content:
  <FlexBox>
    <Radio
      isChecked={item.id === state.shipment.selectedProductId}
      onChange={onChange}
      name={item.name}
      value={item.id}
      isDisabled={isDisabled}
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    />
    {item.name}
  </FlexBox>,
        key: item.id,

      },
      {
        content: item.courierName,
        key: item.id,
      },
      {
        content: item.price,
        key: item.id,
      },
    ],
  }));

  const head = {
    cells: [
      {
        content: 'Product',
        width: 50,
        key: 'product',
        isSortable: true,
      },
      {
        content: 'Couriers',
        key: 'couriers',
        isSortable: true,
        width: 30,
      },
      {
        content: 'Price',
        key: 'price',
        isSortable: true,
        width: 20,
      },
    ],
  };

  return (
    <PreviewShipmentContainer>
      <h5>Quotes</h5>
      <DynamicTable
        head={head}
        rows={rows}
        defaultSortKey="price"
        defaultSortOrder="DESC"
      />
    </PreviewShipmentContainer>
  );
};

export default PreviewQuotes;
