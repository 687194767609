import moment from 'moment';

const calculateDateDifference = (dateTime:string = Date().toString()) => {
  const formattedDate = moment(dateTime).format('YYYYMMDDHHmmss');
  let content = `${moment(formattedDate, 'YYYYMMDDHHmmss').fromNow()}`;
  if (localStorage.getItem('lng') === 'zh') {
    const num = parseInt(content, 10);
    if (content.indexOf('years') > -1) {
      content = `${num} 年前`;
    } else if (content.indexOf('months') > -1) {
      content = `${num} 月前`;
    } else if (content.indexOf('days') > -1) {
      content = `${num} 天前`;
    } else if (content.indexOf('min') > -1) {
      content = `${num} 分前`;
    } else if (content.indexOf('sec') > -1) {
      content = `${num} 秒前`;
    }
  } else {
    content = `since ${content}`;
  }

  return content;
};

export default calculateDateDifference;
