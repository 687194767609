import React from 'react';
import Button from '@atlaskit/button';
import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import { Order } from '../../OrdersListing/types';
import {
  useMagentoOrderMarkAsPaidMutation,
} from '../../../graphql/types';
import addNotification from '../../../utils/addNotification';

interface Props {
  order: Order,
  refetchOrder: () => void,
}

export default ({ order, refetchOrder }: Props) => {
  const [magentoOrderMarkAsPaidMutation] = useMagentoOrderMarkAsPaidMutation();
  const markAsPaid = () => {
    magentoOrderMarkAsPaidMutation({
      variables: {
        markAsPaid: {
          orderId: order.id,
        },
      },
    })
      .then(() => {
        addNotification('Mark as paid successfully', 'success');
        refetchOrder();
      })
      .catch(() => {
        addNotification('Mark as paid failed', 'danger');
      });
  };
  return (
    <>
      <Button
        isDisabled={order.status.code !== 'UNPAID'}
        onClick={markAsPaid}
        iconBefore={<CreditcardIcon label="payment" />}
      >
        Mark As Paid
      </Button>
    </>
  );
};
