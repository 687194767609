import styled from 'styled-components';

const RemoveBtn = styled.div`
  color: #737373;
  position: absolute;
  padding: 5px;
  bottom: 0;
  right: 0;
  background: white;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
  font-size: 1.5em;
`;

export default RemoveBtn;
