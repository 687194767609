import React from 'react';
import moment from 'moment';
/**
 * input:utc string
 * output:formated local string
 *
 */

interface Props {
  children:string,
  format?:string,
}

const MerpDateTime = ({
  children,
  format = 'YYYY-MM-DD HH:mm',

}:Props) => <div>{moment(children).local().format(format)}</div>;

MerpDateTime.defaultProps = {
  format: 'YYYY-MM-DD HH:mm',
};

export default MerpDateTime;
