import { useGetTeamByIdQuery } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';
import CheckChargeType from './CheckChargeType';
// import CheckPlan from './CheckPlan';
import CheckPlanType from './CheckPlanType';

const CheckFreeTrial = () => {
  const { state } = useAuth();
  const teamId = state.currentTeamId || '';
  const {
    data,
  } = useGetTeamByIdQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });

  const teamChargePlanId = data?.GetTeamById.team?.teamChargePlanId! || '';
  const teamChargePlan = CheckPlanType(teamChargePlanId);
  const current = new Date(Date.now()).getTime();
  const teamCharge = CheckChargeType(); // 0=shopify, 1=cash, 2=other
  if (teamChargePlan.planType === 0) { // free trial
    const createdAt = data?.GetTeamById.team?.createdAt;
    // console.log(createdAt);
    const diff = current - new Date(createdAt).getTime();
    const daysLeft = Math.ceil(30 - diff / (1000 * 3600 * 24));
    if (daysLeft < 0) {
      return true;
    }
  } else if (teamChargePlan.planType === 2) { // standard
    if (teamCharge === null) {
      return true;
    }
    if (teamCharge.teamChargeStatus !== 'alive') {
      return true;
    }
  }

  return false;

  // const teamSettingsObj = data?.GetTeamById.team?.settings ? JSON.parse(data?.GetTeamById.team?.settings) : {};

  // const teamCharge = CheckChargeType(); // 0=shopify, 1=cash, 2=other

  // // if free expired, and did not sub return true
  // if (teamSettingsObj.free === 0 && teamCharge === null) {
  //   return true;
  // }

  // if (teamCharge !== null && teamCharge.teamChargeStatus !== 'alive') {
  //   return true;
  // }

  // // if still in free, plan or account create before, return false
  // return false;
};

export default CheckFreeTrial;
