import React, { useState } from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import { useTranslation } from 'react-i18next';
import ColumnsOptionsContainer from '../styles/ColumnsOptionsContainer';
import { defaultColumns } from '../helper/getHeadAndRow';

const ColumnOptions = (
  {
    columns,
    onColumnTick,
  }:{
    columns: typeof defaultColumns,
    onColumnTick: (e: React.ChangeEvent<HTMLInputElement>) => void,
  },
) => {
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        appearance="subtle"
        iconAfter={<EditorSettingsIcon size="medium" label="settings" />}
        testId="columnOptionsButton"
      >
        {t('orders.left.columns.button.columns')}
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
          >
            <ModalHeader>
              <ModalTitle>{t('orders.left.columns.describe')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <ColumnsOptionsContainer>
                <div>
                  <h5>{t('orders.left.columns.order.order')}</h5>
                  {columns.filter((cur) => cur.group === 'Order').map((column) => (
                    <Checkbox
                      defaultChecked={column.isChecked}
                      key={column.key}
                      name={column.key}
                      label={t<string>(column.lng)}
                      value={column.name}
                      testId={`cb-${column.key}`}
                      onChange={onColumnTick}
                    />
                  ))}
                </div>
                <div>
                  <h5>{t('orders.left.columns.channel.channel')}</h5>
                  {columns.filter((cur) => cur.group === 'Channel').map((column) => (
                    <Checkbox
                      defaultChecked={column.isChecked}
                      key={column.key}
                      name={column.key}
                      label={t<string>(column.lng)}
                      value={column.name}
                      testId={`cb-${column.key}`}
                      onChange={onColumnTick}
                    />
                  ))}
                </div>
                <div>
                  <h5>{t('orders.left.columns.customer.customer')}</h5>
                  {columns.filter((cur) => cur.group === 'Customer').map((column) => (
                    <Checkbox
                      defaultChecked={column.isChecked}
                      key={column.key}
                      name={column.key}
                      label={t<string>(column.lng)}
                      value={column.name}
                      testId={`cb-${column.key}`}
                      onChange={onColumnTick}
                    />
                  ))}
                </div>
              </ColumnsOptionsContainer>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ColumnOptions;
