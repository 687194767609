import React from 'react';
import { Appearance, ButtonProps, Spacing } from '@atlaskit/button/types';
import Modal, {
  ModalTransition, ModalHeader, ModalFooter, ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';
// load types
import { ApolloError } from 'apollo-client/errors/ApolloError';
import Button from '@atlaskit/button/new';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { useDeleteProductMutation, DeleteProductMutation } from '../graphql/types';
import { useSubscription } from '../utils/useAuth';

export type DeleteButtonResponse = {
  data: DeleteProductMutation | null|undefined,
  error: ApolloError | undefined
};

export interface DeleteButtonProps extends ButtonProps {
  id: string,
  sku: string,
  getResponse: (response: DeleteButtonResponse) => void,
  // eslint-disable-next-line react/require-default-props
  children?: React.ReactNode,
  // eslint-disable-next-line react/require-default-props
  isCompactSize?: boolean,
}

const DeleteProductButton = (props: DeleteButtonProps) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const isDisabled = useSubscription();
  const {
    id, sku, getResponse, children, isCompactSize,
  } = props;
  const [deleteProduct, { loading }] = useDeleteProductMutation();

  const open = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const close = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const handleResponse = (res: DeleteButtonResponse) => {
    getResponse(res);
  };

  const handleDelete = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    deleteProduct({ variables: { id } })
      .then((res) => handleResponse({ data: res.data, error: undefined }))
      .catch((err: ApolloError) => handleResponse({
        data: undefined,
        error: err,
      }))
      .finally(() => (setIsOpen(false)));
  };

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const extraProps = isCompactSize === true ? {
    appearance: 'subtle' as Appearance,
    spacing: 'none' as Spacing,
    shouldFitContainer: true,
  } : {
    appearance: 'danger' as Appearance,
    iconBefore: <TrashIcon size="medium" label="delete a product" />,
  };

  const MyButton = isCompactSize ? (
    <Button
      appearance="subtle"
      spacing="none"
      shouldFitContainer
      onClick={open}
      testId={`${sku}-delete-btn`}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  ) : (
    <Button
      appearance="danger"
      iconBefore={(iconProps) => <TrashIcon {...iconProps} size="small" />}
      onClick={open}
      testId={`${sku}-delete-btn`}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );

  return (
    <>
      {MyButton}
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            width="small"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEscapePress={false}
          >
            <ModalHeader>
              <ModalTitle appearance="danger">{`Delete SKU:${sku}?`}</ModalTitle>
            </ModalHeader>
            <ModalBody>This action cannot be undone. The eBay listing would be unlisted.</ModalBody>
            <ModalFooter>
              <Button onClick={handleDelete} isLoading={loading} testId="product-del-confirm">DELETE</Button>
              <Button onClick={close} isDisabled={loading}>CANCEL</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default DeleteProductButton;
