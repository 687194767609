import gql, { disableFragmentWarnings } from 'graphql-tag';

disableFragmentWarnings();

const teamCustomerFragment = gql`
fragment SharedTeamCustomerFragment on TeamCustomer {
   id,
      name,
      company,
      email,
      mobile,
      phone,
      shippingAddresses {
        id,
        address {
          id,
          name,
          company,
          email,
          phone,
          fax,
          mobile,
          street,
          suburb,
          state,
          country,
          postcode,
        }
      },
      billingAddresses {
        id,
        address {
          id,
          name,
          company,
          email,
          phone,
          fax,
          mobile,
          street,
          suburb,
          state,
          country,
          postcode,
        }
      },
      team{
        name
      },
      teamChannel{
        name,
        channel{
          name,
        },
      },
      totalExpense,
      purchases,
      createdAt,
}
`;

export default teamCustomerFragment;
