import React, { useState } from 'react';
import Modal, {
  ModalTransition, ModalFooter, ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { useHistory } from 'react-router';
import Button from '@atlaskit/button/new';
import { useCancelTeamChargeMutation } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';
import addNotification from '../../utils/addNotification';

interface CancelChargeInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  chargeID: string
}

const CancelCharge = (props: CancelChargeInputProps) => {
  const { isOpen, close, chargeID } = props;
  const { state } = useAuth();
  const teamId = state.currentTeamId || '';
  const history = useHistory();
  const [cancelTeamChargeMutation] = useCancelTeamChargeMutation();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelCharge = async () => {
    setIsLoading(true);
    await cancelTeamChargeMutation(
      {
        variables: {
          teamId,
          teamChargeId: chargeID,
        },
      },
    ).then(
      () => {
        close();
        history.push('/Billing');
        window.location.reload();
        addNotification('Your plan cancellation for Standard has been successfully processed', 'success');
      },
    ).catch(
      (err) => {
        addNotification(err.toString(), 'danger');
        close();
      },
    );
    setIsLoading(false);
  };

  return (
    <ModalTransition>
      {
        isOpen
        && (
          <Modal
            onClose={close}
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
          >
            <ModalHeader>
              <ModalTitle appearance="danger">Are you sure you want to cancel</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                {/* eslint-disable-next-line max-len */}
                If you are sure about canceling, please click &quot;Confirm&quot; to proceed with the cancellation. If you have changed your mind or have any questions, please click &quot;Cancel&quot;.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={close} appearance="subtle">Cancel</Button>
              <Button onClick={handleCancelCharge} appearance="danger" isLoading={isLoading}>Confirm</Button>
            </ModalFooter>
          </Modal>
        )
      }
    </ModalTransition>
  );
};

export default CancelCharge;
