import React from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';

const PostShipmentButton = (
  { onShipped }:{onShipped:()=>void},
) => {
  const { dispatch } = useShipping();
  const close = () => {
    dispatch({ type: 'setIsModalOpen', payload: false });
    // call back
    onShipped();
  };

  return (
    <Button
      appearance="primary"
      onClick={close}
      testId="PostShipmentButton"
    >
      Done
    </Button>
  );
};

export default PostShipmentButton;
