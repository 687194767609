import React, { useState, SyntheticEvent } from 'react';
import SectionMessage from '@atlaskit/section-message';
import Spinner from '@atlaskit/spinner';
import Pagination from '@atlaskit/pagination';
import { useLogsQuery, LogTableName } from '../../../graphql/types';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { RecordGroup, PaginationWrapper } from '../HistoryLogs/HistoryLogsViewer';
import CommentCard from './CommentCard';
import CommentCreator from './CommentCreator';

interface Props {
  teamId:string,
  tableId:string,
  take?:number,
}

const CommentsLogsViewer = (
  { teamId, tableId, take = 10 }:Props,
) => {
  const tableName:LogTableName = LogTableName.Order;
  const [skip, setSkip] = useState(0);
  const {
    data, loading, error, refetch,
  } = useLogsQuery({
    variables: {
      teamId,
      skip,
      take,
      tableId,
      type: 'MANUAL',
    },
  });

  const refetchAfterCreate = () => {
    // nove
    setSkip(0);
    refetch();
  };

  const handlePageChange = async (event: SyntheticEvent, newPage: number) => {
    setSkip((newPage - 1) * take);
  };

  const logs = data?.Logs.logs;
  const total = data?.Logs.total;
  const totalPages = Math.ceil((total ?? 0) / take);
  const pages = [];
  for (let i = 1; i <= totalPages; i += 1) {
    pages.push(i);
  }

  if (error) {
    return (
      <SectionMessage
        title="It appears to be something wrong."
        appearance="error"
      >
        <p>{graphQLErrorsReader(error)}</p>
      </SectionMessage>
    );
  }

  if (loading) {
    return (
      <Spinner size="medium" />
    );
  }
  const records:JSX.Element[] = [];
  logs?.forEach((log) => {
    const {
      id, active,
    } = log;
    if (active) {
      records.push(
        <CommentCard
          key={id}
          log={log}
        />,
      );
    }
  });

  return (
    <>
      <RecordGroup>
        {records}
        {pages.length > 1
        && (
        <PaginationWrapper>
          <Pagination
            pages={pages}
            onChange={handlePageChange}
            selectedIndex={skip / take}
          />
        </PaginationWrapper>
        )}
        <CommentCreator
          tableId={tableId}
          tableName={tableName}
          refetch={refetchAfterCreate}
        />
      </RecordGroup>
    </>
  );
};

CommentsLogsViewer.defaultProps = {
  take: 10,
};

export default CommentsLogsViewer;
