import styled from 'styled-components';

export const Card = styled.div`
  border-radius:3px;
  box-shadow: 0px 0px 1px 0px rgba(9, 30, 66, 0.31),
              0px 12px 24px -6px rgba(9, 30, 66, 0.25);
  height:150px;
  width:300px;
  overflow:hidden;
  display:flex;
  margin-right:10px;
  padding:15px;
  align-items:flex-start;
  flex-direction:column;
  margin:0px 10px 10px 0px;
  .head {
    width:100%;
    display:flex;
    justify-content:flex-start;
    align-items:flex-start;
    .logo {
      max-height:30px;
    };
    .name {
      flex-shrink:0;
      flex-grow:1;
      padding-left:5px;
      overflow:hidden;
      font-size:0.875rem;
      line-height:0.875rem;
      .subtitle {
        color: rgb(107, 119, 140);
        font-size:0.85em;
        padding-top:0.25em;
        height:1rem;
      }
    }

    .edit {
      width:20px;
      height:20px;
      align-self:flex-start;
    };
  };
  .xero{
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .state {
   margin-top:50px;
   width:100%;
   display:flex;
   justify-content:space-evenly;
   .state-item {
    flex:0 0 60px;
    .state-item-name {
      color: rgb(107, 119, 140);
      font-size:0.75rem;
      line-height:1rem;
    }
    .state-item-value {
      font-size:0.75rem;
      line-height:1rem;
    }
   }
  }
  .xeroEdit{
    position: relative;
    left: 140px;
    top: -20px;
    width:45px;
    height:20px;
  }
`;

export const Container = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;
