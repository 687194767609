import React, { useState } from 'react';
import Modal, {
  ModalTransition, ModalFooter, ModalHeader, ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { Checkbox } from '@atlaskit/checkbox';
import Button from '@atlaskit/button/standard-button';
import EditorSettingsIcon from '@atlaskit/icon/glyph/editor/settings';
import { useTranslation } from 'react-i18next';
import ColumnsOptionsContainer from '../styles/ColumnsOptionsContainer';
import { useProducts } from '../ProductsListing';
import { DS_PRODUCTS_COLUMNS } from '../config';

const ColumnOptions = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { state, dispatch } = useProducts();

  const saveColumnsOptionsStorage = (c: typeof state.display.columns) => {
    const settings = c.filter((cur) => cur.dataSource.length > 0)
      .map(({ key, isChecked }) => ({ key, isChecked }));
    window.localStorage.setItem(
      DS_PRODUCTS_COLUMNS,
      JSON.stringify(settings),
    );
  };

  const onColumnTick = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target; // the name of the checkbox
    const newColumns = [...state.display.columns];
    newColumns.forEach((cur, index) => {
      if (cur.key === name) {
        newColumns[index].isChecked = e.target.checked;
        if (e.target.checked === false && state.query.sortKey === cur.key) {
          // if turn off the display of currently sorting column
          // need to reset the sortKey
          dispatch({
            type: 'setSortKey',
            payload: undefined,
          });
          dispatch({
            type: 'setSortOrder',
            payload: undefined,
          });
        }
      }
    });
    dispatch({
      type: 'setColumns',
      payload: newColumns,
    });
    saveColumnsOptionsStorage(newColumns);
  };

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        appearance="subtle"
        iconAfter={<EditorSettingsIcon size="medium" label="settings" />}
        testId="columnOptionsButton"
      >
        {t('products.left.columns.columns')}
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
          >
            <ModalHeader>
              <ModalTitle>{t('products.left.columns.describe')}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <ColumnsOptionsContainer>
                <div>
                  <h5>{t('products.left.columns.product.product')}</h5>
                  {state.display.columns.filter((cur) => cur.group === 'Product').map((column) => (
                    <Checkbox
                      defaultChecked={column.isChecked}
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={column.name}
                      testId={`cb-${column.key}`}
                      onChange={onColumnTick}
                    />
                  ))}
                </div>
                <div>
                  <h5>{t('products.left.columns.category.category')}</h5>
                  {state.display.columns.filter((cur) => cur.group === 'Category').map((column) => (
                    <Checkbox
                      defaultChecked={column.isChecked}
                      key={column.key}
                      name={column.key}
                      label={column.label}
                      value={column.name}
                      testId={`cb-${column.key}`}
                      onChange={onColumnTick}
                    />
                  ))}
                </div>
              </ColumnsOptionsContainer>
            </ModalBody>

            <ModalFooter>
              <Button onClick={() => (setIsOpen(false))}>Close</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default ColumnOptions;
