import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import ProductDetails from './ProductDetails';
import WithLoading from '../WithLoading/WithLoading';
import productQuery from '../../graphql/product/queries/product';
import { ProductQuery } from '../../graphql/types';

interface InputProps {
  teamID: string,
  productId: string,
}

const ProductDetailsWithLoading = WithLoading(ProductDetails);

const ProductCreationFormContainer = (props: InputProps) => {
  const { productId, teamID } = props;
  const {
    refetch, data, error, loading,
  } = useQuery<ProductQuery>(productQuery, {
    fetchPolicy: 'network-only',
    variables: {
      teamID,
      productId,
    },
  });
  const productData = data?.Product?.product || 'undefined';
  if (loading === false && (error || productData === 'undefined')) {
    return <p>Something wrong</p>;
  }
  const inputProps = { productData, refetch };
  return (
    // eslint-disable-next-line
    <ProductDetailsWithLoading isLoading={loading} {...inputProps} />);
};

export default ProductCreationFormContainer;
