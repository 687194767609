import gql from 'graphql-tag';

const auspostGetAccountsQuery = gql`
query AuspostGetAccount(
  $teamId:String!,
  $key:String!,
  $password:String!,
  $account:String!
) {
  AuspostGetAccounts(
    teamId:$teamId,
    key:$key,
    password:$password,
    account:$account,
  ) {
    status,
    response,
  }
}
`;

export default auspostGetAccountsQuery;
