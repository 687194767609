import React from 'react';
import { Field } from '@atlaskit/form';
import { Controller, Control } from 'react-hook-form';
import Select from '@atlaskit/select';
import _ from 'lodash';
import { ErrorMsg } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import { ErrorType, SingleOption } from './types';

const SelectWithLabelAndError = ({
  name, label, isRequired, control, errors,
  defaultValue, setValue, isMulti, optionsArr, isDisable,
}: {
  name: string, label: string, isRequired: boolean,
  control: Control<Record<string, string>>,
  errors: ErrorType, defaultValue: string | Array<string>, setValue: Function,
  isMulti: boolean, optionsArr: Array<SingleOption>, isDisable: boolean
}) => {
  // Set default value from one of the options
  const parsedDefaultValue = defaultValue && Array.isArray(defaultValue)
    ? defaultValue.map((option) => {
      const targetOptionArr = optionsArr.filter((o) => o.value === option);
      const targetOption = targetOptionArr && Array.isArray(targetOptionArr)
        && targetOptionArr.length === 1
        ? targetOptionArr[0] : [];
      return targetOption;
    }) : [];
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: (value) => {
          if (isRequired && isMulti && _.get(value, 'length', 0) === 0) {
            return false;
          }
          if (isRequired && !isMulti && Array.isArray(value) && _.get(value, 'length', 0) === 0) {
            return false;
          }
          return true;
        },
      }}
      // eslint-disable-next-line no-nested-ternary
      defaultValue={_.isEmpty(parsedDefaultValue)
        ? []
        : (isMulti ? parsedDefaultValue : [parsedDefaultValue[0]])}
      as={(
        <>
          <Field
            key={name}
            name={name}
            isRequired={isRequired}
            label={label}
          >
            {() => (
              <Select
                onChange={(valObj) => {
                  setValue(name, valObj, { shouldValidate: true });
                }}
                placeholder={`Choose a ${label}`}
                isMulti={isMulti}
                options={optionsArr}
                // eslint-disable-next-line no-nested-ternary
                defaultValue={_.isEmpty(parsedDefaultValue)
                  ? []
                  : (isMulti ? parsedDefaultValue : [parsedDefaultValue[0]])}
                isDisabled={isDisable}
              />
            )}
          </Field>
          {_.get(errors, name) && (
          <ErrorMsg>
            {label}
            {' '}
            is required
          </ErrorMsg>
          )}
        </>
      )}
    />
  );
};

export default SelectWithLabelAndError;
