import React from 'react';
import { Grid } from '@atlaskit/primitives';

import SettingMenu from './SettingMenu';
import { useAuth } from '../../utils/useAuth';
import TeamSellersEditor from '../../components/TeamSellersEditor/TeamSellersEditor';

export default function TeamSellersSetting() {
  const { state: { currentTeamId: teamId } } = useAuth();
  return (
    <Grid
      testId="grid-basic"
      rowGap="space.200"
      columnGap="space.400"
      templateColumns="1fr 5fr"
    >
      <SettingMenu />
      <TeamSellersEditor teamId={teamId!} />
    </Grid>
  );
}
