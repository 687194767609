import React from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import { isUndefined } from 'lodash';
import EbayConfigor from '../components/EbayConfigor/EbayConfigor';
import Nofound from './NotFound';

export default () => {
  const { teamChannelID } = useParams<{teamChannelID : string}>();
  const { redirectPath } = queryString.parse(window.location.search);

  if (teamChannelID === 'undefined') {
    return <Nofound />;
  }

  if (isUndefined(redirectPath)) {
    return (
      <EbayConfigor
        teamChannelID={teamChannelID}
      />
    );
  }

  return (
    <EbayConfigor
      teamChannelID={teamChannelID}
      redirectPath={redirectPath as string} // the path to be redirected after save policy
    />
  );
};
