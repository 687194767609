import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import { useShipping } from '../Shipping';

const Error = () => {
  const { state } = useShipping();

  if (state.ui.error) {
    return (
      <SectionMessage
        title="It appears to be something wrong."
        appearance="error"
      >
        <p>{state.ui.error}</p>
      </SectionMessage>
    );
  }
  return <></>;
};
export default Error;
