import React from 'react';

const SignUpSuccessMsg = ({ email }:{ email:string }) => (
  <>
    <h1>Check your email</h1>
    <p>
      We have sent a message to
      {' '}
      {' '}
      <b>{email}</b>
      {' '}
      with a link to activate your account.
      <br />
      If your link is expired, you can try to sign up again.
    </p>
    <h3>Didn&apos;t get an email?</h3>
    <p>
      If you do not see an email from us within a few minutes,
      a few things could have happened:
    </p>
    <ul>
      <li>The email is in your spam folder.</li>
      <li>The email address you entered had a mistake or typo. </li>
      <li>You accidentally gave us another email address.</li>
      <li>We can not deliver the email to this address..</li>
    </ul>
    <a href={`/signup/${email}/resend-activation/`}>Re-enter your email and try again</a>
  </>
);

export default SignUpSuccessMsg;
