import React from 'react';
import TeamCustomersContainer
  from '../components/TeamCustomersContainer/TeamCustomersContainer';
import { useAuth } from '../utils/useAuth';

const TeamCustomersListingPage = () => {
  const { state: { currentTeamId: teamID } } = useAuth();

  if (!teamID) {
    return <p>Team ID not found</p>;
  }
  return <TeamCustomersContainer teamID={teamID!} />;
};

export default TeamCustomersListingPage;
