import React from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';
import { useCreateShipmentMutation } from '../../../../graphql/types';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';

const PickUpConfirmButton = (
  { onShipped }:{onShipped:()=>void},
) => {
  const { state, dispatch } = useShipping();
  const [createShipment] = useCreateShipmentMutation();
  const close = () => dispatch({ type: 'setIsModalOpen', payload: false });
  const createPickupShipment = async () => {
    dispatch({ type: 'setIsSubmiting', payload: true });
    dispatch({ type: 'setIsPrimaryButtonDisabled', payload: true });
    dispatch({ type: 'setError', payload: '' });
    const {
      orderId, courierId,
    } = state.shipment;

    // pickup shipment only rquired orderid as argument
    await createShipment({
      variables: {
        shipment: {
          orderId,
          courierId,
        },
      },
    })
      .then(() => {
        // callback
        onShipped();
        close();
      })
      .catch((err) => {
        dispatch({ type: 'setError', payload: graphQLErrorsReader(err) });
      })
      .finally(() => {
        dispatch({ type: 'setIsSubmiting', payload: false });
        dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
      });
  };

  return (
    <Button
      appearance="primary"
      isDisabled={state.ui.isPrimaryButtonDisabled}
      onClick={createPickupShipment}
      testId="PickUpConfirmButton"
    >
      Confirm
    </Button>
  );
};

export default PickUpConfirmButton;
