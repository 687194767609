import React from 'react';
import SMTPEditor from '../../components/SMTPEditor/SMTPEditor';
import { useAuth } from '../../utils/useAuth';

export default () => {
  const { state: { currentTeamId: teamId } } = useAuth();

  return (
    <SMTPEditor teamId={teamId!} />
  );
};
