import React, { useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import printJS from 'print-js';
import { useShipping } from '../Shipping';
import { PreviewShipmentContainer } from '../Shipping.style';
import addNotification from '../../../utils/addNotification';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';

const PostShipmentReview = () => {
  const { state } = useShipping();
  const { postShipment } = state;

  const {
    courier, trackNumber, createdAt, labelUrl,
  } = postShipment!;

  useEffect(() => {
    axios
      .get(labelUrl, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const pdf = new Blob([response.data], {
          type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdf);
        printJS(pdfUrl);
      })
      .catch((err) => addNotification(graphQLErrorsReader(err), 'danger'));
  }, [labelUrl]);

  return (
    <PreviewShipmentContainer>
      <h5>Shipment Summary</h5>
      <div>
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Courier</td>
              <td>{courier.name}</td>
            </tr>
            <tr>
              <td>Tracking Number</td>
              <td>{trackNumber}</td>
            </tr>
            <tr>
              <td>CreatedAt</td>
              <td>{moment.utc(createdAt).format('YYYY-MM-DD HH:MM')}</td>
            </tr>
            <tr>
              <td>Label</td>
              <td><a href={labelUrl} target="_blank" rel="noopener noreferrer">Download</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </PreviewShipmentContainer>
  );
};

export default PostShipmentReview;
