import gql from 'graphql-tag';

const fullAddressFragment = gql`
fragment fullAddressFragment on Address {
   id,
    name,
    company,
    email,
    phone,
    fax,
    mobile,
    street,
    suburb,
    state,
    country,
    postcode,
    comments,
   
}
`;

export default fullAddressFragment;
