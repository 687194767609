import React from 'react';
import { NavLink } from 'react-router-dom';
import { ErrorMessage } from '@atlaskit/form';

const NOT_ACTIVATED_ERROR_REGEX = RegExp('not .* activated', 'g');
const INACTIVATED_ERROR_REGEX = RegExp('inactivated', 'g');
const EMAIL_EXISTS_ERROR_REGEX = RegExp('email already exists', 'g');

const FriendlyErrorMessage = (errMsg:string, email:string) => {
  if (NOT_ACTIVATED_ERROR_REGEX.test(errMsg)
    || INACTIVATED_ERROR_REGEX.test(errMsg)) {
    return (
      <ErrorMessage testId="serverError">
        The email already exist but has not been confirmed. Click
        <NavLink to={`/signup/${email}/resend-activation`} style={{ display: 'contents' }}> here </NavLink>
        to resend the confirmation email.
      </ErrorMessage>
    );
  } if (EMAIL_EXISTS_ERROR_REGEX.test(errMsg)) {
    return (
      <ErrorMessage testId="serverError">
        The email already exists. Click
        <NavLink to="/signin" style={{ display: 'contents' }}> here </NavLink>
        to sign in.
      </ErrorMessage>
    );
  }
  return (
    <ErrorMessage testId="serverError">
      { errMsg }
    </ErrorMessage>
  );
};

export default FriendlyErrorMessage;
