import React from 'react';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { CommonProps } from '../types';
import { FlexBox, ValueBefore, ValueAfter } from '../styles';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';

const InventoryValueChange = (
  { lineItem }:CommonProps,
) => {
  const newValue = lineItem.response?.AdjustInventory.product?.totalInventoryCost;
  const oldValue = lineItem.response?.AdjustInventory.totalInventoryValueBeforeMutation;

  if (typeof newValue !== 'undefined') {
    return (
      <FlexBox>
        <ValueBefore data-testid="valueBefore">
          {formatMoneyWithPrefix(oldValue ?? 0)}
        </ValueBefore>
        <ArrowRightIcon label="changed to" primaryColor="green" />
        <ValueAfter data-testid="valueAfter">{formatMoneyWithPrefix(newValue)}</ValueAfter>
      </FlexBox>
    );
  }

  return (
    <FlexBox>
      <JiraFailedBuildStatusIcon
        label="Inventory value update failed"
        primaryColor="red"
        testId="errorIcon"
      />
    </FlexBox>
  );
};
export default InventoryValueChange;
