import styled from 'styled-components';

const FilterSection = styled.div`
  border:1px solid #ccc;
`;

const FilterInputsGroup = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  padding:10px;
  .selector {
    width:200px;
    margin-right:2px;
  }
  .reset {
    margin:10px;
  }
`;

const SelectedFiltersGroup = styled.div`
  display:flex;
  flex-wrap:wrap;
  align-items:center;
  padding:10px;
`;

const TagContainer = styled.div`
  span {
    max-width:259px;
  }
`;

export {
  FilterInputsGroup,
  SelectedFiltersGroup,
  FilterSection,
  TagContainer,
};
