import styled from 'styled-components';

export const FormContainer = styled.div`
  width: 100% !important;
`;

export const Label = styled.p`
  font-size: 16px;
  color: black;
`;

export const LabelContainer = styled.div`
  color: #6B778C;
  margin-top: 20px;
  font-size: 0.85em;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
`;

export const ButtonItem = styled.div`
  margin-right: 2px;
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;

export const MessageWrapper = styled.div`
  position: fixed;
  left: 40px;
  bottom: 40px;
`;
