import React, { useState } from 'react';
import Spinner from '@atlaskit/spinner';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import InlineEdit from '@atlaskit/inline-edit';
import TextArea from '@atlaskit/textarea';
import _ from 'lodash';
import {
  useTeamCustomerCommentsQuery,
  useCreateTeamCustomerCommentMutation,
} from '../../graphql/types';
import MerpAvatar from '../MerpAvatar/MerpAvatar';
import {
  CommentContainer,
  CommentContentContainer,
  CommentsLable,
  CommentRowContainer,
  CommentContentTitleContainer,
  CommentContentDetailContainer,
  ReadViewContainer,
  FormContainer,
  MessageWrapper,
} from './teamCustomerComments.styles';
import calculateDateDifference from '../../utils/calculateDateDifference';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import Comment from './types';
import PlanUpgrade from '../Billing/PlanUpgrade';
import { useSubscription } from '../../utils/useAuth';

interface CommentsInputProps {
  teamId: string,
  customerId: string,
}

const TeamCustomerComments = (props: CommentsInputProps) => {
  const { customerId, teamId } = props;
  const variables = { customerId, teamID: teamId };
  const [createTeamCustomerComment] = useCreateTeamCustomerCommentMutation();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState('');
  const freeExpired = useSubscription();
  const [disable, setDisable] = React.useState<boolean>(false);
  const closeUpgrade = () => {
    setDisable(false);
  };
  const {
    data, error, loading, refetch,
  } = useTeamCustomerCommentsQuery({ variables });
  if (loading) {
    return <Spinner />;
  }
  if (error) {
    return <h1>Fetching comments failed</h1>;
  }
  const comments = _.get(data, 'TeamCustomerComments.comments', []);
  const renderCommentsList = () => (
    comments.map((comment: Comment) => {
      const firstName = _.get(comment, 'createdBy.firstName', '');
      const lastName = _.get(comment, 'createdBy.lastName', '');
      return (
        <CommentRowContainer key={comment.id}>
          <MerpAvatar size={24} firstName={firstName} lastName={lastName} />
          <CommentContentContainer>
            <CommentContentTitleContainer>
              {`${firstName} ${lastName} ${calculateDateDifference(comment.updatedAt)}`}
            </CommentContentTitleContainer>
            <CommentContentDetailContainer>
              {comment.content}
            </CommentContentDetailContainer>
          </CommentContentContainer>
        </CommentRowContainer>
      );
    })
  );
  const handleCreateComment = (value: string) => {
    if (freeExpired === true) {
      setDisable(true);
      return;
    }
    createTeamCustomerComment({
      variables: {
        teamId,
        customerId,
        content: value,
      },
    })
      .then(() => {
        refetch();
      })
      .catch((err) => {
        setShowMessage(true);
        const msg = graphQLErrorsReader(err) || 'Update failed';
        setErrorMsg(msg);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
  };
  return (
    <>
      <CommentContainer>
        <CommentsLable>Comments</CommentsLable>
        {renderCommentsList()}
        <CommentRowContainer>
          <MerpAvatar size={24} />
          <FormContainer>
            <InlineEdit
              defaultValue=""
              editView={(fieldProps, ref) => (
              // @ts-ignore - textarea does not currently correctly pass through ref as a prop
                <TextArea {...fieldProps} ref={ref} minimumRows={3} />
              )}
              readView={() => (
                <ReadViewContainer>
                  {'' || 'Click to enter your comment'}
                </ReadViewContainer>
              )}
              onConfirm={(value) => handleCreateComment(value)}
              keepEditViewOpenOnBlur
              readViewFitContainerWidth
            />
          </FormContainer>
        </CommentRowContainer>
        {showMessage
        && (
          <MessageWrapper>
            <Flag
              appearance="error"
              icon={<ErrorIcon secondaryColor={R400} label="error" />}
              id="error"
              key="error"
              title={errorMsg}
            />
          </MessageWrapper>
        )}
      </CommentContainer>
      <PlanUpgrade isOpen={disable} close={closeUpgrade} />
    </>
  );
};

export default TeamCustomerComments;
