/* eslint-disable react/require-default-props */
import Button from '@atlaskit/button';
import React, { useState } from 'react';
import styled from 'styled-components';
import LinkIcon from '@atlaskit/icon/glyph/link';
import EditorSuccessIcon from '@atlaskit/icon/glyph/editor/success';
import { Order } from '../../OrdersListing/types';

const OrderAddressContainer = styled.div`

    .order-address {
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-gap: 0px;
      justify-content: space-between;
      width: 205px;

    }

    .label{
      grid-column: 1 / span 3;
    }

    box-sizing:border-box;
    width:33%;
    padding-right:20px;
    padding-left:30px;
    .label{
        color:rgb(107, 119, 140);
        font-size:11px;
        line-height:16px;
        padding-bottom:5px;
    }
    .email {
      word-break:break-word;
    }
    .hoverHighlight:hover {
      background: #9fc9ff;
    }

    .copyIcon {
      display: none;
    }

    .inlineBtn:hover .copyIcon {
       display: inline;
       margin-left:10px;
    }


    .inlineBtn {
      display: inline;
      border: none;
      background: none;
      text-align: left;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
`;

const Popover = styled.div`
    .wrapper {
      position: relative;
      display: inline-block;
    }
    .content {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      background-color: #eee;
      padding: 0.5rem;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      top: -5px;
      left: calc(100% + 1rem);
    }
    .content:before {
      position: absolute;
      z-index: -1;
      content: "";
      top: calc(50% - 10px);
      left: -20px;
      border: solid 10px transparent;
      border-right-color: #eee;
    }
    .content.copied {
      z-index: 10;
      opacity: 1;
      visibility: visible;
      transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
    }
`;

interface Props {
  address: Order['shippingAddress'] | Order['billingAddress'],
  teamCustomer:Order['teamCustomer'],
  title:React.ReactNode,
}

const CopyToClipboard = (
  { getElement }:
  { getElement: (showCopiedPopup: () => void) => React.ReactNode },
) => {
  const [isCopied, setIsCopied] = useState(false);
  const showCopiedPopup = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 800);
  };
  return (
    <Popover>
      <div className="wrapper">
        {getElement(showCopiedPopup)}
        <div className={`content ${isCopied ? 'copied' : ''}`}><EditorSuccessIcon label="copied" size="small" primaryColor="green" /></div>
      </div>
    </Popover>
  );
};

const TextWithCopyToClipboard = ({ content, className = '' }: {content: string, className?: string}) => (
  <CopyToClipboard getElement={
      (showCopiedPopup: () => void) => (
        <button
          onClick={() => {
            navigator.clipboard.writeText(content);
            showCopiedPopup();
          }}
          type="button"
          className={`inlineBtn hoverHighlight ${className}`}
        >
          {content}
          <div className="copyIcon">
            <LinkIcon label="copy" size="small" />
          </div>

        </button>
      )
  }
  />
);

export default ({ address: a, teamCustomer: t, title }:Props) => (
  <OrderAddressContainer>

    <div className="order-address">
      <div className="label">{title}</div>
      <CopyToClipboard getElement={
          (showCopiedPopup: () => void) => (
            <Button
              appearance="subtle"
              onClick={() => {
                navigator.clipboard.writeText(`${a.name}\n${a.street}\n${a.suburb}\n${a.state} ${a.postcode}`);
                showCopiedPopup();
              }}
            >
              <LinkIcon label="copy" size="medium" />
            </Button>
          )
        }
      />
    </div>
    {a.name && (
      <div className="name">
        <a href={`/customers/${t.id}`} target="_blank" rel="noopener noreferrer" style={{ color: '#7d7d7d', fontWeight: 'bold' }}>{a.name}</a>
      </div>
    )}
    {a.company && <TextWithCopyToClipboard className="company" content={a.company} />}
    {a.street && (
    <TextWithCopyToClipboard className="street" content={a.street} />
    )}
    <TextWithCopyToClipboard className="suburb" content={`${a.suburb} ${a.state} ${a.postcode}`} />
    {a.phone && <TextWithCopyToClipboard className="phone" content={a.phone} />}
    <TextWithCopyToClipboard className="email" content={a.email || t.email} />

  </OrderAddressContainer>
);
