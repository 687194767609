import React from 'react';
import Button from '@atlaskit/button';
import DEFAULT_STAT from './config';
import { useTicket } from './TicketsContainer';

const StatusFilter = () => {
  const { state, dispatch } = useTicket();
  return (
    <div>
      {DEFAULT_STAT.map((ele) => (
        <Button
          key={ele.name}
          appearance="subtle"
          isSelected={ele.value === state.status}
          style={{ marginRight: 10 }}
          testId={`status-filter-${ele.name.toLowerCase()}`}
          spacing="compact"
          onClick={() => dispatch({ type: 'setStatus', payload: ele.value })}
        >
          {ele.name}
        </Button>
      ))}
    </div>
  );
};

export default StatusFilter;
