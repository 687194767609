import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalBody,
} from '@atlaskit/modal-dialog';

interface Props {
  isOpen:boolean,
  heading:string,
  children:React.ReactNode,
  width:number | string,
  onClose:()=>void,
}

export default ({
  heading, children, isOpen, onClose, width,
}:Props) => (
  <ModalTransition>
    {isOpen && (
      <Modal
        onClose={onClose}
        shouldScrollInViewport
        width={width}
        shouldCloseOnOverlayClick={false}
      >
        <ModalHeader>
          <ModalTitle>{heading}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>

      </Modal>
    )}
  </ModalTransition>
);
