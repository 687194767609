import React from 'react';
import Button from '@atlaskit/button';
import { Appearance, Spacing } from '@atlaskit/button/types';
import WatchFilledIcon from '@atlaskit/icon/glyph/watch-filled';
import { useSubscription } from '../../../utils/useAuth';

const openNewTab = (urlKey: string) => {
  window.open(urlKey, '_blank');
};

const ViewMagentoListingBtn = ({
  urlKey, children, isCompactSize,
}: {
  urlKey: string,
  children: React.ReactNode,
  isCompactSize: boolean,
}) => {
  const isDisabled = useSubscription();
  const subtle = 'subtle' as Appearance;
  const none = 'none' as Spacing;
  const extraProps = isCompactSize !== true ? {
    iconBefore: <WatchFilledIcon size="medium" label="view Magento listing" />,
  } : {
    appearance: subtle,
    spacing: none,
  };
  return (
    <Button
      {...extraProps}
      onClick={() => openNewTab(urlKey)}
      isDisabled={isDisabled}
    >
      {children}
    </Button>
  );
};

export default ViewMagentoListingBtn;
