import React from 'react';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import ProductTitle from '../ProductTitle/ProductTitle';
import { TableContainer, SkuBox } from './styles';
import type { LineItem } from './types';
import QtyChange from './widgets/QtyChange';
import InventoryValueChange from './widgets/InventoryValueChange';
import MovingAverage from './widgets/MovingAverage';
import StatusFeedback from './widgets/StatusFeedback';
import QtyIncreasedBy from './widgets/QtyIncreasedBy';

const head:HeadType = {
  cells: [
    {
      content: 'Name',
      width: 25,
    },
    {
      content: 'SKU',
      width: 10,
    },
    {
      content: 'Purchase Qty',
      width: 10,
    },
    {
      content: 'Qty',
      width: 10,
    },
    {
      content: 'Total Cost (Excl.)',
      width: 15,
    },
    {
      content: 'Moving Average (Excl.)',
      width: 10,
    },
    {
      content: 'Status',
      width: 10,
    },
  ],
};

export default ({ lineItems }:{lineItems:LineItem[]}) => {
  const rows:Array<RowType> = lineItems.map((item, index) => ({
    key: `${item.id}-${index}`,
    cells: [
      {
        content:
  <ProductTitle
    product={item.product}
  />,
      },
      { content: <SkuBox>{item.sku}</SkuBox> },
      { content: <QtyIncreasedBy lineItem={item} /> },
      { content: <QtyChange lineItem={item} /> },
      { content: <InventoryValueChange lineItem={item} /> },
      { content: <MovingAverage lineItem={item} /> },
      {
        content: <StatusFeedback lineItem={item} />,
      },
    ],
  }));

  return (
    <TableContainer>
      <DynamicTableStateless
        head={head}
        rows={rows}
      />
    </TableContainer>
  );
};
