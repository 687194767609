import { isInteger } from 'lodash';
import { SelectedOrderItem } from '../OrderItemEditor/types';

const isItemsInValid = (
  items: Array<SelectedOrderItem>,
) => {
  if (items.length === 0) return true;

  return items.map((item) => {
    const {
      teamChannelProductid,
      qty,
      unitPrice,
      taxClassId,
    } = item;

    return !!(
      teamChannelProductid
      && isInteger(qty)
      && !Number.isNaN(Number(unitPrice))
      && Number(unitPrice) >= 0
      && taxClassId
    );
  }).includes(false);
};

// eslint-disable-next-line import/prefer-default-export
export { isItemsInValid };
