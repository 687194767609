import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width:100%;
`;

export const ProfileTitleContainer = styled.div`
    display:flex;
    align-items:center;
    padding:10px;
    .title{
      padding-left:0.825rem;
    }
`;

export const InlineEditLikeDiv = styled.div`
padding:8px 6px;
`;
