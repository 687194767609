import React, { SyntheticEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import DynamicTable from '@atlaskit/dynamic-table';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import Button from '@atlaskit/button';
import Pagination from '@atlaskit/pagination';
import Select from '@atlaskit/select';
import _, { map } from 'lodash';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import SplitView from '../SplitView/SplitView';
import {
  TeamCustomer,
  ColumnProps,
  TeamCustomerQueryInputProps,
} from './types';
import {
  TeamCustomerTableContainer,
  TeamCustomerPagination,
  FilterItem,
  FilterContainer,
  MoreSpan,
} from './teamCustomers.styles';
import formatMoneyWithPrefix from '../../helpers/formatMoney';
import DropdownMenuColumns from './DropdownMenuColumns';
import formattedDate from '../../utils/formattedDate';
import Wrapper from '../ListView/Wrapper';

import { useTeamCustomersQuery } from '../../graphql/types';
import GlobalSearch from './GlobalSearch';
import { useCustomer } from './TeamCustomersContainer';
import CustomerView from './views/DetailedView/CustomerView';

const PAGE_SIZE = 20;
const TeamCustomersListing = (props: {teamID: string}) => {
  const { t } = useTranslation();
  // const history = useHistory();
  const [skip, setSkip] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [teamChannelOptions, setTeamChannelOptions] = useState<{
    label: string, value: string,
  }[]>([]);
  const [dateRange, setDateRange] = useState({
    label: i18next.t<string>('customers.filter.select.anyTime'), value: 'all',
  });
  const [sortKey, setSortKey] = useState('name');
  const [inputSortOrder, setInputSortOrder] = useState('ASC');
  const [sortTheOrder, setTheSortOrder] = useState<'DESC' | 'ASC' | undefined>('DESC');
  const [hideFilter, setHideFilter] = useState(true);
  const { teamID } = props;
  const [customerID, setID] = useState<string>('');
  const [customerName, setName] = useState<string>('');
  const { state } = useCustomer();
  const handlePageChange = (newPage: number) => {
    setSkip((newPage - 1) * PAGE_SIZE);
    setCurrentPage(newPage - 1);
  };
  const handleTeamChannelIdsChange = (options: {
    label: string, value: string,
  }[]) => {
    setTeamChannelOptions(options);
  };
  const handleDateRangeChange = (val: { label: string, value: string }) => {
    setDateRange(val);
  };
  const handleSortArgChange = (val: { key: string, sortOrder: 'ASC' | 'DESC' }) => {
    const { key } = val;
    let { sortOrder } = val;

    if (key === sortKey) {
      if (inputSortOrder === 'ASC') {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
    } else if (key !== sortKey) {
      if (inputSortOrder === 'ASC') {
        sortOrder = 'ASC';
      } else {
        sortOrder = 'DESC';
      }
    }

    setSortKey(key);
    setInputSortOrder(sortOrder);
    setTheSortOrder(sortOrder);
  };
  const teamChannelIds = map(
    teamChannelOptions, (tco: { label: string, value: string}) => tco!.value,
  );
  const savedColumns = window.localStorage.getItem('customerColumns') || '{}';

  let columnsVal = JSON.parse(savedColumns);

  const selectedColumns = [
    {
      key: 'store', label: i18next.t('customers.columns.select.store'), isSortable: false, isChecked: true,
    },
    {
      key: 'name', label: i18next.t('customers.columns.select.name'), isSortable: true, isChecked: true,
    },
    {
      key: 'email', label: i18next.t('customers.columns.select.email'), isSortable: false, isChecked: true,
    },
    {
      key: 'phone', label: i18next.t('customers.columns.select.cn'), isSortable: false, isChecked: true,
    },
    {
      key: 'purchases', label: i18next.t('customers.columns.select.purchases'), isSortable: true, isChecked: true,
    },
    {
      key: 'totalExpense', label: i18next.t('customers.columns.select.te'), isSortable: true, isChecked: true,
    },
    {
      key: 'createdAt', label: i18next.t('customers.columns.select.ca'), isSortable: true, isChecked: true,
    },
  ];
  if (_.isEmpty(columnsVal)) {
    columnsVal = [
      {
        key: 'store', label: i18next.t('customers.columns.select.store'), isSortable: false, isChecked: true,
      },
      {
        key: 'name', label: i18next.t('customers.columns.select.name'), isSortable: true, isChecked: true,
      },
      {
        key: 'email', label: i18next.t('customers.columns.select.email'), isSortable: false, isChecked: true,
      },
      {
        key: 'phone', label: i18next.t('customers.columns.select.cn'), isSortable: false, isChecked: true,
      },
      {
        key: 'purchases', label: i18next.t('customers.columns.select.purchases'), isSortable: true, isChecked: true,
      },
      {
        key: 'totalExpense', label: i18next.t('customers.columns.select.te'), isSortable: true, isChecked: true,
      },
      {
        key: 'createdAt', label: i18next.t('customers.columns.select.ca'), isSortable: true, isChecked: true,
      },
      {
        key: 'action', label: i18next.t('customers.columns.action'), isSortable: false, isChecked: true,
      },
    ];
  }

  let check = false;
  for (let i = 0; i < columnsVal.length; i++) {
    if (columnsVal[i].key === 'action') {
      check = true;
    }
  }

  if (!check) {
    columnsVal.push({
      key: 'action', label: i18next.t('customers.columns.action'), isSortable: false, isChecked: true,
    });
  }

  const [columns, setColumns] = useState<ColumnProps[]>(columnsVal);
  const [selectedColumnsCheck, setSelectedColumns] = useState<ColumnProps[]>(selectedColumns);
  const variables: TeamCustomerQueryInputProps = {
    teamID,
    searchTxt: state.searchKey,
    teamChannelIds,
    dateRange: dateRange?.value || '',
    sortArg: { key: sortKey, sortOrder: inputSortOrder },
    skip,
    take: PAGE_SIZE,
  };

  const {
    data, error, loading,
  } = useTeamCustomersQuery({ variables });
  if (error) {
    return <p>Something went wrong!</p>;
  }
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const teamCustomers: any[] = data?.TeamCustomers?.teamCustomers
  || [];

  const closeOrdeView = () => {
    setID('');
    setName('');
  };

  // const handleRefetch = async () => {
  //   // setIsRefetching(true);
  //   // await refetch()
  //   //   .finally(() => setIsRefetching(false));
  // };

  const total = data?.TeamCustomers?.total || 0;
  const teamChannels = data?.TeamCustomers?.teamChannels || [];
  const pages = Math.ceil(total / PAGE_SIZE);
  const totalPages = [];
  for (let i = 1; i <= pages; i += 1) {
    totalPages.push(i);
  }
  const onPageChange = (event: SyntheticEvent, newPage: number) => {
    handlePageChange(newPage);
  };
  const dateRangeChange = (option: any) => {
    handleDateRangeChange(option);
  };
  const teamChannelsChange = (options: any) => {
    handleTeamChannelIdsChange(options);
  };
  const sortChange = (sortData: { key: string, sortOrder: 'ASC' | 'DESC' }) => {
    handleSortArgChange(sortData);
  };
  const selectedColumnsChange = (colData: ColumnProps) => {
    const updatedColumns = columns.map((ele: ColumnProps) => (
      ele.key === colData.key ? colData : ele
    ));
    const updatedSelectedColumns = selectedColumnsCheck.map((ele: ColumnProps) => (
      ele.key === colData.key ? colData : ele
    ));
    setColumns(updatedColumns);
    setSelectedColumns(updatedSelectedColumns);
    window.localStorage.setItem('customerColumns', JSON.stringify(updatedColumns));
  };
  const initTeamChannelOptions = teamChannels.map((tc: any) => ({
    label: tc.name,
    value: tc.id,
  }));
  const headCells = columns.filter((col: ColumnProps) => (
    col.isChecked
  )).map((col: ColumnProps) => ({
    key: col.key,
    content: col.label,
    isSortable: col.isSortable,
  }));
  const head = { cells: headCells };
  const rows = teamCustomers.map(
    (tc: TeamCustomer, idx) => {
      const {
        id,
        teamChannel,
        totalExpense,
        createdAt,
        email,
        phone,
        name,
      } = tc;
      const { name: teamChannelName } = teamChannel;
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const cells: any = columns.filter((col: ColumnProps) => (
        col.isChecked
      )).map((col: ColumnProps) => {
        if (col.key === 'store') {
          return {
            key: 'store',
            content: teamChannelName,
          };
        }
        if (col.key === 'totalExpense') {
          return {
            key: 'totalExpense',
            content: formatMoneyWithPrefix(totalExpense),
          };
        }
        if (col.key === 'createdAt') {
          return {
            key: 'createdAt',
            content: formattedDate(createdAt),
          };
        }
        if (col.key === 'email') {
          return {
            key: 'email',
            content: email === '' ? '-' : email,
          };
        }
        if (col.key === 'phone') {
          return {
            key: 'phone',
            content: phone === '' ? '-' : phone,
          };
        }
        if (col.key === 'action') {
          return {
            key: 'action',
            content: (
              <DropdownMenu
                // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars, no-shadow
                trigger={() => (
                  <MoreSpan>
                    <MoreIcon label="more" />
                  </MoreSpan>
                )}

              >
                <DropdownItemGroup>
                  <DropdownItem>
                    <Link to={`/customers/${id}`}>View Detail</Link>
                  </DropdownItem>
                </DropdownItemGroup>
              </DropdownMenu>
            ),
          };
        }
        return {
          key: col.key,
          content: tc[col.key],
        };
      });

      return {
        key: `row-${idx}-team-customer`,
        cells,
        onClick: () => {
          setID(id);
          setName(name);
        },
      };
    },
  );

  const renderFilters = () => (
    <FilterContainer>
      <FilterItem>
        <Select
          className="multi-select"
          classNamePrefix="react-select"
          options={initTeamChannelOptions}
          isMulti
          isSearchable={false}
          placeholder={t('customers.filter.placeholder')}
          onChange={(option) => teamChannelsChange(option)}
          value={teamChannelOptions}
          rows={rows}
        />
      </FilterItem>
      <FilterItem>
        <Select
          options={
            [
              { label: t<string>('customers.filter.select.anyTime'), value: 'all' },
              { label: t<string>('customers.filter.select.otaw'), value: 'week' },
              { label: t<string>('customers.filter.select.otam'), value: 'month' },
              { label: t<string>('customers.filter.select.ot6m'), value: '6months' },
              { label: t<string>('customers.filter.select.otay'), value: 'year' },
            ]
          }
          isSearchable={false}
          defaultValue={{ label: t('customers.filter.select.anyTime'), value: 'all' }}
          onChange={(val) => dateRangeChange(val)}
          value={dateRange}
          autoFocus
        />
      </FilterItem>
    </FilterContainer>
  );

  return (
    <SplitView
      left={(
        <Wrapper style={{ backgroundColor: '#ffffff' }}>
          <div className="heading">
            <h2>{t('customers.customers')}</h2>
          </div>
          <div className="search-filter-wrapper">
            <>
              <div className="filters" />
              <div className="search-box">
                <GlobalSearch />
                <Button
                  appearance="link"
                  onClick={() => setHideFilter(!hideFilter)}
                >
                  {t('customers.advanced')}
                </Button>
              </div>
            </>
          </div>
          { !hideFilter && renderFilters() }
          <div className="table-controls">
            <div className="pagination" />
            <div className="customer-column-options">
              <DropdownMenuColumns
                columns={selectedColumnsCheck}
                selectedColumnsChange={selectedColumnsChange}
              />
            </div>
          </div>
          <TeamCustomerTableContainer>
            <DynamicTable
              head={head}
              rows={rows}
              loadingSpinnerSize="large"
              isLoading={loading}
              defaultPage={1}
              isFixedSize
              sortKey={sortKey}
              onSort={sortChange}
              sortOrder={sortTheOrder}
            />
          </TeamCustomerTableContainer>
          <TeamCustomerPagination>
            {rows.length > 0
          && (
          <Pagination
            pages={totalPages}
            onChange={onPageChange}
            selectedIndex={currentPage}
          />
          )}
          </TeamCustomerPagination>
        </Wrapper>
    )}
      right={
        customerID ? (
          <CustomerView
            customerId={customerID}
            customerName={customerName}
            teamId={teamID}
            closeOrdeView={closeOrdeView}
            // refetch={handleRefetch}
          />
        ) : null
    }
    />

  );
};

export default TeamCustomersListing;
