import React, { useEffect, useState } from 'react';
import { Controller, Control } from 'react-hook-form';
import Toggle from '@atlaskit/toggle';
import { useTranslation } from 'react-i18next';

type TextFieldInputProps = {
  name: string;
  control: Control<Record<string, string>>;
  defaultValue: boolean;
  setValue: Function;
  // eslint-disable-next-line react/no-unused-prop-types
  testId: string;
  isDisable: boolean;
};

const ProductIsForSaleSwitch = ({
  name,
  control,
  defaultValue,
  setValue,
  isDisable,
}: TextFieldInputProps) => {
  const [isChecked, setIsChecked] = useState(defaultValue);
  const { t } = useTranslation();
  useEffect(() => {
    setValue(name, isChecked);
  }, [isChecked, name, setValue]);
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={() => (
          <>
            <div className="isForSaleLabel">
              <h3>{t('products.product.edit.fs')}</h3>
            </div>
            <Toggle
              name="isForSale"
              isChecked={isChecked}
              size="regular"
              onChange={() => {
                setIsChecked((prev) => !prev);
                setValue(name, !isChecked);
              }}
              isDisabled={isDisable}
            />
          </>
        )}
      />
    </>
  );
};

export default ProductIsForSaleSwitch;
