import React from 'react';
import {
  isArray, findIndex, isUndefined, cloneDeep,
} from 'lodash';
import styled from 'styled-components';
import { ProductsQuery } from '../../../graphql/types';
import { COLUMNS_CONFIG } from '../config';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import { getChannelLogoWithChannelName } from './getHeadAndRow';
import { TeamChannelForTableHead } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TeamChannelProducts = Extract<ProductsQuery['Products']['products'], Array<any>>[number]['teamChannelProducts'];

export const ChannelPriceLabel = styled.div`
    white-space: nowrap;
    text-overflow: ellipsis;
    display:block;
    overflow:hidden;
`;

export default (
  productsQuery: ProductsQuery | undefined,
):typeof COLUMNS_CONFIG => {
  const teamChannels = productsQuery?.GetTeamChannelsByTeamId.teamChannels;
  const columns = cloneDeep(COLUMNS_CONFIG);

  if (isArray(teamChannels)) {
    let jumpToPosition = findIndex(COLUMNS_CONFIG, (column) => column.name.includes('Stock on hand'));
    // if can't locate what it should jump in the line
    if (jumpToPosition === -1) {
      jumpToPosition = COLUMNS_CONFIG.length;
    } else {
      jumpToPosition += 1;
    }

    // prepare configs for multiple pricing head
    const mutiplePricingHead:typeof COLUMNS_CONFIG = teamChannels.sort(
      (a, b) => {
      // to ensure each channel logo displayed in a propery order
      // so will will have an unified looking
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      },
    ).map((teamChannel:TeamChannelForTableHead) => ({
      name: getChannelLogoWithChannelName(teamChannel),
      label: getChannelLogoWithChannelName(teamChannel),
      defaultChecked: true,
      isSortable: false,
      dataSource: ['teamChannelProducts'],
      defaultValue: [],
      compactMode: false,
      extraIdentifier: teamChannel.id,
      transformData: (v:TeamChannelProducts) => {
        const [tcp] = v.filter((cur) => cur.teamChannel.id === teamChannel.id);
        if (isUndefined(tcp)) {
          return '';
        }
        return formatMoneyWithPrefix(tcp.unitPrice);
      },
      render: (v: string) => <>{v}</>,
      width: 1,
    }));

    columns.splice(jumpToPosition, 0, ...mutiplePricingHead);
  }

  return columns;
};
