import styled from 'styled-components';

const ReorderBtn = styled.div`
  color: #737373;
  position: absolute;
  padding: 5px;
  top: 0;
  left: 0;
  background: white;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  font-size: 1.5em;
`;

export default ReorderBtn;
