import { isNull } from 'lodash';
import { DS_PRODUCTS_COLUMNS } from '../config';

const isChecked = (key: string) => {
  const settings = window.localStorage.getItem(DS_PRODUCTS_COLUMNS);
  let checked: null | boolean = null;
  if (isNull(settings)) return null;
  try {
    JSON.parse(settings).forEach((cur: { key: string, isChecked: boolean }) => {
      if (cur.key === key) { checked = cur.isChecked; }
    });
  } catch (error) {
    // on error rest the localstorage
    window.localStorage.removeItem(DS_PRODUCTS_COLUMNS);
    return null;
  }
  return checked;
};

export default isChecked;
