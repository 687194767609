import React from 'react';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import { Order } from '../../OrdersListing/types';
import {
  useCancelOrderMutation,
} from '../../../graphql/types';
import addNotification from '../../../utils/addNotification';

interface Props {
  order: Order,
  refetchOrder: ()=>void,
}

export default ({ order, refetchOrder }:Props) => {
  const { t } = useTranslation();
  // const [magentoOrderMarkAsPaidMutation] = useMagentoOrderMarkAsPaidMutation();
  const [cancelOrderMutation] = useCancelOrderMutation();
  const cancelOrder = () => {
    cancelOrderMutation({
      variables: {
        cancelOrder: {
          orderId: order.id,
        },
      },
    })
      .then(() => {
        addNotification('Cancel order successfully', 'success');
        refetchOrder();
      })
      .catch(() => {
        addNotification('Cancel order failed', 'danger');
      });
  };
  const isCanCanceled = order.status.code === 'UNPAID' || order.status.code === 'PAID';
  return (
    <>
      <Button
        isDisabled={!isCanCanceled}
        onClick={cancelOrder}
      >
        {t('orderDetails.co')}
      </Button>
    </>
  );
};
