import React, { useState } from 'react';
import Textfield from '@atlaskit/textfield';
import { isInteger } from 'lodash';

interface Props {
  qty:number,
  onChange:(newQty:number)=>void,
  testid?:string,
  isDisabled?:boolean,
}

const QtyEditor = ({
  qty, onChange, testid, isDisabled = false,
}:Props) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    try {
      const input = event.currentTarget.value;

      if (Number(input) <= 0) {
        return;
      }
      if (!isInteger(Number(input))) {
        return;
      }

      onChange(Number(input));
    } catch (error) {
      // just highlight the input box
      // may need find a proper place to dispaly error msg
      setIsInvalid(true);
    }
  };

  return (
    <Textfield
      type="number"
      value={qty}
      onChange={handleOnChange}
      isInvalid={isInvalid}
      width={60}
      name="qty"
      testId={testid}
      isDisabled={isDisabled}
    />

  );
};

QtyEditor.defaultProps = {
  testid: undefined,
  isDisabled: false,
};

export default QtyEditor;
