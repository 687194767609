import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import printJS from 'print-js';
import axios from 'axios';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';

interface Props {
  url:string,
}

export default ({ url }:Props) => {
  const [loading, setLoading] = useState(false);

  const print = async (endpoint:string) => {
    setLoading(true);
    await axios
      .get(endpoint, {
        responseType: 'arraybuffer',
      })
      .then((response) => {
        const pdf = new Blob([response.data], {
          type: 'application/pdf',
        });
        const pdfUrl = URL.createObjectURL(pdf);
        printJS(pdfUrl);
      })
      .catch((err) => addNotification(graphQLErrorsReader(err), 'danger'))
      .finally(() => setLoading(false));
  };

  return (
    <Button
      appearance="subtle"
      onClick={() => print(url)}
      isLoading={loading}
      spacing="none"
    >
      Print
    </Button>
  );
};
