import { colors } from '@atlaskit/theme';
import styled from 'styled-components';

const Option = styled.li`
  box-sizing:border-box;
  border-bottom: 1px solid #ccc;
  margin: 0;
  padding:10px 5px 10px 5px;
  width:100%;
  cursor: pointer;
  &:hover {
    background-color:#e1e1e1;
  }
  span {
    font-weight:700;
  }
  div {
    color:${colors.subtleText}
  }
`;

export default Option;
