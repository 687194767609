import styled, { css } from 'styled-components';
import { elevation, themed } from '@atlaskit/theme';

interface BoxProps {
  readonly padding?: string;
}

export default styled.div<BoxProps>`
  ${elevation.e200}
  background-color: ${() => themed({ light: 'white', dark: '#283447' })};
  border-radius: 3px;
  margin-bottom: 2em;
  min-width: 240px;
  padding: 16px 24px;
  text-align: left;

  ${(props) => props.padding
    && css`
      padding: ${props.padding}
    `
};
`;
