import React from 'react';
import PreviewShipping from '../PreviewShipping';
import ShippingMethod from '../ShippingMethod';
import CancelButton from '../Buttons/CancelButton';
import { Buttons } from '../../Shipping.style';
import MarkAsShipped from '../MarkAsShipped';
import PickUpConfirmButton from '../Buttons/PickUpConfirmButton';

export default ({ onShipped }:{onShipped:()=>void}) => (
  <>
    <PreviewShipping />
    <ShippingMethod />
    <MarkAsShipped />
    <Buttons>
      <CancelButton />
      <PickUpConfirmButton onShipped={onShipped} />
    </Buttons>
  </>
);
