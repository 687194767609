import React, { useEffect } from 'react';
import Avatar from '@atlaskit/avatar';
import { v4 as uuidV4 } from 'uuid';
// import Button from '@atlaskit/button';

import Comment, {
  CommentAuthor,
  CommentTime,
} from '@atlaskit/comment';

import avatarImg from '../../assets/svg/avatar.png';
import formattedDate from '../../utils/formattedDate';
import {
  TicketCommentsListingContainer,
  // ScrollButton,
} from './TicketCommentsListing.styles';

interface ITicketCommentsListing {
  comments: TicketComment[] | undefined
}

interface Attachment {
  id: string,
  file_name: string,
  content_url: string,
}

interface TicketComment {
  id: string,
  author: string,
  htmlBody: string,
  created_at: string,
  attachments: Attachment[]
}

// eslint-disable-next-line no-redeclare
const TicketCommentsListing = (props: ITicketCommentsListing) => {
  const { comments } = props;
  // const [scrollHeight, setScrollHeight] = useState(0);
  useEffect(() => {
    // window.addEventListener('scroll', onScrollListener, true);
    window.scrollTo(0, document.body.scrollHeight + 600);
  }, []);
  // const onScrollListener = () => {
  //   setScrollHeight(window.pageYOffset);
  // }
  const renderTicketComments = () => {
    if (!comments) {
      return null;
    }
    return comments.map((comment: TicketComment) => {
      const body = comment.htmlBody;
      const array = body.split('\n');
      const contents = array.map((itm) => (
        <p key={uuidV4()}>
          {itm}
          <br />
        </p>
      ));
      return (
        <Comment
          key={comment.id}
          avatar={<Avatar src={avatarImg} size="medium" />}
          author={<CommentAuthor>{comment.author}</CommentAuthor>}
          time={<CommentTime>{formattedDate(comment.created_at)}</CommentTime>}
          type="author"
          content={(
            <div>
              {contents}
            </div>
        )}
        />
      );
    });
  };
  return (
    <>
      <TicketCommentsListingContainer>
        { renderTicketComments() }
      </TicketCommentsListingContainer>
    </>
  );
};

export default TicketCommentsListing;
