import React from 'react';
import TeamMembersListing from '../components/TeamMembersListing/TeamMembersListing';
import { useAuth } from '../utils/useAuth';

export default function Orders() {
  const { state: { currentTeamId: teamId } } = useAuth();
  return (
    <>
      <TeamMembersListing teamId={teamId!} />
    </>
  );
}
