import React from 'react';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../utils/useAuth';

interface WithOnboardedBoundaryProps {
  children:JSX.Element,
}

const WithOnboardedBoundary = (props: WithOnboardedBoundaryProps) => {
  const { children } = props;
  const { state: { teams } } = useAuth();
  const history = useHistory();
  let ebayTokenValid = false;
  let activeTeamChannelId = '';

  // loop to find if there any teamChannel has onbarded and active
  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    const { teamChannels } = team;
    for (let j = 0; j < teamChannels.length; j++) {
      const teamChannel = teamChannels[j];
      if (teamChannel.active && teamChannel.onBoarded) {
        ebayTokenValid = true;
        break;
      }
    }
    if (ebayTokenValid) {
      break;
    }
  }

  if (ebayTokenValid) {
    return children;
  }

  // need to find the first active teamChannel from state.
  for (let i = 0; i < teams.length; i++) {
    const team = teams[i];
    const { teamChannels } = team;
    for (let j = 0; j < teamChannels.length; j++) {
      const teamChannel = teamChannels[j];
      if (teamChannel.active) {
        activeTeamChannelId = teamChannel.id;
        break;
      }
    }
  }
  // to check if user has any active teamChannel
  // if none, show below
  if (!activeTeamChannelId) {
    return (
      <div>
        <p style={{ marginTop: 20, marginBottom: 20 }}>
          Please create a marketplace before you could create a product.
        </p>

        <Button
          onClick={() => history.push('/marketplace')}
          appearance="primary"
        >
          Marketplace
        </Button>

      </div>

    );
  }

  // if no onborded teamChannels,
  // show below
  return (
    <div>
      <p style={{ marginTop: 20, marginBottom: 20 }}>
        Please complete onboard settings before you could create a product!
      </p>

      <Button
        onClick={() => history.push(`/connect/ebay/${activeTeamChannelId}`)}
        appearance="primary"
      >
        Onboard Wizard
      </Button>

    </div>
  );
};

export default WithOnboardedBoundary;
