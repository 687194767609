import Button from '@atlaskit/button/new';
import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import addNotification from '../../../utils/addNotification';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { useUpdateOrderDiscountMutation } from '../../../graphql/types';

export default ({
  orderId,
  orderAmount,
  children,
  onFinish,
}: {
  orderId: string,
  orderAmount: number,
  children: React.ReactNode
  onFinish: () => void
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isDiscountEntered, setIsDiscountEntered] = React.useState(false);
  const [discountAmt, setDiscountAmt] = React.useState(0);
  const [
    updateOrderDiscount,
    updateOrderDiscountState,
  ] = useUpdateOrderDiscountMutation();

  const handleApplyDiscount = async () => {
    await updateOrderDiscount({
      variables: {
        discount: {
          orderId,
          discountAmt,
        },
      },
    })
      .then(() => {
        onFinish();
        setIsOpen(false);
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  };

  // const actions = [
  //   {
  //     text: 'Confirm',
  //     isDisabled: isDiscountEntered === false,
  //     onClick: handleApplyDiscount,
  //     isLoading: updateOrderDiscountState.loading,
  //   },
  //   {
  //     text: 'Cancel',
  //     onClick: () => setIsOpen(false),
  //   },
  // ];

  const discountAmountHandler = (evt: React.FormEvent<HTMLInputElement>) => {
    const { value } = evt.currentTarget;
    const floatVal = parseFloat(value) * 100;
    if (floatVal >= 0 && floatVal <= orderAmount) {
      setIsDiscountEntered(true);
      setDiscountAmt(floatVal);
    } else {
      setIsDiscountEntered(false);
      setDiscountAmt(0);
    }
  };

  return (
    <>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            width="medium"
            shouldCloseOnEscapePress={false}
            shouldCloseOnOverlayClick={false}
          >
            <ModalHeader>
              <ModalTitle>Please enter discount amount:</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                <Textfield
                  placeholder="Enter discount amount"
                  onChange={discountAmountHandler}
                />
              </p>
            </ModalBody>
            <ModalFooter>
              <Button isDisabled={isDiscountEntered === false} isLoading={updateOrderDiscountState.loading} onClick={handleApplyDiscount}>Confirm</Button>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
      <Button
        appearance="subtle"
        spacing="compact"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </Button>
    </>
  );
};
