import { upperCase } from 'lodash';
import { StateUser } from '../utils/types';

const getLoginUserFullName = (user: StateUser) => {
  const firstName = (user && user.firstName) || '';
  const lastName = (user && user.lastName) || '';
  return `${upperCase(firstName)} ${upperCase(lastName)}`;
};

export default getLoginUserFullName;
