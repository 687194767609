import React from 'react';
import { useAuth } from '../../../utils/useAuth';
import AuspostIntergration from '../../../components/AuspostIntergration/AuspostIntergration';

export default () => {
  const { state: { currentTeamId: teamId } } = useAuth();

  return (
    <AuspostIntergration teamId={teamId!} />
  );
};
