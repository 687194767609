import React from 'react';
import Button from '@atlaskit/button';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';

import {
  TicketsRecordContainer,
  TicketRecordTitle,
} from './TicketsContainer.styles';

interface TicketsStatisticsProps {
  cursorFrom: number,
  cursorTo: number,
  ticketsTotal: number,
  handleRefetch: () => void,
}

const TicketsStatistics = (
  props: TicketsStatisticsProps,
) => {
  const {
    cursorFrom,
    cursorTo,
    ticketsTotal,
    handleRefetch,
  } = props;
  return (
    <TicketsRecordContainer>
      <TicketRecordTitle>Records:</TicketRecordTitle>
      {ticketsTotal > 0
        && (
          <div style={{ marginRight: '5px' }}>
            {cursorFrom}
            -
            {cursorTo}
            {' '}
            of
            {' '}
            {ticketsTotal}
          </div>
        )}
      <Button
        appearance="subtle-link"
        iconBefore={<RefreshIcon label="refresh" size="small" />}
        spacing="none"
        onClick={handleRefetch}
      />
    </TicketsRecordContainer>
  );
};
export default TicketsStatistics;
