import React from 'react';

import ErrorIcon from '@atlaskit/icon/glyph/error';
import { Y500 } from '@atlaskit/theme/colors';

import Flag from '@atlaskit/flag';
import { store } from 'react-notifications-component';

const ErrorFlag = ({ title }:
{title: string}) => (
  <Flag
    appearance="error"
    icon={<ErrorIcon label="Error" secondaryColor={Y500} />}
    id="error"
    key="error"
    title="Update Product Fail"
    description={title}
    actions={[
      {
        content: 'Close',
        onClick: () => {
          store.removeNotification('error');
        },
      },
    ]}
  />
);

export default ErrorFlag;
