/* eslint-disable max-len */
import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import { Redirect } from 'react-router-dom';
import { useAuth } from '../../../utils/useAuth';
import { getTeamChannelLists } from '../../../utils/reducer';

interface Props {
  teamChannelId:string,
}

export default ({ teamChannelId }:Props) => {
  const { state: { teams, currentTeamId } } = useAuth();

  const teamChannels = getTeamChannelLists(currentTeamId!, teams);
  const [teamChannel] = teamChannels.filter((cur) => cur.id === teamChannelId);

  if (teamChannel && teamChannel.channelName === 'eBay') {
    const { name, channelName } = teamChannel;

    return (
      <SectionMessage
        title="Lost connection with eBay"
        appearance="warning"
      >
        <p>
          MERP detected an error when connecting to
          {' '}
          <strong>{channelName}</strong>
          {' '}
          for your
          {' '}
          <strong>
            store(
            {name}
            )
          </strong>
          {' '}
          on MERP.
        </p>
        <p>This is usually caused by these reasons:</p>
        <ol>
          <li>eBay authorization to MERP has expired.</li>
          <li>Your store has been temporarily suspended by eBay due to various reasons.</li>
        </ol>
        <p>To resolve the issue:</p>
        <ol>
          <li>
            Sign in your
            {' '}
            <a href="https://ebay.com.au">eBay</a>
            {' '}
            store and make sure it has not been suspended. If it does, please contact eBay to re-active your store first.
          </li>
          <li>
            Click
            <a data-testid="merp-connection-link" href={`/connect/ebay/${teamChannelId}?redirectPath=/marketplace/${teamChannelId}`}> here </a>
            to update MERP connection.
            {' '}
          </li>
        </ol>

      </SectionMessage>
    );
  }

  return (
    <Redirect to={`/marketplace/${teamChannelId}`} />
  );
};
