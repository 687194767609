import styled from 'styled-components';

const Wrapper = styled.div`
  width:100%;
  color: rgb(23, 43, 77);
  font-size:0.875rem;
  line-height: 1.25rem;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .heading-btns button{
    margin-left:20px;
  }
  h2{
    margin-top:1rem;
    margin-bottom:1rem;
  }
  .search-filter-wrapper {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-box {
    margin-right: -12px; /* offset button padding */
    display: flex;
    align-items: center;
    justify-content:space-between;
  }
  .table-controls {
    display: flex;
    margin-top: 12px;
    justify-content:space-between;
  }
  .count{
      display:flex;
      font-weight: normal;
      span {
          font-weight: 600;
      }
      .summary{
        margin-right:10px;
        font-weight:normal;
      }
      
   }
  .count>*{
      margin-right:5px;
   }
  .column-options {
    margin-right: -16px; /* offset button padding */
    display: flex;
    justify-content: space-between;
  }
  .flex {
    display:flex;
    align-items:center;
    height:2rem;
  }
  .advanced-filter {
    margin:1rem 0rem 1rem 0rem;
  }
  table {
    margin-top: 12px;
    tr {
      &:hover{
        cursor: pointer;
      }
    }
    td {
      align-items:center;
      line-height: 1;
      overflow-wrap: anywhere;
    }
  }
  .pinShipByDate{
    display:flex;
    align-items:center;
    font-weight:500;
  }
  .productSearch {
    /* display: flex;
    flex-direction: row; */
    max-width: 650px;
    margin: 10px auto 30px;
    z-index: 1;
  }
  .synnexSearchBtn {
    text-align: center;
    width: 650px;
    margin: 30px auto;
  }
`;

export default Wrapper;
