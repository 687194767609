import React, { useState } from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import _ from 'lodash';
import styled from 'styled-components';
import Button from '@atlaskit/button/new';
import TextField from '@atlaskit/textfield';
import Form, { Field, FormFooter } from '@atlaskit/form';
import { useAuth } from '../utils/useAuth';
import { Box, Logo } from '../components';
import SignUpSuccessMsg from '../components/SignUpSuccessMsg/SignUpSuccessMsg';
import FriendlyErrorMessage from '../components/Signin/FriendlyErrorMessage';

const Wrapper = styled.div`
  align-items: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
`;

interface SignUpForm {
  firstName: string;
  lastName: string;
  email: string;
  teamName: string;
}

export default function Login() {
  const [serverError, setServerError] = useState('');
  const { signUp } = useAuth();
  const [email, setEmail] = useState('');
  const [isSignedUp, setIsSignedUp] = useState(false);

  const handleSignUp = (data: SignUpForm) => {
    signUp({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      teamName: data.teamName,
    })
      .then((res) => {
        setIsSignedUp(true);
        setEmail(_.get(res, 'email', ''));
      })
      .catch((errors: ApolloError) => {
        const errMsg = errors.graphQLErrors.map((e) => (e.message)).toString();
        setEmail(data.email);
        setServerError(errMsg);
      });
  };

  const renderForm = () => (
    <Form<SignUpForm> onSubmit={handleSignUp}>
      {({ formProps, dirty, submitting }) => (
        <form {...formProps}>
          <Field name="firstName" label="First name" defaultValue="" isRequired>
            {({ fieldProps }) => (
              <TextField testId="firstName" {...fieldProps} />
            )}
          </Field>
          <Field name="lastName" label="Last name" defaultValue="" isRequired>
            {({ fieldProps }) => (
              <TextField testId="lastName" {...fieldProps} />
            )}
          </Field>
          <Field name="email" label="Email" defaultValue="" isRequired>
            {({ fieldProps }) => (
              <TextField testId="email" type="email" {...fieldProps} />
            )}
          </Field>
          <Field name="teamName" label="Company Name" defaultValue="" isRequired>
            {({ fieldProps }) => (
              <TextField testId="teamName" {...fieldProps} />
            )}
          </Field>
          { serverError && FriendlyErrorMessage(serverError, email) }
          <FormFooter>
            <Button
              type="submit"
              appearance="primary"
              isDisabled={!dirty || submitting}
              isLoading={submitting}
              shouldFitContainer
            >
              Sign up
            </Button>
          </FormFooter>
        </form>
      )}
    </Form>
  );

  return (
    <Wrapper>
      <Logo />
      <Box style={{ padding: '30px 40px 40px 40px', width: isSignedUp ? '600px' : '240px' }}>
        {
          isSignedUp
            ? <SignUpSuccessMsg email={email} />
            : renderForm()
        }
      </Box>
    </Wrapper>
  );
}
