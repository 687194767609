import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import { G300 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import { useHistory } from 'react-router-dom';
import {
  ActivateInvitedUserContainer,
  FormHeader,
  Label,
  ButtonContainer,
  ErrorMsg,
  MessageWrapper,
} from './ActivateInvitedUser.styles';
import { useActivateInvitedUserMutation, User } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';

interface InputProps {
  userId: string,
  token: string,
}
interface FormValuesProps {
  firstName: string,
  lastName: string,
  password: string,
  confirmPassword: string,
}

export default function ActivateInvitedUser(props: InputProps) {
  const {
    control, handleSubmit, errors, setError,
  } = useForm();
  const { dispatch } = useAuth();
  const {
    userId,
    token,
  } = props;
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [activateInvitedUser] = useActivateInvitedUserMutation();
  const [errorMessage, setErrorMsg] = useState<string>();
  const history = useHistory();
  const onSubmit = ((values: FormValuesProps) => {
    const {
      firstName,
      lastName,
      password,
      confirmPassword,
    } = values;
    if (password !== confirmPassword) {
      setError('confirmPassword', {
        type: 'manual',
        message: 'confirm password not match password',
      });
      return;
    }
    setSpinner(true);
    activateInvitedUser({
      variables: {
        userId,
        token,
        firstName,
        lastName,
        password,
      },
    })
      .then(({ data }) => {
        setSpinner(false);
        setShowMessage(true);
        /** sign the user in */
        if (data && data.ActivateInvitedUser && data.ActivateInvitedUser.user) {
          const invitedUser = data.ActivateInvitedUser.user;
          dispatch({
            type: 'login',
            loginPayload: invitedUser as User,
          });
        }
        // setShowMessage(false);
        history.push('/users');
      })
      .catch((err) => {
        const msg = err.graphQLErrors[0]?.message || 'server internal error';
        setErrorMsg(msg);
        setSpinner(false);
      });
  });

  return (
    <ActivateInvitedUserContainer>
      <FormHeader>Invited User Activation</FormHeader>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Label>*First Name</Label>
        <Controller
          as={Textfield}
          name="firstName"
          placeholder="please input your firstName"
          control={control}
          rules={{
            required: true,
          }}
          testId="activation-first-name"
        />
        {errors.firstName && <ErrorMsg>{errors.firstName.message || 'FirstName required'}</ErrorMsg>}
        <Label>*Last Name</Label>
        <Controller
          as={Textfield}
          name="lastName"
          placeholder="please input your lastName"
          control={control}
          rules={{
            required: true,
          }}
          testId="activation-last-name"
        />
        {errors.lastName && <ErrorMsg>{errors.lastName.message || 'LastName required'}</ErrorMsg>}
        <Label>*Password</Label>
        <Controller
          as={Textfield}
          type="password"
          name="password"
          placeholder="please input your password, min length is 6"
          control={control}
          rules={{
            required: true,
            minLength: 6,
          }}
          testId="activation-password"
        />
        {errors.password && <ErrorMsg>{errors.password.message || 'password min length 6'}</ErrorMsg>}
        <Label>*Confirm Password</Label>
        <Controller
          as={Textfield}
          type="password"
          name="confirmPassword"
          placeholder="please confirm your password"
          control={control}
          rules={{
            required: true,
            minLength: 6,
          }}
          testId="activation-confirm-password"
        />
        {errors.confirmPassword && <ErrorMsg>{errors.confirmPassword.message || 'confirm password not match passord'}</ErrorMsg>}
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
        <ButtonContainer>
          <Button
            type="submit"
            appearance="primary"
            iconAfter={
            showSpinner ? <Spinner appearance="invert" /> : <></>
          }
            testId="activation-submit-btn"
          >
            Submit
          </Button>
        </ButtonContainer>
      </form>
      {showMessage
        && (
          <MessageWrapper>
            <Flag
              icon={<SuccessIcon primaryColor={G300} label="Info" />}
              description="You have been activated successfully"
              id="1"
              key="1"
              title="User Invitation"
              testId="successMsg"
            />
          </MessageWrapper>
        )}
    </ActivateInvitedUserContainer>
  );
}
