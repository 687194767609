import React, { useReducer, useContext, createContext } from 'react';
import TeamCustomersListing from './TeamCustomersListing';

interface InputProps {
  teamID: string,
}

interface CustomerState {
  searchKey: string,
}

interface CustomerAction {
  type: 'setKeyword' | null,
  payload: string,
}

interface CustomerContextType {
  state: CustomerState,
  dispatch: React.Dispatch<CustomerAction>,
}

const CustomerContext = createContext<CustomerContextType>(
  {} as CustomerContextType,
);

const TeamCustomersContainer = (props: InputProps) => {
  const { teamID } = props;
  const initialState: CustomerState = {
    searchKey: '',
  };

  const reducer = (
    state: CustomerState,
    action:CustomerAction,
  ) => {
    switch (action.type) {
      case 'setKeyword':
        return {
          ...state,
          searchKey: action.payload,
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <CustomerContext.Provider value={{ state, dispatch }}>
      <TeamCustomersListing teamID={teamID} />
    </CustomerContext.Provider>
  );
};

export default TeamCustomersContainer;

export const useCustomer = () => useContext(CustomerContext);
