import React from 'react';
import DynamicTable from '@atlaskit/dynamic-table';
import { formatNumber } from 'accounting';
import { useShipping } from '../Shipping';
import { PreviewShipmentContainer } from '../Shipping.style';
// import { LineItemsConfig } from '../types';
import { getHead } from '../helper';
import renderItemTitle from './Widgets/renderItemTitle';

const PreviewShipment = () => {
  const {
    state,
    // dispatch
  } = useShipping();
  const { order } = state;
  // const handleLineItemsConfigChange = (
  //   value: LineItemsConfig,
  //   index:number,
  // ) => {
  //   const configs = [
  //     ...state.lineItemsConfig,
  //   ];
  //   configs[index] = value;
  //   dispatch({ type: 'setLineItemsConfig', payload: configs });
  // };

  const head = getHead(
    // order.shippingServiceCode, state.shippingMethod,
  );

  const rows = order.orderItems.map((cur) => {
    const cells = [
      { content: renderItemTitle(cur.name, cur.sku) },
      { content: formatNumber(cur.qty) },
    ];
    return ({ cells });
  });

  return (
    <PreviewShipmentContainer>
      <div className="section lineItems">
        <DynamicTable
          head={head}
          rows={rows}
        />
      </div>
    </PreviewShipmentContainer>
  );
};

export default PreviewShipment;
