import React from 'react';
import * as QueryString from 'query-string';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import CourierIntegrationList from '../components/CourierIntegrationList/CourierIntegrationList';
import Wrapper from '../components/ListView/Wrapper';
import TeamChannelListing from '../components/TeamChannelListing/TeamChannelListing';
import { Container } from '../components/TeamChannelListing/TeamChannelListing.style';
import AppInquiry from './AppInquiry';
import ZendeskConnector from '../components/ZendeskConnector/ZendeskConnector';
import SupplierListing from '../components/SupplierListing/SupplierListing';
import XeroCard from '../components/Xero/XeroCard';
import { getAccessToken } from '../utils/storages';
import { useAuth } from '../utils/useAuth';

export default (props: { location: { search: string } }) => {
  const { t } = useTranslation();
  const { location } = props;
  const { search } = location;
  const { state: { currentTeamId: teamID } } = useAuth();
  const params = QueryString.parse(search);
  if (params && params.pendingId) { // should deal this for another
    // console.log(params);
    const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
    || 'http://localhost:4000';
    axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/shopify/installTeamChannel?pendingId=${params.pendingId}&teamId=${teamID}`,
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
        },
      },
    );
  }
  return (
    <Wrapper data-testid="marketplacePage">
      <div>
        <TeamChannelListing isConnect={params?.shopify_connect} />
      </div>
      <div>
        <div className="heading">
          <h2>{t('channels.accounting')}</h2>
        </div>
        <Container>
          <XeroCard isXeroConnect={params?.xero_connect} />
        </Container>
      </div>
      <div>
        <div className="heading">
          <h2>{t('channels.cs')}</h2>
        </div>
        <Container>
          <ZendeskConnector />
        </Container>
      </div>
      <div>
        <div className="heading">
          <h2>{t('channels.ci')}</h2>
        </div>
        <Container>
          <CourierIntegrationList />
          <AppInquiry />
        </Container>
      </div>
      <div>
        <SupplierListing />
      </div>
    </Wrapper>
  );
};
