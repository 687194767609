import styled from 'styled-components';

const ProductDetailsContainer = styled.div`
  .row{}
  .heading {
    display:flex;
    justify-items:center;
    img {
    width:120px;
    display:inline-block
    }
  }
  .heading div{
    h2 {
      font-size:1em;
    }
  }
  .product-info {
    p {
      font-size:0.825em;
    }
  }
  .product-identifiers {
    dl{
        display:table-row;
        margin:0;
        padding:2px 0px 2px 0px;
        dt,dd{
          display:table-cell;
          vertical-align:middle;
        }
        dd {
          padding-left:5px;
        }
      }
  }
`;

export default ProductDetailsContainer;
