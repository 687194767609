import styled from 'styled-components';

export const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  a {
    font-size: 16px;
    margin-top: -10px;
    margin-left: 20px;
    color: rgba(0, 0, 0, 0.45);
  }
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;

export const ReportContainer = styled.div`
  margin-top: 10px;
`;
