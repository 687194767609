import React from 'react';
import Purchase from '../components/PurchaseDataEntry';

export default () => (
  <div style={{
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }}
  >
    <div>
      <Purchase />
    </div>

  </div>
);
