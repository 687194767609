import gql from 'graphql-tag';

const auspostGetQuoteQuery = gql`
query AuspostGetQuote(
  $itemsInput: ItemsInput!,
) {
  AuspostGetQuote(
    itemsInput:$itemsInput,
  ) {
    status,
    response,
  }
}
`;

export default auspostGetQuoteQuery;
