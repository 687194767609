import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import HistoryLogsViewer from './HistoryLogs/HistoryLogsViewer';
import CommentsLogsViewer from './CommentsLogs/CommentsLogsViewer';
import { LogsQuery } from '../../graphql/types';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Logs = Extract<LogsQuery['Logs']['logs'], Array<any>>;
export type Log = Logs[number];

const LogSwitcher = styled.div`
  display:flex;
  align-items:center;
  margin-bottom:1em;
  >span {
    margin-right:10px;
    font-weight:500;
  }
  >button {
    margin-right:10px;
  }
`;

const Title = styled.div`
  margin-bottom:1em;
`;

interface Props {
  teamId:string,
  tableId:string,
  title:ReactNode,
}

const MerpLogs = (
  { teamId, tableId, title }:Props,
) => {
  const { t } = useTranslation();
  const [activeElement, setActiveElement] = useState<'comments'|'history'>('comments');

  return (
    <>
      <Title>{title}</Title>
      <LogSwitcher>
        <span>{t('orderDetails.activity.show')}</span>
        <Button
          appearance="default"
          isSelected={activeElement === 'comments'}
          onClick={() => setActiveElement('comments')}
          spacing="compact"
        >
          {t('orderDetails.activity.comments')}
        </Button>
        <Button
          appearance="default"
          isSelected={activeElement === 'history'}
          onClick={() => setActiveElement('history')}
          spacing="compact"
        >
          {t('orderDetails.activity.history')}
        </Button>
      </LogSwitcher>
      {activeElement === 'history' && <HistoryLogsViewer teamId={teamId} tableId={tableId} take={10} />}
      {activeElement === 'comments' && <CommentsLogsViewer teamId={teamId} tableId={tableId} take={10} />}

    </>
  );
};

export default MerpLogs;
