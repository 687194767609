const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

const formattedDate = (datetime: string) => {
  const dateTime = new Date(datetime);
  return `${dateTime.getDate()} ${monthNames[dateTime.getMonth()]}, ${dateTime.getFullYear()}`;
};

export default formattedDate;
