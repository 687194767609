import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Button from '@atlaskit/button';
import { useHistory } from 'react-router-dom';
import NotificationProps from './types';
import firebase from '../../utils/firebase';
import calculateDateDifference from '../../utils/calculateDateDifference';

const env = process.env.REACT_APP_NODE_ENV || 'development';

const Line = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
`;
const DateContainer = styled.div`
  padding: 0px 18px;
`;

interface InputProps {
  data: NotificationProps,
}

export default (props: InputProps) => {
  const { data } = props;
  const {
    docId,
    id,
    teamId,
    objectId,
    teamChannelID,
    type,
    subType,
    status,
    createdAt,
    updatedAt,
  } = data;
  const local = moment.utc(updatedAt).toDate().toISOString();
  const sincedTitle = `since ${calculateDateDifference(local)}`;
  const markAsRead = async () => {
    await firebase.firestore().collection(`notifications_${env}`)
      .doc(docId).set({
        id,
        objectId,
        type,
        subType,
        teamId,
        teamChannelID,
        status: 'READ',
        createdAt,
        updatedAt: moment().toISOString(),
      });
  };
  const history = useHistory();

  const notificationMappings = {
    teamChannel: {
      'ebay-refresh-token-failed': {
        value: 'eBay Conenction Error',
        link: `/notification/connection/error/${teamChannelID}`,
      },
    },
    shipmentOrder: {
      new: {
        value: `${subType} ${type}: ${objectId}`,
        link: `/order/${id}`,
      },
    },
    order: {
      new: {
        value: `${subType} ${type}: ${objectId}`,
        link: `/order/${id}`,
      },
      update: {
        value: `${subType} ${type}: ${objectId}`,
        link: `/order/${id}`,
      },
    },
  };

  let msg:string;
  let link:string;
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    msg = (notificationMappings as any)[type!][subType!].value;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    link = (notificationMappings as any)[type!][subType!].link;
  } catch (error) {
    msg = `${subType} ${type}: ${objectId}`;
    link = '/';
  }

  return (
    <div>
      <Line>
        <Button
          appearance="link"
          onClick={() => {
            markAsRead();
            history.push(link);
          }}
          testId="link-btn"
        >
          {msg}
        </Button>
        { status === 'UNREAD'
        && (
          <Button appearance="link" onClick={() => markAsRead()}>
            Mark as read
          </Button>
        )}
      </Line>
      <DateContainer>
        <p>{sincedTitle}</p>
      </DateContainer>
    </div>
  );
};
