import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import AnimatedNumber from 'animated-number-react';

import FlexSpaceBetweenContainer from '../ProductEdit/styles/FlexSpaceBetweenContainer';

const getBackgroundColor = (theme: string) => {
  switch (theme) {
    case 'darkblue':
      return '#1f1498!important;';
    case 'lightblue':
      return '#2982cc!important;';
    case 'orange':
      return '#f6960b!important;';
    case 'red':
      return '#d93737!important;';
    default:
      return '#1f1498!important;';
  }
};

const getBackgroundGradient = (theme: string) => {
  switch (theme) {
    case 'darkblue':
      return 'linear-gradient(45deg,#321fdb,#1f1498)!important;';
    case 'lightblue':
      return 'linear-gradient(45deg,#39f,#2982cc)!important;';
    case 'orange':
      return 'linear-gradient(45deg,#f9b115,#f6960b)!important;';
    case 'red':
      return 'linear-gradient(45deg,#e55353,#d93737)!important;';
    default:
      return 'linear-gradient(45deg,#321fdb,#1f1498)!important;';
  }
};

const Card = styled.div`
  border: 0;
  flex-grow: 1;
  box-shadow: 0 1px 1px 0 rgba(60,75,100,.14), 0 2px 1px -1px rgba(60,75,100,.12), 0 1px 3px 0 rgba(60,75,100,.2);

  color: #fff!important;

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-right: 1rem;
  border-radius: .25rem;

  background: ${(props) => props.theme.bgColor}
  background: ${(props) => props.theme.bgGradient}
  border-color: ${(props) => props.theme.bgColor}
`;

const CardBody = styled.div`
  padding: 1rem 1.25rem;
`;

const LargeText = styled.span`
  font-size: 1.3125rem;
  font-weight: 600;
`;

/*
const formatFloat = (val: number) => `${Number(val).toFixed(2)}`;
const formatInteger = (val: number) => `${Number(val).toFixed(0)}`;
*/

const SummaryElement = ({
  title, prefix, value, color = 'darkblue', icon,
}:
{title: string, prefix: string, value: number,
  color: string, icon: JSX.Element}) => (
    <Card theme={{
      bgColor: getBackgroundColor(color),
      bgGradient: getBackgroundGradient(color),
    }}
    >
      <CardBody>
        <FlexSpaceBetweenContainer>
          <div>
            <LargeText>
              {prefix}
              <AnimatedNumber
                value={value}
                duration={500}
                formatValue={(n :number) => new Intl.NumberFormat('en-US').format(n)}
              />
            </LargeText>
            <div>{title}</div>
          </div>
          <div>{icon}</div>
        </FlexSpaceBetweenContainer>
      </CardBody>
    </Card>
);

export default SummaryElement;
