import styled from 'styled-components';

const CenteredContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export default CenteredContainer;
