import React from 'react';
import SectionMessage from '@atlaskit/section-message';
import { FormHeader, FormFooter } from '@atlaskit/form';
import { isString } from 'lodash';
import Table from '@atlaskit/dynamic-table';
import { Link } from 'react-router-dom';
import { ButtonGroup } from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import auspostLogo from '../../assets/svg/auspost.svg';
import { Account } from './AuspostIntergration.types';

export const renderFormHeader = () => (
  <FormHeader title="Auspost Integration" description={<img src={auspostLogo} alt="auspost" />} />
);

export const renderError = (serverError:unknown) => (
  <SectionMessage
    title="It appears to be something wrong."
    appearance="error"
  >
    <p>{isString(serverError) ? serverError : ''}</p>
  </SectionMessage>
);

export const renderTestSuccessMsg = (account:Account) => (
  <SectionMessage
    title="Auspost intergration test passed and configurations saved"
    appearance="success"
  >
    {/* eslint-disable-next-line max-len */}
    <p>Congratuations! A test connection with Auspost was successful using the credentials provided.</p>
    <p> Below is a summary of your account info from Auspost:</p>
    <Table
      head={{
        cells: [
          { content: '', width: 2 },
          { content: '', width: 8 },
        ],
      }}
      rows={[
        { cells: [{ content: 'Account Number' }, { content: account.account_number }] },
        { cells: [{ content: 'Name' }, { content: account.name }] },
        { cells: [{ content: 'Valid from' }, { content: account.valid_from }] },
        { cells: [{ content: 'Valid to' }, { content: account.valid_to }] },
        { cells: [{ content: 'Credit blocked' }, { content: account.credit_blocked ? 'True' : 'False' }] },
      ]}
    />

    <p>
      If you have
      {' '}
      <strong>enabled</strong>
      {' '}
      {/* eslint-disable-next-line max-len */}
      Auspost, you can now enjoy the convenience of automatically shipment creation with eBay.
      {' '}
    </p>
    <p>
      If you would like to
      {' '}
      <strong>disable</strong>
      {' '}
      {/* eslint-disable-next-line max-len */}
      Auspost integration at any time, simply just turn it off by toggling below enable/disable option.
    </p>

    <p>
      Click
      {' '}
      <Link to="/orders">here</Link>
      {' '}
      to back to order page
    </p>

  </SectionMessage>
);
export const renderFormFooter = (submitting: boolean) => (
  <FormFooter>
    <ButtonGroup>
      <Button
        type="submit"
        appearance="primary"
        isLoading={submitting}
      >
        Save configuration
      </Button>
    </ButtonGroup>
  </FormFooter>
);
