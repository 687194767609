import styled from 'styled-components';

export const TeamCustomerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TeamCustomerDetailContainer = styled.div`
  margin-top: 18px;
  margin-left: 36px;
  display: flex;
  flex-direction: column;
`;

export const TeamCustomerBasicInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Header = styled.h1`
  font-size: 2em;
  font-style: inherit;
  line-height: 1.16667;
  color: rgb(23, 43, 77);
  font-weight: 500;
`;

export const SubTitle = styled.h3`
  margin-top: 40px;
  font-size: 1.2em;
  font-style: inherit;
  line-height: 1.16667;
  color: rgb(23, 43, 77);
  font-weight: 500;
`;

export const TeamCustomerInfoCol = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 60px;
`;

export const TeamCustomerInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 80px;
`;

export const Label = styled.label`
  font-size: 0.85em;
  color: #6B778C;
  font-weight: 600;
  line-height: 2em;
`;

export const TeamCustomerInfoInput = styled.div`
  margin-left: -8px;
  margin-top: -12px;
`;

export const TeamCustomerAddressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 40px;
`;

export const TeamCustomerShippingAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 60px;
`;
export const TeamCustomerShippingAddressDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const TeamCustomerBillingAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const TeamCustomerBillingAddressDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const MessageWrapper = styled.div`
  position: fixed;
  left: 40px;
  bottom: 40px;
`;

export const SinceTitleContainer = styled.div`
  margin-top: 10px;
  color: #6B778C;
`;
