import gql from 'graphql-tag';

const orderItemFragment = gql`
fragment orderItemFragment on OrderItem {
   id,
    productID,
    teamChannelProductID,
    sku,
    name,
    description,
    unitPrice,
    unitPriceExclGST,
    qty,
    discountAmount,
    totalPrice,
    taxAmt,
    taxRate,
    createdAt,
    updatedAt,
    totalAmtInclTax,
    shipByDate,
    productTaxClassId
}
`;

export default orderItemFragment;
