/* eslint-disable prefer-const */
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  TeamCustomerInfoContainer,
  OrderListingContainer,
  CommentsContainer,
} from './TeamCustomerDetail.styles';
import TeamCustomerDetail from '../../components/TeamCustomerDetail/TeamCustomerDetail';
import OrdersByCustomerListing from '../../components/OrdersByCustomerListing/OrdersByCustomerListing';
import { useAuth } from '../../utils/useAuth';
import Comments from '../../components/TeamCustomerComments/teamCustomerComments';

export default function TeamCustomerDetailPage() {
  let { customerId, customerName } = useParams<{ customerId: string, customerName: string }>();
  if (!customerName) {
    customerName = customerId;
  }
  const { state: { currentTeamId } } = useAuth();
  const teamId = currentTeamId!;
  return (
    <TeamCustomerInfoContainer>
      <TeamCustomerDetail customerId={customerId} teamId={teamId} customerName={customerName} />
      <OrderListingContainer>
        <OrdersByCustomerListing customerId={customerId} teamId={teamId} />
      </OrderListingContainer>
      <CommentsContainer>
        <Comments customerId={customerId} teamId={teamId} />
      </CommentsContainer>
    </TeamCustomerInfoContainer>
  );
}
