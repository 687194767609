import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import { ErrorMessage, HelperMessage, ValidMessage } from '@atlaskit/form';
import _, { isUndefined } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  useQueryEbayOpenOrdersQtyQuery,
  usePullEbayOpenOrdersMutation,
  useQueryEbayListingsQtyQuery,
  useImportEbayListingsMutation,
  useUpdateTeamChannelMutation,
} from '../../graphql/types';
import ebay from '../../assets/svg/ebay.svg';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { EbayImporterContainier, SubHeadingContainer, ProgressBarContainer } from './EbayImporter.style';
import { useAuth } from '../../utils/useAuth';

interface EbayImporterProps {
  teamChannelID: string;
  onComplete:()=>void;
}

const EbayListingsImporter = (props: EbayImporterProps) => {
  const { teamChannelID, onComplete } = props;
  const listingsQty = useQueryEbayListingsQtyQuery({
    variables: {
      teamChannelID,
    },
  });

  const [importListings, importListingsState] = useImportEbayListingsMutation();
  const [importListingsProgress, setImportListingsProgress] = useState(0);

  const handleListingsImport = async () => {
    importListings({
      variables: {
        teamChannelID,
      },
    })
      .then(() => {
        setImportListingsProgress(1);
        onComplete();
      })
      .catch(() => {
        // importListingsState will handle for error
        // leave catch here because to avoid uncaught promise reject error.
      });
  };

  return (
    <>
      <div className="row">
        <div className="action" style={{ width: '300px' }}>
          <Button
            appearance="primary"
            testId="importListings"
            isDisabled={
                importListingsState.loading
                || listingsQty.loading
                || listingsQty.data?.QueryEbayListingsQty.numberOfListings === 0
                || listingsQty.error !== undefined
                || !isUndefined(importListingsState.data)
              }
            onClick={handleListingsImport}
          >
            Import Listings
          </Button>
        </div>
        <div className="progress">
          <SubHeadingContainer>
            <div className="name" data-testid="listingImportBarHeading">Listings</div>
            <div className="msg" data-testid="listingImportBarMsg">
              <HelperMessage>
                {listingsQty.loading && 'Connecting to ebay...'}
                {!listingsQty.loading
                  && !listingsQty.error
                  && _.get(listingsQty, ['data', 'QueryEbayListingsQty', 'numberOfListings'], 0) > 0
                  && isUndefined(importListingsState.data)
                  && !importListingsState.loading
                  && `0 / ${_.get(listingsQty, ['data', 'QueryEbayListingsQty', 'numberOfListings'], 0)}`}
                {listingsQty.data?.QueryEbayListingsQty.numberOfListings === 0
                  && 'You have 0 listings to import.'}
                {importListingsState.loading && 'Importing products...'}
              </HelperMessage>
              {importListingsState.data
                && (
                  <ValidMessage>
                    {importListingsState.data
                      .ImportEbayListings.numberOfProductsCreated}
                    {' '}
                    Products Imported
                  </ValidMessage>
                )}
            </div>
          </SubHeadingContainer>
          <div style={ProgressBarContainer}>
            <SuccessProgressBar
              isIndeterminate={importListingsState.loading}
              value={importListingsProgress}
            />
          </div>
        </div>
      </div>
      <div style={{ width: 'fit-content', margin: 'auto' }}>
        {listingsQty.error && (
          <ErrorMessage>
            {graphQLErrorsReader(listingsQty.error)}
          </ErrorMessage>
        )}
        {importListingsState.error && (
          <ErrorMessage>
            {graphQLErrorsReader(importListingsState.error)}
          </ErrorMessage>
        )}
      </div>
    </>
  );
};

const EbayOrderImporter = (props: EbayImporterProps) => {
  const { teamChannelID, onComplete } = props;
  const orderQty = useQueryEbayOpenOrdersQtyQuery({
    variables: {
      teamChannelID,
    },
  });
  const [importOrders, importOrdersState] = usePullEbayOpenOrdersMutation();
  const [importOrderProgress, setImportOrderProgress] = useState(0);
  /**
   * forceDisableButton is temp solution
   * when catch http 503 timeout error.
   * we force the button disabled to avoid been click again
   * which will triger race conditon, and result in duplicate
   * orders.
   */
  const [forceDisableButton, setForceDisableButton] = useState(false);

  const handleOrderImport = async () => {
    importOrders({
      variables: {
        teamChannelID,
      },
    })
      .then(() => {
        setImportOrderProgress(1);
        onComplete();
      })
      .catch(() => {
        setForceDisableButton(true);
      });
  };

  return (
    <>
      <div className="row">
        <div className="action" style={{ width: '300px' }}>
          <Button
            appearance="primary"
            testId="importOrdersCustomers"
            isDisabled={
                (importOrdersState.loading
                || orderQty.loading
                || orderQty.data?.queryEbayOpenOrdersQty.numberOfOrders === 0
                || orderQty.error !== undefined
                || !isUndefined(importOrdersState.data))
                || forceDisableButton
              }
            onClick={handleOrderImport}
          >
            Import Orders & Customers
          </Button>

        </div>
        <div className="progress">
          <SubHeadingContainer>
            <div className="name" data-testid="orderImportBarHeading">Orders</div>
            <div className="msg" data-testid="orderImportBarMsg">
              <HelperMessage>
                {orderQty.loading && 'Connecting to ebay...'}
                {!orderQty.loading
                && !orderQty.error
                && _.get(orderQty, ['data', 'queryEbayOpenOrdersQty', 'numberOfOrders'], 0) > 0
                && isUndefined(importOrdersState.data)
                && !importOrdersState.loading
                && `0 / ${_.get(orderQty, ['data', 'queryEbayOpenOrdersQty', 'numberOfOrders'], 0)}`}
                {orderQty.data?.queryEbayOpenOrdersQty.numberOfOrders === 0
                  && 'You have 0 orders to import.'}
                {importOrdersState.loading && 'Importing orders...'}
              </HelperMessage>
              {importOrdersState.data
                && (
                <ValidMessage>
                  {importOrdersState.data
                    .PullEbayOpenOrders.numberOfOrdersCreated}
                  {' '}
                  Orders Imported
                </ValidMessage>
                )}
            </div>
          </SubHeadingContainer>
          <div style={ProgressBarContainer}>
            <SuccessProgressBar
              isIndeterminate={importOrdersState.loading}
              value={importOrderProgress}
            />
          </div>
          <br />
          <SubHeadingContainer>
            <div className="name" data-testid="customerImportBarHeading">Customer</div>
            <div className="msg" data-testid="customerImportBarMsg">
              <HelperMessage>
                {orderQty.loading && 'Connecting to ebay...'}
                {!orderQty.loading
                  && !orderQty.error
                  && _.get(orderQty, ['data', 'queryEbayOpenOrdersQty', 'numberOfOrders'], 0) > 0
                  && isUndefined(importOrdersState.data)
                  && !importOrdersState.loading
                  && `0 / ${_.get(orderQty, ['data', 'queryEbayOpenOrdersQty', 'numberOfOrders'], 0)}`}
                {orderQty.data?.queryEbayOpenOrdersQty.numberOfOrders === 0
                  && 'You have 0 customer to import.'}
                {importOrdersState.loading && 'Importing customers...'}
              </HelperMessage>
              {importOrdersState.data
                && (
                <ValidMessage>
                  {importOrdersState.data
                    .PullEbayOpenOrders.numberOfCustomersCreated}
                  {' '}
                  Customers Imported
                </ValidMessage>
                )}
            </div>
          </SubHeadingContainer>
          <div style={ProgressBarContainer}>
            <SuccessProgressBar
              isIndeterminate={importOrdersState.loading}
              value={importOrderProgress}
            />
          </div>
        </div>
      </div>
      <div style={{ width: 'fit-content', margin: 'auto' }}>
        {orderQty.error && (
          <ErrorMessage>
            {graphQLErrorsReader(orderQty.error)}
          </ErrorMessage>
        )}
        {importOrdersState.error && (
          <ErrorMessage>
            {graphQLErrorsReader(importOrdersState.error)}
          </ErrorMessage>
        )}
      </div>

    </>

  );
};

type Step = 'importProduct' | 'importOrder' | 'finish';

const EbayImporter = ({ teamChannelID }:{ teamChannelID:string}) => {
  const [step, setStep] = useState<Step>('importProduct');
  const [updateTeamChannel] = useUpdateTeamChannelMutation();
  const [skipButtonLoading, setSkipButtonLoading] = useState(false);
  const [finishButtonLoading, setFinishButtonLoading] = useState(false);
  const [serverErr, setServerErr] = useState('');
  const history = useHistory();
  const { dispatch } = useAuth();

  const handleImportOrderComplete = () => {
    setStep('finish');
  };

  const handleSkip = () => {
    // set button to loading
    setSkipButtonLoading(true);
    // update to onboarded = true
    updateTeamChannel({
      variables: {
        teamChannelID,
        onBoarded: true,
      },
    }).then(() => {
      // update local state onBoarded = true
      dispatch({
        type: 'setTeamChannelOnboarded',
        setTeamChannelOnboardedPayload: {
          teamChannelId: teamChannelID,
          value: true,
        },
      });
      // push to default page after onboarding
      history.push('/me');
    }).catch((err) => {
      setServerErr(graphQLErrorsReader(err));
      setFinishButtonLoading(false);
    });
  };

  const handleFinish = () => {
    // set button to loading
    setFinishButtonLoading(true);
    // set to onboarded = true
    updateTeamChannel({
      variables: {
        teamChannelID,
        onBoarded: true,
      },
    }).then(() => {
      dispatch({
        type: 'setTeamChannelOnboarded',
        setTeamChannelOnboardedPayload: {
          teamChannelId: teamChannelID,
          value: true,
        },
      });
      history.push('/me');
    })
      .catch((err) => {
        setFinishButtonLoading(false);
        setServerErr(graphQLErrorsReader(err));
      });
  };

  return (
    <EbayImporterContainier>
      <img src={ebay} alt="ebay" data-testid="ebayLogo" />

      <EbayListingsImporter
        teamChannelID={teamChannelID}
        onComplete={() => setStep('importOrder')}
      />

      {/* don't mount this component initially */}
      {step !== 'importProduct'
        && (
        <EbayOrderImporter
          teamChannelID={teamChannelID}
          onComplete={handleImportOrderComplete}
        />
        )}
      {serverErr
        && (
        <div style={{ width: 'fit-content', margin: 'auto' }}>
          <ErrorMessage>
            {serverErr}
          </ErrorMessage>
        </div>
        )}
      <div className="action">
        {step === 'finish'
          && (
          <Button
            appearance="primary"
            testId="nextButton"
            isLoading={finishButtonLoading}
            onClick={handleFinish}
          >
            {' '}
            Finish
            {' '}
          </Button>
          )}

        {step !== 'finish'
          && (
          <Button
            appearance="primary"
            isLoading={skipButtonLoading}
            onClick={handleSkip}
          >
            {' '}
            Skip
            {' '}
          </Button>
          )}
      </div>
    </EbayImporterContainier>
  );
};

export default EbayImporter;
