import React, { useState, useEffect } from 'react';
import Select, { OptionType, ValueType, OptionsType } from '@atlaskit/select';
import Button from '@atlaskit/button';
import Textfield from '@atlaskit/textfield';
import SearchIcon from '@atlaskit/icon/glyph/search';
import { useTranslation } from 'react-i18next';
import { FilterInputsGroup, FilterSection } from './AdvancedFilter.style';
import { useProducts } from '../../ProductsListing';
import { ProductFilterKey, ProductFilterCondition } from '../../../../graphql/types';
import { getAdvancedFilterPayload } from './helpers';
import { AvailableFilters } from './constants';
import SelectedAdvancedFilter from './SelectedAdvancedFilter';

const AdvancedFilter = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useProducts();
  const fieldSelectorOptions = AvailableFilters.map((filter) => ({
    label: filter.displayName,
    value: filter.key,
  }));
  const [
    selectedKey,
    setSelectedKey,
  ] = useState<ValueType<OptionType>>(fieldSelectorOptions[0]);
  const [
    conditionOptions,
    setConditionOptions,
  ] = useState<OptionsType<OptionType>>([]);

  // let initArray: OptionType[] = [];
  const [
    selectedCondition,
    setSelectedCondition,
  ] = useState<ValueType<OptionType>>();

  const [keyword, setKeyword] = useState('');
  const [inputType, setInputType] = useState('text');

  const handleKeyChange = (option: ValueType<OptionType>) => {
    setSelectedKey(option);
  };
  // update condition option as changes in key selected
  useEffect(() => {
    const index = AvailableFilters
      .findIndex((filter) => filter.key === (selectedKey as OptionType).value);
    if (index >= 0) {
      const filter = AvailableFilters[index];
      // @ts-ignore
      const conditionSelectorOptions = filter.condition.map((c) => ({
        label: c.label,
        value: c.value,
      }));
      setInputType(filter.valueType);
      setConditionOptions(conditionSelectorOptions);
      setSelectedCondition(conditionSelectorOptions[0]);
      setKeyword('');
    }
  }, [selectedKey]);

  const handleConditionChange = (option: ValueType<OptionType>) => {
    setSelectedCondition(option);
  };

  const handleKeywordChange = (e: React.FormEvent<HTMLInputElement>) => {
    setKeyword(e.currentTarget.value);
  };

  /**
   * use it to search immidientilly
   */
  const handleSearchNow = () => {
    const key = (selectedKey as OptionType).value;
    const condition = (selectedCondition as OptionType).value;
    const value = keyword.trim();

    dispatch({
      type: 'setAdvancedFilter',
      payload: getAdvancedFilterPayload(
        state.query.advancedFilter,
        {
          filterKey: (key as ProductFilterKey),
          filterCondition: (condition as ProductFilterCondition),
          filterValue: value,
        },
      ),
    });
  };

  const handleUserEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword.trim().length > 0) {
      // triger immediently
      handleSearchNow();
    }
  };
  // WHEN: key, condition,value all entered and valid.
  // dispatch filter value to sql query
  // if (
  //   (selectedKey as OptionType).value
  //   && (selectedCondition as OptionType).value
  // )
  useEffect(() => {
    if (
      selectedKey !== null && selectedKey !== undefined && selectedKey.value
      && selectedCondition !== null && selectedCondition !== undefined && selectedCondition.value
    ) {
      clearTimeout(state.ui.debonced);
      const key = (selectedKey as OptionType).value;
      const condition = (selectedCondition as OptionType).value;
      const value = keyword.trim();

      const timmerId = setTimeout(() => {
        dispatch({
          type: 'setAdvancedFilter',
          payload:
            getAdvancedFilterPayload(
              state.query.advancedFilter,
              {
                filterKey: (key as ProductFilterKey),
                filterCondition: (condition as ProductFilterCondition),
                filterValue: value,
              },
            ),
        });
      }, 500);

      dispatch({
        type: 'setDebonced',
        payload: timmerId,
      });
    }
  }, [selectedKey,selectedCondition,keyword]) //eslint-disable-line

  const reset = () => {
    setSelectedKey(fieldSelectorOptions[0]);
    dispatch({
      type: 'reset',
    });
  };

  return (
    <FilterSection>
      <FilterInputsGroup>
        <div className="selector key-selector" data-testid="filterType">
          <Select<OptionType>
            options={fieldSelectorOptions}
            value={selectedKey}
            onChange={handleKeyChange}
          />
        </div>
        <div className="selector condition-selector" data-testid="filterCondition">
          <Select<OptionType>
            options={conditionOptions}
            value={selectedCondition}
            onChange={handleConditionChange}
          />
        </div>
        <div className="selector value-input" data-testid="filterValue">
          <Textfield
            value={keyword}
            placeholder="Enter value"
            key="search"
            onChange={handleKeywordChange}
            type={inputType}
            onKeyDown={handleUserEnterKey}
            elemAfterInput={(
              <Button
                onClick={handleSearchNow}
                appearance="subtle-link"
                spacing="none"
              >
                <SearchIcon label="search" size="medium" />
              </Button>
            )}
          />
        </div>
        <div className="reset">
          <Button
            appearance="link"
            onClick={reset}
            testId="resetButton"
          >
            {t('orders.left.button.reset')}
          </Button>
        </div>
      </FilterInputsGroup>
      <SelectedAdvancedFilter />
    </FilterSection>
  );
};

export default AdvancedFilter;
