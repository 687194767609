import { colors } from '@atlaskit/theme';
import styled from 'styled-components';

const SearchResultContainer = styled.div`
  box-sizing:border-box;
  width:100%;
  margin-top:20px;
  border-radius:5px;
  display:flex;
  min-height:200px;
  h2{
      font-size:1.25em;
      color:${colors.subtleText};
      padding:10px;
      display:block;
      margin:auto;
  }
  section{
    padding:0px 10px 0px 10px;
    /* width:80%; */
    width:100%;
    h2 {
      font-size:1.25em;
      color:${colors.subtleText};
    }
    ul {
      display:flex;
      flex-wrap:wrap;
      padding:0;
      margin:0px;
      list-style:none;
      width:100%;
      li {
        padding:10px;
        /* border:1px solid #ccc; */
        display:flex;
        width:400px;
        margin-bottom:20px;
        div.product-image {
          width:150px;
          flex-shrink:0;
          img {
           max-width:120px;
           display:block;
           margin:auto;
          }
        }
        div.product-detail{
          h3{
           margin:0px 0px 5px 0px;
           font-size:1em;
           }
          dl{
            display:table-row;
            margin:0;
            padding:2px 0px 2px 0px;
            dt,dd{
              display:table-cell;
              vertical-align:middle;
            }
            dd {
              padding-left:5px;
            }
          }
          .select-button{
            margin-top:10px;
            padding:0px 10px 0px 10px;
          }
        }
      }
    }
  }
`;

export default SearchResultContainer;
