import React from 'react';
import CrossIcon from '@atlaskit/icon/glyph/cross';
import Button from '@atlaskit/button';
import { Order } from '../../types';
import OrderDetails from '../../../OrderDetails/OrderDetails';
/**
  * render order details view
  */
const OrderView = (
  { order, closeOrdeView, refetch }: {
    order: Order,
    closeOrdeView: () => void,
    refetch:()=>void,
  },
) => (
  <div style={{ position: 'relative' }} data-testid="orderDetailView">
    <div
      style={{
        position: 'absolute',
        right: 20,
        top: 30,
      }}
    >
      <Button
        appearance="subtle-link"
        onClick={closeOrdeView}
        testId="close-button"
      >
        <CrossIcon label="close" size="large" />
      </Button>

    </div>
    <div>
      <OrderDetails
        order={order}
        refetch={refetch}
      />
    </div>
  </div>
);

export default OrderView;
