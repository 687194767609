import styled from 'styled-components';

const SearchResultContainer = styled.div`
  position: relative;
`;
const SearchResultPopUp = styled.ul`
  position:absolute;
  top:0;
  left:0;
  background:#fff;
  width:100%;
  z-index:999;
  border-top:0px;
  border-left:1px;
  border-right:1px;
  border-bottom:1px;
  border-color:#ccc;
  border-style:solid;
  box-sizing:border-box;
  padding:10px;
`;
const SearchResultPopUpItem = styled.li`
  display: grid;
  grid-template-rows:25px 25px;
  grid-template-columns:60px 25% 25% 25%;
  padding:5px;
  :hover{
    background-color:#e1e1e1;
    cursor: pointer;
  }
  span {
    color: rgb(107, 119, 140);
  }
  img {
    width:50px;
    height:50px;
    object-fit:cover;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .name {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .sku {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .priceInc {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .soh {
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`;

export {
  SearchResultContainer,
  SearchResultPopUp,
  SearchResultPopUpItem,
};
