/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@atlaskit/button/new';
import { Grid } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import LoadingOverlay from 'react-loading-overlay-ts';

import validator from 'validator';
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalTransition,
} from '@atlaskit/modal-dialog';

import TextField from '@atlaskit/textfield';

import Form, {
  ErrorMessage,
  Field,
  FormHeader,
  RequiredAsterisk,
} from '@atlaskit/form';
import Select, { ValueType } from '@atlaskit/select';
import { usePaymentInputs } from 'react-payment-inputs';
import styled from 'styled-components';
import { useAuth } from '../../utils/useAuth';
import addNotification from '../../utils/addNotification';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useCreateTeamChargeMutation, useCybersourceChargeSubMutation } from '../../graphql/types';

const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
  || 'http://localhost:4000';

interface CreateSubscribeInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
  // planID: string
  defaultBillingInfo: DefaultBillingInfo,
}

export interface DefaultBillingInfo {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  street: string,
  suburb: string,
  state: string,
  postcode: string,
  country: string,
}

interface SubscribeFormInfo {
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  street: string
  suburb: string,
  state: any,
  postcode: string,
  country: string,
  cardNumber: string,
  cardHolder: String,
  expiryDate: string,
  cvc: string,
}

interface Option {
  label: string;
  value: string;
}

const states = [
  { label: 'Victoria', value: 'VIC' },
  { label: 'Queensland', value: 'QLD' },
  { label: 'New South Wales', value: 'NSW' },
  { label: 'Western Australia', value: 'WA' },
  { label: 'South Australia', value: 'SA' },
  { label: 'Tasmania', value: 'TAS' },
];

export default function CreateSubscribe(props: CreateSubscribeInputProps) {
  const {
    isOpen, close, defaultBillingInfo,
  } = props;

  const history = useHistory();

  // flow1 = filling form
  // flow2 = device data collection
  // flow3 = step up 3ds
  const [subscribeFlow, setSubscribeFlow] = useState(1);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [buttonEable, setButtonEnable] = useState(true);

  const { state: { currentTeamId: teamId } } = useAuth();

  const [wsrTitle, setwsrTitle] = useState('Cybersource Subscribe is running now');
  const [wsrDesc, setwsrDesc] = useState('Cybersource Subscribe will take few seconds, Please wait a momnet');

  // 3ds1
  const formRef = useRef(null);
  const [confirmationUrl, setConfirmationUrl] = useState('');
  const [DDCIaccessToken, setDDCIaccessToken] = useState('');

  // 3ds2
  const [checkAccessTokenUrl, setSecondAccessTokenUrl] = useState('');
  const [checkAccessToken, setSecondAccessToken] = useState('');
  const [checkTransactionId, setCheckTransactionId] = useState('');

  const [createTeamCharge] = useCreateTeamChargeMutation();
  const [cybersourceChargeSub] = useCybersourceChargeSubMutation();

  const onSubmit = async (formData: SubscribeFormInfo) => {
    setSubmitLoading(true);
    setButtonEnable(false);
    await createTeamCharge({
      variables: {
        teamId: teamId!,
        teamChannelId: '',
        chargeType: 1,
        cardNumber: formData.cardNumber.replace(/\s*/g, ''),
        expiryDate: formData.expiryDate.replace(/\s*/g, ''),
        cvc: formData.cvc,
      },
    }).then((res) => {
      setSubscribeFlow(2);
      setConfirmationUrl(res?.data?.CreateTeamCharge.confirmationUrl!);
      setDDCIaccessToken(res?.data?.CreateTeamCharge.accessToken!);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const cardinalCollectionForm: any = document.querySelector('#cardinal_collection_form');
      if (cardinalCollectionForm) {
        let authenticationTransactionId2 = '';
        window.addEventListener('message', async (event) => {
          // console.log(event);
          if (event.origin === 'https://centinelapistag.cardinalcommerce.com') {
            // console.log(event.data);
            // setSessionId(JSON.parse(event.data).SessionId);
            await cybersourceChargeSub({
              variables: {
                teamId: teamId!,
                chargeId: res?.data?.CreateTeamCharge.chargeId!,
                authenticationTransactionId: JSON.parse(event.data).SessionId,
                email: formData.email,
                phone: formData.phone,
                state: formData.state.value,
                postcode: formData.postcode,
                country: 'AU',
                street: formData.street,
                suburb: formData.suburb,
                firstName: formData.firstName,
                lastName: formData.lastName,
                cardNumber: formData.cardNumber.replace(/\s*/g, ''),
                expiryDate: formData.expiryDate.replace(/\s*/g, ''),
                cvc: formData.cvc,
                step: 1,
              },
            })
              .then((resN) => {
                if (resN?.data?.CybersourceChargeSub.checkStatus === 'PENDING_AUTHENTICATION') {
                  setSubmitLoading(false);
                  setSubscribeFlow(3);
                  setSecondAccessTokenUrl(resN?.data?.CybersourceChargeSub.userCheckUrl!);
                  setSecondAccessToken(resN?.data?.CybersourceChargeSub.accessToken!);
                  setCheckTransactionId(resN?.data?.CybersourceChargeSub.authenticationTransactionId!);
                  authenticationTransactionId2 = resN?.data?.CybersourceChargeSub.authenticationTransactionId!;
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const stepUpForm: any = document.querySelector('#step-up-form');
                  if (stepUpForm) { // Step-Up form exists
                    stepUpForm.submit();
                  }
                }
              })
              .catch((err) => {
                addNotification(graphQLErrorsReader(err), 'danger');
              });
          }

          if (event.origin === REACT_APP_GRAPHQL_SERVER_HOST) {
            setSubscribeFlow(3);
            await cybersourceChargeSub({
              variables: {
                teamId: teamId!,
                chargeId: res?.data?.CreateTeamCharge.chargeId!,
                authenticationTransactionId: authenticationTransactionId2,
                email: formData.email,
                phone: formData.phone,
                state: formData.state.value,
                postcode: formData.postcode,
                country: 'AU',
                street: formData.street,
                suburb: formData.suburb,
                firstName: formData.firstName,
                lastName: formData.lastName,
                cardNumber: formData.cardNumber.replace(/\s*/g, ''),
                expiryDate: formData.expiryDate.replace(/\s*/g, ''),
                cvc: formData.cvc,
                step: 2,
              },
            })
              .then((resN) => {
                if (resN.data?.CybersourceChargeSub.status === 'ok') {
                  close();
                  history.push('/Billing');
                  window.location.reload();
                  addNotification('Your plan for Standard has been successfully Subscribed', 'success');
                }
              })
              .catch((err) => {
                setwsrTitle('Sorry, Subscribe failed');
                const errMsg = graphQLErrorsReader(err);
                setwsrDesc(errMsg);
              });
          }
        }, false);
        cardinalCollectionForm.submit();
      }
    }).catch((err) => {
      addNotification(graphQLErrorsReader(err), 'danger');
    });

    return undefined;
  };

  const onCancel = () => {
    setSubscribeFlow(1);
    setSubmitLoading(false);
    close();
  };

  const validateName = (value: string = '') => {
    if (value.length < 1) {
      return 'EMPTY';
    }
    return undefined;
  };

  const validateEmail = (value: string = '') => {
    if (!validator.isEmail(value)) {
      return 'INVALID';
    }
    return undefined;
  };

  const validatePhoneNumber = (value: string = '') => {
    if (!validator.isMobilePhone(value, 'en-AU')) {
      return 'INVALID';
    }
    return undefined;
  };

  const validateCardNumber = (value: string = '') => {
    const trimValue = value.trim().replace(/\s+/g, '');
    // Regex patterns for Amex, Visa, and MasterCard
    const amexPattern = /^3[47][0-9]{13}$/; // American Express: Starts with 34 or 37, length 15
    const visaPattern = /^4[0-9]{12}(?:[0-9]{3})?$/; // Visa: Starts with 4, length 13 or 16
    const mastercardPattern = /^5[1-5][0-9]{14}$/; // MasterCard: Starts with 51-55, length 16

    if (
      amexPattern.test(trimValue)
      || visaPattern.test(trimValue)
      || mastercardPattern.test(trimValue)
    ) {
      return undefined;
    }
    return 'INVALID';
  };

  const validateExpiryDate = (value: string = ''): string | undefined => {
    const trimValue = value.trim().replace(/\s+/g, '');
    // Regex pattern for MM/YY format
    const expiryPattern = /^(0[1-9]|1[0-2])\/\d{2}$/;

    if (!expiryPattern.test(trimValue)) {
      return 'INVALID';
    }

    const [month, year] = trimValue.split('/').map(Number);

    // Get the current month and year
    const now = new Date();
    const currentMonth = now.getMonth() + 1; // getMonth() returns 0-11
    const currentYear = now.getFullYear() % 100; // get last two digits of the year

    // Check if the expiry date is not earlier than the current date
    if (year < currentYear || (year === currentYear && month < currentMonth)) {
      return 'EXPIRED';
    }

    return undefined;
  };

  const validateCardSecurityCode = (value: string = '') => {
    // Regex pattern for CVV: 3 or 4 digits
    const cvvPattern = /^[0-9]{3,4}$/;

    if (cvvPattern.test(value)) {
      return undefined;
    }
    return 'INVALID';
  };

  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs();

  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close} shouldCloseOnOverlayClick={false}>
          <Form<SubscribeFormInfo> onSubmit={onSubmit}>
            {({ formProps, submitting }) => (
              <form {...formProps}>
                <ModalHeader>
                  <ModalTitle>Subscribe to keep using MERP</ModalTitle>
                </ModalHeader>
                {subscribeFlow < 3 && (
                  <ModalBody>
                    <LoadingOverlay
                      active={submitLoading}
                      styles={{
                        overlay: (base) => ({
                          ...base,
                          background: 'rgba(196, 196, 196, 0.3)',
                        }),
                        spinner: (base) => ({
                          ...base,
                          width: '65px',
                          '& svg circle': {
                            stroke: 'rgba(0, 82, 204, 1)',
                          },
                        }),
                        content: (base) => ({
                          ...base,
                          color: 'rgba(0, 82, 204, 1)',
                        }),
                      }}
                      text="Processing..."
                    >
                      <FormHeader>
                        <h4>Billing Address</h4>
                        <p aria-hidden="true">
                          Required fields are marked with an asterisk
                          {' '}
                          <RequiredAsterisk />
                        </p>
                      </FormHeader>

                      <Grid gap="space.100" templateColumns="1fr 1fr">
                        <Field
                          name="firstName"
                          label="First Name"
                          isRequired
                          validate={validateName}
                          defaultValue={defaultBillingInfo.firstName}
                        >
                          {({ fieldProps, error }) => (
                            <>
                              <TextField autoComplete="firstName" {...fieldProps} defaultValue={defaultBillingInfo.firstName} />
                              {error === 'EMPTY' && (
                                <ErrorMessage>
                                  First name can not be empty
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </Field>
                        <Field
                          name="lastName"
                          label="Last Name"
                          isRequired
                          validate={validateName}
                          defaultValue={defaultBillingInfo.lastName}
                        >
                          {({ fieldProps, error }) => (
                            <>
                              <TextField
                                {...fieldProps}
                                type="text"
                                defaultValue={defaultBillingInfo.lastName}
                              />
                              {error === 'EMPTY' && (
                                <ErrorMessage>
                                  Last name can not be empty
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Grid gap="space.100" templateColumns="1fr 1fr">
                        <Field
                          name="email"
                          label="Email Address"
                          isRequired
                          validate={validateEmail}
                          defaultValue={defaultBillingInfo.email}
                        >
                          {({ fieldProps, error }) => (
                            <>
                              <TextField
                                autoComplete="email"
                                {...fieldProps}
                                defaultValue={defaultBillingInfo.email}
                              />
                              {error === 'INVALID' && (
                                <ErrorMessage>
                                  Please input a valid email
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </Field>
                        <Field
                          name="phone"
                          label="Phone Number"
                          isRequired
                          validate={validatePhoneNumber}
                          defaultValue={defaultBillingInfo.phone}
                        >
                          {({ fieldProps, error }) => (
                            <>
                              <TextField
                                {...fieldProps}
                                type="tel"
                                inputMode="numeric"
                                elemBeforeInput={<div style={{ marginLeft: '6px' }}>+61</div>}
                                placeholder="0412341234"
                                defaultValue={defaultBillingInfo.phone}
                              />
                              {error === 'INVALID' && (
                                <ErrorMessage>
                                  Please input a valid phone number
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </Field>
                      </Grid>
                      <Field
                        name="street"
                        label="Street Address"
                        isRequired
                        defaultValue={defaultBillingInfo.street}
                      >
                        {({ fieldProps }) => (
                          <TextField type="street" {...fieldProps} defaultValue={defaultBillingInfo.street} />
                        )}
                      </Field>
                      <Grid autoFlow="column" gap="space.100" templateColumns="2fr 2fr 1fr">
                        <Field
                          name="suburb"
                          label="Suburb"
                          isRequired
                          defaultValue={defaultBillingInfo.suburb}
                        >
                          {({ fieldProps }) => (
                            <TextField type="suburb" {...fieldProps} defaultValue={defaultBillingInfo.suburb} />
                          )}
                        </Field>
                        <Field<ValueType<Option>>
                          name="state"
                          label="State"
                          isRequired
                          defaultValue={{ label: 'Victoria', value: 'VIC' }}
                        >
                          {({ fieldProps }) => (
                            <Select<Option>
                              {...fieldProps}
                              options={states}
                              isClearable
                              menuPlacement="auto"
                            />
                          )}
                        </Field>
                        <Field
                          name="postcode"
                          label="Post Code"
                          isRequired
                          defaultValue={defaultBillingInfo.postcode}
                        >
                          {({ fieldProps }) => (
                            <TextField type="postcode" {...fieldProps} defaultValue={defaultBillingInfo.postcode} />
                          )}
                        </Field>
                      </Grid>
                      <FormHeader>
                        <h4>Payment Info</h4>
                      </FormHeader>
                      <Field
                        name="cardNumber"
                        label="Card Number"
                        isRequired
                        validate={validateCardNumber}
                      >
                        {({ fieldProps, error }) => (
                          <>
                            <TextField
                              {...fieldProps}
                              {...getCardNumberProps({ onBlur: fieldProps.onBlur, onChange: fieldProps.onChange })}
                            />
                            {error === 'INVALID' && (
                              <ErrorMessage>
                                Please ensure to input a valid card, We only accept VISA, MASTER, and AMEX
                              </ErrorMessage>
                            )}
                          </>

                        )}
                      </Field>
                      <Grid autoFlow="column" gap="space.100" templateColumns="2fr 2fr 1fr">
                        <Field
                          name="cardHolder"
                          label="Card Holder"
                          isRequired
                        >
                          {({ fieldProps }) => (
                            <TextField type="cardholder" {...fieldProps} />
                          )}
                        </Field>
                        <Field
                          name="expiryDate"
                          label="Expire Date"
                          isRequired
                          validate={validateExpiryDate}
                        >
                          {({ fieldProps, error }) => (
                            <>
                              <TextField
                                {...getExpiryDateProps({ onBlur: fieldProps.onBlur, onChange: fieldProps.onChange })}
                              />
                              {error === 'INVALID' && (
                                <ErrorMessage>
                                  Please input a valid date
                                </ErrorMessage>
                              )}
                              {error === 'EXPIRED' && (
                                <ErrorMessage>
                                  Expire date can not be earlier than today
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </Field>
                        <Field
                          name="cvc"
                          label="CVC Code"
                          isRequired
                          validate={validateCardSecurityCode}
                        >
                          {({ fieldProps, error }) => (
                            <>
                              <TextField
                                {...fieldProps}
                                {...getCVCProps({ onBlur: fieldProps.onBlur, onChange: fieldProps.onChange })}
                              />
                              {error === 'INVALID' && (
                                <ErrorMessage>
                                  Please input a valid cvc code
                                </ErrorMessage>
                              )}
                            </>
                          )}
                        </Field>
                      </Grid>
                    </LoadingOverlay>
                  </ModalBody>
                )}
                {
                  subscribeFlow === 2 && (
                    <div>
                      <iframe id="cardinal_collection_iframe" name="collectionIframe" height="10" width="10" title="cybersourcePayerAuth" style={{ display: 'none' }} />
                      <form id="cardinal_collection_form" method="POST" target="collectionIframe" action={confirmationUrl}>
                        <input id="cardinal_collection_form_input" type="hidden" name="JWT" value={DDCIaccessToken} ref={formRef} />
                      </form>
                    </div>
                  )
                }
                {
                  subscribeFlow === 3
                  && (
                    <ModalBody>
                      <p />
                      <iframe title="secondSetup" name="step-up-iframe" height="500" width="100%" />
                      <form id="step-up-form" target="step-up-iframe" method="post" action={checkAccessTokenUrl}>
                        <input type="hidden" name="JWT" value={checkAccessToken} />
                        <input type="hidden" name="MD" value={checkTransactionId} />
                      </form>
                    </ModalBody>
                  )
                }
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: token('space.300', '24px'),
                }}
                >
                  <div style={{ textAlign: 'left' }}>
                    <p style={{ margin: 0 }}><strong>Payment secured by ANZ wordline*</strong></p>
                  </div>
                  <div style={{ display: 'flex', gap: '8px', marginLeft: 'auto' }}>
                    <Button appearance="subtle" onClick={onCancel}>Cancel</Button>
                    <Button appearance="primary" type="submit" isLoading={submitting} isDisabled={!buttonEable}>
                      Make Payment
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </Modal>
      )}
    </ModalTransition>
  );
}
