import gql from 'graphql-tag';

const taxonomyGetCategorySuggestions = gql`
query QueryEbayCategorySuggestions(
  $q:String!,
  ) {
  QueryEbay(
    resource: "taxonomy.getCategorySuggestions",
    params: [{value: "15"}, {value: {q: $q}}],
    ){
    response
  }
}`;

export default taxonomyGetCategorySuggestions;
