/* eslint-disable max-len */
import React from 'react';
import SectionMessage from '@atlaskit/section-message';

export default () => (
  <SectionMessage title="Congratulations" appearance="success">
    <p>
      You have successfully integrated Aramex Couriers with MERP.
    </p>
    <p>
      Now you can automatically get quotes, create a Fastway consignment and print labels.
    </p>

    <p>
      To do so is extremely easy, just select
      {' '}
      <strong>Fastway - integration</strong>
      {' '}
      when shipping your order.
    </p>
  </SectionMessage>
);
