import { useGetTeamByIdQuery, useGetTeamChargePlanByIdQuery } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';
// import CheckChargeType from './CheckChargeType';

const CheckPlan = () => {
  const { state } = useAuth();
  const teamId = state.currentTeamId || '';
  const {
    data,
  } = useGetTeamByIdQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });

  const teamChargePlanId = data?.GetTeamById.team?.teamChargePlanId! || '';

  const {
    data: teamChargePlan,
  } = useGetTeamChargePlanByIdQuery(
    {
      variables: { teamChargePlanId },
      // to avoid still show cached data after update when switching team
      fetchPolicy: 'network-only',
    },
  );
  const id = teamChargePlan?.GetTeamChargePlanById.teamChargePlan?.id || '';
  const title = teamChargePlan?.GetTeamChargePlanById.teamChargePlan?.title || '';
  // eslint-disable-next-line max-len
  const amount = teamChargePlan?.GetTeamChargePlanById.teamChargePlan?.amount || 0;
  const planType = teamChargePlan?.GetTeamChargePlanById.teamChargePlan?.planType || 0;

  // let daysLeft = 0;
  // const current = new Date(Date.now()).getTime();

  // // eslint-disable-next-line max-len
  // if (teamChargePlan?.GetTeamChargePlanById.teamChargePlan && teamChargePlan?.GetTeamChargePlanById.teamChargePlan.planType === 2) {
  //   // const teamCharge = CheckChargeType(); // 0=shopify, 1=cash, 2=other
  //   // const teamCharge = CheckChargeType();
  //   // console.log(teamCharge);
  //   /*
  //   if (teamCharge === null || (teamCharge !== null && teamCharge.teamChargeStatus !== 'alive')) {
  //     title = 'Plan Expired';
  //     daysLeft = 0;
  //   }

  //   const activeEnd = teamCharge?.activeEnd;
  //   const diff = new Date(activeEnd).getTime() - current;
  //   if (daysLeft > 0) {
  //     daysLeft = Math.ceil(diff / (1000 * 3600 * 24));
  //   } else {
  //     daysLeft = 0;
  //   }
  //   */
  // }
  // // eslint-disable-next-line max-len
  // if (teamChargePlan?.GetTeamChargePlanById.teamChargePlan && teamChargePlan?.GetTeamChargePlanById.teamChargePlan.planType === 0) {
  //   const createdAt = data?.GetTeamById.team?.createdAt;
  //   // console.log(createdAt);
  //   const diff = current - new Date(createdAt).getTime();
  //   daysLeft = Math.ceil(30 - diff / (1000 * 3600 * 24));
  //   if (daysLeft < 0) {
  //     title = 'Plan Expired';
  //     daysLeft = 0;
  //   }
  // }

  // eslint-disable-next-line max-len
  // const { id, title, amount } = teamChargePlan?.GetTeamChargePlanById.//  teamChargePlan!;
  // const {
  //   data: teamPlans,
  // } = useGetTeamChargePlansQuery(
  //   {
  //     variables: {},
  //     // to avoid still show cached data after update when switching team
  //     fetchPolicy: 'network-only',
  //   },
  // );

  // const standard = teamPlans?.GetTeamChargePlans.teamChargePlans || [];
  // let amount = standard.length !== 0 ? standard[0].amount : 0;
  // let id = standard.length !== 0 ? standard[0].id : '';
  // let title = standard.length !== 0 ? standard[0].title : '';
  // const teamSettingsObj = data?.GetTeamById.team?.settings ? JSON.parse(data?.GetTeamById.team?.settings) : {};

  // const teamCharge = CheckChargeType(); // 0=shopify, 1=cash, 2=other

  // const teamChargePlanId = teamCharge?.teamChargePlanId || '';
  // const {
  //   data: teamPlan,
  // } = useGetTeamChargePlanByIdQuery(
  //   {
  //     variables: { teamChargePlanId },
  //     // to avoid still show cached data after update when switching team
  //     fetchPolicy: 'network-only',
  //   },
  // );

  // if (teamPlan?.GetTeamChargePlanById.teamChargePlan === null) {
  //   if (teamSettingsObj.free === 0 && teamCharge === null) {
  //     title = 'Plan Expired';
  //   }

  //   if (teamCharge !== null && teamCharge.teamChargeStatus !== 'alive') {
  //     title = 'Plan Expired';
  //   }

  //   if (teamSettingsObj.free === 1 && teamCharge === null) {
  //     const current = new Date(Date.now()).getTime();
  //     const create = teamSettingsObj.createdTime;
  //     const diff = current - create;
  //     const freeDay = Math.ceil(30 - diff / (1000 * 3600 * 24));
  //     if (freeDay < 0) {
  //       title = 'Plan Expired';
  //     } else {
  //       title = 'Free Trial';
  //       amount = 0;
  //     }
  //   }
  // } else {
  //   title = teamPlan?.GetTeamChargePlanById.teamChargePlan?.title || '';
  //   amount = teamPlan?.GetTeamChargePlanById.teamChargePlan?.amount || 0;
  //   id = teamPlan?.GetTeamChargePlanById.teamChargePlan?.id || '';
  // }

  // // if free expired, return true

  return {
    title, amount, id, planType,
  };
};

export default CheckPlan;
