import React from 'react';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import { TeamTaxClasses } from '../types';
import { getCompoundedTaxRateMutiplier } from '../../../utils/taxHelper';

export interface TaxInfo {
  taxClassId:string,
  taxName:string,
  taxMutiplier:number,
}

interface Props {
  teamTaxClasses:TeamTaxClasses,
  currentSelectedTaxId:string,
  onChange:(taxInfo:TaxInfo)=>void,
}

const TaxSelector = (
  { teamTaxClasses, currentSelectedTaxId, onChange }:Props,
) => {
  const taxClassOptions = teamTaxClasses
    .filter((t) => !t.isForShipping && !t.name.includes('GST Excluded Goods'))
    .map((tc) => ({
      label: tc.name.replace('Goods', ''),
      value: tc.id,
    }));

  const [defaultValue] = taxClassOptions
    .filter((tcOption) => tcOption.value === currentSelectedTaxId);

  const handleOnChange = (value: ValueType<OptionType>) => {
    const selectedTaxId = ((value as OptionType).value as string);
    const [{ name: taxName }] = teamTaxClasses.filter(
      (cur) => cur.id === selectedTaxId,
    );
    const taxMultiplier = getCompoundedTaxRateMutiplier(
      selectedTaxId,
      teamTaxClasses,
    );

    const taxInfo:TaxInfo = {
      taxClassId: selectedTaxId,
      taxName,
      taxMutiplier: taxMultiplier,
    };
    onChange(taxInfo);
  };

  return (
    <Select
      options={taxClassOptions}
      value={defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default TaxSelector;
