import React from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';
import { ItemInput } from '../../../../graphql/types';

const IntergratedConfirmQuoteButton = () => {
  const { state, dispatch } = useShipping();

  const {
    boxes,
    selectedProductId,
  } = state.shipment;

  const items: Array<ItemInput> = boxes.map((cur) => ({
    length: Number(cur.length),
    width: Number(cur.width),
    height: Number(cur.height),
    weight: Number(cur.weight),
  }));

  let isDisabled = true;
  items.forEach((key) => {
    isDisabled = (() => !![
      Number(key.length) > 0,
      Number(key.height) > 0,
      Number(key.width) > 0,
      Number(key.weight) > 0,
    ].includes(false))();
  });

  isDisabled = !![
    String(selectedProductId).length > 0,
  ].includes(false);

  const selectQuote = async () => {
    dispatch({
      type: 'setStage',
      payload: 'confirmQuote',
    });
  };

  return (
    <Button
      appearance="primary"
      isDisabled={isDisabled}
      onClick={selectQuote}
      testId="IntergratedConfirmQuoteButton"
    >
      Select
    </Button>
  );
};

export default IntergratedConfirmQuoteButton;
