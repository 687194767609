import React, { useState, useEffect } from 'react';
import { Field } from '@atlaskit/form';
import Select, { ValueType, OptionType } from '@atlaskit/select';
import { isUndefined, isArray } from 'lodash';
import { useShipping } from '../Shipping';
import { PreviewShipmentContainer } from '../Shipping.style';
import {
  GetCouriersQuery,
  GetTeamCourierQuery,
} from '../../../graphql/types';
import { useAuth } from '../../../utils/useAuth';
import client from '../../../graphql/client';
import getCouriersQuery from '../../../graphql/shipment/queries/couriers';
import getTeamCourierQuery from '../../../graphql/teamCourier/queries/getTeamCourier';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { getCourierList, getIntegratedOptions } from '../helper';

export const AUSPOST_AUTOPILOT = 'Australia Post - Integration';
export const ARAMEX_AUTOPILOT = 'Aramex Couriers - Integration';

const PreferredShippingMethod = () => {
  const { state: { order } } = useShipping();
  return (
    <>
      <div className="section">
        <h5 className="subtle">
          Customer Preferred Courier:
        </h5>
        <div className="block alert">
          {order.shippingCarrierCode}
          {order.shippingServiceCode && '('}
          {order.shippingServiceCode}
          {order.shippingServiceCode && ')'}
        </div>
      </div>
    </>
  );
};

const ShippingMethod = () => {
  const { state, dispatch } = useShipping();
  const { state: { currentTeamId: teamId } } = useAuth();
  const [loading, setLoading] = useState(true);

  const [
    couriers,
    setCouriers,
  ] = useState<GetCouriersQuery['GetCouriers']['couriers']>([]);

  const manualOptions = getCourierList(couriers);

  const otherOptions = manualOptions.filter((o) => o.label.toLowerCase() !== 'local pickup' && o.label.toLowerCase() !== 'aupost letter without tracking');

  const untrackedOptions = manualOptions.filter((o) => o.label.toLowerCase() === 'local pickup' || o.label.toLowerCase() === 'aupost letter without tracking');

  const integratedOptions = getIntegratedOptions(
    couriers,
    {
      auspost: state.integration.auspost.enabled,
      fastway: state.integration.fastway.enabled,
    },
  );

  const options = [
    {
      label: 'Connected Couriers',
      options: integratedOptions,
    },
    {
      label: 'Untracked Couriers',
      options: untrackedOptions,
    },
    {
      label: integratedOptions.length > 0 ? 'Other Couriers' : 'Couriers',
      options: otherOptions,
    },
  ];

  const shippingCarrierCode = (
    state.shippingMethod.length > 0 ? state.shippingMethod[0] : state.order.shippingCarrierCode
  );

  const handleSelectChange = React.useCallback((
    value: ValueType<OptionType, true>,
  ) => {
    dispatch({ type: 'setShippingMethod', payload: value });

    try {
      if (isArray(value)) {
        dispatch({ type: 'setCourierId', payload: value!.slice(-1)[0].value });
      } else {
        dispatch({ type: 'setCourierId', payload: '' });
      }
    } catch (error) {
      dispatch({ type: 'setCourierId', payload: '' });
    }
  }, [dispatch]);

  // calculate default selection of courier on mount
  useEffect(() => {
    const autoSelectCourier = () => {
      if (
        shippingCarrierCode === 'Australia Post'
      && state.integration.auspost.enabled
      ) {
        const [auspostAutoModeOption] = [...integratedOptions]
          .filter((option) => option.label === AUSPOST_AUTOPILOT);
        if (!isUndefined(auspostAutoModeOption)) {
          handleSelectChange([auspostAutoModeOption]);
        }
        return;
      }

      if (
        shippingCarrierCode === 'FASTWAY COURIERS'
      && state.integration.fastway.enabled
      ) {
        const [fastwayAutoModeOption] = [...integratedOptions]
          .filter((option) => option.label === ARAMEX_AUTOPILOT);
        if (!isUndefined(fastwayAutoModeOption)) {
          handleSelectChange([fastwayAutoModeOption]);
        }
        return;
      }

      if (shippingCarrierCode === '') {
        const [pickup] = [...manualOptions]
          .filter((option) => option.label.toLowerCase().includes('pickup'));
        if (!isUndefined(pickup)) {
          handleSelectChange([pickup]);
        }
      } else {
        const [defaultOptions] = [...manualOptions]
          .filter((option) => option.label === shippingCarrierCode);
        if (!isUndefined(defaultOptions)) {
          handleSelectChange([defaultOptions]);
        }
      }
    };

    if (state.shippingMethod.length === 0) {
      autoSelectCourier();
    }
  },
  // eslint-disable-next-line
  [
    shippingCarrierCode,
    manualOptions,
    integratedOptions,
    state.integration.auspost.enabled,
    state.integration.fastway.enabled,
    handleSelectChange,
  ]);

  // on mounting
  useEffect(() => {
    const chainedFetch = async () => {
      setLoading(true);

      /**
       * 1.fetch all couriers
       */
      await client.query({
        query: getCouriersQuery,
        variables: {},
      })
      /**
       * 2.find auspostCourier and fastwayCourier
       * 3.return data
       */
        .then(({ data }:{data:GetCouriersQuery}) => {
          const { couriers: couriersReceived } = data!.GetCouriers;
          // if can't find default courier
          const [auspostCourier] = couriersReceived?.filter((cur) => cur.code === 'AustraliaPost');
          const [fastwayCourier] = couriersReceived?.filter((cur) => cur.code === 'FASTWAYCOURIERS');

          return {
            couriers: couriersReceived,
            auspostCourier,
            fastwayCourier,
          };
        })
      /**
       * 4.fetch auspost and fastway intergration info
       * 5.return data
       */
        .then(async (data) => {
          const auspostTeamCourier = await client.query({
            query: getTeamCourierQuery,
            variables: {
              courierId: data.auspostCourier.id,
              teamId: teamId!,
            },
          });

          const fastwayTeamCourier = await client.query({
            query: getTeamCourierQuery,
            variables: {
              courierId: data.fastwayCourier.id,
              teamId: teamId!,
            },
          });

          return {
            ...data,
            auspostTeamCourier,
            fastwayTeamCourier,
          };
        })
      /**
       * 6.set select option list
       * 7.set auspost intergration state
       */
        .then((data) => {
          const {
            couriers: couriersTobeMemeried,
            auspostTeamCourier,
            fastwayTeamCourier,
          } = data;

          const {
            GetTeamCourier: { teamCourier: ausposTC },
          } = (auspostTeamCourier.data as GetTeamCourierQuery);

          const {
            GetTeamCourier: { teamCourier: fastwayTC },
          } = (fastwayTeamCourier.data as GetTeamCourierQuery);

          dispatch({ type: 'setAuspostIntegration', payload: ausposTC?.active ?? false });
          dispatch({ type: 'setAuspostIntegrationConfig', payload: JSON.parse(ausposTC?.configuration ?? '{}') });
          dispatch({ type: 'setFastwayIntegration', payload: fastwayTC?.active ?? false });
          setCouriers(couriersTobeMemeried);
        })
        .catch((err) => {
          dispatch({ type: 'setAuspostIntegration', payload: false });
          dispatch({ type: 'setFastwayIntegration', payload: false });
          dispatch({ type: 'setError', payload: graphQLErrorsReader(err) });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    chainedFetch();
  }, [teamId, dispatch]);

  return (
    <PreviewShipmentContainer>
      <PreferredShippingMethod />
      <div className="section shipMethod">
        <div className="block shippingServiceCode">
          <Field
            name="carrier"
            label="Courier"
            isRequired
          >
            {() => (
              <>
                <Select
                  options={options}
                  isLoading={loading}
                  isDisabled={state.ui.isSubmiting}
                  onChange={handleSelectChange}
                  placeholder="Select courier"
                  aria-label="Select courier"
                  value={state.shippingMethod}
                  isClearable
                  isMulti
                />
              </>
            )}
          </Field>
        </div>
      </div>
    </PreviewShipmentContainer>
  );
};

export default ShippingMethod;
