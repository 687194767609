import { OptionsType } from '@atlaskit/select';
import moment, { Moment } from 'moment';
import { AuspostIntergrationFormData } from './AuspostIntergration.types';

export const submissionVlidation = (data:AuspostIntergrationFormData) => {
  const errors = {
    key: !data.key
      ? 'Key is a requird field.'
      : undefined,
    password: !data.password
      ? 'Password is a requird field.'
      : undefined,
    account: !data.account
      ? 'Account is a requird field.'
      : undefined,
    name: !data.name
      ? 'Name is a requird field.'
      : undefined,
    address1: !data.address1
      ? 'Address line 1 is a requird field.'
      : undefined,
    suburb: !data.suburb
      ? 'Suburb is a requird field.'
      : undefined,
    // eslint-disable-next-line max-len
    postcode: (!data.postcode) || Number.isNaN(Number(data.postcode)) || data.postcode.length !== 4
      ? 'Postcode should be 4 number'
      : undefined,
    state: !data.state
      ? 'State is a required field.'
      : undefined,
  };
  return errors;
};

export const selectStateOptions:OptionsType = [
  { label: 'NSW', value: 'NSW' },
  { label: 'NT', value: 'NT' },
  { label: 'QLD', value: 'QLD' },
  { label: 'SA', value: 'SA' },
  { label: 'TAS', value: 'TAS' },
  { label: 'VIC', value: 'VIC' },
  { label: 'WA', value: 'WA' },
];

export const selectCutOffTimesOptions:OptionsType = (() => {
  const localHours:Array<Moment> = new Array(24)
    .fill(null)
    .map(() => moment('2000-01-01 00:00:00'));

  localHours.forEach((cur, index) => cur.add(index, 'hours'));

  const UTCHours = localHours.map((cur) => cur.toDate().getUTCHours());

  return localHours.map((cur, index) => ({
    label: `${cur.toDate().getHours()}:00`, value: UTCHours[index],
  }));
})();
