import styled from 'styled-components';

const Container = styled.div`
margin-top:50px;
display:flex;
flex-direction:column;
justify-content:flex-start;
  .head {
    display:flex;
    align-items:center;
    .head-item{
      padding:10px;
    }
    .logo{
      padding:0px;
      width:32px;
      height:32px;
      border-radius:50%;
      border:1px solid blue;
      display:flex;
      justify-content:center;
      align-items:center;
      img {
      padding:0px;
      width:25px;
      height:auto;
      border-radius:0;
      border:0;
      }     
    }
    .name {
      font-size:1.5rem;
      font-weight:500;
    }
  }
  .block {
    padding-left:42px;
    margin-top:25px;
    .button-container {
      padding-top:1rem;
    }
    .danger-zone {
    border-radius:5px;
    border:1px solid red;
    padding:1rem;
    }
    .heading {
      font-size: 1.25rem;
      font-weight: 500;
      padding-bottom:20px;
    }
    .toggle{
      display:flex;
      align-items:center;
      label {
        font-size: 0.6875rem;      
      }
    }
   .field {
     margin-bottom:1rem;
     .field-name{
       color: rgb(107, 119, 140);
       font-size:0.6875rem;
       line-height:1rem;
     }
   }
   .danger {
     color:red;
   }
  }
`;

export default Container;
