import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const PING = gql`
  query Ping {
    ping
  }
`;

const Ping = () => {
  const { loading, error, data } = useQuery(PING);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error</p>;

  return <p>{data.ping}</p>;
};

export default Ping;
