import React, { useState, useRef, useEffect } from 'react';
import Button from '@atlaskit/button';
import TextArea from '@atlaskit/textarea';
import Form, { Field, FormFooter } from '@atlaskit/form';
import TextField from '@atlaskit/textfield';
import { useTranslation } from 'react-i18next';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import { useAddLogMutation, LogTableName } from '../../../graphql/types';
import addNotification from '../../../utils/addNotification';
import MerpAvatar from '../../MerpAvatar/MerpAvatar';
import { CommentCardContainer, CommentCardContainerColumn } from './CommentCard';
import { useAuth, useSubscription } from '../../../utils/useAuth';

interface CommentCreatorProps {
  tableId:string,
  tableName:LogTableName,
  refetch:()=>void;
}

const CommentCreator = ({
  tableId, tableName, refetch,
}:CommentCreatorProps) => {
  const { t } = useTranslation();
  const { state: { user, currentTeamId } } = useAuth();
  const { firstName, lastName } = user!;
  const [addLogMutation] = useAddLogMutation();
  const [mode, setMode] = useState<'view'|'edit'>('view');
  const [value, setValue] = useState('');
  const textareaElement = useRef<HTMLTextAreaElement|null>(null);
  const freeExpired = useSubscription();
  const blur = () => {
    if (textareaElement.current) {
      textareaElement.current.blur();
    }
  };

  const onCancel = () => {
    setValue('');
    setMode('view');
    blur();
  };

  const onSubmit = async () => {
    if (value.trim().length === 0) {
      return;
    }

    await addLogMutation({
      variables: {
        teamId: currentTeamId!,
        tableId,
        tableName,
        content: value,
      },
    })
      .then(() => {
        setMode('view');
        setValue('');
        blur();
        refetch();
      })
      .catch((err) => addNotification(graphQLErrorsReader(err), 'danger'));
  };

  useEffect(() => {
    if (textareaElement.current) {
      textareaElement.current.focus();
    }
  }, [mode]);

  return (
    <CommentCardContainer>
      <CommentCardContainerColumn>
        <MerpAvatar
          firstName={firstName}
          lastName={lastName}
          size={32}
        />
      </CommentCardContainerColumn>
      <CommentCardContainerColumn style={{ width: '100%' }}>
        <Form
          onSubmit={onSubmit}
        >
          {({ formProps, submitting }) => (
            <form {...formProps}>
              <Field
                name="text"
              >
                {() => {
                  if (mode === 'view') {
                    return (
                      <TextField
                        placeholder={t('orderDetails.activity.addAComment')}
                        onClick={() => setMode('edit')}
                        testId="createCommentPlaceholder"
                        isDisabled={freeExpired}
                      />
                    );
                  }
                  return (
                    <TextArea
                      ref={textareaElement}
                      value={value}
                      onBlur={onCancel}
                      onChange={(e) => setValue(e.currentTarget.value)}
                      isCompact
                      testId="createCommentEditor"
                      onPointerEnterCapture={undefined}
                      onPointerLeaveCapture={undefined}
                    />
                  );
                }}
              </Field>
              {mode === 'edit'
            && (
            <FormFooter align="start">
              <Button
                appearance="primary"
                style={{ marginRight: 10 }}
                isDisabled={submitting}
                type="submit"
              >
                {t('orderDetails.activity.save')}
              </Button>
              <Button
                appearance="default"
                onClick={onCancel}
              >
                {t('orderDetails.activity.cancel')}
              </Button>
            </FormFooter>
            )}
            </form>
          )}
        </Form>
      </CommentCardContainerColumn>
    </CommentCardContainer>
  );
};

export default CommentCreator;
