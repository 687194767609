import React from 'react';
import { colors } from '@atlaskit/theme';
import styled from 'styled-components';
import Flag from '@atlaskit/flag';

import InfoIcon from '@atlaskit/icon/glyph/info';
import Wrapper from './ListView/Wrapper';

const Container = styled.div`
  box-sizing:border-box;
  width:100%;
  margin-top:20px;
  border-radius:5px;
  display:flex;
  min-height:200px;
  h2{
      font-size:1.25em;
      color:${colors.subtleText};
      padding:10px;
      display:block;
      margin:auto;
  }
`;

const Nofound = ({
  showProducts,
  showSynnexProducts,
  showSynnex,
  showEbay,
}: {

  showProducts: boolean,
  showSynnexProducts: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showSynnex: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showEbay: any,
}) => (
  <>
    <Wrapper>
      <div className="productSearch">
        {showProducts && (
        <Flag
          icon={(<InfoIcon primaryColor={colors.B300} label="Info" />)}
          description={(
            <>
              <p>Searching from third party data feed(For Computer Tablet &amp; Networking)</p>
            </>
             )}
          id="1"
          key="1"
          title="Can't find your product in the result?"
          actions={[
            {
              content: 'Search Now',
              onClick: () => { showSynnex(); },
            },
          ]}
        />
        )}
        {showSynnexProducts && (
        <Flag
          icon={(<InfoIcon primaryColor={colors.B300} label="Info" />)}
          id="1"
          key="1"
          title="Can't find your product in the result?"
          actions={[
            {
              content: 'Switch back',
              onClick: () => { showEbay(); },
            },
          ]}
        />
        )}
      </div>
    </Wrapper>
    <Container data-testid="no-found">
      <h2>There are no results for your search.</h2>
    </Container>

  </>

);

export default Nofound;
