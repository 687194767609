import styled, { css } from 'styled-components';
import { typography } from '@atlaskit/theme';

interface Props {
  padding?: string;
  margin?: string;
}

export const H1 = styled.div<Props>`${typography.h100()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H2 = styled.div<Props>`${typography.h200()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H3 = styled.div<Props>`${typography.h300()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H4 = styled.div<Props>`${typography.h400()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H5 = styled.div<Props>`${typography.h500()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H6 = styled.div<Props>`${typography.h600()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H7 = styled.div<Props>`${typography.h700()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H8 = styled.div<Props>`${typography.h800()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
export const H9 = styled.div<Props>`${typography.h900()}; ${({ margin }) => margin && css`margin: ${margin}`}`;
