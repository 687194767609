import React from 'react';
import SectionMessage from '@atlaskit/section-message';

export default () => (
  <SectionMessage title="Instructions" appearance="information">
    <ol>
      <li>
        Open
        {' '}
        <a href="https://myfastway.com.au/" target="_blank" rel="noopener noreferrer">Aramex Couriers</a>
        {' '}
        and sign in
      </li>
      <li>Navigate to Administration - API Keys</li>
      <li>Create an api key for MERP</li>
      <li>Enter the client ID and secret below</li>
      <li>Click Save & Connect Button</li>
    </ol>
  </SectionMessage>
);
