import React, { useEffect } from 'react';
// graphql
import { useLazyQuery } from '@apollo/react-hooks';
// component
import Spinner from '@atlaskit/spinner';
import Textfield from '@atlaskit/textfield';
import ClickAwayListener from 'react-click-away-listener';
import categoryOptionsCreator from './helpers';
// types
import { SearchProductProps, OptionType, QueryVariables } from './types';
// styles
import SearchBoxWrap from './styles/SearchBoxWrap';
import InputWrap from './styles/InputWrap';
// local component
import CategoryOptions from './CategoryOptions';
import SearchResult from './SearchResult';
import SearchResultContainer from './styles/SearchResultContainer';
// querys
import taxonomyGetCategorySuggestions from '../../graphql/ebay/queries/taxonomyGetCategorySuggestions';
import catalogSearch from '../../graphql/ebay/queries/catalogSearch';
// state
const Loading = () => (
  <SearchResultContainer>
    <div style={{ margin: 'auto' }}>
      <Spinner size={50} />
    </div>
  </SearchResultContainer>
);
const SearchProduct = (props: SearchProductProps) => {
  const {
    placeholder = 'Enter product name, UPC, ISBN, ePID etc',
    inputStyle = {},
    onComplete, // Required. the final call back function to pass epid
    onSelectProductCategory,
    onSearchComplete,
    className,
  } = props;

  /** ****START OF category suggetion related STATE AND METHOD ******* */
  const [inputValue, setInputValue] = React.useState<string>('');
  const [timer, setTimer] = React.useState<number>();
  const [disableDropdown, setDisableDropdown] = React.useState<boolean>(true);
  // for ebay category suggestion
  const [loadEbayCategory, ebayCategoryState] = useLazyQuery(
    taxonomyGetCategorySuggestions,
    {
      variables: { q: inputValue },
      fetchPolicy: 'network-only',
    },
  );
  React.useEffect(() => {
    // on new category suugest data receied , open the dropdown
    if (ebayCategoryState.data) {
      setDisableDropdown(false);
    }
  }, [ebayCategoryState.data]);

  const handleUserInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const q = e.currentTarget.value;
    setInputValue(q);
    clearInterval(timer);
    if (q.trim().length > 0) {
      const timerExec = setTimeout(() => {
        loadEbayCategory({
          variables: { q },
        });
      }, 1000);
      setTimer(
        Number(timerExec),
      );
    }
  };
  /** ****END OF category suggetion related STATE AND METHOD ******* */
  /** ****SEARCH PRODUCT RELATED ******* */
  const [searchEbayProducts, ebayProductsState] = useLazyQuery(catalogSearch,
    {
      variables: {
        q: inputValue,
      },
    });

  useEffect(() => {
    if (ebayProductsState.loading === false && ebayProductsState.data) {
      onSearchComplete(ebayProductsState.data);
    }
  }, [ebayProductsState.loading, ebayProductsState.data, onSearchComplete]);

  const handleProductSearch = (variables: QueryVariables) => {
    setDisableDropdown(true);
    clearInterval(timer);// clear the timer otherwise delayed fetch may triger the dropdown open
    // to stop alreaddy triggered category suggestion query.this prevent dropdown showing up after search result come.
    if (ebayCategoryState?.client) {
      ebayCategoryState.client.stop();
    }
    searchEbayProducts({
      variables,
    });
  };

  const handleUserEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim().length > 0) {
      handleProductSearch({
        q: inputValue,
      });
    }
  };

  const handleOptionClick = (option: OptionType, keyword: string) => {
    // console.log('option click event triggered, options:');
    // console.log(option);
    handleProductSearch({
      q: keyword,
      categoryId: option.value,
    });
  };
  /** ****END OF SEARCH PRODUCT RELATED ******* */
  return (
    <>
      {/* <Heading role="heading">{heading}</Heading> */}
      <SearchBoxWrap className={`search-box-wrap ${ebayProductsState.loading || ebayProductsState.data ? '' : 'screenCenter'}`}>
        <InputWrap className="input-wrap">

          <Textfield
            name="search"
            placeholder={placeholder}
            style={inputStyle}
            className={className}
            onChange={(e) => (handleUserInputChange(e))}
            value={inputValue}
            type="text"
            aria-label="Search"
            onKeyPress={(e) => (handleUserEnterKey(e))}
            isDisabled={ebayProductsState.loading}
            autoComplete="off"
            testId="ebay-product-search-input"
          />

          {ebayCategoryState.data
            && (
            <>
              <ClickAwayListener onClickAway={() => setDisableDropdown(true)}>
                <CategoryOptions
                  query={ebayCategoryState?.variables?.q}
                  inputValue={inputValue}
                  disabled={disableDropdown}
                  options={categoryOptionsCreator(ebayCategoryState.data)}
                  handleClick={handleOptionClick}
                />
              </ClickAwayListener>
            </>
            )}
        </InputWrap>
        {/* <Button
          appearance="primary"
          style={searchButtonStyle}
          isDisabled={ebayProductsState.loading
            || inputValue.trim().length === 0}
          onClick={() => handleProductSearch({ q: inputValue })}
          isLoading={ebayProductsState.loading}
          testId="ebay-product-search-button"
        >
          {searchButtonText}
        </Button> */}
      </SearchBoxWrap>
      {ebayProductsState.loading && <Loading />}
      {ebayProductsState.data
        && (
          <SearchResult
            data={ebayProductsState.data}
            onComplete={onComplete}
            inputValue={inputValue}
            onSelectProductCategory={onSelectProductCategory}
          />
        )}
      {ebayProductsState.error
        && <span>{ebayProductsState.error.message}</span>}
    </>
  );
};

export default SearchProduct;
