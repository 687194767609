import styled from 'styled-components';

export const TickeDetailHeader = styled.h1`
  margin-bottom: 60px;
  margin-top: 40px !important;
`;

export const TicketCommentsContainer = styled.div`
  margin-left: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;
`;
export const TicketCommentsListingContainer = styled.div`
  width: 100%;
`;
export const TicketCommentFormContainer = styled.div`
  z-index: 99;
  background: white;
  position:sticky;
  bottom: 0px;
  width: 100%;
`;

export const TicketsSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TicketsStatisticsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TicketsSearchBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TicketsRecordContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TicketRecordTitle = styled.h5`
  margin-right: 5px;
`;

export const StatusContainer = styled.div`
  height: 20px;
  width: 20px;
  background: ${(props) => props.color};
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
`;

export const TicketLayoutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
