import React from 'react';
import PreviewShipping from '../PreviewShipping';
import ShippingMethod from '../ShippingMethod';
import ManualShip from '../ManualShip';

import CancelButton from '../Buttons/CancelButton';
import { Buttons } from '../../Shipping.style';
import ManualShipConfirmButton from '../Buttons/ManualShipConfirmButton';

export default ({ onShipped }:{onShipped:()=>void}) => (
  <>
    <PreviewShipping />
    <ShippingMethod />
    <ManualShip />
    <Buttons>
      <CancelButton />
      <ManualShipConfirmButton onShipped={onShipped} />
    </Buttons>
  </>
);
