import styled from 'styled-components';
import { elevation } from '@atlaskit/theme';

export const Wrapper = styled.div`
  ${elevation.e100}  
  border-radius: 5px;
  padding: 20px;
  min-width: 600px;
  tbody {
    font-size:0.825rem;
    tr {
      border-bottom:1px solid #ccc;
      &:hover{
        background-color:#eaeaea;
      }
      td{
        padding:10px 0px 10px 0px;
        font-size:0.825rem;
      }
    }
  }
`;

export const Table = styled.table`
  padding: 2rem;
  font-size:0.825rem;
  width:100%;
  tr {
    &:hover{
      background-color:#e1e1e1;      
      cursor:pointer;
    }
    td{
      padding:5px;
      &:nth-child(1) {
      background-color:#e1e1e1;
    }
     &:nth-child(2) {
       padding-left:10px;      
    }
    }
    

  }
`;

export const HeadingWrap = styled.div`
display:flex;
justify-content:space-between;
a button {
  font-size:1rem;
  align-items:center;
}
`;
