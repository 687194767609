/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import Button from '@atlaskit/button/new';
import styled from 'styled-components';
import ExcelSpreadsheet16Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/16';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import CustomizeModal from '../Modal/CustomizedModal';
import { ProgressBarContainer } from '../Styles/styles';
import addNotification from '../../utils/addNotification';
import { useExportOrdersMutation } from '../../graphql/types';

const ExportBtnContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }

  &.margin-above {
    margin-top: 32px;
  }

  &.margin-below {
    margin-bottom: 30px;
  }

  &.margin-left {
    margin-left: -30px;
  }
`;

const ExportReport = ({ values, teamId }:{ values: any, teamId: string}) => {
  const {
    dateSelectedValue,
    teamChannelInput,
    advancedFilterType,
    advancedFilterInputs,
  } = values;
  const date = dateSelectedValue.value;
  let teamChannelIDs: never[] = [];
  if (teamChannelInput !== null && !teamChannelInput.isEmpty) {
    teamChannelIDs = teamChannelInput.map((tc : any) => tc.value);
  }

  const customerName = advancedFilterType?.value === 'customerName' ? advancedFilterInputs : undefined;
  const customerEmail = advancedFilterType?.value === 'customerEmail' ? advancedFilterInputs : undefined;
  const username = advancedFilterType?.value === 'username' ? advancedFilterInputs : undefined;
  const orderNumber = advancedFilterType?.value === 'orderNumber' ? advancedFilterInputs : undefined;
  const productName = advancedFilterType?.value === 'productName' ? advancedFilterInputs : undefined;
  const sku = advancedFilterType?.value === 'sku' ? advancedFilterInputs : undefined;

  const [downloading, setDownloading] = useState(false);
  const [exportOrdersMutation] = useExportOrdersMutation();
  const exportOrder = () => {
    setDownloading(true);
    exportOrdersMutation({
      variables: {
        teamId,
        date,
        teamChannelIDs,
        customerName,
        customerEmail,
        username,
        orderNumber,
        productName,
        sku,
      },
    })
      .then((response) => {
        setDownloading(false);
        const url = response?.data?.ExportOrders?.downloadUrl;
        if (!url) {
          addNotification('No order for download', 'danger');
        } else {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.click();
        }
      })
      .catch(() => {
        setDownloading(false);
        addNotification('No order for download', 'danger');
      });
  };
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={exportOrder}
        appearance="primary"
        isLoading={downloading}
        testId="exportOrderButton"
      >
        {t('orders.left.button.exportOrder')}
      </Button>
    </>
  );
};

export default ExportReport;
