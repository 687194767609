import styled from 'styled-components';

export const CommentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
export const CommentsLable = styled.label`
  font-size: 1.42em;
  font-weight: 500;
`;

export const CommentRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top:24px;
`;

export const CommentContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 18px;
`;

export const CommentContentTitleContainer = styled.div`

`;

export const CommentContentDetailContainer = styled.div`
  margin-top: 10px;
`;

export const ReadViewContainer = styled.div`
  line-height: ___CSS_0___;
  min-height: ___CSS_1___px;
  padding: ___CSS_2___px ___CSS_3___px;
  word-break: break-word;
`;

export const FormContainer = styled.div`
  margin-left: 18px;
  margin-top: -6px;
  width: 50%;
`;
export const MessageWrapper = styled.div`
  position: fixed;
  left: 40px;
  bottom: 40px;
`;
