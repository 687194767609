import React from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import styled from 'styled-components';
import isEmail from 'validator/lib/isEmail';
import queryString from 'query-string';
import { isString } from 'lodash';
import { Box, Logo } from '../components';
import graphQLErrorsReader from '../utils/graphQLErrorsReader';
import { useAuth } from '../utils/useAuth';
import ForgotPasswordForm, { ForgotPasswordFormData } from '../components/Signin/ForgotPasswordForm';
import ResetPasswordFeedback from '../components/Signin/ResetPasswordFeedback';

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const ForgotPassword = () => {
  const [serverResponseError, setServerResponseError] = React.useState('');
  const { sendPasswordResetEmail } = useAuth();
  const [isEmailSent, setIsEmailSent] = React.useState(false);
  const [emailSentTo, setEmailSentTo] = React.useState('');
  let { message } = queryString.parse(window.location.search);
  if (isString(message) && message === 'expired_token') {
    message = 'The recovery link you selected has expired. Re-submit your email address to receive a new recovery link.';
  } else {
    message = '';
  }

  const handleResetPassword = async (formData: ForgotPasswordFormData) => {
    const err = {
      email:
        isEmail(formData.email)
          ? undefined
          : 'Email is invalid',
    };
    if (err.email) {
      return err;
    }

    await sendPasswordResetEmail({
      email: formData.email,
    })
      .then((email) => {
        setEmailSentTo(email!);
        setIsEmailSent(true);
      })
      .catch((errors: ApolloError) => {
        setServerResponseError(graphQLErrorsReader(errors));
      });
    return undefined;
  };

  return (
    <Wrapper>
      <Logo />
      <Box style={{ padding: '40px', minHeight: 100, marginTop: '1rem' }}>
        {!isEmailSent
        && (
        <ForgotPasswordForm
          handleResetPassword={handleResetPassword}
          error={serverResponseError}
          message={message}
        />
        )}
        {isEmailSent
        && <ResetPasswordFeedback emailSentTo={emailSentTo} />}
      </Box>
    </Wrapper>
  );
};

export default ForgotPassword;
