import React from 'react';
import styled from 'styled-components';
import { Product } from '../ProductsListing/types';
import noimage from '../../assets/svg/noimage.svg';
import unescapeHtml from '../../utils/unescapeHtml';

const ProductTitleContainer = styled.div`
    display:flex;
    align-items:center;
    img {
      height:36px;
      width:36px;
      object-fit:cover;
    }
    >* {
      margin-right:5px;
    }
    margin:5px 0px 5px 0px;
    a {
      color:inherit;
    }
    a:link:focus, :visited:focus {
      outline: none;
    }
`;

interface Props {
  product:Product,
}

export default ({ product }:Props) => {
  const [img] = product.images;

  return (
    <ProductTitleContainer>
      <img src={img ? img.url : noimage} alt={product.name} />
      <a
        href={`/product/${product.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {unescapeHtml(product.name).substr(0, 70)}
      </a>
    </ProductTitleContainer>
  );
};
