import styled from 'styled-components';

const CenteredOverlay = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 9999;
`;

export default CenteredOverlay;
