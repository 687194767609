import styled from 'styled-components';

const AddImageBtn = styled.div`
  font-size: 1.5em;
  color: grey;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  &:hover {
    background: #ebebeb;
  }
`;

export default AddImageBtn;
