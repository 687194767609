import {
  FastwayPackageType, FastwayGetQuoteQuery, FastwaySatchelSize, ItemInput,
} from '../../../../../graphql/types';
import client from '../../../../../graphql/client';
import getQuoteQuery from '../../../../../graphql/fastway/queries/getQuote';

interface FastwayQuoteParams {
  name: string,
  teamId:string,
  phone: string,
  email: string,
  street: string,
  suburb: string,
  state: string,
  postcode: string,
  items: ItemInput[],
}

export default ({
  name, phone, email, street, suburb, state, postcode,
  items, teamId,
}: FastwayQuoteParams) => {
  const quoteOptions = [
    {
      packageType: FastwayPackageType.Package,
      satchelSize: null,
    },
    {
      packageType: FastwayPackageType.Satchel,
      satchelSize: FastwaySatchelSize.A2,
    },
    {
      packageType: FastwayPackageType.Satchel,
      satchelSize: FastwaySatchelSize.A3,
    },
    {
      packageType: FastwayPackageType.Satchel,
      satchelSize: FastwaySatchelSize.A4,
    },
    {
      packageType: FastwayPackageType.Satchel,
      satchelSize: FastwaySatchelSize.A5,
    },
  ];
  const fastwayQuotesPromises = quoteOptions
    .map((quoteOption) => client.query<FastwayGetQuoteQuery>({
      query: getQuoteQuery,
      variables: {
        teamId,
        consignment: {
          quantityBox: items.length,
          packageType: quoteOption.packageType,
          name,
          phone,
          email,
          street,
          suburb,
          state,
          postcode,
          items,
          satchelSize: quoteOption.satchelSize,
        },
      },
    }));
  return Promise.all(fastwayQuotesPromises);
};
