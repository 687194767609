import React from 'react';
import { useParams } from 'react-router-dom';
import queryString from 'query-string';
import AmazonConnector from '../components/Amazon/AmazonConnector/AmazonConnector';

export default () => {
  const { teamChannelID } = useParams<{teamChannelID : string}>();
  let { redirectPath } = queryString.parse(window.location.search);
  if (!redirectPath) redirectPath = '/';

  return (
    <AmazonConnector
      teamChannelId={teamChannelID}
      redirectPath={redirectPath as string} // the path to be redirected after oAuth
    />
  );
};
