import React, { SyntheticEvent } from 'react';
import Textfield from '@atlaskit/textfield';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { useTranslation } from 'react-i18next';

interface KeyWordFilterProps {
  value:string,
  searchNow:()=>void,
  onSearchTextChange:(e: SyntheticEvent<HTMLInputElement>)=>void,
  handleUserEnterKey: (e: React.KeyboardEvent<HTMLInputElement>) =>void,
  onBlur: (event: SyntheticEvent<HTMLInputElement>) => void,
  width?:number | undefined,
  placeholder?:string,
  isDisabled?:boolean,
  onFocus?:()=>void,
  testId:string,
}

const SearchBox = (props: KeyWordFilterProps) => {
  const { t } = useTranslation();
  const {
    value,
    onSearchTextChange,
    handleUserEnterKey,
    width = 120,
    placeholder = 'orders.left.search',
    isDisabled = false,
    onBlur,
    testId,
    ...otherProps
  } = props;
  return (
    <Textfield
      value={value}
      placeholder={t(placeholder)}
      key="search"
      isDisabled={isDisabled}
      style={{ width }}
      elemBeforeInput={<EditorSearchIcon size="medium" label="search" />}
      onChange={onSearchTextChange}
      onBlur={onBlur}
      onKeyPress={handleUserEnterKey}
      testId={testId}
      {...otherProps}
    />
  );
};
SearchBox.defaultProps = {
  width: 120,
  placeholder: 'orders.left.search',
  isDisabled: false,
  onFocus: () => {},
};
export default SearchBox;
