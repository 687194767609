import React, { useState } from 'react';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';

interface ModalProps {
  isOpen: boolean,
  onClose: () => void,
  onConfirm: () => void,
  testid?: string,
}

export const ConfirmRemoveItemModal = ({
  isOpen, onClose, onConfirm, testid,
}: ModalProps) => (
  <ModalTransition>
    {isOpen && (
      <Modal
        onClose={onClose}
        width="small"
      >
        <ModalHeader>
          <ModalTitle appearance="danger">Delete an item</ModalTitle>
        </ModalHeader>
        <ModalBody>
          Deleting an item will remove it from the form.
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} appearance="default">Cancel</Button>
          <Button appearance="danger" onClick={() => { onConfirm(); onClose(); }} testId={`${testid ? (`${testid}-`) : ''}delete-item`}>Delete</Button>
        </ModalFooter>
      </Modal>
    )}
  </ModalTransition>
);

ConfirmRemoveItemModal.defaultProps = {
  testid: undefined,
};

const RemoveButtonContainer = styled.div`
  display: flex;
  justify-content:center;
  align-items:center;
`;

interface Props {
  onRemove: () => void,
  testid?: string,
  isDisabled?: boolean,
}

const RemoveButton = ({ onRemove, testid, isDisabled = false }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  const handleOnRemove = () => {
    onRemove();
    onClose();
  };

  return (
    <>
      <Button
        appearance="link"
        onClick={onOpen}
        testId={`${testid ? (`${testid}-`) : ''}remove-button`}
        isDisabled={isDisabled}
      >
        <RemoveButtonContainer><EditorRemoveIcon label="remove" primaryColor="grey" size="large" /></RemoveButtonContainer>
      </Button>
      <ConfirmRemoveItemModal
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleOnRemove}
        testid={testid}
      />
    </>

  );
};
RemoveButton.defaultProps = {
  testid: undefined,
  isDisabled: false,
};

export default RemoveButton;
