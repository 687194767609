import styled from 'styled-components';

export const TicketCommentsListingContainer = styled.div`
  display: flex;
  flex-direction:column;
`;

export const ScrollButton = styled.div`
  width: 100%;
  z-index: 9999;
  position: fixed;
  margin-left: 100px;
  bottom: 240px;
`;
