import React, { useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import Button from '@atlaskit/button/new';
import Textarea from '@atlaskit/textarea';
import SuccessIcon from '@atlaskit/icon/glyph/check-circle';
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import { G300 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import { store } from 'react-notifications-component';
import { useCreateCommentMutation } from '../../graphql/types';
import { useTicket } from '../TicketsContainer/TicketsContainer';

import {
  FormContainer,
  LabelContainer,
  ErrorMsg,
  ButtonContainer,
  ButtonItem,
} from './TicketCommentForm.styles';

interface TicketProps {
  teamID: string,
  ticketStatus: string | undefined,
  ticketId: string,
  assigneeId: string,
  refetch: Function,
}

const submitActions = [
  {
    key: 'open',
    label: 'Submit As Open',
  },
  {
    key: 'pending',
    label: 'Submit As Pending',
  },
  {
    key: 'solved',
    label: 'Submit As Solved',
  },
  {
    key: 'closed',
    label: 'Submit As Closed',
  },
];

export default (props: TicketProps) => {
  const [errorMessage, setErrorMsg] = useState<string>();
  const {
    teamID,
    ticketStatus,
    ticketId,
    assigneeId,
    refetch,
  } = props;
  const [createCommentMutation] = useCreateCommentMutation();
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useTicket();
  const onSubmit = (action: string | undefined) => {
    if (!comment) {
      setErrorMsg('Reply should not be empty.');
      return;
    }
    setIsLoading(true);
    const variables = {
      teamID,
      ticketId,
      assigneeId,
      action: action || 'open',
      authorId: assigneeId,
      body: comment,
    };
    createCommentMutation({
      variables,
    }).then(() => {
      setIsLoading(false);
      setComment('');
      refetch();
      const { ticket } = state;
      dispatch({
        type: 'setTicket',
        payload: {
          ...ticket,
          status: action,
        },
      });
      store.addNotification({
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 3000,
        },
        content: <Flag
          icon={<SuccessIcon primaryColor={G300} label="Info" />}
          description="You have sent message successfully"
          id="1"
          key="1"
          title="Reply Message"
        />,
      });
    }).catch((err) => {
      const msg = 'server internal error' || err.graphQLErrors[0]?.message;
      setErrorMsg(msg);
    });
  };
  const statusItem = submitActions.find((item) => item.key === ticketStatus) || submitActions[0];
  const statusOtherItems = submitActions.filter((item) => item.key !== ticketStatus);
  if (statusItem.key === 'closed') {
    return null;
  }
  return (
    <FormContainer>
      <LabelContainer>* Reply</LabelContainer>
      <Textarea
        name="comment"
        value={comment}
        testId="test-comment"
        onChange={(e) => {
          setErrorMsg('');
          setComment(e.target.value);
        }}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />
      {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
      <ButtonContainer>
        <ButtonItem>
          <Button
            type="submit"
            appearance="primary"
            isLoading={isLoading}
            onClick={() => onSubmit(ticketStatus)}
            testId={`submit-as-${ticketStatus}-btn`}
          >
            {statusItem.label}
          </Button>
        </ButtonItem>
        <DropdownMenu
          key={uuidV4()}
          trigger="button"
          placement="top-end"
        >
          <DropdownItemGroup>
            {
              statusOtherItems.map((item) => (
                <DropdownItem key={uuidV4()} onClick={() => onSubmit(item.key)}>
                  {item.label}
                </DropdownItem>
              ))
            }
          </DropdownItemGroup>
        </DropdownMenu>
      </ButtonContainer>
    </FormContainer>
  );
};
