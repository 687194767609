import React from 'react';
import Textfield from '@atlaskit/textfield';
import { Field, ErrorMessage } from '@atlaskit/form';
import { useShipping } from '../Shipping';
import { PreviewShipmentContainer } from '../Shipping.style';
import { Shipment } from '../types';

const ShippingWeight = () => {
  const { state, dispatch } = useShipping();

  const isDisabled = (state.ui.stage !== 'preview') && (state.ui.stage !== 'quote');

  const handleShipmentChange = (value:Shipment) => dispatch({ type: 'setShipment', payload: value });

  /**
   * Given index, e(event) to update each boxes's weight
   * @param index
   * @param e
   */
  const handleTotalWeightChange = (
    index: number,
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const newBoxes = state.shipment.boxes;
    const { value } = e.currentTarget;

    newBoxes[index].weight = value;
    newBoxes[index].isWeightInvalid = Number.isNaN(Number(value))
      || Number(value) <= 0 || value.length === 0;

    handleShipmentChange(
      {
        ...state.shipment,
        boxes: newBoxes,
      },
    );

    // if change weight,
    // update state to preview
    // so the button will change to 'quote'
    dispatch({
      type: 'setStage',
      payload: 'preview',
    });

    // if change weight,
    // clear previous quote
    // so don't confuse people

    dispatch({
      type: 'setQuotes',
      payload: [],
    });
  };

  React.useEffect(() => {
    let isTotalWeightInvalid = false;

    if (!(Number(state.shipment.totalWeight) > 0)) {
      state.shipment.isTotalWeightInvalid = true;
      isTotalWeightInvalid = true;
    }
    handleShipmentChange(
      {
        ...state.shipment,
        isTotalWeightInvalid,
      },
    );
  }, []); //eslint-disable-line

  return (
    <PreviewShipmentContainer>
      <div className="section summary">
        <div className="block weight" style={{ width: 300 }}>
          <Field
            name="totalWeight"
            label="Total weight (KG)"
            isRequired
          >
            {() => (
              <>
                {state.shipment.boxes.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div style={{ marginTop: '10px' }} key={`item_${index}`}>
                    <Textfield
                      value={item.weight}
                      isInvalid={item.isWeightInvalid}
                      onChange={(e) => handleTotalWeightChange(index, e)}
                      isDisabled={isDisabled || state.ui.isSubmiting}
                      isCompact
                      width={150}
                      testId="total-weight"
                    />
                    {
                        (!item.weight
                        || Number(item.weight) <= 0)
                        && (
                        <ErrorMessage>
                          Weight should be greater than 0 Kg.
                        </ErrorMessage>
                        )
                        }
                    {
                        (item.weight
                        && Number(item.weight) > 0)
                        && (
                          <div style={{ marginTop: '30px' }} />
                        )
                    }

                  </div>
                ))}

              </>
            )}
          </Field>
        </div>
      </div>
    </PreviewShipmentContainer>
  );
};

export default ShippingWeight;
