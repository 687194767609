import React from 'react';

const renderItemTitle = (
  name:string,
  sku:string,
) => (
  <div>
    <div>{name}</div>
    {sku && (
    <div className="sku">
      {`SKU: ${sku}`}
    </div>
    )}
  </div>
);

export default renderItemTitle;
