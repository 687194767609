import React, { useState } from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalBody,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import { useForm, Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  LabelContainer,
  ButtonContainer,
  ErrorMsg,
} from './SendInvoiceEmailModal.styles';
import { useSendInvoiceEmailMutationMutation } from '../../graphql/types';

interface SendInvoiceEmailInputProps {
  isOpen: boolean,
  orderId: string,
  close: any, //eslint-disable-line
}

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
interface FormValuesProps {
  emailTo: string,
  subject: string,
  body: string,
}

const SendInvoiceEmailModal = (props: SendInvoiceEmailInputProps) => {
  const { isOpen, close, orderId } = props;
  const { control, handleSubmit, errors } = useForm();
  const [sendInvoiceEmailMutation] = useSendInvoiceEmailMutationMutation();
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [errorMessage, setErrorMsg] = useState<string>();
  const onSubmit = (data: any) => { //eslint-disable-line
    setSpinner(true);
    const { emailTo, subject, body } = data;
    const emails = emailTo.split(';');
    const args = {
      orderId,
      emailTo: emails,
      subject,
      body,
    };
    sendInvoiceEmailMutation({
      variables: {
        args,
      },
    })
      .then(() => {
        setSpinner(false);
        close();
      })
      .catch((err) => {
        const msg = err?.graphQLErrors[0]?.message || 'send email failed';
        setErrorMsg(msg);
        setSpinner(false);
      });
  };
  return (
    <ModalTransition>
      {isOpen && (
        <Modal onClose={close}>
          <ModalHeader>
            <ModalTitle>Send Email With Order/Invoice Attachment</ModalTitle>
          </ModalHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <LabelContainer>*Email Addresses</LabelContainer>
              <Controller
                as={Textfield}
                name="emailTo"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                placeholder="Use ; separate multiple recipients"
                testId="emailTo"
              />
              {errors.emailTo
              && <ErrorMsg data-testid="emails-error-message">email addresses are required</ErrorMsg>}
              <LabelContainer>*Email Subject</LabelContainer>
              <Controller
                as={Textfield}
                name="subject"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                testId="subject"
              />
              {errors.subject
              && <ErrorMsg data-testid="subject-error-message">email subject is required</ErrorMsg>}
              <LabelContainer>*Email Body</LabelContainer>
              <Controller
                as={ReactQuill}
                name="body"
                control={control}
                defaultValue=""
                rules={{ required: true }}
                data-testId="email-body"
              />
              {errors.body
              && <ErrorMsg data-testid="body-error-message">email body is required</ErrorMsg>}
              {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
              <ButtonContainer>
                <Button
                  testId="submit-button"
                  type="submit"
                  appearance="primary"
                  iconAfter={
                  showSpinner ? <Spinner appearance="invert" /> : <></>
                }
                >
                  Submit
                </Button>
                <Button
                  testId="cancel-button"
                  appearance="link"
                  onClick={() => close()}
                >
                  Cancel
                </Button>
              </ButtonContainer>
            </ModalBody>
          </form>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default SendInvoiceEmailModal;
