import React, { useState } from 'react';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { formatNumber } from 'accounting';
import DynamicTable from '@atlaskit/dynamic-table';
import styled from 'styled-components';
import EditorRemoveIcon from '@atlaskit/icon/glyph/editor/remove';
import Button from '@atlaskit/button/new';
import Modal, {
  ModalTransition, ModalHeader, ModalFooter, ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { Order } from '../../OrdersListing/types';
import MerpDateTime from '../../MerpDateTime/MerpDateTime';
import formatMoneyWithPrefix from '../../../helpers/formatMoney';
import PdfPrintButton from '../../PdfPrintButton/PdfPrintButton';
import { useDeleteShipmentMutation } from '../../../graphql/types';
import addNotification from '../../../utils/addNotification';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import ShippingButton from '../../Shipping/Shipping';

const ShipmentsContainer = styled.div`
    margin-bottom:20px; 
    .head {
      width:100%;
      display:flex;
      justify-content:space-between;
      align-items:center;
    }
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom:10px;
    } 
`;

interface DeleteShipmentButtonProps {
  shipmentId: string,
}
const DeleteShipmentButton = (
  { shipmentId }: DeleteShipmentButtonProps,
) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const open = () => setIsOpen(true);

  const [deleteShipmentMutation, { loading }] = useDeleteShipmentMutation();

  const onConfirmDelete = async () => {
    await deleteShipmentMutation({
      variables: {
        shipmentId,
      },
    })
      .catch((error) => {
        addNotification(graphQLErrorsReader(error), 'danger');
      })
      .finally(() => close());
  };

  return (
    <>
      <Button
        appearance="subtle"
        onClick={open}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <EditorRemoveIcon label="delete" />
        </div>
      </Button>
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={close}
            width="small"
          >
            <ModalHeader>
              <ModalTitle>shipment</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>
                You will mark the shipment as deleted.
              </p>
            </ModalBody>
            <ModalFooter>
              <Button appearance="default" onClick={close}>Cancel</Button>
              <Button appearance="danger" testId="delete-shipment" isLoading={loading} onClick={onConfirmDelete}>Delete</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>

    </>
  );
};

interface Props {
  order: Order,
  markOrderShipped: () => void,
}

export default ({ order, markOrderShipped }: Props) => {
  const { shipments } = order;
  const shipmentHead: HeadType = {
    cells: [
      {
        content: 'Date',
      },
      {
        content: 'Courier',
      },
      {
        content: 'Track number',
      },
      {
        content: 'Weight (kg)',
      },
      {
        content: 'Cost (Inc.)',
      },
      {
        content: 'Label',
      },
      {
        content: 'Action',
      },
    ],
  };
  const shipmentRows: Array<RowType> = shipments.map((cur) => (
    {
      cells: [
        { content: <MerpDateTime>{cur.createdAt}</MerpDateTime> },
        { content: cur.courier.name },
        // eslint-disable-next-line no-nested-ternary
        { content: cur.active ? (cur.trackNumber !== 'undefined' ? cur.trackNumber : '') : <del>{cur.trackNumber}</del> },
        { content: cur.totalWeight > 0 ? formatNumber(cur.totalWeight) : '' },
        { content: cur.totalCost > 0 ? formatMoneyWithPrefix(cur.totalCost) : '' },
        { content: cur.labelUrl.length > 0 ? <PdfPrintButton url={cur.labelUrl} /> : '' },
        { content: cur.active ? <DeleteShipmentButton shipmentId={cur.id} /> : '' },
      ],
    }));
  if (shipments.length > 0) {
    return (
      <ShipmentsContainer>
        <div className="head">
          <h5>Shipments</h5>
          <div><ShippingButton order={order} onShipped={markOrderShipped} isAlwaysClickable overrideText="Add" /></div>
        </div>
        <DynamicTable
          head={shipmentHead}
          rows={shipmentRows}
        />
      </ShipmentsContainer>
    );
  }
  return <></>;
};
