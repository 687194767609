import React from 'react';
import { useSpring, animated } from 'react-spring';

const withFadeIn = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => ({
    ...props
  }) => {
    const style = useSpring({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    });
    return (
      <animated.div style={style}>
        <Component
          {...props}
        />
      </animated.div>
    );
  };

export default withFadeIn;
