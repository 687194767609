import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../utils/useAuth';
import TopSkuSalesChart from '../components/Report/TopSkuSalesChart';
import TopCustomerSalesChart from '../components/Report/TopCustomerSalesChart';
import Wrapper from '../components/ListView/Wrapper';

export default function Report() {
  const { state: { currentTeamId } } = useAuth();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <div className="heading">
        <h2>{t('home.report')}</h2>
      </div>
      <div style={{
        maxWidth:
                 1580, // width
        marginTop: 30,
      }}
      >
        { currentTeamId && <TopSkuSalesChart teamId={currentTeamId} />}
        { currentTeamId && <TopCustomerSalesChart teamId={currentTeamId} />}
      </div>
    </Wrapper>
  );
}
