import styled from 'styled-components';

export const PlainText = styled.div`
  font-weight:bold;
  color:#545452;
  text-align:left;
  overflow:hidden;
`;

export const HighlightedText = styled(PlainText)`
  background-color:#f7f7d5;
  font-weight:normal;
`;

export const FieldGroup = styled.div`
  display:flex;
  width:100%;
`;

export const FieldItem = styled.div`
  width:50%;
`;
