import React from 'react';
import { Field } from '@atlaskit/form';
import { Controller, Control } from 'react-hook-form';
import { CreatableSelect } from '@atlaskit/select';
import _ from 'lodash';
import { ErrorMsg } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import { ErrorType, SingleOption } from './types';

const CreatableSelectWithLabelAndError = ({
  name, label, isRequired, control, errors,
  defaultValue, setValue, isMulti, optionsArr,
  isDisable,
}: {
  name: string, label: string, isRequired: boolean,
  control: Control<Record<string, string>>,
  errors: ErrorType, defaultValue: string | Array<string>, setValue: Function,
  isMulti: boolean, optionsArr: Array<SingleOption>, isDisable: boolean
}) => {
  const parsedDefaultValue = defaultValue && Array.isArray(defaultValue)
    ? defaultValue.map((option) => ({
      label: option, value: option,
    })) : [];
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        // eslint-disable-next-line no-nested-ternary
        validate: (value) => (isRequired
          ? (isMulti ? _.get(value, 'length', 0) >= 1
            : _.get(value, 'length', 0) === 1) : _.get(value, 'length', 0) >= 0),
      }}
      // eslint-disable-next-line no-nested-ternary
      defaultValue={_.isEmpty(parsedDefaultValue)
        ? []
        : (isMulti ? parsedDefaultValue : [parsedDefaultValue[0]])}
      as={(
        <>
          <Field
            key={name}
            name={name}
            isRequired={isRequired}
            label={label}
          >
            {() => (
              <CreatableSelect
                isDisabled={isDisable}
                onChange={(valObj) => {
                  // eslint-disable-next-line no-nested-ternary
                  const val = isMulti
                    ? valObj
                    : (_.isNull(valObj) ? [] : [valObj]);
                  setValue(name, val, { shouldValidate: true });
                }}
                isClearable
                isMulti={isMulti}
                options={optionsArr}
                // eslint-disable-next-line no-nested-ternary
                defaultValue={_.isEmpty(parsedDefaultValue)
                  ? []
                  : (isMulti ? parsedDefaultValue : [parsedDefaultValue[0]])}
              />
            )}
          </Field>
          {_.get(errors, name) && (
          <ErrorMsg>
            {label}
            {' '}
            is required
          </ErrorMsg>
          )}
        </>
      )}
    />
  );
};

export default CreatableSelectWithLabelAndError;
