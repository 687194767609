import React from 'react';
import Button from '@atlaskit/button/new';
import Select from '@atlaskit/select';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { useHistory } from 'react-router-dom';
import _, { isUndefined } from 'lodash';
import Form, {
  ErrorMessage,
  Field,
  FormSection,
  FormFooter,
  FormHeader,
} from '@atlaskit/form';
import EbayConfigorContainier from './EbayConfigor.styles';
import { EbayConfigorFormProps, EbayConfigorProps, OptionType } from './types';
import { ValueType } from '../../../node_modules/@types/react-select/src/types.d';
import {
  useQueryFulfilmentPoliciesQuery,
  useQueryPaymentPoliciesQuery,
  useQueryReturnPoliciesQuery,
  useFindOrCreateLocationQuery,
  useUpdateTeamChannelMutation,
} from '../../graphql/types';
import {
  mapFulfillmentsOptions,
  mapPaymentsOptions,
  mapReturnsOptions,
  mapLocationssOptions,
} from './helpers';
import { useAuth } from '../../utils/useAuth';
import { getTeamChannelLists } from '../../utils/reducer';

const EbayConfigor = (props: EbayConfigorProps) => {
  const { teamChannelID, redirectPath } = props;
  const { dispatch, state } = useAuth();
  const { currentTeamId, teams } = state;

  let defaultPolicies = {
    fulfillmentPolicy: {
      label: '',
      value: '',
    },
    paymentPolicy: {
      label: '',
      value: '',
    },
    returnPolicy: {
      label: '',
      value: '',
    },
    merchantLocation: {
      label: '',
      value: '',
    },
  };

  try {
    const tc = getTeamChannelLists(currentTeamId!, teams)
      .filter((cur) => cur.id === teamChannelID)[0];
    defaultPolicies = {
      ...defaultPolicies,
      fulfillmentPolicy: {
        label: tc.settings.fulfillmentPolicy.name,
        value: tc.settings.fulfillmentPolicy.id,
      },
      paymentPolicy: {
        label: tc.settings.paymentPolicy.name,
        value: tc.settings.paymentPolicy.id,
      },
      returnPolicy: {
        label: tc.settings.returnPolicy.name,
        value: tc.settings.returnPolicy.id,
      },
      merchantLocation: {
        label: tc.settings.merchantLocation.name,
        value: tc.settings.merchantLocation.id,
      },
    };
  } catch (error) {
    // do nothing
  }

  const [serverErr, setServerErr] = React.useState('');
  const history = useHistory();
  // fulfillments policies
  const fulfilmentsState = useQueryFulfilmentPoliciesQuery({
    variables: {
      teamChannelId: teamChannelID,
    },
  });
  const [fulfilmentsOptions, setFulfilmentsOptions] = React.useState([]);
  const [selectedFulfilmentName, setSelectedFulfilmentName] = React.useState('');
  // payments policies
  const paymentsState = useQueryPaymentPoliciesQuery({
    variables: {
      teamChannelId: teamChannelID,
    },
  });
  const [selectedPaymentName, setSelectedPaymentName] = React.useState('');
  const [paymentsOptions, setPaymentsOptions] = React.useState([]);
  // refund policies
  const returnsState = useQueryReturnPoliciesQuery({
    variables: {
      teamChannelId: teamChannelID,
    },
  });
  const [selectedReturnName, setSelectedReturnName] = React.useState('');
  const [returnsOptions, setReturnsOptions] = React.useState([]);
  // locations
  const locationsState = useFindOrCreateLocationQuery({
    variables: {
      teamChannelId: teamChannelID,
    },
  });
  const [selectedLocationName, setSelectedLocationName] = React.useState('');
  const [locationsOptions, setLocationsOptions] = React.useState([]);
  // save mutation
  const [saveConfig] = useUpdateTeamChannelMutation();
  /** side effects */
  // fulfilments
  React.useEffect(() => {
    setFulfilmentsOptions(mapFulfillmentsOptions(fulfilmentsState.data));
  }, [fulfilmentsState.data]);
  // payments
  React.useEffect(() => {
    setPaymentsOptions(mapPaymentsOptions(paymentsState.data));
  }, [paymentsState.data]);
  // returns
  React.useEffect(() => {
    setReturnsOptions(mapReturnsOptions(returnsState.data));
  }, [returnsState.data]);
  // locations
  React.useEffect(() => {
    setLocationsOptions(mapLocationssOptions(locationsState.data));
  }, [locationsState.data]);

  const handleSaveConfigs = async (data: EbayConfigorFormProps) => {
    const err = {
      fulfillmentPolicyId:
        !data.fulfillmentPolicyId
          ? 'Fullfilment policy is required'
          : undefined,
      paymentPolicyId:
        !data.paymentPolicyId
          ? 'Rayment policy is required'
          : undefined,
      returnPolicyId:
        !data.returnPolicyId
          ? 'Return policy is required'
          : undefined,
      merchantLocationKey:
        !data.merchantLocationKey
          ? 'Merchant Location is required. If no options available, please create one in eBay.'
          : undefined,
    };
    if (
      err.fulfillmentPolicyId
      || err.paymentPolicyId
      || err.returnPolicyId
      || err.merchantLocationKey
    ) {
      return err;
    }

    await saveConfig({
      variables: {
        teamChannelID,
        ...data,
        fulfillmentPolicyName: selectedFulfilmentName,
        paymentPolicyName: selectedPaymentName,
        merchantLocationName: selectedLocationName,
        returnPolicyName: selectedReturnName,
        onBoarded: true,
      },
    })
      .then(() => {
        // update local state
        // fulfilement
        dispatch({
          type: 'saveFulfillmentPolicy',
          savePoliciesPayload: {
            teamId: currentTeamId!,
            teamChannelId: teamChannelID,
            policyId: data.fulfillmentPolicyId,
            value: selectedFulfilmentName,
          },
        });
        // payment
        dispatch({
          type: 'savePaymentPolicy',
          savePoliciesPayload: {
            teamId: currentTeamId!,
            teamChannelId: teamChannelID,
            policyId: data.paymentPolicyId,
            value: selectedPaymentName,
          },
        });
        // return policy
        dispatch({
          type: 'saveReturnPolicy',
          savePoliciesPayload: {
            teamId: currentTeamId!,
            teamChannelId: teamChannelID,
            policyId: data.returnPolicyId,
            value: selectedReturnName,
          },
        });
        // merchant local
        dispatch({
          type: 'saveMerchantLocation',
          savePoliciesPayload: {
            teamId: currentTeamId!,
            teamChannelId: teamChannelID,
            policyId: data.merchantLocationKey,
            value: selectedLocationName,
          },
        });

        if (isUndefined(redirectPath)) {
          // if redirectPath assume is on onboarding wizard, so redirect to target martket place
          history.push(`/marketplace/${teamChannelID}?link_state=true`);
        } else {
          // if redirectPath, use the customized redirect url
          history.push(redirectPath);
        }
      })
      .catch((errors: ApolloError) => (setServerErr(errors.message)));
    return undefined;
  };

  const handleValueChange = (
    selected: ValueType<OptionType, boolean>,
    fn: (value: string) => void,
    field:'fulfillment'|'return'|'payment'|'location',
  ) => {
    fn(_.get(selected, 'value', ''));
    const label = _.get(selected, 'label', '');
    switch (field) {
      case 'fulfillment': {
        setSelectedFulfilmentName(label);
        break;
      }
      case 'return': {
        setSelectedReturnName(label);
        break;
      }
      case 'payment': {
        setSelectedPaymentName(label);
        break;
      }
      case 'location': {
        setSelectedLocationName(label);
        break;
      }
      default: {
        break;
      }
    }
  };

  return (
    <EbayConfigorContainier>
      <Form<EbayConfigorFormProps>
        onSubmit={handleSaveConfigs}
      >
        {({ formProps, submitting }) => (
          <form {...formProps}>
            <FormHeader title="Default eBay policies" />
            <FormSection>
              <Field
                name="fulfillmentPolicyId"
                label="Fulfillment Policy"
                defaultValue=""
                isRequired
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select<OptionType>
                      options={fulfilmentsOptions}
                      defaultValue={defaultPolicies.fulfillmentPolicy}
                      isLoading={fulfilmentsState.loading}
                      placeholder="Choose a fulfillment Policy"
                      onChange={(selected) => (
                        handleValueChange(
                          selected,
                          fieldProps.onChange,
                          'fulfillment',
                        )
                      )}
                    />
                    {error && <ErrorMessage testId="fulfillmentPolicyIdErr">{error}</ErrorMessage>}
                  </>
                )}
              </Field>
              <Field
                name="paymentPolicyId"
                label="Payment Policy"
                defaultValue=""
                isRequired
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select<OptionType>
                      options={paymentsOptions}
                      defaultValue={defaultPolicies.paymentPolicy}
                      isLoading={paymentsState.loading}
                      placeholder="Choose a Payment Policy"
                      onChange={(selected) => (
                        handleValueChange(
                          selected,
                          fieldProps.onChange,
                          'payment',
                        )
                      )}
                    />
                    {error && <ErrorMessage testId="paymentPolicyIdErr">{error}</ErrorMessage>}
                  </>
                )}
              </Field>
              <Field
                name="returnPolicyId"
                label="Return Policy"
                defaultValue=""
                isRequired
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select<OptionType>
                      options={returnsOptions}
                      defaultValue={defaultPolicies.returnPolicy}
                      isLoading={returnsState.loading}
                      placeholder="Choose a Return Policy"
                      onChange={(selected) => (
                        handleValueChange(
                          selected,
                          fieldProps.onChange,
                          'return',
                        )
                      )}
                    />
                    {error && <ErrorMessage testId="paymentPolicyIdErr">{error}</ErrorMessage>}
                  </>
                )}
              </Field>
              <Field
                name="merchantLocationKey"
                label="Merchant Location"
                defaultValue=""
                isRequired
              >
                {({ fieldProps, error }) => (
                  <>
                    <Select<OptionType>
                      options={locationsOptions}
                      defaultValue={defaultPolicies.merchantLocation}
                      isLoading={locationsState.loading}
                      placeholder="Choose a Merchant Location Key"
                      onChange={(selected) => (
                        handleValueChange(
                          selected,
                          fieldProps.onChange,
                          'location',
                        )
                      )}
                    />
                    {error && <ErrorMessage testId="paymentPolicyIdErr">{error}</ErrorMessage>}
                  </>
                )}
              </Field>
            </FormSection>
            {serverErr && <ErrorMessage testId="serverErrMsg">{serverErr}</ErrorMessage>}
            <FormFooter>
              <Button
                type="submit"
                appearance="primary"
                isDisabled={submitting}
                isLoading={submitting}
              >
                Save
              </Button>
            </FormFooter>
          </form>
        )}
      </Form>
    </EbayConfigorContainier>
  );
};

export default EbayConfigor;
