import { useGetTeamChargeAliveQuery } from '../../graphql/types';
import { useAuth } from '../../utils/useAuth';

export default function CheckChargeType() {
  const { state } = useAuth();
  const teamId = state.currentTeamId || '';

  const { data } = useGetTeamChargeAliveQuery(
    {
      variables: { teamId },
      // to avoid still show cached data after update when switching team
      fetchPolicy: 'network-only',
    },
  );

  const teamCharge = data?.GetTeamChargeAlive.teamCharge;

  if (teamCharge === null) {
    return null;
  }
  const teamChargeType = data?.GetTeamChargeAlive.teamCharge?.chargeType;
  const teamChargeStatus = data?.GetTeamChargeAlive.teamCharge?.status;
  const activeEnd = data?.GetTeamChargeAlive.teamCharge?.activeEndAt;
  const chargeID = data?.GetTeamChargeAlive.teamCharge?.id;
  const monthPlan = data?.GetTeamChargeAlive.teamCharge?.monthPlan;
  const teamChargePlanId = data?.GetTeamChargeAlive.teamCharge?.teamChargePlanId;

  return {
    teamChargeType, teamChargeStatus, activeEnd, chargeID, monthPlan, teamChargePlanId,
  };
}
