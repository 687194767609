import React from 'react';
import Button from '@atlaskit/button/new';
import { useShipping } from '../../Shipping';
import {
  useCreateShipmentMutation,
  useAuspostCreateShipmentMutation,
  useAuspostCreateLabelsMutation,
  useFastwayCreateShipmentMutation,
  useFastwayCreateLabelMutation,
  FastwayPackageType,
  FastwaySatchelSize,
  ItemInput,
  CreateItem,
} from '../../../../graphql/types';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';
import { useAuth } from '../../../../utils/useAuth';
import {
  CreateDomesticShipmentsResponse,
  CreateLabelsResponse,
} from '../../types';

/**
 * this component will be used when
 * auspost intergration is on.file
 *
 * the outcome of this component:
 * 1. create auspost shipment
 * 2. get auspost label
 * 3. create merp shipment,
 * 4. chage the state.ui.stage === postShipment
 * 5. set state.postShipment  = MERPshipment
 */

const IntergratedCreateShipmentButton = () => {
  const { state, dispatch } = useShipping();
  const { state: { currentTeamId: teamId } } = useAuth();
  const [createMerpShipment] = useCreateShipmentMutation();
  const [createAuspostShipment] = useAuspostCreateShipmentMutation();
  const [createAuspostLabel] = useAuspostCreateLabelsMutation();
  const [createFastwayShipment] = useFastwayCreateShipmentMutation();
  const [createFastwayLabel] = useFastwayCreateLabelMutation();

  const {
    boxes,
    orderId,
    selectedProductId,
    courierId,
    totalCost: cost,
    notes,
  } = state.shipment;

  const { order } = state;

  const items: Array<CreateItem> = boxes.map((cur) => ({
    product_id: selectedProductId.split('-')[0],
    length: Number(cur.length),
    width: Number(cur.width),
    height: Number(cur.height),
    weight: Number(cur.weight),
  }));

  const itemsFast: Array<ItemInput> = boxes.map((cur) => ({
    length: Number(cur.length),
    width: Number(cur.width),
    height: Number(cur.height),
    weight: Number(cur.weight),
  }));

  let isDisabled = true;
  items.forEach((key) => {
    isDisabled = (() => !![
      Number(key.length) > 0,
      Number(key.height) > 0,
      Number(key.width) > 0,
      Number(key.weight) > 0,
    ].includes(false))();
  });

  isDisabled = !![
    state.shipment.courierId.length > 0,
  ].includes(false);

  const auspostCreateShipment = async () => {
    /**
     * 1. create auspost shipment
     */
    await createAuspostShipment({
      variables: {
        createShip: {
          teamId: teamId!,
          orderId,
          notesOnLabel: notes,
          items,
        },
      },
    })
      .then(({ data }) => {
        const {
          response,
        }:{
          response:CreateDomesticShipmentsResponse
        } = data!.AuspostCreateShipment;

        const trackingNumber: string[] = [];

        response.shipments[0].items.forEach((sp) => {
          trackingNumber.push(sp.tracking_details.article_id);
        });

        const shipmentId = response.shipments[0].shipment_id;

        return {
          trackingNumber,
          shipmentId,
          externalRes: response,
        };
      })
      /**
       * 2.get auspost labels
       */
      .then(async ({ shipmentId, trackingNumber, externalRes }) => {
        const labels = await createAuspostLabel({
          variables: {
            teamId: teamId!,
            shipmentIds: [shipmentId],
          },
        });
        const { data } = labels;
        const {
          response,
        }:{
          response:CreateLabelsResponse
        } = data!.AuspostCreateLabels;

        const labelUrl = response.labels[0].url;
        return {
          labelUrl,
          trackingNumber,
          shipmentId,
          externalRes,
        };
      })
      /**
       * 3.create Merp shipment
       */
      .then(async ({
        trackingNumber: trackNumber,
        labelUrl,
        shipmentId: externalId,
        externalRes,
      }) => {
        let totalWeight = 0;
        items.forEach((item) => {
          totalWeight += item.weight;
        });
        const merpShipment = await createMerpShipment({
          variables: {
            shipment: {
              orderId,
              trackNumber,
              courierId,
              cost: Number(cost),
              weight: Number(totalWeight),
              labelUrl,
              externalId,
              externalRes: JSON.stringify(externalRes),
            },
          },
        });

        return merpShipment;
      })
      /**
       * 4.  change the state.ui.stage === postShipment
       */
      .then(({ data }) => {
        const merpShipment = data?.CreateShipment.shipment;
        dispatch({
          type: 'setPostShipment',
          payload: merpShipment,
        });
        dispatch({
          type: 'setStage',
          payload: 'postShipment',
        });
      })
      .catch((err) => {
        dispatch({ type: 'setError', payload: graphQLErrorsReader(err) });
      })
      .finally(() => {
        dispatch({ type: 'setIsSubmiting', payload: false });
        dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
      });
  };

  const fastwayCreateShipment = async () => {
    const [type, satchelSize] = selectedProductId.split('-');
    const packageType = type.toLowerCase() === 'package'
      ? FastwayPackageType.Package
      : FastwayPackageType.Satchel;

    const {
      name, street, suburb, state: stateOrProvince,
      postcode, email: shippingEmail,
    } = order.shippingAddress;
    const { email } = order.teamCustomer;
    const phone = order.shippingAddress.mobile || order.shippingAddress.phone;

    /**
     * 1. create auspost shipment
     */
    await createFastwayShipment({
      variables: {
        teamId: teamId!,
        consignment: {
          quantityBox: itemsFast.length,
          packageType,
          name,
          phone,
          email: shippingEmail || email,
          street,
          suburb,
          state: stateOrProvince,
          postcode,
          items: itemsFast,
          notesOnLabel: notes,
          satchelSize: satchelSize as FastwaySatchelSize || undefined,
        },
      },
    })
      .then(({ data }) => {
        const {
          conId,
          trackingNumber,
          externalResponse,
        } = data!.FastwayCreateShipment;
        return {
          trackingNumber,
          conId,
          externalRes: externalResponse,
        };
      })
    /**
     * 2.get fastway labels
     */
      .then(async ({ conId, trackingNumber, externalRes }) => {
        const labels = await createFastwayLabel({
          variables: {
            teamId: teamId!,
            conId,
          },
        });
        const { data } = labels;
        const { url } = data!.FastwayCreateLabel;
        return {
          labelUrl: url,
          trackingNumber,
          conId,
          externalRes,
        };
      })
    /**
     * 3.create Merp shipment
     */
      .then(async ({
        trackingNumber: trackNumber,
        labelUrl,
        conId: externalId,
        externalRes,
      }) => {
        let newWeight = 0;
        for (let i = 0; i < externalRes.items.length; i++) {
          newWeight += externalRes.items[i].weightDead;
        }
        const merpShipment = await createMerpShipment({
          variables: {
            shipment: {
              orderId,
              trackNumber: [trackNumber],
              courierId,
              cost: Number(cost),
              weight: Number(newWeight),
              labelUrl,
              externalId,
              externalRes: JSON.stringify(externalRes),
            },
          },
        });
        return merpShipment;
      })

    /**
     * 4.  change the state.ui.stage === postShipment
     */
      .then(({ data }) => {
        const merpShipment = data?.CreateShipment.shipment;
        dispatch({
          type: 'setPostShipment',
          payload: merpShipment,
        });
        dispatch({
          type: 'setStage',
          payload: 'postShipment',
        });
      })
      .catch((err) => {
        dispatch({ type: 'setError', payload: graphQLErrorsReader(err) });
      })
      .finally(() => {
        dispatch({ type: 'setIsSubmiting', payload: false });
        dispatch({ type: 'setIsPrimaryButtonDisabled', payload: false });
      });
  };

  const createShipment = async () => {
    dispatch({ type: 'setIsSubmiting', payload: true });
    dispatch({ type: 'setIsPrimaryButtonDisabled', payload: true });
    dispatch({ type: 'setError', payload: '' });

    if (state.integration.selectedIntegration === 'fastway') {
      await fastwayCreateShipment();
    }

    if (state.integration.selectedIntegration === 'auspost') {
      await auspostCreateShipment();
    }
  };

  return (
    <Button
      appearance="primary"
      isDisabled={isDisabled || state.ui.isSubmiting}
      onClick={createShipment}
      testId="IntergratedShipmentConfirm"
      isLoading={state.ui.isSubmiting}
    >
      Confirm
    </Button>
  );
};

export default IntergratedCreateShipmentButton;
