import React from 'react';
import styled from 'styled-components';

export const EbayImporterContainier = styled.div`
  img {
    display:block;
    height:50px;
    margin-left:auto;
    margin-right:auto;
    margin-bottom:3em;
  }
  .row {
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
    align-items:center;
    align-content:center;
    margin:10;
    padding:10px;

    .action {
      margin:10px;
    }
    .progress{
    /* border:1px dotted #ccc; */
    padding:10px;
    }
  }
  .action {
    margin-top:100px;
    display:flex;
    justify-content:center;
    button {
      padding-left:50px;
      padding-right:50px;
    }
  }
`;

export const SubHeadingContainer = styled.div`
  display:flex;
  margin-bottom:10px;
  justify-content:space-between;
  .name {
    font-weight:500;
    display:block;
  }
  .msg {
    display:block;
  }
`;

export const ProgressBarContainer: React.CSSProperties = {
  boxSizing: 'border-box',
  width: 350,
  display: 'flex',
  alignItems: 'center',
};
