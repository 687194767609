import React from 'react';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import ErrorSection from '../ErrorSection/ErrorSection';

export default (
  { error }:{error:ApolloError},
) => (
  <ErrorSection>
    {graphQLErrorsReader(error)}
  </ErrorSection>
);
