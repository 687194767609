import React from 'react';
import SectionMessage from '@atlaskit/section-message';

interface Props {
  children:React.ReactNode,
}
export default ({ children }:Props) => (
  <SectionMessage
    title="It appears to be something wrong."
    appearance="error"
  >
    {children}
  </SectionMessage>
);
