import React from 'react';
import styled from 'styled-components';
import Button from '@atlaskit/button/new';
import axios from 'axios';
import shopify from '../../assets/svg/shopify.svg';
import { ShopifyOauthParam } from '../../pages/Home';

const REACT_APP_GRAPHQL_SERVER_HOST = process.env.REACT_APP_GRAPHQL_SERVER_HOST
  || 'http://localhost:4000';

const ShopifyConnectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  img {
    display: block;
    height: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
  }

  button {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

interface ShopifyConnectorProps {
  teamChannelId: string,
  shopifyUrl: string,
}

const ShopifyConnector = (props: ShopifyConnectorProps) => {
  const [isLoading, setIsloading] = React.useState(false);
  const { teamChannelId, shopifyUrl } = props;
  const state: ShopifyOauthParam = {
    teamChannelId,
    shopifyUrl,
  };

  const storeConnect = async () => {
    setIsloading(true);
    const res = await axios.get(
      `${REACT_APP_GRAPHQL_SERVER_HOST}/oauth/shopify/url?state=${JSON.stringify(state)}`,
    );
    const { url } = res.data;
    if (url) {
      window.location.assign(url);
    }
  };

  return (
    <ShopifyConnectorContainer>
      <div>
        <img src={shopify} alt="shopify" data-testid="shopifyLogo" />
        <Button
          appearance="primary"
          onClick={storeConnect}
          isLoading={isLoading}
          testId="connectshopifyButton"
        >
          Connect
        </Button>
      </div>
    </ShopifyConnectorContainer>
  );
};

export default ShopifyConnector;
