import gql from 'graphql-tag';

const fastwayGetQuote = gql`
query FastwayGetQuote($teamId:String!,$consignment:FastwayConsignmentInput!){
  FastwayGetQuote(teamId:$teamId,consignment:$consignment){
    status,
    quote{
      priceInc,
      description,
      packageType,
      satchelSize,
      courierId,
    }
  }
}
`;

export default fastwayGetQuote;
