import React from 'react';
import { AspectsListProps } from './types';

export default ({ aspects, limit, compact = false }: AspectsListProps) => {
  const defaultLimit = limit || aspects.length;
  const numOfStr = (compact ? 15 : 100);

  if (aspects?.length > 0) {
    return (
      <>
        {aspects.slice(0, defaultLimit).map(((aspect) => (
          <dl key={aspect.localizedName}>
            <dt>
              {aspect.localizedName.length > numOfStr
                ? `${aspect.localizedName.substr(0, numOfStr)}...`
                : aspect.localizedName}
              :
            </dt>
            <dd>
              {aspect.localizedValues.toString().length > numOfStr
                ? `${aspect.localizedValues.toString().substr(0, numOfStr)}...`
                : aspect.localizedValues.toString()}
            </dd>
          </dl>
        )))}
      </>
    );
  }
  return (<></>);
};
