import styled from 'styled-components';

const Footer = styled.div`
    margin-top:20px;
    display:flex;
    justify-content:flex-end;
    >*{
      margin-left:10px;
    }
`;

const SectionHeading = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  width:100%;
`;

const SummaryTag = styled.div`
  display:flex;
  justify-content:flex-end;
  font-weight:500;
`;

export {
  Footer,
  SectionHeading,
  SummaryTag,
};
