import styled from 'styled-components';

export const TickeDetailHeader = styled.h1`
  margin-left: 40px;
  margin-bottom: 60px;
  margin-top: 40px !important;
`;

export const TicketDetailContainer = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;

export const TicketSummaryInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 160px;
`;

export const TicketCommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
`;
export const TicketCommentsListingContainer = styled.div`
`;
export const TicketCommentFormContainer = styled.div`
  z-index: 99;
  background: white;
  position:sticky;
  bottom: 0px;
  width: 100%;
`;
