import React from 'react';
import Modal, {
  ModalTransition, ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';

import Button from '@atlaskit/button/standard-button';

interface PlanUpgradeInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
}

const PlanUpgrade = (props: PlanUpgradeInputProps) => {
  const {
    isOpen, close,
  } = props;

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={close}
          width="medium"
          shouldCloseOnEscapePress={false}
          shouldCloseOnOverlayClick={false}
        >
          <ModalHeader>
            <ModalTitle>Want to continue use?</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <p>
              Your current plan has expired, please visit the
              {' '}
              <a href="/Billing" rel="noopener noreferrer" style={{ color: '#00000', fontWeight: 'bold' }}>
                billing page
              </a>
              {' '}
              to reactivate your account.
            </p>
          </ModalBody>

          <ModalFooter>
            <Button onClick={close}>
              Close
            </Button>
          </ModalFooter>
        </Modal>

      )}
    </ModalTransition>

  );
};

export default PlanUpgrade;
