/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useRef, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import request from 'superagent';
import { ErrorMsg } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import unescapeHtml from '../../utils/unescapeHtml';
import HiddenFileInput from '../ImageUploader/HiddenFileInput';

// inline Font
// @ts-ignore
ReactQuill.Quill.register(ReactQuill.Quill.import('attributors/style/font'), true);
// @ts-ignore
ReactQuill.Quill.register(ReactQuill.Quill.import('attributors/style/direction'), true);
// @ts-ignore
ReactQuill.Quill.register(ReactQuill.Quill.import('attributors/style/align'), true);

const RTEWithLabelAndError = ({
  // @ts-ignore
  name, label, isRequired, control, errors, defaultValue, setValue, isDisable,
}) => {
  const bounceSetValue = _.debounce((val) => {
    setValue(name, val, { shouldValidate: true });
  }, 500);
  const unescapedHtml = unescapeHtml(defaultValue);

  const fileInput = useRef<HTMLInputElement>(null);
  const editorRef = useRef<ReactQuill>(null);

  const pickImages = () => {
    (fileInput!.current! as HTMLInputElement).click();
  };

  const onPhotoSelected = (files: FileList | null) => {
    const title = 'merp';
    const cloudName = process.env.REACT_APP_CLOUDINARY_NAME || '';
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET || '';
    if (files) {
      Array.from(files).forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            // Disable editor when uploading an image
            if (editorRef) {
              editorRef.current!.getEditor().enable(false);
            }
          }
        };
        reader.readAsDataURL(file);
        request
          .post(url)
          .field('upload_preset', uploadPreset)
          .field('file', file)
          .field('multiple', true)
          .field('tags', title ? `myphotoalbum,${title}` : 'myphotoalbum')
          .field('context', title ? `photo=${title}` : '')
          .end((error, response) => {
            // Append image to body & enable editor
            if (editorRef) {
              const imgUrl = response.body.secure_url;
              const editor = editorRef.current!.getEditor();
              const range = editor.getSelection();
              const cursorPosition = range ? range.index : 0;
              editor.insertEmbed(cursorPosition, 'image', imgUrl);
              editor.insertText(cursorPosition + 1, '\n', 'api');
              editor.setSelection(cursorPosition + 2, 1);
              editor.enable(true);
            }
          });
      });
    }
  };

  useEffect(() => {
    if (editorRef) {
      const toolbar = editorRef.current!.getEditor().getModule('toolbar');
      toolbar.addHandler('image', pickImages);
    }
  }, []);

  const modules = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote', 'code-block'],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ direction: 'rtl' }], // text direction

        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ['link', 'image'],

        ['clean'], // remove formatting button
      ],
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={unescapedHtml}
      rules={{
        required: isRequired,
      }}
      as={(
        <>
          <HiddenFileInput
            type="file"
            id="fileupload"
            accept="image/*"
            multiple
            ref={fileInput}
            onChange={() => onPhotoSelected(fileInput!.current!.files)}
          />
          <ReactQuill
            defaultValue={unescapedHtml}
            onChange={(html) => {
              bounceSetValue(html);
            }}
            ref={editorRef}
            modules={modules}
            readOnly={isDisable}
          />
          {errors[name] && (
          <ErrorMsg>
            {label}
            {' '}
            is required
          </ErrorMsg>
          )}
        </>
    )}
    />
  );
};

export default RTEWithLabelAndError;
