import React, { SyntheticEvent } from 'react';
import Textfield from '@atlaskit/textfield';
import EditorSearchIcon from '@atlaskit/icon/glyph/editor/search';
import { useTicket } from './TicketsContainer';

const GlobalSearch = () => {
  const { state, dispatch } = useTicket();

  const handleChange = (e: SyntheticEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    dispatch({
      type: 'setKeyword',
      payload: val,
    });
  };
  return (
    <Textfield
      name="searchTxt"
      value={state.searchKey}
      style={{ width: 300 }}
      placeholder="Search SKU / Order No / Subject / Requester"
      autoFocus
      elemBeforeInput={<EditorSearchIcon size="medium" label="search" />}
      onChange={handleChange}
    />
  );
};

export default GlobalSearch;
