/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';
import Modal, {
  ModalTransition, ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Textfield from '@atlaskit/textfield';
import Button from '@atlaskit/button/standard-button';

interface CallToConfirmModalProps {
  heading: string,
  message: React.ReactNode,
  helpMessage: string,
  onConfirm: Function,
  onValidate: (userInput: string) => boolean,
  drawToActionComponent: (onClick: () => void) => JSX.Element,
}

const CallToConfirmModal = ({
  heading,
  message,
  helpMessage,
  onConfirm,
  onValidate,
  drawToActionComponent,
}: CallToConfirmModalProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [userInput, setUserInput] = React.useState('');
  const isDisabled = !onValidate(userInput);

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  // const actions = [
  //   {
  //     text: <>Confirm</>,
  //     isDisabled,
  //     onClick: handleConfirm,
  //     testId: 'confirmButton',
  //   },
  //   {
  //     text: <>Cancel</>,
  //     onClick: () => setIsOpen(false),
  //   },
  // ];

  return (
    <>
      {drawToActionComponent(() => setIsOpen(true))}
      {isOpen}
      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={() => setIsOpen(false)}
            width="medium"
          >
            <ModalHeader>
              <ModalTitle appearance="danger">{heading}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              {message}
              <div style={{ marginTop: 10 }}>
                <Textfield
                  placeholder={helpMessage}
                  onChange={(e) => setUserInput(e.currentTarget.value)}
                  testId="confirmInput"
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <Button appearance="subtle" onClick={handleConfirm}>
                Confirm
              </Button>
              <Button appearance="primary" autoFocus onClick={() => setIsOpen(false)}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default CallToConfirmModal;
