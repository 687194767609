import React from 'react';
import styled from 'styled-components';
import Textfield from '@atlaskit/textfield';
import { Field } from '@atlaskit/form';
import { isArray } from 'lodash';
import { useShipping } from '../Shipping';
import { Shipment } from '../types';

const ManualShipmentContainer = styled.div`
  margin-top:20px;
  color: rgb(23, 43, 77);
  .section {
    h5{
    font-size:12px;
    font-weight:600;
    margin-bottom:10px;
    }
  }     
`;

const ManualShipment = () => {
  const { state, dispatch } = useShipping();

  const handleTrackingNumChange = (
    e:React.FormEvent<HTMLInputElement>,
  ) => {
    const newShipment:Shipment = {
      ...state.shipment,
      trackingNumber: e.currentTarget.value,
    };

    dispatch({ type: 'setShipment', payload: newShipment });
  };

  const handleTotalCostChange = (
    e: React.FormEvent<HTMLInputElement>,
  ) => {
    const { value } = e.currentTarget;
    const newShipment:Shipment = {
      ...state.shipment,
      totalCost: value,
      isTotalCostInvalid: !(Number(value) > 0),
    };

    dispatch({ type: 'setShipment', payload: newShipment });
  };

  const selectedCourier = (() => {
    const { shippingMethod } = state;
    if (isArray(shippingMethod)) {
      return shippingMethod.slice(-1)[0].label;
    }
    return '';
  })();

  return (
    <ManualShipmentContainer>
      <div className="section inputs">
        <h5>Shipment:</h5>
        <div className="block">
          <Field
            name="selectedCourier"
            label="Selected Courier"
          >
            {() => (
              <div>
                {selectedCourier}
              </div>
            )}
          </Field>
          <Field
            name="trackingNumber"
            label="Tracking Number"
            isRequired
          >
            {() => (
              <>
                <Textfield
                  value={state.shipment.trackingNumber}
                  isDisabled={state.ui.isSubmiting}
                  onChange={handleTrackingNumChange}
                  isCompact
                  testId="tracking-number"
                />
              </>
            )}
          </Field>
          <Field
            name="totalCost"
            label="Total cost"
            isRequired
          >
            {() => (
              <>
                <Textfield
                  value={state.shipment.totalCost}
                  isInvalid={state.shipment.isTotalCostInvalid}
                  isDisabled={state.ui.isSubmiting}
                  onChange={handleTotalCostChange}
                  isCompact
                  testId="total-cost"
                />
              </>
            )}
          </Field>
        </div>
      </div>
    </ManualShipmentContainer>
  );
};

export default ManualShipment;
