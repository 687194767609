import React from 'react';

type Props = {
  // eslint-disable-next-line react/require-default-props
  width?: number,
};

const Logo = ({ width }: Props) => (
  <a href="/" style={{ outline: 'none' }}>
    <img
      src={`${process.env.PUBLIC_URL}/img/logo.png`}
      style={{ width: width || 180, margin: '8px' }}
      alt="Logo"
    />
  </a>
);

export default Logo;
