import React from 'react';
import Button from '@atlaskit/button';
import FileIcon from '@atlaskit/icon/glyph/file';
import { useTranslation } from 'react-i18next';
import {
  useInvoicePdfCreationMutation,
} from '../../../graphql/types';
import addNotification from '../../../utils/addNotification';

interface Props {
  orderId:string,
}

export default ({ orderId: id }:Props) => {
  const [invoicePdfCreation] = useInvoicePdfCreationMutation();
  const pdfPrint = (orderId: string) => {
    invoicePdfCreation({
      variables: {
        orderId,
      },
    }).then((res) => {
      const url = res?.data?.InvoicePdfCreation?.url || '';
      if (!url) {
        addNotification('Order Pdf Creation Failed', 'danger');
      } else {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.click();
      }
    })
      .catch(() => {
        addNotification('Order Pdf Creation Failed', 'danger');
      });
  };
  const { t } = useTranslation();
  return (
    <Button
      iconBefore={<FileIcon label="print" />}
      onClick={() => pdfPrint(id)}
    >
      {t('orderDetails.toolBar.print')}
    </Button>
  );
};
