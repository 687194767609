import React from 'react';
import Spinner from '@atlaskit/spinner';
import { useQueryCategorySubtreeQuery } from '../../graphql/types';

const DisplayCategoryById = ({ categoryId }: {
  categoryId : string
}) => {
  const { data, loading } = useQueryCategorySubtreeQuery({
    variables: {
      categoryId,
    },
  });
  return loading === true ? <Spinner /> : (
    <div data-testid="display-category-name-by-id">
      {data?.QueryEbay.response.categorySubtreeNode.category.categoryName}
    </div>
  );
};

export default DisplayCategoryById;
