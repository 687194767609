import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import WooCommerceConnector from '../components/WooCommerceConnector/WooCommerceConnector';

export default () => {
  const { teamChannelID } = useParams<{teamChannelID : string}>();
  const location = useLocation();
  const wooUrl = location.search.substring(1);
  let { redirectPath } = queryString.parse(window.location.search);
  if (!redirectPath) redirectPath = '/';

  return (
    <WooCommerceConnector
      teamChannelId={teamChannelID}
      wooUrl={wooUrl}
    />
  );
};
