import React from 'react';
import {
  DropdownItem,
  DropdownItemGroup,
} from '@atlaskit/dropdown-menu';
import Popup from '@atlaskit/popup';
import MoreIcon from '@atlaskit/icon/glyph/more';
import Button from '@atlaskit/button';
import ActionContainer from '../styles/ActionContainer';

const ActionButton = (
  { v, handleTableRowClick }:
  {
    v: string,
    handleTableRowClick: () => void
  },
) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClick = ((e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  });
  const onClose = () => {
    setIsOpen(false);
  };
  const onClickViewDetails = () => {
    handleTableRowClick();
    setIsOpen(!isOpen);
  };

  const Content = () => (
    <DropdownItemGroup>
      <ActionContainer>
        <DropdownItem onClick={onClickViewDetails}>{v}</DropdownItem>
      </ActionContainer>
    </DropdownItemGroup>
  );

  return (
    <>
      <Popup
        placement="bottom-start"
        content={Content}
        isOpen={isOpen}
        onClose={onClose}
        testId="settings-popup"
        trigger={(triggerProps) => (
          <Button
            appearance="subtle"
            testId="ActionBtn"
            onClick={onClick}
            {...triggerProps}
          >
            <MoreIcon label="more" size="small" />
          </Button>
        )}
      />

    </>
  );
};

export default ActionButton;
