import React from 'react';
import SendIcon from '@atlaskit/icon/glyph/send';
import { colors } from '@atlaskit/theme';
import {
  FormFooterChildContainer,
  FormTitle,
  Feedback,
} from './ForgotPassword.styles';

interface ForgotPasswordFormProps {
  emailSentTo:string,
}

const ResetPasswordFeedback = (props: ForgotPasswordFormProps) => {
  const { emailSentTo } = props;
  return (
    <>
      <FormTitle>Forgot Your Password?</FormTitle>
      <Feedback>
        <div className="feedback icon"><SendIcon label="recovery link sent" size="xlarge" primaryColor={colors.B100} /></div>
        <div className="feedback msg">We sent a recovery link to you at</div>
        <div className="feedback target">{emailSentTo}</div>
      </Feedback>
      <FormFooterChildContainer>
        <ul>
          <li>
            <a href="/signin">Return to log in</a>
          </li>
          <li>
            <a href="/signin/forgot/">Resend recovery link</a>
          </li>
        </ul>
      </FormFooterChildContainer>
    </>
  );
};

export default ResetPasswordFeedback;
