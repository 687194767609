import React from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';

const MarkAsShippedButton = () => {
  const { state, dispatch } = useShipping();
  const handleClick = () => dispatch({ type: 'setStage', payload: 'pickup' });

  return (
    <Button
      appearance="primary"
      isDisabled={state.ui.isPrimaryButtonDisabled}
      onClick={handleClick}
      testId="MarkAsShippedButton"
    >
      Mark as shipped
    </Button>
  );
};

export default MarkAsShippedButton;
