import React, { useRef } from 'react';
import request from 'superagent';
import { v4 as uuidv4 } from 'uuid';
import { IImage } from './types';
import AddImageBtn from './AddImageBtn';
import HiddenFileInput from './HiddenFileInput';
import FlexCenterContainer from './FlexCenterContainer';
import { supportedImageFormatArr } from './ImageUploader';

export default ({
  onUpdateImage,
}: {
  onUpdateImage: (image: IImage) => void;
}) => {
  const onPhotoSelected = (files: FileList | null) => {
    const title = 'merp';
    const cloudName = process.env.REACT_APP_CLOUDINARY_NAME || '';
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
    const uploadPreset = process.env.REACT_APP_CLOUDINARY_PRESET || '';
    if (files) {
      Array.from(files).forEach((file) => {
        const id = uuidv4();
        const reader = new FileReader();
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            onUpdateImage({
              id,
              loading: true,
              rawSrc: e!.target!.result as string,
            });
          }
        };
        reader.readAsDataURL(file);
        request
          .post(url)
          .field('upload_preset', uploadPreset)
          .field('file', file)
          .field('multiple', true)
          .field('tags', title ? `myphotoalbum,${title}` : 'myphotoalbum')
          .field('context', title ? `photo=${title}` : '')
          .end((error, response) => {
            onUpdateImage({
              id,
              loading: false,
              url: response.body.secure_url,
              isLowRes: response.body.width < 500 && response.body.height < 500,
              isUnSupportedFormat: !supportedImageFormatArr
                .includes(response.body.format),
            });
          });
      });
    }
  };
  const fileInput = useRef<HTMLInputElement>(null);
  return (
    <AddImageBtn
      onClick={() => {
        (fileInput!.current! as HTMLInputElement).click();
      }}
    >
      <HiddenFileInput
        type="file"
        id="fileupload"
        accept="image/*"
        multiple
        ref={fileInput}
        onChange={() => onPhotoSelected(fileInput!.current!.files)}
      />
      <FlexCenterContainer>+</FlexCenterContainer>
    </AddImageBtn>
  );
};
