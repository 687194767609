import React from 'react';
import Button from '@atlaskit/button';
import { useTranslation } from 'react-i18next';
import { DEFAULT_STAT } from '../config/config';

const StatusFilter = (
  { status, onStatusClick }:
  { status: typeof DEFAULT_STAT,
    onStatusClick:(name:string)=>void, },
) => {
  const { t } = useTranslation();
  return (
    <div>
      {status.map((e) => (
        <Button
          key={e.name}
          appearance="subtle"
          isSelected={e.isSelected}
          onClick={() => onStatusClick(e.name)}
          style={{ marginRight: 10 }}
          testId={`status-filter-${e.name.toLowerCase()}`}
          spacing="compact"
        >
          {t(e.name)}
        </Button>
      ))}
    </div>
  );
};

export default StatusFilter;
