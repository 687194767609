import React from 'react';
import { SimpleTag } from '@atlaskit/tag';
import Button from '@atlaskit/button';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { ApolloError } from 'apollo-client';
import { CommonProps } from '../types';
import graphQLErrorsReader from '../../../utils/graphQLErrorsReader';
import ErrorDisplayModal from '../../ErrorDisplayModal';

const StatusTag = (
  { text }:{text:string},
) => (
  <SimpleTag
    appearance="default"
    color={text === 'Success' ? 'green' : 'red'}
    text={text}
  />
);

const StatusFeedback = (
  { lineItem }:CommonProps,
) => {
  if (lineItem.status === 'Failed') {
    return (
      <>
        <ErrorDisplayModal
          heading="Error"
          // message={graphQLErrorsReader(lineItem.error!)}
          message={graphQLErrorsReader(lineItem.error as ApolloError)}
          drawToActionComponent={(onClick) => (
            <Button
              appearance="link"
              onClick={onClick}
              spacing="none"
              iconBefore={<JiraFailedBuildStatusIcon label="failed" primaryColor="red" testId="errorIcon" />}
            >
              <StatusTag text="Click to view" />
            </Button>
          )}
        />
      </>
    );
  }

  return <StatusTag text={lineItem.status} />;
};

export default StatusFeedback;
