import styled from 'styled-components';

const DottedBoarderContainer = styled.div`
  width: 150px;
  height: 150px;
  box-sizing: border-box;
  border: 2px dashed #e6e6e6;
  margin-right: 5px;
  margin-bottom: 5px;
`;

export default DottedBoarderContainer;
