import React from 'react';
import styled from 'styled-components';

const OrderCommentsContainer = styled.div`
    margin-bottom:20px;    
    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin-bottom:10px;
    }
    .content {
      background-color:rgb(251,231,232);
      padding:5px 0px 5px 0px;
    }
`;

export default ({ comments }:{comments:string}) => {
  if (comments) {
    return (
      <OrderCommentsContainer>
        <h5>Customer notes</h5>
        <div className="content">
          {comments}
        </div>
      </OrderCommentsContainer>
    );
  }
  return <></>;
};
