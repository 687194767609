import { isUndefined } from 'lodash';
import { OrdersQuery } from '../../../graphql/types';
/**
 * return a number array for pagination component props
 */
const getPages = (
  d: OrdersQuery | undefined,
  take: number,
) => {
  const totalPages: Array<number> = [];
  if (!isUndefined(d)) {
    for (let index = 1; index < (d?.Orders.total / take + 1); index++) {
      totalPages.push(index);
    }
  }
  return totalPages;
};

export default getPages;
