import styled from 'styled-components';

export const ActivateInvitedUserContainer = styled.div`
  margin-left: 37.5%;
  width: 25%;
`;
export const FormHeader = styled.h3`
  margin-bottom:40px;
`;

export const Label = styled.p`
  font-size: 16px;
  color: black;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;

export const MessageWrapper = styled.div`
  position: fixed;
  left: 40px;
  bottom: 40px;
`;
