import React from 'react';
import Tag from '@atlaskit/tag';
import { useProducts } from '../../ProductsListing';
import { ProductsAdvancedFilterInput } from '../../../../graphql/types';
import { getAdvancedFilterPayload, lookupName } from './helpers';

interface FilterTagProps {
  filter:ProductsAdvancedFilterInput
}
export default ({ filter }:FilterTagProps) => {
  const { filterKey, filterCondition, filterValue } = filter;
  const { state, dispatch } = useProducts();
  const onAfterRemoveAction = () => {
    dispatch({
      type: 'setAdvancedFilter',
      payload: getAdvancedFilterPayload(
        state.query.advancedFilter,
        {
          ...filter,
          filterValue: '',
        },
      ),
    });
  };

  return (
    <Tag
      text={`${lookupName(filterKey)} ${lookupName(filterCondition)} ${lookupName(filterValue)}`}
      removeButtonLabel="Remove"
      isRemovable
      onAfterRemoveAction={onAfterRemoveAction}
      testId={`searchTag-${filterKey}`}
    />
  );
};
