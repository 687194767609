import { isUndefined } from 'lodash';
import { ProductsQuery } from '../../../graphql/types';
/**
 * return a number array for pagination component props
 */
const getPages = (
  d: ProductsQuery | undefined,
  take: number,
) => {
  const totalPages: Array<number> = [];
  if (!isUndefined(d)) {
    for (let index = 1; index < (d?.Products.total / take + 1); index++) {
      totalPages.push(index);
    }
  }
  return totalPages;
};

export default getPages;
