import React from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '@atlaskit/spinner';
import {
  TicketCommentsContainer,
  // TicketSummaryInfoContainer,
  TicketDetailContainer,
  TickeDetailHeader,
  TicketCommentFormContainer,
  TicketCommentsListingContainer,
} from './TicketDetail.styles';
import { useAuth } from '../../utils/useAuth';
import { useGetTicketCommentsQuery } from '../../graphql/types';
import TicketCommentsListing from '../../components/TicketCommentsListing/TicketCommentsListing';
import TicketCommentForm from '../../components/TicketCommentForm/TicketCommentForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function TicketDetailPage() {
  // eslint-disable-next-line react/destructuring-assignment
  const { ticketId } = useParams<{ticketId : string}>();
  const { state: { currentTeamId } } = useAuth();
  const teamId = currentTeamId!;
  const variables = {
    teamID: teamId,
    ticketId,
  };
  const {
    data, error, loading, refetch,
  } = useGetTicketCommentsQuery({ variables });
  if (loading) {
    return (
      <Spinner size="medium" />
    );
  }
  if (error) {
    return <p>Something went wrong!</p>;
  }
  // const assignee = data?.getTicketComments.assignee || '';
  // const requester = data?.getTicketComments.requester || '';
  const assigneeId = data?.getTicketComments.assigneeId || '';
  const comments = data?.getTicketComments.comments;
  const status = data?.getTicketComments.status || 'open';
  return (
    <div>
      <TickeDetailHeader>{`Support Ticket #${ticketId}`}</TickeDetailHeader>
      <TicketDetailContainer>
        <TicketCommentsContainer>
          <TicketCommentsListingContainer>
            <TicketCommentsListing comments={comments} />
          </TicketCommentsListingContainer>
          <TicketCommentFormContainer>
            <TicketCommentForm
              ticketStatus={status}
              assigneeId={assigneeId}
              ticketId={ticketId}
              teamID={teamId}
              refetch={refetch}
            />
          </TicketCommentFormContainer>
        </TicketCommentsContainer>
      </TicketDetailContainer>
    </div>
  );
}
