import styled from 'styled-components';

export const ElementContainer = styled.div`
  padding:2px 20px 5px 20px;
  flex:0 0 50%;
  box-sizing:border-box;
  span {
    font-size:11px;
    color: rgb(107, 119, 140);
  }
  div {
    // leave blank intentianl for completness
  }
  * {
    padding-left:0px !important; 
    padding-bottom:0px !important; 
    margin-top:0px !important;
    margin-bottom:0px !important;    
  }
  >div{
    border-bottom:1px dotted #ccc;
    min-height:35px;    
  }
`;

export const TeamCustomerEditorContainer = styled.div`
  margin-top:10px;
  display:flex;
  overflow:hidden;
  width:100%;  
`;

export const Block = styled.div`
    overflow:hidden;
    flex: 0 0 48%;  
`;

export const VerticalToolBar = styled.div`
    overflow:hidden;
    flex: 0 0 4%;  
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    >:nth-child(1){
       margin-bottom:50px;
    }
   
    
`;

export const BlockItems = styled.div`
    width:100%;
    display:flex;
    flex-wrap:wrap;
    align-items:flex-end;
`;
