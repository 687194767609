import React from 'react';
import styled from 'styled-components';
import Weight from './ShippingWeight';
import Dimensions from './ShippingDimensions';

const WeightAndDimension = styled.div`   
    display:flex;
    align-items:flex-start;
    flex-wrap:nowrap; 
`;

export default () => (
  <WeightAndDimension>
    <Weight />
    <Dimensions />
  </WeightAndDimension>
);
