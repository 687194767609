import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { isUndefined } from 'lodash';
import FindCustomer from './Widgets/FindCustomer/FindCustomer';
import { TeamCustomer, TeamChannel } from './types';
import CreateCustomerMin from '../CreateCustomer/CreateCustomerMin';
import FindCustomerAddButton from './Widgets/FindCustomer/FindCustomerAddButton';
import ChannelSelector from './Widgets/ChannelSelector/ChannelSelector';
import OrderCreationForm from './Widgets/OrderCreationForm/OrderCreationForm';
import { Order } from '../OrdersListing/types';

const OrderCreationContainer = styled.div`
  padding-bottom:20px;
`;

type OrderCreateStage = 'findChannel'|'findCustomer'|'createCustomer'|'createOrder';

const zoomOrderCreateViewEvent = new CustomEvent('zoomOrderCreateView');
interface Props {
  // eslint-disable-next-line react/require-default-props
  order?:Order,
  // eslint-disable-next-line react/require-default-props
  preSelectedTeamChannel?:TeamChannel
}

export default ({ order, preSelectedTeamChannel }:Props) => {
  const clonedTeamCustomer = order?.teamCustomer;
  const [
    teamCustomer,
    setTeamCustomer,
  ] = useState<TeamCustomer | undefined>(clonedTeamCustomer);
  const [
    teamChannel,
    setTeamChannel,
  ] = useState<TeamChannel | undefined>(preSelectedTeamChannel);
  const [step, setStep] = useState<OrderCreateStage>('findChannel');

  useEffect(() => {
    setTeamChannel(preSelectedTeamChannel);
    if (preSelectedTeamChannel) {
      setStep(teamCustomer ? 'createOrder' : 'findCustomer');
    }
  }, [preSelectedTeamChannel, teamCustomer]);

  useEffect(() => {
    if (step === 'createOrder') {
      document.dispatchEvent(zoomOrderCreateViewEvent);
    }
  }, [step]);

  const onCustomerSelectComplete = useCallback(
    (
      selectedTeamCustomer:TeamCustomer|undefined,
    ) => {
      setTeamCustomer(selectedTeamCustomer);
      setStep('createOrder');
    },
    [setTeamCustomer, setStep],
  );

  const onChannelSelectComplete = useCallback((
    selectedTeamChannel:TeamChannel|undefined,
  ) => {
    setTeamChannel(selectedTeamChannel);
    setStep(teamCustomer ? 'createOrder' : 'findCustomer');
  }, [setTeamChannel, setStep, teamCustomer]);

  return (
    <OrderCreationContainer>
      {step === 'findChannel'
      && (
      <ChannelSelector
        onConfirmSelection={onChannelSelectComplete}
      />
      )}

      {step === 'findCustomer'
      && (
      <FindCustomer
        onConfirmSelection={onCustomerSelectComplete}
        elementAfter={<FindCustomerAddButton onClick={() => setStep('createCustomer')} />}
      />
      )}

      {step === 'createCustomer'
      && (
      <CreateCustomerMin
        teamChannel={teamChannel!}
        onCancel={() => setStep('findCustomer')}
        onSave={onCustomerSelectComplete}
      />
      )}

      {step === 'createOrder' && !isUndefined(teamCustomer)
      && (
      <OrderCreationForm
        teamCustomer={teamCustomer}
        teamChannel={teamChannel!}
        order={order}
      />
      )}

    </OrderCreationContainer>
  );
};
