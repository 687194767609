/* eslint-disable max-len */
import React, { useState } from 'react';
import { isUndefined } from 'lodash';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import MarketplaceIcon from '@atlaskit/icon/glyph/marketplace';
import { useTranslation } from 'react-i18next';
import {
  ProductProps, ProductAttributeValue, AspectMap,
} from './type';
import ProductEditByCategoryId from '../ProductEdit/ProductEditByCategoryId';
import DisplayCategoryById from '../ProductEdit/DisplayCategoryById';
import SuggestCategoriesBtn from '../ProductEdit/SuggestCategoriesBtn';
import CategoryEditContainer from '../ProductEdit/styles/CategoryEditContainer';
import ProductFormContainer from '../ProductEdit/ProductFormContainer';
import ViewEbayListingBtn from '../ProductsListing/widget/ViewEbayListingBtn';
import ViewMagentoListingBtn from '../ProductsListing/widget/ViewMagentoListingBtn';
import DeleteProductButton, { DeleteButtonResponse } from '../DeleteProductButton';
import ActionBtnContainer from './ActionBtnContainer';
import addNotification from '../../utils/addNotification';
import { useProducts } from '../ProductsListing/ProductsListing';
import ReplaceEbayProductBtn from '../ProductsListing/widget/ReplaceEbayProductBtn';
import { EBAY_CHANNEL_ID, MAGENTO_CHANNEL_ID } from './Constants';
import { useAuth, useSubscription } from '../../utils/useAuth';

const ProductDetails = ({ productData }:
{productData: ProductProps}) => {
  const { t } = useTranslation();
  const {
    id,
    name,
    sku,
    shortDescription,
    fullDescription,
    unitPrice,
    stockOnHand,
    images,
    isForSale,
    productAttributeValues,
    attributeSet,
    productTaxClass,
    teamChannelProducts,
    teamID,
    totalInventoryCost,
    fromChannelId,
  } = productData;
  const getAspects = (tmpProductAttributeValues:
  Array<ProductAttributeValue>) => tmpProductAttributeValues
    .reduce((obj: AspectMap, productAttr: ProductAttributeValue) => {
      if (obj[productAttr.productAttribute.name]) {
        return {
          ...obj,
          [productAttr.productAttribute.name]: [
            ...obj[productAttr.productAttribute.name], productAttr.value,
          ],
        };
      }
      return {
        ...obj,
        [productAttr.productAttribute.name]: [productAttr.value],
      };
    }, {});

  const [
    categoryId,
    setCategoryId,
  ] = useState(attributeSet.channelAttributeSetId);

  // const [wooCommerceProductType] = React.useState(attributeSet.channelAttributeSetId);

  /**
   * if this component is not inside the useProductProvider
   * the dispatch may be undefined
   * for example :http://localhost:3000/product/cki3v6h8805893wslpb2i7woq-324419356008
   */
  const { dispatch } = useProducts();
  const history = useHistory();
  const isSplitView = !isUndefined(dispatch);

  const getResponse = (res: DeleteButtonResponse) => {
    if (res.data && isSplitView) {
      addNotification(
        'Product Delete Successful',
        'success',
      );
      dispatch({
        type: 'setLastDeletedId',
        payload: res.data.DeleteProduct.deletedId,
      });
      dispatch({
        type: 'setCurrentProductID',
        payload: undefined,
      });
    }
    if (res.data && !isSplitView) {
      history.push('/products');
    }

    // on error, no mather isSplitivew or standalong view,
    // just pop up error toast
    if (res.error) {
      addNotification(
        'Product Delete Failed',
        'warning',
      );
    }
  };

  const convertedImgs = images.map((img) => img.url);
  const aspects = getAspects(productAttributeValues);
  const productFormInitData = {
    id,
    name,
    sku,
    shortDescription,
    fullDescription,
    unitPrice,
    isForSale,
    stockOnHand,
    images: convertedImgs,
    aspects,
    productTaxClass,
    teamChannelProducts: teamChannelProducts.filter(
      (c) => c.teamChannel.active,
    ),
    totalInventoryCost,
    wooCommerceProductType: categoryId,
    fromChannelId,
  };

  // get eBay product directUrl
  const { state } = useAuth();

  const freeExpired = useSubscription();

  function findEbayTeamChannel(prop: { channelName: string; }) {
    return prop.channelName === 'eBay';
  }
  const eBayID = state.teams[0].teamChannels.filter(findEbayTeamChannel)[0];
  let EbayTeamChannelId: string;
  if (eBayID !== undefined) {
    EbayTeamChannelId = state.teams[0].teamChannels.filter(findEbayTeamChannel)[0].id;
  }

  function findEbayProductExternalId(prop: { teamChannel: { id: string; }; }) {
    return prop.teamChannel.id === EbayTeamChannelId;
  }

  const pro = productFormInitData.teamChannelProducts.filter(findEbayProductExternalId);

  const productEbayId = Array.isArray(pro) && pro.length > 0 ? pro[0].externalListingId : null;

  // Filter ebay channel product based on external listing id
  const ebayChannelProducts = productData.teamChannelProducts
    .filter((tcP) => (tcP.externalListingId.trim() !== '' && tcP.teamChannel.active && tcP.teamChannel.channelID === process.env.REACT_APP_EBAY_CHANNEL_ID));

  return (
    <ProductFormContainer>
      <h1>{t('products.product.productUpdate')}</h1>
      <ActionBtnContainer>
        {ebayChannelProducts.map((tcP) => {
          if (tcP.teamChannel.channelID === EBAY_CHANNEL_ID) {
            return (
              <ViewEbayListingBtn
                isCompactSize={false}
                ebayItemId={tcP.externalListingId}
                isDisabled={freeExpired}
              >
                {t('products.product.viewEBayListing')}
              </ViewEbayListingBtn>
            );
          }
          if (tcP.teamChannel.channelID === MAGENTO_CHANNEL_ID) {
            return (
              <ViewMagentoListingBtn
                isCompactSize={false}
                urlKey={tcP.externalListingId}
              >
                {t('products.product.viewMagentoListing')}
              </ViewMagentoListingBtn>
            );
          }
          return null;
        })}
        <ReplaceEbayProductBtn
          sourceProduct={productData}
          teamID={teamID}
        >
          {t('products.product.sep')}
        </ReplaceEbayProductBtn>

        <DeleteProductButton
          id={productData.id}
          sku={productData.sku}
          getResponse={getResponse}
        >
          {t('products.product.delete')}
        </DeleteProductButton>

        {
          productEbayId ? (
            <Button
              iconBefore={<MarketplaceIcon label="eBay" />}
              onClick={() => window.open(`https://www.ebay.com.au/itm/${productEbayId}`)}
              style={{ marginLeft: 10 }}
            >
              {t('products.product.openOnEBay')}
            </Button>
          )
            : null
        }

      </ActionBtnContainer>

      <CategoryEditContainer>
        <SuggestCategoriesBtn
          appearance="subtle-link"
          text={<DisplayCategoryById categoryId={categoryId} />}
          onCategoryChosen={(cateId: string) => {
            setCategoryId(cateId);
          }}
        />
      </CategoryEditContainer>
      <ProductEditByCategoryId
        categoryId={categoryId}
        productData={productFormInitData}
      />
    </ProductFormContainer>
  );
};

export default ProductDetails;
