import React from 'react';
/** types */
import { CategoryOptionsProp } from './types';
// styles
import OptionContainer from './styles/OptionContainer';
import Option from './styles/Option';

export default (props: CategoryOptionsProp) => {
  const {
    query, disabled, options, inputValue, handleClick,
  } = props;
  if (disabled) return null;
  return (
    <OptionContainer data-testid="category-suggestion-menu">
      {options.length > 0
        ? (options.map((option) => (
          <Option
            key={option.value}
            onClick={() => (handleClick(option, inputValue))}
            data-testid="option"
          >
            <div>
              Search
              {' '}
              {' '}
              <span>{query}</span>
              {' '}
              in:
            </div>
            <div>{option.label}</div>
          </Option>
        )))
        : (
          <div>
            Searching
            {' '}
            {inputValue}
            ...
          </div>
        )}
    </OptionContainer>
  );
};
