import React from 'react';
import { useShipping } from '../Shipping';
import { ShippingMatrix, State } from '../types';
import { convertShippingMethodToUnifiedValue } from '../helper';
import PickupPreview from './Landings/PickupPreview';
import PickupPickup from './Landings/PickupPickup';
import IntergratedPreview from './Landings/IntergratedPreview';
import IntergratedQuote from './Landings/IntergratedQuote';
import DefaultPreview from './Landings/DefaultPreview';
import IntergratedConfirmQuote from './Landings/IntergratedConfirmQuote';
import PostShipment from './Landings/PostShipment';
import DefaultManual from './Landings/DefaultManual';

const renderUI = (
  state:State,
  onShipped:()=>void,
) => {
  const { stage } = state.ui;
  const { shippingMethod } = state;
  const courier = convertShippingMethodToUnifiedValue(shippingMethod);
  const components:ShippingMatrix = {
    pickup: {
      preview: <PickupPreview />,
      pickup: <PickupPickup onShipped={onShipped} />,
    },
    integrated: {
      preview: <IntergratedPreview />,
      quote: <IntergratedQuote />,
      confirmQuote: <IntergratedConfirmQuote />,
      postShipment: <PostShipment onShipped={onShipped} />,
    },
    default: {
      preview: <DefaultPreview />,
      manual: <DefaultManual onShipped={onShipped} />,
    },
  };

  return components[courier][stage];
};

const ShippingHooks = ({ onShipped }:{onShipped:()=>void}) => {
  const { state } = useShipping();
  return (
    <>
      {renderUI(state, onShipped)}
    </>
  );
};

export default ShippingHooks;
