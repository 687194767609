import { TeamChargePlan, useGetTeamChargePlanByIdQuery } from '../../graphql/types';

export default function CheckPlanType(planId: string) {
  const {
    data: teamPlan,
  } = useGetTeamChargePlanByIdQuery(
    {
      variables: { teamChargePlanId: planId },
      // to avoid still show cached data after update when switching team
      fetchPolicy: 'network-only',
    },
  );
  const id = teamPlan?.GetTeamChargePlanById.teamChargePlan?.id;
  const title = teamPlan?.GetTeamChargePlanById.teamChargePlan?.title;
  const amount = teamPlan?.GetTeamChargePlanById.teamChargePlan?.amount;
  const currency = teamPlan?.GetTeamChargePlanById.teamChargePlan?.currency;
  const planType = teamPlan?.GetTeamChargePlanById.teamChargePlan?.planType;

  const plan = {
    id, title, amount, currency, planType,
  } as TeamChargePlan;

  return plan;
}
