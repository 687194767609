import React from 'react';
import PreviewShipping from '../PreviewShipping';
import ShippingMethod from '../ShippingMethod';
import CancelButton from '../Buttons/CancelButton';
import { Buttons } from '../../Shipping.style';
import PreviewQuotes from '../PreviewQuotes';
import WeightAndDimension from '../WeightAndDimension';
import ConfirmQuote from '../ConfirmQuote';
import IntergratedCreateShipmentButton from '../Buttons/IntergratedCreateShipmentButton';

export default () => (
  <>
    <PreviewShipping />
    <ShippingMethod />
    <WeightAndDimension />
    <PreviewQuotes />
    <ConfirmQuote />
    <Buttons>
      <CancelButton />
      <IntergratedCreateShipmentButton />
    </Buttons>
  </>
);
