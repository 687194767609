import React, {
  useState, useEffect, useCallback,
} from 'react';
import Spinner from '@atlaskit/spinner';
import { get } from 'lodash';
import { ErrorMessage } from '@atlaskit/form';
import styled from 'styled-components';
import addNotification from '../../utils/addNotification';
import {
  useSetTeamSmtpMutation,
  SetTeamSmtpMutationVariables,
  useGetTeamSmtpQuery,
  GetTeamSmtpQuery,
} from '../../graphql/types';
import SMTPEditorForm, { SMTPEditorFormData } from './SMTPEditorForm';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useAuth } from '../../utils/useAuth';

interface TeamProfileEditorProps {
  teamId:string,
}

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width:100%;
`;

const SMTPEditor = (props: TeamProfileEditorProps) => {
  const { teamId } = props;
  const [updateTeamSmtpSetting] = useSetTeamSmtpMutation();
  const {
    data, loading, error, refetch,
  } = useGetTeamSmtpQuery({
    variables: { teamId },
    // to avoid still show cached data after update when switching team
    fetchPolicy: 'network-only',
  });

  const { dispatch } = useAuth();

  const handleSubmit = useCallback(async (formData: SMTPEditorFormData) => {
    const variables: SetTeamSmtpMutationVariables = {
      teamId,
      teamSMTP: {
        host: formData.host,
        port: formData.port,
        username: formData.username,
        password: formData.password,
      },
    };

    await updateTeamSmtpSetting({
      variables,
    })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
      .then(({ data: resData }) => {
        // update the state so the team switcher can tell the changes in team Name
        // also update the session as well
        const newTeamSTMP = '';
        // console.log(resData);
        if (newTeamSTMP) {
          // update state
          dispatch({
            type: 'changeTeamName',
            changeTeamNamePayLoad: {
              teamId,
              value: newTeamSTMP,
            },
          });
        }

        addNotification('STMP Setting saved', 'success');
        // tell the form has updated
      })
      .catch((err) => {
        addNotification(graphQLErrorsReader(err), 'danger');
      });
  }, [teamId, updateTeamSmtpSetting]);// eslint-disable-line

  const getFormValues = useCallback((
    teamSmtpData: GetTeamSmtpQuery | undefined,
  ) => {
    const teamSmtpSetting = teamSmtpData?.GetTeamSMTP.teamSMTP;
    return {
      values: {
        host: get(teamSmtpSetting, 'host', ''),
        port: get(teamSmtpSetting, 'port', ''),
        username: get(teamSmtpSetting, 'username', ''),
        password: get(teamSmtpSetting, 'password', ''),
      },
      actions: {
        handleSubmit,
      },
    };
  }, [handleSubmit]);

  const [formValues, setFormValues] = useState(getFormValues(data));

  useEffect(() => {
    setFormValues(getFormValues(data));
  }, [data, getFormValues]);

  useEffect(() => {
    refetch();
  }, [teamId, refetch]);

  return (
    <Wrapper>
      <div style={{ width: '100%' }}>
        {(loading) && <Spinner size="xlarge" testId="teamProfileFormLoading" />}
        {data && (
          <SMTPEditorForm
            teamId={teamId}
            values={formValues.values}
            actions={formValues.actions}
          />
        )}
        {error
          && <ErrorMessage>{graphQLErrorsReader(error)}</ErrorMessage>}
      </div>
    </Wrapper>
  );
};

export default SMTPEditor;
