import React from 'react';
import TicketsContainer from '../components/TicketsContainer/TicketsContainer';
import { useAuth } from '../utils/useAuth';

export default function Products() {
  const { state: { currentTeamId: teamID } } = useAuth();
  return (
    <>
      <TicketsContainer teamID={teamID!} />
    </>
  );
}
