import React from 'react';
import { SelectedFiltersGroup, TagContainer } from './AdvancedFilter.style';
import { useProducts } from '../../ProductsListing';
import FilterTag from './FilterTag';

export default () => {
  const { state } = useProducts();
  if (state.query.advancedFilter.length === 0) {
    return <></>;
  }
  const description = state.query.advancedFilter.length > 1 ? 'Applied filters: ' : 'Applied filter: ';
  return (
    <SelectedFiltersGroup>
      <span>{description}</span>
      {state.query.advancedFilter.map((filter) => (
        <TagContainer key={filter.filterKey}>
          <FilterTag
            filter={filter}
          />
        </TagContainer>
      ))}
    </SelectedFiltersGroup>
  );
};
