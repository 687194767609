import React from 'react';
import Button from '@atlaskit/button';
import { useShipping } from '../../Shipping';

const CancelButton = () => {
  const { state, dispatch } = useShipping();
  const close = () => dispatch({ type: 'setIsModalOpen', payload: false });
  return (
    <Button
      appearance="default"
      isDisabled={state.ui.isSubmiting}
      onClick={close}
    >
      Cancel
    </Button>
  );
};

export default CancelButton;
