import styled from 'styled-components';

export const TeamCustomerInfoContainer = styled.div`
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const OrderListingContainer = styled.div`
  margin-left: 108px;
  margin-top: 48px;
`;

export const CommentsContainer = styled.div`
  margin-left: 108px;
  margin-top: 48px;
`;
