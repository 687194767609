import React, { useState } from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { useHistory } from 'react-router-dom';
import Button from '@atlaskit/button';
import styled from 'styled-components';
import moment from 'moment';
import { State, StateTeamChannel } from '../../utils/types';
import { useAuth } from '../../utils/useAuth';

const ListContainer = styled.ul`
  margin:20px 10px 20px 10px;
  padding:10px;
  list-style:none;
  border:1px dotted #ccc;
  border-radius:5px;
  li {
    display:flex;
    flex-basis:100%;
    justify-content:space-between;    
    margin-top:5px;
    .subtitle {
      color:rgb(107, 119, 140);
    }
  }  
`;

const findExpireTeamChannels = (state: State) => {
  const { teams } = state;
  const expiringTCs: StateTeamChannel[] = [];
  try {
    teams.forEach((team) => {
      team.teamChannels.forEach((tc) => {
        if (
          tc.onBoarded
          && tc.active
          && moment(tc.settings.refresh_token_expires_at).isBefore(moment().add(30, 'days'))) {
          expiringTCs.push(tc);
        }
      });
    });
  } catch (error) {
    // do nothing
  }

  return expiringTCs;
};

const RefreshTokenExpireAlert = () => {
  const { state } = useAuth();
  const history = useHistory();
  const hasTokenAlertShown = window.localStorage.getItem('hasTokenAlertShown') || 'true';
  // ts stand for teamChannel
  const [tcsNearExpire] = useState<StateTeamChannel[]>(
    () => findExpireTeamChannels(state),
  );
  const [isOpen, setIsopen] = useState(false);

  React.useEffect(() => {
    if (hasTokenAlertShown === 'false' && tcsNearExpire.length > 0) {
      setIsopen(true);
    }
  }, [hasTokenAlertShown, tcsNearExpire.length]);

  const close = () => {
    window.localStorage.setItem('hasTokenAlertShown', 'true');
    setIsopen(false);
  };

  const navTo = (id: string) => {
    // set the alert to has show
    window.localStorage.setItem('hasTokenAlertShown', 'true');
    history.push(`/connect/ebay/${id}?redirectPath=/marketplace/${id}`);
  };

  return (
    <ModalTransition>
      {isOpen && (
        <Modal>
          <ModalHeader>
            <ModalTitle appearance="warning">Action required</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <h5>
              Below stores are required to
              renew the authentication from markeplace:
            </h5>
            <ListContainer>
              {tcsNearExpire.map((tc) => (
                <li key={tc.id}>
                  <div>
                    {tc.channelName}
                    {' '}
                    -
                    {' '}
                    {tc.name}
                    {' '}
                    (
                    {tc.externalId}
                    )
                    <div className="subtitle">
                      (Expires
                      {' '}
                      {moment(tc.settings.refresh_token_expires_at).fromNow()}
                      )
                    </div>
                  </div>

                  <Button
                    appearance="warning"
                    spacing="compact"
                  // TODO calculate url if support other market place
                    onClick={() => navTo(tc.id)}
                  >
                    re-connect with
                    {' '}
                    {' '}
                    {tc.channelName}
                  </Button>
                </li>
              ))}
            </ListContainer>
          </ModalBody>

          <ModalFooter>
            <Button onClick={close}>Close</Button>
          </ModalFooter>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default RefreshTokenExpireAlert;
