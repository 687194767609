import { EbaySuggestCategoryResponse, EbayCategory } from './types';
// component level specified helpers
function categoryOptionsCreator(res: EbaySuggestCategoryResponse) {
  /**
  * take raw respon from EBAY api "taxonomy.getCategorySuggestions",
  * and format the result into appropriate formation used for component.
  * NEED TO TEST IF EBAY API JSON RESPONSE IS GOOD OR NOT.
  * IF KEYWORD NOT FOUND, EBAY WILL RESPONSE HTTP 404 AND A JSON
  * BUT THE BACKEND LIBRARY 'ebay-node-client' DOES NOT TREAT THE HTTP 404 AS A REJECT OF A PROMISE
  * SO HERE, FIRST THING TO DO IS TO CHECK IF THE RESPONSE IS VALID OR NOT
  * IF NOT, RETURN EMPTY ARRAY.
  */
  function categoryTreeAncestorReducer(categoryTreeNodeAncestors: []) {
    if (categoryTreeNodeAncestors !== undefined) {
      const parentTreeArray = categoryTreeNodeAncestors.reverse()
        .map((tree: { categoryName: string }) => (tree.categoryName));
      const parentTreeString = parentTreeArray.reduce((acc: string, cur: string) => `${acc} / ${cur}`);
      return parentTreeString;
    }
    return '';
  }
  const { response } = res.QueryEbay;
  if (response.categorySuggestions) {
    const suggestions = response.categorySuggestions;
    const options = suggestions.map((suggetion: EbayCategory) => (
      {
        label: `${categoryTreeAncestorReducer(suggetion.categoryTreeNodeAncestors)} / ${suggetion.category.categoryName}`,
        value: suggetion.category.categoryId,
      }
    ));
    return options;
  }
  return [];
}

export default categoryOptionsCreator;
