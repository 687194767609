import React from 'react';
import UserInvitation from '../components/UserInvitation/UserInvitation';

export default function UserInvitationPage() {
  return (
    <>
      <UserInvitation />
    </>
  );
}
