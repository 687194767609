/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { Field, HelperMessage } from '@atlaskit/form';
import { Controller, Control } from 'react-hook-form';
import Textfield from '@atlaskit/textfield';
import _ from 'lodash';
import { ErrorMsg } from '../ProductCreationFormContainer/ProductCreationForm.styles';
import { ErrorType } from './types';
import FlexSpaceBetweenContainer from './styles/FlexSpaceBetweenContainer';

type TextFieldInputProps = {
  name: string;
  label: React.ReactNode;
  isRequired: boolean;
  control: Control<Record<string, string>>;
  errors: ErrorType;
  defaultValue: string | Array<string> | number;
  setValue: Function;
  testId: string;
  helpMessage: string;
  maxLength: number;
  type?: string;
  minVal?: number;
  validateOnMinVal?:boolean,
  isDisable: boolean;
};

const TextFieldWithLabelAndError = ({
  name, label, isRequired, control, errors,
  defaultValue, setValue, testId, helpMessage = '',
  maxLength = -1,
  type = 'string',
  minVal = -1,
  validateOnMinVal = true,
  isDisable,
}: TextFieldInputProps) => {
  const [charLength, setCharLength] = useState(0);
  const bounceSetValue = _.debounce((val) => {
    setValue(name, val, { shouldValidate: true });
  }, 500);

  useEffect(() => {
    if (typeof defaultValue === 'string') {
      setCharLength(defaultValue.trim().length);
    }
  }, [defaultValue]);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={{
          validate: {
            required: (wording) => {
              const isWordingString = wording && typeof wording === 'string' && type === 'string';
              const isWordingEmpty = isWordingString && wording.trim() === '';
              return isRequired === true && isWordingString === true
              && isWordingEmpty ? `${label} is required` : undefined;
            },
            maxLengthReached: (wording) => {
              const isWordingString = wording && typeof wording === 'string' && type === 'string';
              const isMaxLengthReached = isWordingString
               && maxLength !== -1 && (wording as string).length > maxLength;
              return isRequired === true && isWordingString === true
              && isMaxLengthReached ? `Max length ${maxLength} reached for ${label}.` : undefined;
            },
            minValCheck: (numStr) => {
              if (validateOnMinVal) {
                const isGreaterOrEqualThanMinVal = parseFloat(numStr) >= minVal;
                return type === 'number' && isGreaterOrEqualThanMinVal === false ? `The entered value ${numStr} should not less than ${minVal}.` : undefined;
              }
              return undefined;
            },
          },
        }}
        defaultValue={defaultValue}
        render={(ctrlProps) => (
          <>
            <Field
              isDisabled={isDisable}
              key={name}
              name={name}
              label={label}
            >
              {({ fieldProps }) => (
                <Textfield
                  {...ctrlProps}
                  {...fieldProps}
                  isDisabled={isDisable}
                  testId={testId}
                  name={name}
                  defaultValue={defaultValue}
                  onChange={(evt) => {
                    const val = evt.currentTarget.value;
                    if (typeof val === 'string') {
                      setCharLength(val.trim().length);
                    }
                    bounceSetValue(val);
                  }}
                />
              )}
            </Field>
            <HelperMessage>
              <FlexSpaceBetweenContainer>
                <div>
                  {helpMessage}
                </div>
                { maxLength > 0 && (maxLength - charLength >= 0)
              && (
                <div>
                  {maxLength - charLength}
                  {' '}
                  character(s) left.
                </div>
              )}
              </FlexSpaceBetweenContainer>
            </HelperMessage>
            {_.get(errors, name) && (
            // eslint-disable-next-line react/jsx-no-comment-textnodes
            <ErrorMsg>
              {_.get(errors, [name, 'message']).message}
            </ErrorMsg>
            )}
          </>
        )}
      />
    </div>
  );
};

export default TextFieldWithLabelAndError;
