import React from 'react';
import { useQueryEbayProductQuery } from '../../graphql/types';
// component
import AspectsList from './AspectsList';
// assets
import noImage from '../../assets/svg/noimage.svg';
// styles
import ProductDetailsContainer from './styles/ProductDetailsContainer';
// types
import { ProductDetailsProps } from './types';

export default (props: ProductDetailsProps) => {
  const { epid } = props;
  const { data, loading, error } = useQueryEbayProductQuery({
    variables: {
      epid,
    },
  });
  if (loading) return (<p>Loading...</p>);
  if (error) return (<p>Error</p>);
  if (data === undefined) {
    return null;
  }
  return (
    <ProductDetailsContainer>
      <div className="row heading" data-testid="ebay-search-product-details">
        <img src={data.QueryEbay.response?.image?.imageUrl ? data.QueryEbay.response.image.imageUrl : noImage} alt="" />
        <div>
          <h2>{data.QueryEbay.response.title}</h2>
        </div>
      </div>
      <div className="row product-info">
        <h3>Product Information</h3>
        <p>{data.QueryEbay.response?.description}</p>
      </div>
      <div className="row product-identifiers">
        <h3>Product Identifiers</h3>
        <AspectsList
          aspects={data.QueryEbay.response.aspects}
        />
      </div>
    </ProductDetailsContainer>
  );
};
