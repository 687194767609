import gql from 'graphql-tag';

const couriersQuery = gql`
query GetCouriers {
  GetCouriers {
    status,
    couriers{
      id,
      name,
      code,
      active,
    }
  }
}
`;
export default couriersQuery;
