/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalBody,
} from '@atlaskit/modal-dialog';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import Button from '@atlaskit/button';
import Spinner from '@atlaskit/spinner';
import ArrowDownIcon from '@atlaskit/icon/glyph/arrow-down';
import AWS from 'aws-sdk';
import { v4 as uuidV4 } from 'uuid';
import { get } from 'lodash';
import {
  ButtonContainer,
} from './BulkProductsUpdateModal.styles';
import {
  useUpdateBulkProductsMutation,
  useCreateBulkProductUpdateReportMutation,
  useCreateBulkProductTemplateMutation,
} from '../../graphql/types';
import BulkProductsUpdateProgressBar from './BulkProductsUpdateProgressBar';
import addNotification from '../../utils/addNotification';
import './uploader.css';
import { useAuth } from '../../utils/useAuth';

interface BulkProductsUpdateModalInputProps {
  isOpen: boolean,
  close: any, //eslint-disable-line
}

const { Dragger } = Upload;
const accessKeyId = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const secretAccessKey = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
const region = process.env.REACT_APP_AWS_REGION || 'ap-southeast-2';

AWS.config.update({
  accessKeyId,
  secretAccessKey,
  region,
});
const S3 = new AWS.S3();
const id = uuidV4();

const BulkProductsUpdateModal = (props: BulkProductsUpdateModalInputProps) => {
  const { state: { currentTeamId: teamID } } = useAuth();
  const {
    isOpen, close,
  } = props;
  const [fileList, updateFileList] = useState([]);
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [downloadReport, setDownloadReport] = useState<boolean>(true);
  const [uploadDisabled, setUploadDisabled] = useState<boolean>(false);
  const [jobId, setJobId] = useState('');
  const [numOfProducts, setNumOfProducts] = useState(0);
  const [updateBulkProductsMuation] = useUpdateBulkProductsMutation();
  const [
    createBulkProductUpdateReportMutation,
  ] = useCreateBulkProductUpdateReportMutation();
  const [createBulkProductTemplateMutation] = useCreateBulkProductTemplateMutation();
  const onDownload = () => {
    createBulkProductUpdateReportMutation({
      variables: { jobId },
    })
      .then((response) => {
        const url = response?.data?.CreateBulkProductUpdateReport?.url;
        if (!url) {
          addNotification('Download bulk product update report failed', 'danger');
        } else {
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.target = '_blank';
          anchor.click();
          setShowProgress(false);
        }
      })
      .catch(() => {
        addNotification('Download bulk product update report failed', 'danger');
      });
  };
  const draggerProps = {
    accept: 'text/csv',
    openFileDialogOnClick: !uploadDisabled,
    fileList,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (info: any) => {
      updateFileList(info.fileList.filter((file: any) => !!file.status));
    },
    onRemove: () => {
      updateFileList([]);
      setUploaded(false);
      setDownloadReport(true);
      setUploadDisabled(false);
    },
    beforeUpload: (file: any) => {
      const isSmaller20M = file.size / (1024 * 1024) < 20;
      if (!isSmaller20M) {
        addNotification('CSV file should less than 20M', 'danger');
      }
      if (fileList.length > 0) {
        addNotification('you should delete file when you re-upload file', 'danger');
      }
      return isSmaller20M && fileList.length <= 0;
    },
    customRequest({
      file,
      onError,
      onSuccess,
    }: any) {
      const params = {
        Key: `${id}.csv`,
        ACL: 'public-read',
        Body: file,
        Bucket: 'merp-saas-csv',
        ContentType: 'text/csv',
      };
      S3.upload(params)
        .promise()
        .then((res) => {
          setUploaded(true);
          setUploaded(true);
          setUploadDisabled(true);
          onSuccess(res.Location, file);
        })
        .catch((
          err,
        ) => {
          // eslint-disable-next-line
          addNotification(JSON.stringify(err), 'danger');
          onError();
        });
    },
  };

  const createBulkTemplate = () => {
    createBulkProductTemplateMutation({
      variables: { teamID: teamID || '' },
    })
      .then((response) => {
        const url = get(response, 'data.CreateBulkProductTemplate.url', '');
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.click();
      })
      .catch(() => {
        addNotification('Download bulk product template failed', 'danger');
      });
  };

  const onSubmit = () => {
    setSpinner(true);
    setUploaded(false);
    updateBulkProductsMuation({
      variables: { fileName: `${id}.csv`, teamID: teamID || '' },
    })
      .then((response) => {
        setSpinner(false);
        setUploaded(true);
        const queueJobId = get(response, 'data.UpdateBulkProducts.jobId', '');
        const qty = get(response, 'data.UpdateBulkProducts.numberOfBulkProducts', 0);
        setJobId(queueJobId);
        setNumOfProducts(qty);
        setShowProgress(true);
      })
      .catch(() => {
        setUploaded(true);
        setSpinner(false);
      });
  };
  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          onClose={close}
          shouldCloseOnOverlayClick={false}
        >
          {' '}
          <ModalHeader>
            <ModalTitle>Bulk Products Update</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Dragger {...draggerProps}>
              <div className="content-container">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <div className="text-container">
                  <h4 className="ant-upload-text">
                    Click or drag file to this area to upload
                  </h4>
                  <p className="upload-hint">
                    1. Upload CSV file(File maximin size: 20MB)
                  </p>
                  <div className="download-container">
                    <p className="ant-upload-hit">
                      2. Download report when update complete
                    </p>
                  </div>
                  <p className="ant-upload-hint">
                    You should delete file when you re-upload file.
                  </p>
                </div>
              </div>
            </Dragger>
            {showProgress
              && (
                <BulkProductsUpdateProgressBar
                  jobId={jobId}
                  numOfProducts={numOfProducts}
                  setDownloadReport={setDownloadReport}
                />
              )}
            {!downloadReport && (
              <Button
                className="download-report-btn"
                appearance="link"
                isDisabled={downloadReport}
                onClick={onDownload}
                iconAfter={<ArrowDownIcon label="download icon" size="small" />}
              >
                Download Report
              </Button>
            )}
            <ButtonContainer>
              <Button
                onClick={() => createBulkTemplate()}
                appearance="link"
                iconAfter={<ArrowDownIcon label="download icon" size="small" />}
              >
                Download CSV Template
              </Button>
              <Button
                testId="submit-button"
                isDisabled={!uploaded}
                type="submit"
                appearance="primary"
                onClick={() => onSubmit()}
                iconAfter={
                  showSpinner ? <Spinner appearance="invert" /> : <></>
                }
              >
                Submit
              </Button>
              <Button
                testId="cacel-button"
                appearance="link"
                onClick={() => close()}
              >
                Close
              </Button>
            </ButtonContainer>
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
};

export default BulkProductsUpdateModal;
