import React from 'react';
import PreviewShipping from '../PreviewShipping';
import ShippingMethod from '../ShippingMethod';
import CancelButton from '../Buttons/CancelButton';
import { Buttons } from '../../Shipping.style';
import AddTrackingNumberButton from '../Buttons/AddTrackingNumberButton';

export default () => (
  <>
    <PreviewShipping />
    <ShippingMethod />
    <Buttons>
      <CancelButton />
      <AddTrackingNumberButton />
    </Buttons>
  </>
);
