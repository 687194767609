import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { TeamChannelSettingsData } from '../../utils/types';
import MarketPlaceCreator from '../MarketPlaceCreator/MarketPlaceCreator';
// import { useSubscription } from '../../utils/useAuth';

const LinkWrapper = styled.div`
  margin: 12px 0 0px;
  display: flex;
  justify-content: center;
`;

export interface Step {
  key: string,
  title: string,
  completed: boolean,
  isCurrent: boolean,
  content: JSX.Element,
}

export default (
  settings: TeamChannelSettingsData,
  teamChannelId: string,
  selectedKey: string,
  freeExpired: boolean,
) => {
  // const freeExpired = useSubscription();
  const connect = {
    key: 'connect',
    title: i18next.t('quickStart.connectToMarketPlace.connectToMarketPlace'),
    completed: settings?.access_token?.access_token !== undefined,
    isCurrent: false,
    content: (
      <>
        {i18next.t('quickStart.connectToMarketPlace.describe')}
        <LinkWrapper>
          <MarketPlaceCreator onComplete={() => { }} freeExpired={freeExpired} config />
        </LinkWrapper>
      </>
    ),
  };

  const configureStore = {
    key: 'configureStore',
    title: i18next.t('quickStart.configureStore.csp'),
    completed: settings?.paymentPolicyId !== undefined,
    isCurrent: false,
    content: (
      <>
        {i18next.t('quickStart.configureStore.describe')}
        <LinkWrapper>
          <Link to={`/config/ebay/${teamChannelId}`}>{i18next.t('quickStart.configureStore.cs') as string}</Link>
        </LinkWrapper>
      </>
    ),
  };

  const configureCompany = {
    key: 'configureCompany',
    title: i18next.t('quickStart.configureCompany.ccp'),
    completed: false,
    isCurrent: false,
    content: (
      <>
        {i18next.t('quickStart.configureCompany.describe')}
        <LinkWrapper>
          <Link to="/team/profile">{i18next.t('quickStart.configureCompany.cp') as string}</Link>
        </LinkWrapper>
      </>
    ),
  };

  const importProducts = {
    key: 'importProducts',
    title: i18next.t('quickStart.importProducts.ip'),
    completed: false,
    isCurrent: false,
    content: (
      <>
        {i18next.t('quickStart.importProducts.describe')}
        <LinkWrapper>
          <Link to={`/import/ebay/${teamChannelId}`}>{i18next.t('quickStart.importProducts.ip') as string}</Link>
        </LinkWrapper>
      </>
    ),
  };

  const importOrders = {
    key: 'importOrders',
    title: i18next.t('quickStart.importOrders.io'),
    completed: false,
    isCurrent: false,
    content: (
      <>
        {i18next.t('quickStart.importOrders.describe')}
        <LinkWrapper>
          <Link to={`/import/ebay/${teamChannelId}`}>{i18next.t('quickStart.importOrders.io') as string}</Link>
        </LinkWrapper>
      </>
    ),
  };

  const sequence = [
    connect,
    configureStore,
    configureCompany,
    importProducts,
    importOrders,
  ];

  const calcCurrentKey = ():string => {
    let key = '';
    for (let i = 0; i < sequence.length; i++) {
      if (!sequence[i].completed) {
        key = sequence[i].key;
        break;
      }
    }
    return key;
  };

  const selectionAwareSteps = () => {
    const curKey = selectedKey || calcCurrentKey();
    for (let i = 0; i < sequence.length; i++) {
      if (curKey === sequence[i].key) sequence[i].isCurrent = true;
    }
    return sequence;
  };

  const calcCompletionRate = () => {
    let total = 0;
    sequence.forEach((step) => {
      if (step.completed) total += 1;
    });
    return total / sequence.length;
  };

  return {
    steps: selectionAwareSteps(),
    completionRate: calcCompletionRate(),
  };
};
