import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import ShopifyConnector from '../components/ShopifyConnector/ShopifyConnector';

export default () => {
  const { teamChannelID } = useParams<{teamChannelID : string}>();
  const location = useLocation();
  const shopifyUrl = location.search.substring(1);
  let { redirectPath } = queryString.parse(window.location.search);
  if (!redirectPath) redirectPath = '/';

  return (
    <ShopifyConnector
      teamChannelId={teamChannelID}
      shopifyUrl={shopifyUrl}
    />
  );
};
