import gql from 'graphql-tag';

const getTeamCourierQuery = gql`
query GetTeamCourier($teamId: String!,$courierId:String!) {
  GetTeamCourier(teamId:$teamId,courierId:$courierId) {
    status,
    teamCourier {
      id,
      teamId,
      courierId,
      configuration,
      active,
      createdById,
      updatedById,
      createdAt,
      updatedAt,
      info,
    },
  }
}
`;

export default getTeamCourierQuery;
