import styled from 'styled-components';
import Button from '@atlaskit/button';

export const DefaultButton = styled(Button)`
  background-color: #89D9FA;
  height: 2.3rem;
  color: black !important;
  border-radius: 5px;
`;

export const PDFButton = styled(Button)`
  background-color: #89D9FA;
  color: black !important;
  border-radius: 5px;
  float: right;
`;

export const Wrapper = styled.div`
  width:100%;
`;

export const BillingB = styled.div`
  margin: auto;
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

export const LeftCard = styled.div`
  width: 45%;
  float: left;
  padding: 0 10px;
  h2 {
    padding: 10px 0;
  }
`;

export const CurrenStatus = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 20px;
`;

export const RightCard = styled.div`
  width: 45%;
  padding: 0 10px;
  h2 {
    padding: 10px 0;
  }
`;

export const InvoiceHistory = styled.div`
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
`;

export const BillInfo = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 16px;
`;

export const LeftWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const LeftColumn = styled.div` 
  p {
    font-size: 1.1em;
    text-align: center;
    color: grey;
  }
`;

export const MidColumn = styled.div`
  p {
    font-size: 1em;
  }
`;

export const RightColumn = styled.div`
  margin-top: 14px;
`;

export const Paid = styled.div`
  background-color: #228B22;
  text-align: center;
  color: white;
`;

export const UnPaid = styled.div`
  background-color: #D22B2B;
  text-align: center;
  color: white;
`;

export default Wrapper;
