import React, { useState } from 'react';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import { SortableHandle } from 'react-sortable-hoc';
import DragHandler from '@atlaskit/icon/glyph/drag-handler';
import Zoom from 'react-medium-image-zoom';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button/standard-button';
import { IImage } from './types';
import ThumbnailContainer from './ThumbnailContainer';
import ThumbnailItem from './ThumbnailItem';
import CenteredOverlay from './CenteredOverlay';
import RemoveBtn from './RemoveBtn';
import ReorderBtn from './ReorderBtn';
import 'react-medium-image-zoom/dist/styles.css';

const DragHandle = SortableHandle(() => (
  <ReorderBtn>
    <DragHandler label="drag handler" />
  </ReorderBtn>
));

export default ({
  image,
  onRemove,
  isDisable,
}: {
  image: IImage;
  // eslint-disable-next-line no-shadow
  onRemove: (image: IImage) => void;
  isDisable: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const removeImgWithDelay = () => {
    closeModal();
    setTimeout(() => {
      onRemove(image);
    }, 50);
  };

  return (
    <>
      <ThumbnailContainer>
        {image.loading === true && (
          <CenteredOverlay>
            <small>Uploading...</small>
          </CenteredOverlay>
        )}
        <Zoom>
          <ThumbnailItem src={image.rawSrc ? image.rawSrc : image.url} />
        </Zoom>
        <DragHandle />
        {isDisable === false && (
          <RemoveBtn
            onClick={openModal}
            data-testid="image-remove-btn"
          >
            <TrashIcon label="trash" />
          </RemoveBtn>
        )}
      </ThumbnailContainer>

      <ModalTransition>
        {isOpen && (
          <Modal
            onClose={closeModal}
          >
            <ModalHeader>
              <ModalTitle>Confirm to remove?</ModalTitle>
            </ModalHeader>
            <ModalBody>
              Are you sure to remove this image?
            </ModalBody>
            <ModalFooter>
              <Button onClick={removeImgWithDelay}>Confirm Delete</Button>
              <Button onClick={closeModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};
