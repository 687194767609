import styled from 'styled-components';

export const Wrapper = styled.div`
  align-items: center;
  justify-content: left;
  display: flex;
  flex-direction: column;
`;

export const FormHeader = styled.div`
  font-size: 1rem;
  font-weight: 500;
  color: #6B778C;
  width: 100%;
  text-align: center;
  margin-bottom:24px;
`;

export const Label = styled.p`
  font-size: 0.857143em;
  font-style: inherit;
  line-height: 1.33333;
  color: rgb(107, 119, 140);
  font-weight: 600;
  display: inline-block;
  margin-bottom: 4px;
  margin-top: 0px;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ErrorMsg = styled.p`
  margin-top:10px;
  color: #bf1650;
  &:before {
    content: "⚠ "
  }
`;

export const MessageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: auto;
`;
