import React, { SyntheticEvent } from 'react';
import Button from '@atlaskit/button';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Pagination from '@atlaskit/pagination';
import { ErrorMessage } from '@atlaskit/form';
import { RowType, HeadType } from '@atlaskit/dynamic-table/types';
import { ApolloError } from 'apollo-client/errors/ApolloError';
import { OptionType, ValueType } from '@atlaskit/select';
import RefreshIcon from '@atlaskit/icon/glyph/refresh';
import Toggle from '@atlaskit/toggle';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import graphQLErrorsReader from '../../../../utils/graphQLErrorsReader';
import PaginationWrapper from '../../styles/PaginationWrapper';
import Wrapper from '../../../ListView/Wrapper';
import AdvancedFilter from '../../widget/AdvancedFilter';
import ColumnOptions from '../../widget/ColumnOptions';
import StatusFilter from '../../widget/StatusFilter';
import KeyWordFilter from '../../widget/SearchBox';
import { defaultColumns } from '../../helper/getHeadAndRow';
import { DEFAULT_STAT } from '../../config/config';
import CreateOrderButton from '../../../CreateOrder/CreateOrderButton';
import formatMoneyWithPrefix from '../../../../helpers/formatMoney';

interface ListViewProps {
  teamID: string,
  // for table
  head: HeadType | undefined,
  rows: Array<RowType>,
  onSort: (data: any) => void, // eslint-disable-line @typescript-eslint/no-explicit-any
  sortKey: string | undefined,
  sortOrder: 'DESC' | 'ASC' | undefined,
  highlightedRowIndex: number | undefined,
  // for UI
  error: ApolloError | undefined
  isLoading: boolean,
  isCompact: boolean,
  // pagination
  pages: number[],
  handlePageChange: (event: SyntheticEvent, newPage: number) => void,
  currentPage: number | undefined,
  // customized columns,
  columns: typeof defaultColumns,
  onColumnTick: (e: React.ChangeEvent<HTMLInputElement>) => void,
  // status
  status: typeof DEFAULT_STAT,
  onStatusClick: (name: string) => void,
  // global text search
  searchInput: string,
  searchNow: () => void,
  onSearchTextChange: (e: SyntheticEvent<HTMLInputElement>) => void,
  handleUserEnterKey: (e: React.KeyboardEvent<HTMLInputElement>) => void,
  // advanced filter show/hide
  showAdvancedFilter: boolean,
  toggleAdvancedSearch: () => void,
  // date filter
  dateSelectedValue: ValueType<OptionType> | undefined,
  handleDatesChange: (option: ValueType<OptionType>) => void,
  // xero filter
  xeroSyncedValue: ValueType<OptionType> | undefined,
  handleXeroSyncedChange: (option: ValueType<OptionType>) => void,
  // channel filter
  teamChannelInput: Array<OptionType> | undefined,
  handleTeamChannelSelect: (options: ValueType<OptionType, true>) => void,
  // advanced filter type
  advancedFilterType: ValueType<OptionType>,
  handleAdvancedFilterTypeChange: (options: ValueType<OptionType>) => void,
  // advanced filter inputs
  advancedFilterInputs: string,
  handleAdvancedFilterTextChange: (val: string) => void,
  // reset advanced filter to default,
  reset: () => void,
  // orders count
  cursorFrom:number,
  cursorTo:number|undefined,
  ordersTotal:number,
  totalValue:number,
  totalOutstanding:number,
  // refetch
  handleRefetch:()=>void,
  // pin overDue order
  shouldPinShipByOrder:boolean,
  handleShouldPinShipByOrder:(value:boolean)=>void,
  // eslint-disable-next-line react/require-default-props
  exportReports?: React.ReactNode,
}

const OrdersListView = (props: ListViewProps) => {
  const { t } = useTranslation();
  const {
    teamID,
    // for table
    head,
    rows,
    onSort,
    sortKey,
    sortOrder,
    highlightedRowIndex,
    // for UI
    error,
    isLoading,
    isCompact,
    // pagination
    pages,
    handlePageChange,
    currentPage,
    // customized columns
    columns,
    onColumnTick,
    // status filter
    status,
    onStatusClick,
    // global text search
    searchInput,
    searchNow,
    onSearchTextChange,
    handleUserEnterKey,
    // advanced filter show/hide
    showAdvancedFilter,
    toggleAdvancedSearch,
    // date filter
    dateSelectedValue,
    handleDatesChange,
    // xero filter
    xeroSyncedValue,
    handleXeroSyncedChange,
    // channel filter
    teamChannelInput,
    handleTeamChannelSelect,
    // advanced search type
    advancedFilterType,
    handleAdvancedFilterTypeChange,
    // advanced search inputs
    advancedFilterInputs,
    handleAdvancedFilterTextChange,
    // reset advanced filters to default
    reset,
    // orders count
    cursorFrom,
    cursorTo,
    ordersTotal,
    totalValue,
    totalOutstanding,
    // refetch
    handleRefetch,
    // pin overDue order
    shouldPinShipByOrder,
    handleShouldPinShipByOrder,
    exportReports,
  } = props;

  const [searchBarWidth, setSearchBarWidth] = React.useState(105);
  const [searchBarPlaceholder, setSearchBarPlaceholder] = React.useState(i18next.t<string>('orders.left.search'));

  const expandSearchBar = () => {
    setSearchBarWidth(300);
    setSearchBarPlaceholder('Search by order number, customer name, email, phone etc.');
  };

  const shrinkSearchBar = () => {
    setSearchBarWidth(105);
    setSearchBarPlaceholder(t('orders.left.search'));
  };
  const searchBaronBlur = () => {
    shrinkSearchBar();
    searchNow();
  };

  const onClickAdvancedSearch = () => {
    /**
     * because when toggleAdvancedSearch will set gloabl
     * search bar to disabled.
     * before doing that, we need firstly remove the forcus of
     * this element in order to triger the onBlur() before
     * it disabled.
     */
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    toggleAdvancedSearch();
  };

  const renderSearchBox = () => (
    <>
      <div className="filters">
        <StatusFilter status={status} onStatusClick={onStatusClick} />
      </div>
      <div className="search-box">
        <KeyWordFilter
          value={searchInput}
          searchNow={searchNow}
          onSearchTextChange={onSearchTextChange}
          onBlur={searchBaronBlur}
          handleUserEnterKey={handleUserEnterKey}
          isDisabled={showAdvancedFilter}
          width={searchBarWidth}
          placeholder={searchBarPlaceholder}
          onFocus={expandSearchBar}
          testId="globalSearchBar"
        />
        <Button
          appearance="link"
          onClick={onClickAdvancedSearch}
          testId="advanced-search-toggle-button"
        >
          {t('orders.left.advanced')}
        </Button>
      </div>
    </>
  );

  const renderAdvancedFilters = () => (
    <div className="advanced-filter">
      <AdvancedFilter
        teamID={teamID}
        values={{
          dateSelectedValue,
          xeroSyncedValue,
          teamChannelInput,
          advancedFilterType,
          advancedFilterInputs,
        }}
        actions={{
          handleDatesChange,
          handleXeroSyncedChange,
          handleTeamChannelSelect,
          handleAdvancedFilterTypeChange,
          handleAdvancedFilterTextChange,
          reset,
        }}
        exportReports={exportReports}
      />
      {}
    </div>
  );

  const renderPagination = () => (
    <div className="count">
      <span>{t('orders.left.records')}</span>
      {ordersTotal > 0
        && (
          <div>
            {cursorFrom}
            -
            {cursorTo}
            {' '}
            of
            {' '}
            {ordersTotal}
          </div>
        )}
      <Button
        appearance="subtle-link"
        iconBefore={<RefreshIcon label="refresh" size="small" />}
        spacing="none"
        onClick={handleRefetch}
      />
      <div className="totalAmt summary">
        <span>{t('orders.left.totalAmt')}</span>
        {' '}
        {formatMoneyWithPrefix(totalValue)}
      </div>
      <div className="totalDue summary">
        <span>{t('orders.left.totalDue')}</span>
        {' '}
        {formatMoneyWithPrefix(totalOutstanding)}
      </div>
    </div>
  );

  const renderPinShipByToggle = () => (
    <div className="pinShipByDate">
      <label htmlFor="pinShipByDate">
        {t('orders.left.pin')}
        <Toggle
          id="pinShipByDate"
          onChange={() => handleShouldPinShipByOrder(!shouldPinShipByOrder)}
          isChecked={shouldPinShipByOrder}
          size="regular"
        />
      </label>
    </div>
  );

  return (
    <Wrapper data-testid="orderListView" style={{ backgroundColor: '#ffffff' }}>
      <div className="heading">
        <h2>{t('orders.left.orders')}</h2>
        <CreateOrderButton />
      </div>
      <div className="search-filter-wrapper">
        {renderSearchBox()}
      </div>
      { showAdvancedFilter && renderAdvancedFilters() }
      <>
        <div className="table-controls">
          <div className="pagination">
            {renderPinShipByToggle()}
            {renderPagination()}
          </div>
          <div className="column-options">
            {!isCompact && (
            <ColumnOptions columns={columns} onColumnTick={onColumnTick} />
            )}
          </div>
        </div>
        <DynamicTableStateless
      // isFixedSize
          head={head}
          rows={rows}
          loadingSpinnerSize="large"
          isLoading={isLoading}
          testId="order-listing-table"
          onSort={onSort}
          sortKey={sortKey}
          sortOrder={sortOrder}
          emptyView={<div>No Match</div>}
          highlightedRowIndex={highlightedRowIndex}
        />

      </>
      {error
        && (
          <ErrorMessage>
            {graphQLErrorsReader(error)}
          </ErrorMessage>
        )}

      <PaginationWrapper data-testid="pagination">
        {(!isLoading && rows.length > 0)
          && (
            <Pagination
              pages={pages}
              onChange={handlePageChange}
              selectedIndex={currentPage}
            />
          )}
      </PaginationWrapper>

    </Wrapper>
  );
};

export default OrdersListView;
