import React, { useState, useEffect } from 'react';
import Textfield from '@atlaskit/textfield';
import { isNaN } from 'lodash';

interface Props {
  price:number | string,
  onChange:(newUnitPrice:number|string)=>void,
  testid?:string,
  isDisabled?:boolean,

}

const PriceEditor = ({
  price, onChange, testid, isDisabled = false,
}:Props) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    setIsInvalid(false);
    const input = event.currentTarget.value;
    onChange(input);
  };

  useEffect(() => {
    if (isNaN(Number(price))) {
      setIsInvalid(true);
    }

    if (!isNaN(Number(price)) && Number(price) < 0) {
      setIsInvalid(true);
    }
  }, [price]);

  return (
    <Textfield
      value={price}
      onChange={handleOnChange}
      isInvalid={isInvalid}
      width={100}
      testId={testid}
      isDisabled={isDisabled}
    />
  );
};

PriceEditor.defaultProps = {
  testid: undefined,
  isDisabled: false,
};

export default PriceEditor;
