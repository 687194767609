import React from 'react';
import OrdersListing from '../components/OrdersListing/OrdersListing';
import { useAuth } from '../utils/useAuth';

export default function Orders() {
  const { state: { currentTeamId: teamID } } = useAuth();

  return (
    <>
      <OrdersListing teamID={teamID!} />
    </>
  );
}
