import gql from 'graphql-tag';

const shipmentFragment = gql`
fragment shipmentFragment on Shipment {
  id,
  active,
  orderId,
  trackNumber,
  courierId,
  courier{
    id,
    name,
  },
  noOfBoxes,
  totalCost,
  totalWeight,
  status,
  externalId,
  labelUrl,
  externalRes,
  createdAt,
  updatedAt,    
}
`;

export default shipmentFragment;
