import styled from 'styled-components';

const MarketplaceStatusContainer = styled.div`
  display:flex;  
  .logo {
      max-height:12px;      
      margin-right:5px;
    };
`;

export default MarketplaceStatusContainer;
