import React from 'react';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import JiraFailedBuildStatusIcon from '@atlaskit/icon/glyph/jira/failed-build-status';
import { isUndefined } from 'lodash';
import { CommonProps } from '../types';
import { FlexBox, ValueBefore, ValueAfter } from '../styles';

const QtyChange = (
  { lineItem }:CommonProps,
) => {
  const newQty = lineItem.response?.AdjustInventory.product?.stockOnHand;
  const oldQty = lineItem.response?.AdjustInventory.sohBeforeMutation;

  if (isUndefined(newQty) || isUndefined(oldQty)) {
    return (
      <FlexBox>
        <JiraFailedBuildStatusIcon label="qty update failed" primaryColor="red" testId="errorIcon" />
      </FlexBox>
    );
  }

  return (
    <FlexBox>
      <ValueBefore data-testid="valueBefore">{oldQty}</ValueBefore>
      <ArrowRightIcon label="changed to" primaryColor="green" />
      <ValueAfter data-testid="valueAfter">{newQty}</ValueAfter>
    </FlexBox>
  );
};

export default QtyChange;
