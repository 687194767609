import styled from 'styled-components';

const ColumnsOptionsContainer = styled.div`
  display: flex;
  div {    
    h5 {
      font-size:12px;
      font-weight:normal;
      padding:5px;
      margin-right:10px;
      color: rgb(23, 43, 77);
      border-bottom:1px solid #ccc;
     }
    width:50%;
  }
  
`;

export default ColumnsOptionsContainer;
