import styled from 'styled-components';

const EbayConfigorContainier = styled.div`
  margin-bottom: 2em;
  min-width: 300px;
  max-width: 350px;
  text-align: left;
  margin:auto;
  display:block;
  padding:1rem;
`;

export default EbayConfigorContainier;
