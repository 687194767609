import React, { useEffect, SyntheticEvent } from 'react';
import { DynamicTableStateless } from '@atlaskit/dynamic-table';
import Pagination from '@atlaskit/pagination';
import { ErrorMessage } from '@atlaskit/form';
import Spinner from '@atlaskit/spinner';
import { get } from 'lodash';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useOrdersByCustomerQuery } from '../../graphql/types';
import head from './head';
import { ObcOrder } from './types';
import { OrdersListingContainer, PaginationWrapper } from './OrderByCustomerListing.styles';
import formattedDate from '../../utils/formattedDate';
import formatMoneyWithPrefix from '../../helpers/formatMoney';

interface OrdersListingProps {
  teamId:string,
  customerId: string,
}

const PAGE_SIZE = 5;

export default (props: OrdersListingProps) => {
  const { teamId, customerId } = props;
  const [skip, setSkip] = React.useState<number>(0);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pages, setPages] = React.useState<number[]>([]);
  const [ordersTotal, setOrdersTotal] = React.useState(0);
  useEffect(() => {
    const totalPages = [];
    for (let index = 1; index <= Math.ceil(ordersTotal / PAGE_SIZE); index++) {
      totalPages.push(index);
    }
    setPages(totalPages);
  }, [ordersTotal]);
  const {
    data, loading, error,
  } = useOrdersByCustomerQuery({
    variables: {
      teamID: teamId,
      customerId,
      skip,
      take: PAGE_SIZE,
    },
  });
  if (loading) {
    return (<Spinner />);
  }
  if (error) {
    return <h1>Fetching orders failed</h1>;
  }
  const orders = get(data, 'OrdersByCustomer.orders', []);
  const total = get(data, 'OrdersByCustomer.total', 0);
  if (total !== ordersTotal) {
    setOrdersTotal(total);
  }
  const handlePageChange = async (event: SyntheticEvent, newPage: number) => {
    setSkip((newPage - 1) * PAGE_SIZE);
    setCurrentPage(newPage - 1);
  };
  const rows = orders.map(
    (order: ObcOrder, idx: number) => {
      const {
        orderNumber,
        status,
        createdAt,
        updatedAt,
        totalAmtInclTax,
      } = order;
      const formattedCreatedAt = formattedDate(createdAt);
      const formattedUpdateAt = formattedDate(updatedAt);
      return {
        key: `row-${idx}-order`,
        cells: [
          {
            key: 'orderNumber',
            content: orderNumber,
          },
          {
            key: 'createdAt',
            content: formattedCreatedAt,
          },
          {
            key: 'updatedAt',
            content: formattedUpdateAt,
          },
          {
            key: 'total',
            content: formatMoneyWithPrefix(totalAmtInclTax),
          },
          {
            key: 'status',
            content: status.name,
          },
        ],
      };
    },
  );
  const OrderListHeading = () => (
    <div>
      <div>Orders</div>
    </div>
  );
  return (
    <OrdersListingContainer>
      <DynamicTableStateless
        caption={<OrderListHeading />}
        head={head}
        rows={rows}
        loadingSpinnerSize="large"
        isLoading={loading}
        isFixedSize
        testId="customer-orders-listing-table"
      />
      <PaginationWrapper data-testid="pagination">
        <Pagination
          pages={pages}
          onChange={handlePageChange}
          selectedIndex={currentPage}
        />
      </PaginationWrapper>
      {error
        && (
        <ErrorMessage>
            {graphQLErrorsReader(error)}
        </ErrorMessage>
        )}
    </OrdersListingContainer>
  );
};
