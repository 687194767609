import React from 'react';
import MerpAvatar from '../../MerpAvatar/MerpAvatar';

const DefaultAvatar = () => (
  <MerpAvatar
    firstName="M"
    lastName="P"
    size={32}
    color="#83d9fb"
  />
);
export default DefaultAvatar;
