import styled from 'styled-components';

const ThumbnailItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  &:hover {
    opacity: 1;
  }
`;

export default ThumbnailItem;
