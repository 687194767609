import _ from 'lodash';
import React from 'react';
import { Field } from '@atlaskit/form';
import Select from '@atlaskit/select';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Wrapper } from './LanguageEditor.style';
import Box from '../Box';
// import { useAuth } from '../../utils/useAuth';
import { OptionType } from './types';
import { ValueType } from '../../../node_modules/@types/react-select/src/types.d';
import { useAuth } from '../../utils/useAuth';
import { setStorageUser } from '../../utils/storages';
// import addNotification from '../../utils/addNotification';

const LanguageEditor = () => {
  const { state: { user }, updateProfile } = useAuth();
  const { t } = useTranslation();
  const handleValueChange = async (
    selected: ValueType<OptionType, boolean>,
    fn: (value: string) => void,
  ) => {
    fn(_.get(selected, 'value', ''));
    const language = _.get(selected, 'value', '');
    updateProfile({
      language,
    });

    user!.language = language;
    setStorageUser(user!);
    localStorage.setItem('lng', language);
    window.location.reload();
    // i18next.changeLanguage(language);
  };

  // const { state: { user } } = useAuth();
  // const firstName = get(user, 'firstName', '');
  const options = [{
    label: i18next.t(t('nav.profile.language.select.english')),
    value: 'en',
  }, {
    label: i18next.t(t('nav.profile.language.select.chinese')),
    value: 'zh',
  }];
  // const lng = i18next.language;
  const lng = user ? user.language : i18next.language;
  let defaultValue = options[0];
  for (let i = 0; i < options.length; i += 1) {
    if (options[i].value === lng) {
      defaultValue = options[i];
    }
  }

  return (
    <Wrapper data-testid="language-editor-wrapper">
      <Box style={{ minWidth: 350 }}>
        <Field
          name="languageSelect"
          label={t('nav.profile.language.field.value')}
          defaultValue=""
          isRequired
        >
          {({ fieldProps }) => (
            <>
              <Select<OptionType>
                options={options}
                defaultValue={defaultValue}
                // isLoading={fulfilmentsState.loading}
                placeholder="Choose language"
                onChange={(selected) => (
                  handleValueChange(
                    selected,
                    fieldProps.onChange,
                  )
                )}
              />

            </>
          )}
        </Field>
      </Box>
    </Wrapper>
  );
};

export default LanguageEditor;
