import React, {
  useReducer, useContext, createContext, useEffect,
} from 'react';
import TicketsListing from './TicketsListing';
import SplitView from '../SplitView/SplitView';
import TicketDetail from './TicketDetail';
import { Ticket } from './types';

interface InputProps {
  teamID: string,
}

interface TicketState {
  searchKey: string,
  ticket: Ticket | null,
  status: string,
}

interface TicketAction {
  type: 'setKeyword' | 'setTicket' | 'closeDetail' | 'setStatus' | null,
  // eslint-disable-next-line
  payload: any,
}

interface TicketContextType {
  state: TicketState,
  dispatch: React.Dispatch<TicketAction>,
}

const TicketContext = createContext<TicketContextType>(
  {} as TicketContextType,
);

const TicketsContainer = (props: InputProps) => {
  const { teamID } = props;
  const initialState: TicketState = {
    searchKey: '',
    ticket: null,
    status: '',
  };

  const reducer = (
    state: TicketState,
    action:TicketAction,
  ) => {
    switch (action.type) {
      case 'setKeyword':
        return {
          ...state,
          searchKey: action.payload,
        };
      case 'setTicket':
        return {
          ...state,
          ticket: action.payload,
        };
      case 'closeDetail':
        return {
          ...state,
          ticket: action.payload,
        };
      case 'setStatus':
        return {
          ...state,
          status: action.payload,
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { ticket } = state;
  const ticketId = ticket?.id;
  useEffect(() => {
    const onESC = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        dispatch({
          type: 'closeDetail',
          payload: null,
        });
      }
    };
    window.addEventListener('keydown', onESC);
    return () => {
      window.removeEventListener('keydown', onESC);
    };
  }, []);
  return (
    <TicketContext.Provider value={{ state, dispatch }}>
      <SplitView
        left={<TicketsListing teamID={teamID} />}
        right={
            ticketId
              ? <TicketDetail ticketId={ticketId} />
              : null
        }
      />
    </TicketContext.Provider>
  );
};

export default TicketsContainer;

export const useTicket = () => useContext(TicketContext);
