import React, { useState, useRef } from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter, ModalFooterProps,
  ModalBody,
} from '@atlaskit/modal-dialog';
import Button from '@atlaskit/button';
import TextField from '@atlaskit/textfield';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import SuggestCategoriesByKeyword from './SuggestCategoriesByKeyword';
import SearchCategoryContainer from './styles/SearchCategoryContainer';
import FlexSpaceBetweenContainer from './styles/FlexSpaceBetweenContainer';
import { useSubscription } from '../../utils/useAuth';

const SuggestCategoriesBtn = ({
  onCategoryChosen, text, appearance,
}: {
  onCategoryChosen: Function,
  text : JSX.Element,
  appearance: 'default' | 'link' | 'danger' | 'primary' | 'subtle' | 'subtle-link' | 'warning' | undefined,
}) => {
  const { t } = useTranslation();
  const isDisabled = useSubscription();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keyword, setKeyword] = useState('');
  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const chosenCategoryCallback = (chosenCategoryId: string) => {
    onCategoryChosen(chosenCategoryId);
    setIsModalOpen(false);
  };

  const debounceHandleSearch = debounce((val: string) => {
    setKeyword(val);
  }, 500);

  const CustomFooter = (props: ModalFooterProps) => (
    <ModalFooter {...props}>
      <span />
      <Button onClick={() => { setIsModalOpen(false); }}>
        {t('products.product.pcm.close')}
      </Button>
    </ModalFooter>
  );

  const prefix = 'products.product.productCatagories';

  return (
    <>
      <Button
        appearance={appearance}
        onClick={() => setIsModalOpen(true)}
        testId="ProductCatagoriesBtn"
        isDisabled={isDisabled}
      >
        {t(prefix)}
        {text}
      </Button>
      <ModalTransition>
        {isModalOpen && (
          <Modal
            onClose={() => setIsModalOpen(false)}
            width="large"
          >
            <ModalHeader>
              <ModalTitle>{t('products.product.pcm.sac')}</ModalTitle>
            </ModalHeader>
            <form onSubmit={(e) => {
              e.preventDefault();
              setKeyword(inputRef.current.value);
            }}
            >
              <ModalBody>
                <FlexSpaceBetweenContainer>
                  <TextField
                    ref={inputRef}
                    name="keyword"
                    placeholder={t('products.product.pcm.placeholder')}
                    onChange={
                    (evt) => debounceHandleSearch(evt.currentTarget.value)
                  }
                  />
                  <Button
                    style={{ height: 40 }}
                    appearance="primary"
                    type="submit"
                  >
                    {t('products.product.pcm.search')}
                  </Button>
                </FlexSpaceBetweenContainer>
              </ModalBody>
            </form>
            <SearchCategoryContainer>
              <SuggestCategoriesByKeyword
                onCategoryChosen={chosenCategoryCallback}
                keyword={keyword}
              />
              {/* {keyword.trim() !== '' && channelId === WOOCOMMERCE_CHANNEL_ID && (
                <SuggestTypesByKeyword onCategoryChosen={chosenCategoryCallback} keyword={keyword} teamId={teamId} />
              )} */}
            </SearchCategoryContainer>
            <CustomFooter />
          </Modal>
        )}
      </ModalTransition>
    </>
  );
};

export default SuggestCategoriesBtn;
