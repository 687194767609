import { cloneDeep } from 'lodash';
import { ProductsAdvancedFilterInput } from './index';
import { NumberCondition, StringCondition, AvailableFilters } from './constants';

export const getAdvancedFilterPayload = (
  currentFilterArray: ProductsAdvancedFilterInput[],
  newFilter: ProductsAdvancedFilterInput,
) => {
  const existingQueryIndex = currentFilterArray.findIndex(
    (cur) => cur.filterKey === newFilter.filterKey,
  );

  if (existingQueryIndex === -1 && !newFilter.filterValue) {
    return currentFilterArray;
  }

  // append new filter to current filters if not exist
  if (existingQueryIndex === -1 && newFilter.filterValue) {
    return [
      ...currentFilterArray,
      newFilter,
    ];
  }
  // remove filter from current filters if given value is empty
  if (existingQueryIndex !== -1 && !newFilter.filterValue) {
    const removedFilterArray = cloneDeep(currentFilterArray);
    removedFilterArray.splice(existingQueryIndex, 1);
    return removedFilterArray;
  }

  // update current filters if the key of the new filter exist
  const updatedFilterArray = cloneDeep(currentFilterArray);
  updatedFilterArray.splice(existingQueryIndex, 1, newFilter);
  return updatedFilterArray;
};

interface Table {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k:string]:any;
}
export const lookupName = (
  key:string,
) => {
  const table: Table = {};
  const filterNames = AvailableFilters.map(
    (e) => ({ value: e.key, label: e.displayName }),
  );
  [
    ...NumberCondition,
    ...StringCondition,
    ...filterNames,
  ].forEach((e) => {
    table[e.value] = e.label;
  });

  if (table[key]) return table[key];

  return key;
};
