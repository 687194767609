import React from 'react';
import { useFlags } from '@atlaskit/flag';
import { Y200 } from '@atlaskit/theme/colors';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import NotificationProps from './types';

const useAddFlagForEbayBadTokenError = (notifications:NotificationProps[]) => {
  const { showFlag } = useFlags();
  const history = useHistory();

  const eBayTokenRelatedNotifications = notifications.filter((cur) => cur.subType === 'ebay-refresh-token-failed');

  if (eBayTokenRelatedNotifications.length > 0) {
    eBayTokenRelatedNotifications.forEach((cur) => {
      const {
        id,
        teamChannelID,
      } = cur;

      let flaged:Array<string>;
      try {
        const flagedLocalStorage = window.localStorage.getItem('flaged');
        if (isNull(flagedLocalStorage)) throw new Error('');
        flaged = JSON.parse(flagedLocalStorage);
      } catch (error) {
        flaged = [];
      }
      if (!flaged.includes((id || ''))) {
        showFlag({
          id,
          appearance: 'normal',
          icon: <WarningIcon label="Warning icon" primaryColor={Y200} />,
          title: 'eBay Conenction Error',
          description: 'One of your eBay store lost connection',
          isAutoDismiss: true,
          actions: [{
            content: 'Fix',
            onClick: () => history.push(`/notification/connection/error/${teamChannelID}`),
          }],
        });

        window.localStorage.setItem('flaged', JSON.stringify([
          ...flaged,
          id,
        ]));
      }
    });
  }
};

export default useAddFlagForEbayBadTokenError;
