import { ErrorMessage } from '@atlaskit/form';
import Spinner from '@atlaskit/spinner';
import React from 'react';
import PresenceActiveIcon from '@atlaskit/icon/glyph/presence-active';
import TrashIcon from '@atlaskit/icon/glyph/trash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useGetTcssByTeamIdQuery, useDeactivateTeamChannelSupplierMutation } from '../../graphql/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useAuth, useSubscription } from '../../utils/useAuth';
import { Card, Container } from '../TeamChannelListing/TeamChannelListing.style';
import SupplierCreator from './SupplierCreator';
import synnex from '../../assets/png/Synnex.webp';
import addNotification from '../../utils/addNotification';

const RemoveBtn = styled.div`
  color: #737373;
  margin-left: 120px;
  background: white;
  display: flex;
`;

export default () => {
  const { t } = useTranslation();
  const { state: { currentTeamId } } = useAuth();
  const isDisabled = useSubscription();
  const {
    data, loading, error,
  } = useGetTcssByTeamIdQuery({
    variables: {
      teamId: currentTeamId!,
    },
    fetchPolicy: 'network-only',
  });

  const teamSupplierChannels = data?.GetTcssByTeamId.teamChannelSuppliers;
  const [deleteSupplier] = useDeactivateTeamChannelSupplierMutation();
  const removeSupplier = (teamChannelSupplierId: string) => {
    deleteSupplier({ variables: { teamChannelSupplierId } })
      .then(() => {
        addNotification('Remove supplier successfully', 'success');
      })
      .catch(() => {
        addNotification('Remove supplier failed', 'danger');
      });
  };
  return (
    <>
      <div className="heading">
        <h2>{t('channels.supplier')}</h2>
      </div>
      {
        loading && (
        <Container>
          <Spinner size="medium" />
        </Container>
        )
      }
      {
        error && (
          <Container>
            <ErrorMessage>{graphQLErrorsReader(error)}</ErrorMessage>
          </Container>
        )
      }
      {
        !loading && !error && (
          <Container>
            {teamSupplierChannels!.map((supplier) => (
              <Card data-testid={`${supplier.name.replace(' ', '-')}-card`}>
                <div className="head">
                  <img className="logo" src={synnex} alt="in-store" data-testid="synnex" />
                  <RemoveBtn
                    onClick={() => (isDisabled === false ? removeSupplier(supplier.id) : null)}
                    data-testid="image-remove-btn"
                  >
                    <TrashIcon label="trash" primaryColor={isDisabled === false ? '#0052CC' : 'grey'} />
                  </RemoveBtn>

                </div>

                <div className="state">
                  <div className="state-item feedback">
                    <div className="state-item-name">In use</div>
                    <div className="state-item-value">
                      <PresenceActiveIcon label="status" primaryColor="green" />
                    </div>
                  </div>
                </div>
              </Card>
            ))}
            <Card>
              <SupplierCreator isDisabled={isDisabled} />
            </Card>

          </Container>
        )
      }
    </>
  );
};
