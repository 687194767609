import React from 'react';
import styled from 'styled-components';
import { TeamCustomer } from '../../types';

const FindCustomerPreview = styled.div`
    margin-top:15px;
    table {
      width:100%;
      tr{
        td{
          vertical-align:top;
          width:50%;
          overflow-wrap:anywhere;
          >:nth-child(1) {
            font-size:11px;
            color:rgb(107, 119, 140);
          }
          >:nth-child(2) {
            color: rgb(23, 43, 77);
          }
        }
      }      
    }
`;

interface Props {
  teamCustomer:TeamCustomer | undefined
}
export default ({ teamCustomer }:Props) => {
  if (!teamCustomer) {
    return (
      <></>
    );
  }

  const {
    name,
    email,
    company,
    mobile,
    phone,
    shippingAddresses,
  } = teamCustomer;

  const [shippingAddress] = shippingAddresses;
  const { address } = shippingAddress;

  return (
    <FindCustomerPreview>
      <h5>{name}</h5>
      <table>
        <tbody>
          <tr>
            <td>
              <div>Email Address</div>
              <div>{email || 'N/A'}</div>
            </td>
            <td>
              <div>Company</div>
              <div>{company || 'N/A' }</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>Contact Number</div>
              <div>{mobile || phone}</div>
            </td>
            <td>
              <div>Shipping Address</div>
              <div>{`${address.street} ${address.suburb} ${address.postcode} ${address.state}`}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </FindCustomerPreview>
  );
};
