import React from 'react';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalBody,
} from '@atlaskit/modal-dialog';
import { SuccessProgressBar } from '@atlaskit/progress-bar';
import type { ProgressPayload } from './types';
import {
  ProgressContainer,
  ProgressDescriptionGroup,
  ProgressDescriptionItem,
  QueueText,
} from './styles';
import ProductTitle from '../ProductTitle/ProductTitle';
import formatMoneyWithPrefix from '../../helpers/formatMoney';

interface SubmitProgressProps {
  isOpen:boolean,
  payload:ProgressPayload,
}
export default ({ isOpen, payload }:SubmitProgressProps) => {
  const { currentLineItem, currentPosition, totalCount } = payload;

  return (
    <ModalTransition>
      {isOpen && (
        <Modal
          width="small"
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEscapePress={false}
        >
          <ModalHeader>
            <ModalTitle>Stocking in</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <ProgressContainer>
              <ProgressDescriptionGroup>
                <ProductTitle product={currentLineItem.product} />
                <ProgressDescriptionItem>
                  <div>Purchase Qty: </div>
                  <div>{currentLineItem.purchaseQty}</div>
                </ProgressDescriptionItem>
                <ProgressDescriptionItem>
                  <div>Purchase cost: </div>
                  <div>
                    {formatMoneyWithPrefix(
                      Number(currentLineItem.unitCostEx) * 100,
                    )}
                  </div>
                </ProgressDescriptionItem>
              </ProgressDescriptionGroup>

              <QueueText>
                {`Queue:${currentPosition} / ${totalCount}`}
              </QueueText>

              <SuccessProgressBar
                value={currentPosition / totalCount}
              />

            </ProgressContainer>
          </ModalBody>
        </Modal>
      )}
    </ModalTransition>
  );
};
