import moment from 'moment';
import { Order } from '../OrdersListing/types';

// eslint-disable-next-line  import/prefer-default-export
export const isPostageNearDue = (
  order: Order,
) => {
  const { shipByDate } = order;

  if (!shipByDate) return false;

  if (
    ['PAID', 'PACKED'].includes(order.status.code)
    // within 48 hours or greater then now
    && moment(shipByDate).subtract(48, 'hours').isBefore(moment().utc())
  ) {
    return true;
  }

  return false;
};
