import React, { useState } from 'react';
import { InlineEditableTextfield } from '@atlaskit/inline-edit';
import ErrorIcon from '@atlaskit/icon/glyph/error';
import { R400 } from '@atlaskit/theme/colors';
import Flag from '@atlaskit/flag';
import {
  TicketInfoInput,
  TicketInfoItem,
  TicketInfoLabel,
  TicketInfoCol,
  MessageWrapper,
  TicketInfoSummaryContainer,
} from './TicketSummaryInfo.styles';
import { Ticket } from '../TicketsContainer/types';
import { useUpdateTicketMutation } from '../../graphql/types';
import graphQLErrorsReader from '../../utils/graphQLErrorsReader';
import { useTicket } from '../TicketsContainer/TicketsContainer';

interface TicketSummaryInfoProps {
  ticket: Ticket | null,
  teamID: string,
}
interface ValueProps {
  channel?: string,
  priority?: string,
}

interface MutationVariables {
  teamID: string,
  ticketId: string,
  channel?: string,
  priority?: string,
}

export default function TicketSummaryInfo(props: TicketSummaryInfoProps) {
  const { ticket, teamID } = props;
  const { state, dispatch } = useTicket();
  const [errorMsg, setErrorMsg] = useState('');
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [updateTicketMutation] = useUpdateTicketMutation();
  if (!ticket) {
    return null;
  }
  const updateTicketInfo = (values: ValueProps) => {
    const { channel, priority } = values;
    const variables: MutationVariables = {
      teamID,
      ticketId: ticket.id,
    };
    const payload: { channel?: string, priority?: string} = {};
    if (channel) {
      variables.channel = channel;
      payload.channel = channel;
    }
    if (priority) {
      variables.priority = priority;
      payload.priority = priority;
    }
    updateTicketMutation({
      variables,
    }).then(() => {
      dispatch({
        type: 'setTicket',
        payload: {
          ...state.ticket,
          ...payload,
        },
      });
    }).catch((err) => {
      setShowMessage(true);
      const msg = graphQLErrorsReader(err) || 'Update failed';
      setErrorMsg(msg);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    });
  };
  const {
    requester,
    channel,
    priority,
  } = ticket;
  return (
    <TicketInfoSummaryContainer>
      <TicketInfoCol>
        <TicketInfoItem>
          <TicketInfoLabel>Channel</TicketInfoLabel>
          <TicketInfoInput>
            <InlineEditableTextfield
              defaultValue={channel}
              readViewFitContainerWidth
              onConfirm={(value) => updateTicketInfo({ channel: value })}
              placeholder=""
            />
          </TicketInfoInput>
        </TicketInfoItem>
        <TicketInfoItem>
          <TicketInfoLabel>Requester</TicketInfoLabel>
          <div>{requester}</div>
        </TicketInfoItem>
      </TicketInfoCol>
      <TicketInfoCol>
        <TicketInfoItem>
          <TicketInfoLabel>Priority</TicketInfoLabel>
          <TicketInfoInput>
            <InlineEditableTextfield
              defaultValue={priority === '-' ? '' : priority}
              readViewFitContainerWidth
              onConfirm={(value) => updateTicketInfo({ priority: value })}
              placeholder=""
            />
          </TicketInfoInput>
        </TicketInfoItem>
      </TicketInfoCol>
      {showMessage
          && (
            <MessageWrapper>
              <Flag
                appearance="error"
                icon={<ErrorIcon secondaryColor={R400} label="error" />}
                id="error"
                key="error"
                title={errorMsg}
              />
            </MessageWrapper>
          )}
    </TicketInfoSummaryContainer>
  );
}
