import React, {
  // useEffect,
  useState,
} from 'react';
import * as QueryString from 'query-string';
import { useHistory } from 'react-router-dom';
import Modal, {
  ModalTransition, ModalHeader, ModalTitle, ModalFooter,
  ModalBody,
} from '@atlaskit/modal-dialog';
import { useTranslation } from 'react-i18next';
import Button from '@atlaskit/button';
import { useAuth } from '../utils/useAuth';
import SummarySection from '../components/Report/SummarySection';
import RefreshTokenExpireAlert from '../components/RefreshTokenExpireAlert/RefreshTokenExpireAlert';
import TopSkuSalesChart from '../components/Report/TopSkuSalesChart';
import TopCustomerSalesChart from '../components/Report/TopCustomerSalesChart';
import Wrapper from '../components/ListView/Wrapper';

export interface EbayOauthParam {
  teamChannelId: string,
  redirectPath: string, // eg. /config/ebay/xxxxxx
}

export interface WooCommerceOauthParam {
  teamChannelId: string,
  wooUrl: string,
}

export interface ShopifyOauthParam {
  teamChannelId: string,
  shopifyUrl: string,
}

const Home = (props: { location: { search: string } }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { state: { currentTeamId } } = useAuth();
  const { location } = props;
  const { search } = location;
  const params = QueryString.parse(search);
  const { errMsg } = params;
  const [isOpen, setIsOpen] = useState(!!errMsg || false);
  const close = () => {
    setIsOpen(false);
  };
  let state: EbayOauthParam;
  try {
    state = JSON.parse(params.state as string);
    if (state.redirectPath && state.redirectPath.trim() !== '') {
      history.push(`${state.redirectPath}?link_state=${params.link_state}`);
    }
  } catch (error) {
    state = {
      teamChannelId: 'undefined',
      redirectPath: '/',
    };
  }

  return (
    <Wrapper>
      <ModalTransition>
        {isOpen && (
          <Modal>
            <ModalHeader>
              <ModalTitle>Store Connect</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <p>{errMsg}</p>
            </ModalBody>
            <ModalFooter>
              <Button onClick={close}>Close</Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
      <RefreshTokenExpireAlert />
      <div className="heading">
        <h2>{t('home.dashboard')}</h2>
      </div>
      <div style={{ maxWidth: 1024 }}>
        {currentTeamId && <SummarySection teamId={currentTeamId} />}
      </div>
      <div style={{
        maxWidth:
          1580, // width
        marginTop: 30,
      }}
      >
        {currentTeamId && <TopSkuSalesChart teamId={currentTeamId} />}
        {currentTeamId && <TopCustomerSalesChart teamId={currentTeamId} />}
      </div>
    </Wrapper>
  );
};

export default Home;
